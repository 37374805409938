import { createNativeStackNavigator } from '@react-navigation/native-stack';
import PartsListScreen from './PartsList';
import PartManageScreen from './PartsManage';


const Stack = createNativeStackNavigator();

export default function BreakdownParts({ navigation }: { navigation: any }) {
  return (
    <Stack.Navigator>
      <Stack.Screen name="list" component={PartsListScreen} options={{ headerShown: false, title: 'Parts'  }} />
      <Stack.Screen name="manage" component={PartManageScreen} options={{ headerShown: false, title: 'Parts Manage'}}  />
    </Stack.Navigator>
  )
};