import React, { useEffect, useState, useCallback } from 'react';
import { View, Text, StyleSheet, Dimensions, ScrollView, TouchableOpacity, Alert } from 'react-native';
import { TitleBar } from "@app/components/TitleBar";
import { useGetEquipmentInspectionsQuery } from "@store/services/api/equipment-api";
import { EquipmentDatabase } from "@store/services/database/equipment-database";
import { LoadingIndicator } from "@web/components/navigation/controls/LoadingIndicator";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { ReportableService } from '@store/services/reportable/reportable.service';
import * as Sharing from 'expo-sharing';
import * as FileSystem from 'expo-file-system';
import { inspectionApi } from '@store/services/api/inspection-api';
import ImageViewer from '@app/components/modals/ImageViewer';

const screenWidth = Dimensions.get("screen").width;
const isMobileScreen = screenWidth < 480;

const EquipmentViewInspectionsScreen = ({ navigation, route }) => {
  const { equipmentId } = route.params;
  const { data: inspectionsData, error: inspectionsError, isLoading: inspectionsLoading, refetch } = useGetEquipmentInspectionsQuery(equipmentId);
  const [getInspectionImages] = inspectionApi.useLazyGetInspectionImagesQuery();

  const [equipment, setEquipment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageViewerVisible, setImageViewerVisible] = useState(false);

  useEffect(() => {
    const fetchEquipment = async () => {
      try {
        const equipmentDb = new EquipmentDatabase();
        const equipmentData = await equipmentDb.getById(equipmentId);
        setEquipment(equipmentData);
      } catch (err) {
        console.error("Error fetching equipment:", err);
        Alert.alert("Error", "Failed to fetch equipment details");

      } finally {
        setLoading(false);
      }
    };

    fetchEquipment();
  }, [equipmentId]);

  const renderInspectionStatus = useCallback((inspection) => {
    if (inspection.missing) {
      return <MaterialCommunityIcons name="progress-question" size={62} color="#9575CD" />;
    } else if (inspection.safeForUse && (inspection.defects == null || inspection.defects == "" || inspection.defects.toLowerCase() == "none")) {
      return <MaterialCommunityIcons name="check-decagram" size={62} color="#4CAF50" />;
    } else if (inspection.safeForUse && (inspection.defects != null && inspection.defects != "" && inspection.defects.toLowerCase() != "none")) {
      return <MaterialCommunityIcons name="check-decagram-outline" size={62} color="#4CAF50" />;
    } else {
      return <MaterialCommunityIcons name="cancel" size={62} color="#E57373" />;
    }
  }, []);

  const handleViewReport = useCallback(async (inspection) => {
    setLoading(true);
    try {
      const reportableService = new ReportableService();
      let data;
      switch (inspection.masterCategoryName) {
        case "PPE":
          data = await reportableService.downloadPPE(inspection.id, true);
          break;
        case "Marine 2006":
          data = await reportableService.downloadMarine(inspection.id, true);
          break;
        case "PUWER 1998":
          data = await reportableService.downloadMachine(inspection.id, true);
          break;
        default:
          data = await reportableService.downloadRote(inspection.id, true);
      }

      if (!data) throw new Error("Error downloading report");

      const fileUri = FileSystem.documentDirectory + data.filename;
      await FileSystem.writeAsStringAsync(fileUri, data.data, { encoding: FileSystem.EncodingType.Base64 });

      const isSharingAvailable = await Sharing.isAvailableAsync();
      
      if (isSharingAvailable) {
        await Sharing.shareAsync(fileUri, { UTI: '.pdf', mimeType: 'application/pdf' });
      } else {
        Alert.alert("Error", "Sharing is not available on this device");
      }
    } catch (ex) {
      Alert.alert("Error", "Error downloading report");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleViewImages = useCallback(async (inspection) => {
    setLoading(true);
    try {
      const response = await getInspectionImages(inspection.id);
      if (response.isError) throw new Error("Error downloading images");

      const images = response.data;
      setSelectedImages(images);
      
      if (images.length > 0) {
        setImageViewerVisible(true);
      } else {
        Alert.alert("No Images", "No images found for this inspection.");
      }
    } catch (ex) {
      Alert.alert("Error", "Error downloading images");
    } finally {
      setLoading(false);
    }
  }, [getInspectionImages]);

  const renderInspectionItem = (inspection) => (
    <View key={inspection.id} style={styles.inspection__item}>
      <View style={styles.inspection__header}>
        <View style={styles.date__status__container}>
          <Text style={styles.inspection__date}>{inspection.inspectionDateFormatted}</Text>
        </View>
        <Text style={[styles.inspection__detail, styles.report__no]}>Report No: {inspection.reportNo}</Text>
      </View>
      <View style={styles.inspection__body}>
        <View style={styles.inspection__body__details}>
          <Text style={styles.inspection__detail}>Next Inspection: {inspection.nextInspectionDateFormatted}</Text>
          <Text style={styles.inspection__detail}>Inspected By: {inspection.inspectedBy}</Text>
          <Text style={styles.inspection__detail}>
            Status: <Text style={styles.status__text}>
              {inspection.missing ? "Not Presented" : (inspection.safeForUse ? "Safe for Use" : "Not Safe for Use")}
            </Text>
          </Text>
          {inspection.defects && <Text style={styles.inspection__detail}>Defects: {inspection.defects}</Text>}
        </View>
        <View style={styles.status__container}>
          {renderInspectionStatus(inspection)}
        </View>
      </View>
      <View style={styles.button__container}>
        <TouchableOpacity 
          style={styles.button} 
          onPress={() => handleViewReport(inspection)}
        >
          <Text style={styles.button__text}>View Report</Text>
        </TouchableOpacity>
        <TouchableOpacity 
          style={styles.button} 
          onPress={() => handleViewImages(inspection)}
        >
          <Text style={styles.button__text}>View Images</Text>
        </TouchableOpacity>
      </View>
    </View>
  );

  return (
    <View style={styles.container}>
      <TitleBar navigation={navigation} title="Equipment Inspections" showBackButton={true} showSearchButton={false} onSynced={null} onSearch={null} />
      
      {imageViewerVisible && 
        <ImageViewer 
          images={selectedImages}
          visible={imageViewerVisible}
          onClose={() => setImageViewerVisible(false)}
        />
      }

      { (loading || inspectionsLoading) &&
        <LoadingIndicator />
      }

      <View style={styles.equipment__details__container}>
        <Text style={styles.section__title}>Equipment Details</Text>
        <Text style={styles.detail__text}>Description: {equipment?.description}</Text>
        <Text style={styles.detail__text}>Serial Number: {equipment?.serialNumber}</Text>
        <Text style={styles.detail__text}>Asset Number: {equipment?.assetNumber}</Text>
        <Text style={styles.detail__text}>Category: {equipment?.categoryName}</Text>
        <Text style={styles.detail__text}>SWL: {equipment?.swl}</Text>
      </View>

      <Text style={styles.section__title}>Inspection History</Text>
      
      <ScrollView style={styles.inspection__list}>
        {inspectionsData && inspectionsData.length > 0 
          ? inspectionsData.map(renderInspectionItem)
          : <Text style={styles.no__inspections__text}>No inspections found</Text>
        }
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F0F0F0',
    padding: isMobileScreen ? 20 : 40,
    paddingTop: 60,
  },
  equipment__details__container: {
    backgroundColor: '#FFF',
    padding: 10,
    borderRadius: 10,
    marginBottom: 10,
  },
  section__title: {
    fontFamily: 'OpenSans-Bold',
    fontSize: 18,
    marginBottom: 15,
    color: '#333',
  },
  detail__text: {
    fontFamily: 'OpenSans-Regular',
    fontSize: 14,
    marginBottom: 8,
    color: '#555',
  },
  inspection__list: {
    flex: 1,
  },
  inspection__item: {
    backgroundColor: '#FFF',
    padding: 15,
    borderRadius: 10,
    marginBottom: 15,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  inspection__header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#E0E0E0',
    paddingBottom: 10,
  },
  date__status__container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  inspection__date: {
    fontFamily: 'OpenSans-Bold',
    fontSize: 18,
    marginRight: 10,
    color: '#333',
  },
  status__container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 5,
  },
  report__no: {
    fontFamily: 'OpenSans-Bold',
    fontSize: 14,
    color: '#666',
  },
  inspection__body: {
    marginBottom: 15,
    flexDirection: 'row',
  },
  inspection__body__details: {
    flex: 1,
  },
  inspection__detail: {
    fontFamily: 'OpenSans-Regular',
    fontSize: 15,
    marginBottom: 5,
    color: '#444',
  },
  status__text: {
    fontWeight: 'bold',
  },
  no__inspections__text: {
    fontFamily: 'OpenSans-Light',
    fontSize: 16,
    textAlign: 'center',
    marginTop: 20,
    color: '#666',
  },
  error__text: {
    fontFamily: 'OpenSans-Regular',
    fontSize: 16,
    color: 'red',
    textAlign: 'center',
    marginTop: 20,
  },
  button__container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  button: {
    backgroundColor: '#007AFF',
    padding: 12,
    borderRadius: 8,
    flex: 1,
    marginHorizontal: 5,
  },
  button__text: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'OpenSans-Bold',
    fontSize: 14,
  },
});

export default EquipmentViewInspectionsScreen;