import * as React from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert } from 'react-native';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { EvilIcons } from '@expo/vector-icons';
import { Controller, useForm } from 'react-hook-form';
import { LoadingIndicator } from '../../../../components/navigation/controls/LoadingIndicator';
import { useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { colourApi, useCreateColourMutation, useGetAllColoursQuery, useLazyGetColoursQuery, useUpdateColourMutation } from '@store/services/api/colour-api';
import { PagedTableDto } from '@store/services/api/base-api';


export default function ColoursManageScreen({navigation, route}: {navigation: any, route: any}) {
  const user = useSelector((state: RootState) => state.user.user);

  const [createColour, createColourResult] = useCreateColourMutation();
  const [updateColour, updateColourResult] = useUpdateColourMutation();
  const { data: colours } = useGetAllColoursQuery(null);


  const [trigger, colourResult] = colourApi.useLazyGetColourQuery();

  const { control, handleSubmit, getValues, setValue, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      name: ""
    }
  });

  React.useEffect(() => {
    if (route.params?.colourId != null) {
      trigger(route.params?.colourId);
    }
  }, [])

  React.useEffect(() => {
    if (colourResult.isSuccess) {
      var data = colourResult.data;
      setValue("id", data.id);
      setValue("name", data.name);
    }
  }, [colourResult]);

  const validateName = async (name) => {
    if (!name) {
      return "Colour name is required.";
    }
    console.log(colours);
    
    const userColours = colours.map(c => c.name);;
    const uniqueColours = new Set(userColours);
    const isNameUnique = !uniqueColours.has(name);
    return isNameUnique ? undefined : "Colour must be unique.";
  };

  const onSubmit = async (data) => {
    var result = null;

    if (data.id != null) {
      result = await updateColour(data) as any;
    } else {
      result = await createColour(data) as any;
    }

    if (result.error) {
      return;
    }

    navigation.goBack();
  };

  if (createColourResult.isLoading || updateColourResult.isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <View style={styles.container}>
      <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon}  onPress={() => navigation.goBack() }>
          <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>{getValues("name") == null ? "CREATE COLOUR" : "UPDATE COLOUR"}</Text>
      </View>
      
      <View style={GlobalStyle.form}>
        {/* ##### ROW ##### */}
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{
                validate: validateName
              }}
              name="name"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Colour (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.name && (
              <Text style={GlobalStyle.form__input__validation}>
                {errors.name.message || "Validation error."}
              </Text>
            )}
          </View>
        </View>

        <TouchableOpacity style={GlobalStyle.form__submit__button} onPress={handleSubmit(onSubmit)}>
          <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
