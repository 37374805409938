import React, {useRef, useState} from "react";
import {Animated, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {Feather} from "@expo/vector-icons";

export function HelpCentreSection({title, children, collapsable = false}: {
  title: string,
  children: React.ReactNode,
  collapsable?: boolean
}) {
  const [collapsed, setCollapsed] = useState(false);
  const animation = useRef(new Animated.Value(1)).current;
  const contentHeight = useRef(0);
  const contentMaxHeight = contentHeight.current == 0 ? 1000 : contentHeight.current;

  const toggleCollapse = () => {
    if (collapsable) {
      setCollapsed((curr) => !curr);
      Animated.timing(animation, {
        toValue: collapsed ? 1 : 0,
        duration: 300,
        useNativeDriver: false,
      }).start();
    }
  };

  return (
    <View style={styles.section}>
      <TouchableOpacity style={styles.sectionHeader} onPress={() => toggleCollapse()}>
        {collapsable && (
          <Feather color='#999' size={18} name='chevron-down' />
        )}
        <Text style={styles.sectionTitle}>{title}</Text>
        {collapsable && (
          <Text style={styles.sectionHint}>Click to {collapsed ? 'expand' : 'hide'}</Text>
        )}
      </TouchableOpacity>
      {collapsable ? (
        <Animated.View style={{
          overflow: 'hidden', maxHeight: animation.interpolate({
            inputRange: [0, 1],
            outputRange: [0, contentMaxHeight],
          })
        }}>
          <View onLayout={(event) => {
            const {height} = event.nativeEvent.layout;
            contentHeight.current = height;
          }}>
            {children}
          </View>
        </Animated.View>
      ) : children}
    </View>
  )
}


const styles = StyleSheet.create({
  section: {
    backgroundColor: 'white',
    borderRadius: 4,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 1},
    shadowOpacity: 0.3,
    shadowRadius: 2,
    elevation: 5,
    padding: 10,
  },
  sectionTitle: {
    fontSize: 24,
    color: '#333',
  },
  sectionHint: {
    fontSize: 12,
    color: '#999',
    flex: 1,
    textAlign: 'right',
  },
  sectionHeader: {
    paddingVertical: 12,
    paddingHorizontal: 18,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    gap: 18,
    maxWidth: 500
  }
});
