import React, { useState } from 'react';
import { View, Image, StyleSheet, Dimensions, TouchableOpacity, Text, Modal } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { baseUrl } from '@store/services/api/base-api';

const { width, height } = Dimensions.get('window');

const ImageViewer = ({ images, visible, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <Modal visible={visible} transparent={true} onRequestClose={onClose}>
      <View style={styles.container}>
        <TouchableOpacity style={styles.close__button} onPress={onClose}>
          <AntDesign name="close" size={24} color="white" />
        </TouchableOpacity>
        <Image
          source={{ uri: images[currentIndex] }}
          style={styles.image}
          resizeMode="contain"
        />
        <View style={styles.navigation}>
          <TouchableOpacity onPress={handlePrevious} style={styles.nav__button}>
            <AntDesign name="left" size={24} color="white" />
          </TouchableOpacity>
          <Text style={styles.image__count}>
            {currentIndex + 1} / {images.length}
          </Text>
          <TouchableOpacity onPress={handleNext} style={styles.nav__button}>
            <AntDesign name="right" size={24} color="white" />
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: width,
    height: height * 0.7,
  },
  close__button: {
    position: 'absolute',
    top: 40,
    right: 20,
    zIndex: 1,
  },
  navigation: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: 20,
    position: 'absolute',
    bottom: 40,
  },
  nav__button: {
    padding: 10,
  },
  image__count: {
    color: 'white',
    fontSize: 16,
  },
});

export default ImageViewer;