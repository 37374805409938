import { EvilIcons, Foundation, MaterialIcons, SimpleLineIcons, AntDesign } from '@expo/vector-icons';
import { useIsFocused } from '@react-navigation/native';
import { PagedTableDto } from '@store/services/api/base-api';
import { jobApi } from '@store/services/api/job-api';
import { ReportableService } from '@store/services/reportable/reportable.service';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, TextInput, TouchableOpacity, ScrollView } from 'react-native';
import { DataTable } from 'react-native-paper';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import Spinner from 'react-native-loading-spinner-overlay';
import { DataTableHeader } from '@web/components/table/DatatableHeader';
import PaginationComponent from '@web/components/Pagination';
import { FIRST_PAGE, MAX_ITEMS_BEFORE_ALL } from '../../../../../../constants/Constants';
import { inspectionApi } from '@store/services/api/inspection-api';
import { store } from '@store/index';

export default function JobApprovalAssetsScreen({ navigation, route }: { navigation: any, route: any }) {
  const isFocused = useIsFocused();
  const [loading, setLoading] = useState<any>(false);
  const [sortedColumn, setSortedColumn] = React.useState<string>("inspectionDate");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(false);

  // Filters 
  const [serialNumber, setSerialNumber] = useState("");

  const [page, setPage] = React.useState<number>(0);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);
  const [trigger, jobsResult] = jobApi.useLazyGetJobAssetInspectionsQuery();
  useEffect(() => {
    if (isFocused && route.params?.jobId != null) {
      loadTable();
    }
  }, [page, isFocused, sortedColumn, sortedColumnAscending, numberOfItemsPerPage]);

  const loadTable = () => {
    const pageNumber = numberOfItemsPerPage > MAX_ITEMS_BEFORE_ALL ? FIRST_PAGE : page;
    trigger(new PagedTableDto(pageNumber, {
      jobId: route.params?.jobId, 
      serialNumber: serialNumber,
      pageSize: numberOfItemsPerPage
    }, sortedColumn, sortedColumnAscending)).then(() => {
      setLoading(false);
    });
  }

  const resetFilters = () => {
    setSerialNumber("");
  }

  const markInspectionDeleted = async (id) => {
    if (confirm("Are you sure you want to remove this inspection?")) {
      var result = await store.dispatch(inspectionApi.endpoints.archiveInspection.initiate(id)) as any;
      if (result.error) {
        alert("Error deleting inspection. Please try again.")
        return;
      }

      loadTable();
    }
  }

  const downloadCert = async (inspection) => {
    setLoading(true);
    var reportableService = new ReportableService();
    
    var data = null;
    
    if (inspection.masterCategoryName == "PPE")
      data = await reportableService.downloadPPE(inspection.id);
    else if (inspection.masterCategoryName == "Marine 2006") {
      data = await reportableService.downloadMarine(inspection.id);
    }
    else if (inspection.masterCategoryName == "PUWER 1998") {
      data = await reportableService.downloadMachine(inspection.id);
    }
      else
        data = await reportableService.downloadRote(inspection.id);

    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data.data, data.filename);
      setLoading(false);
    }
  }

  if (jobsResult.isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <ScrollView>
      <Spinner
        visible={loading}
        textContent={'Loading...'}
        textStyle={GlobalStyle.spinnerTextStyle}
      />
      <View style={styles.container}>
      <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon}  onPress={() => navigation.navigate("job-approval") }>
          <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>{"JOB APPROVAL"}</Text>
      </View>

      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Serial Number</Text>
            <TextInput 
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              placeholder="Serial #"
              value={serialNumber}
              onChangeText={setSerialNumber}
            />
          </View>

          <View style={GlobalStyle.column__flex}></View>
          <View style={GlobalStyle.column__flex}></View>
          <View style={GlobalStyle.column__flex}></View>

          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={() => loadTable()}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
        
      </View>
      
      <DataTable style={GlobalStyle.table}>
          <DataTableHeader
            sortedColumn={sortedColumn}
            sortedColumnAscending={sortedColumnAscending}
            onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
            headers={[
              { text: 'Report', visible: true },
              { text: 'Serial Number', sortedName: "serialNumber", visible: true },
              { text: 'Description', sortedName: "description", visible: true },
              { text: 'Category', sortedName: "categoryName", visible: true },
              { text: 'Inspection Date', sortedName: "inspectionDate", visible: true },
              { text: 'Safe For Use', sortedName: "missing", visible: true },
              { text: 'Options', visible: true },
            ]}
          />

        { jobsResult?.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id} style={{ backgroundColor: element.isAmended ? '#FFE599' : '#FFF' }}>
              <DataTable.Cell>
                <View style={{ flexDirection: 'row'}}>
                  <TouchableOpacity onPress={() => downloadCert(element)}><Foundation name="page-pdf" size={24} color="black" /></TouchableOpacity>
                </View>
              </DataTable.Cell>
              <DataTable.Cell>{element.serialNumber}</DataTable.Cell>
              <DataTable.Cell>{element.description}</DataTable.Cell>
              <DataTable.Cell>{element.categoryName}</DataTable.Cell>
              <DataTable.Cell>{element.inspectionDateFormatted}</DataTable.Cell>
              <DataTable.Cell>{element.missing ? "Not Presented": element.safeForUseFormatted}</DataTable.Cell>
              <DataTable.Cell>
                <View style={{ flexDirection: 'row'}}>
                  <TouchableOpacity onPress={() => navigation.navigate("job-inspect", { equipmentId: element.equipmentId, inspectionId: element.id, jobId: route.params?.jobId, categoryId: element.categoryId })}>
                    {!element.missing && <MaterialIcons name="approval" size={24} color="black" />}
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => markInspectionDeleted(element.id)} style={{ marginLeft: 20 }}>
                      <AntDesign name="delete" size={24} color="red" />
                    </TouchableOpacity>
                </View>
              </DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>

    </View>
    <PaginationComponent
        numberOfItemsPerPage={numberOfItemsPerPage}
        setNumberOfItemsPerPage={setNumberOfItemsPerPage}
        page={page}
        setPage={setPage}
        fetchResult={jobsResult}
        loading={loading}
        setLoading={setLoading}
      />
    </ScrollView>
    
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
