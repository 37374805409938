import { AntDesign } from "@expo/vector-icons";
import { equipmentApi } from "@store/services/api/equipment-api";
import React from "react";
import { View } from "react-native";

export const EquipmentFiles = ({ equipmentId }: { equipmentId: any}) => {
    const [trigger, filesResult] = equipmentApi.useLazyGetEquipmentFilesQuery();

    React.useEffect(() => {
        trigger(equipmentId);
    }, [])
    
    return (
        <View style ={{ display: "flex", flexDirection: "row", paddingRight: 5}}>
            {filesResult?.data?.length > 0 && <AntDesign name="folder1" size={24} color="black" /> }
        </View>
    )
}