import { baseApi, PagedTableDto } from './base-api'

export const categoryApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCategories: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `categories/getcategories?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    getCategory: build.query<any, any>({
      query: (categoryId) => ({
        url: `categories/getcategory/${categoryId}`,
      }),
    }),
    getAllCategories: build.query<any, any>({
      query: (companyId) => ({
        url: `categories/getallcategories`,
      }),
    }),
    getAllMasterCategories: build.query<any, any>({
      query: (companyId) => ({
        url: `categories/getallmastercategories`,
      }),
    }),
    createCategory: build.mutation<any, any>({
      query: (body) => ({
        url: 'categories/addcategory',
        method: 'post',
        body: body
      }),
    }),
    updateCategory: build.mutation<any, any>({
      query: (body) => ({
        url: `categories/updatecategory/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    syncCategories: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `categories/getcategoriesforsync?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    syncMasterCategories: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `categories/getmastercategoriesforsync?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    syncCategoryFields: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `categories/getcategoryfieldsforsync?lastmodifiedon=${lastModifiedOn}`,
      })
    }),

    getCategoryFields: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `categories/getcategoryfields?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    createCategoryField: build.mutation<any, any>({
      query: (body) => ({
        url: 'categories/addcategoryfield',
        method: 'post',
        body: body
      }),
    }),
    updateCategoryField: build.mutation<any, any>({
      query: (body) => ({
        url: `categories/updatecategoryfield/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    deleteCategoryField: build.mutation<any, any>({
      query: (id) => ({
        url: `categories/deletecategoryfield/${id}`,
        method: 'delete',
      }),
    }),
    getAllCategoryFields: build.query<any, any>({
      query: () => ({
        url: `categories/getallcategoryfields/`,
      }),
    }),

    //Category questions
    getCategoryQuestions: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `categories/getcategoryquestionslist?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    syncCategoryQuestions: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `categories/getcategoryquestions?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    syncCategoryQuestionsResponses: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `categories/getcategoryquestionresponses?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    createCategoryQuestion: build.mutation<any, any>({
      query: (body) => ({
        url: 'categories/addcategoryquestion',
        method: 'post',
        body: body
      }),
    }),
    updateCategoryQuestion: build.mutation<any, any>({
      query: (body) => ({
        url: 'categories/updatecategoryquestion',
        method: 'put',
        body: body
      }),
    }),
    deleteCategoryQuestion: build.mutation<any, any>({
      query: (id) => ({
        url: `categories/deletecategoryquestion/${id}`,
        method: 'delete',
      }),
    }),
    //Category questions responses
    getCategoryQuestionResponses: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `categories/getcategoryquestionresponseslist?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    createCategoryQuestionResponse: build.mutation<any, any>({
      query: (body) => ({
        url: 'categories/addcategoryquestionresponse',
        method: 'post',
        body: body
      }),
    }),
    updateCategoryQuestionResponse: build.mutation<any, any>({
      query: (body) => ({
        url: 'categories/updatecategoryquestionresponse',
        method: 'put',
        body: body
      }),
    }),
    deleteCategoryQuestionResponse: build.mutation<any, any>({
      query: (id) => ({
        url: `categories/deletecategoryquestionresponse/${id}`,
        method: 'delete',
      }),
    }),
  }),
  overrideExisting: true,
})

export const { 
  useGetCategoriesQuery, 
  useGetCategoryQuery, 
  useGetAllCategoriesQuery, 
  useGetAllMasterCategoriesQuery, 
  useCreateCategoryMutation, 
  useUpdateCategoryMutation,

  useGetCategoryFieldsQuery,
  useCreateCategoryFieldMutation,
  useUpdateCategoryFieldMutation,
  useDeleteCategoryFieldMutation,
  useGetAllCategoryFieldsQuery,

  useSyncCategoryFieldsMutation,

  useGetCategoryQuestionsQuery,
  useCreateCategoryQuestionMutation,
  useUpdateCategoryQuestionMutation,
  useDeleteCategoryQuestionMutation,

  useGetCategoryQuestionResponsesQuery,
  useCreateCategoryQuestionResponseMutation,
  useUpdateCategoryQuestionResponseMutation,
  useDeleteCategoryQuestionResponseMutation

} = categoryApi