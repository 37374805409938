import { createNativeStackNavigator } from '@react-navigation/native-stack';
import ColoursManageScreen from './ColoursManage';
import ColoursListScreen from './ColoursList';

const Stack = createNativeStackNavigator();

export default function Manufacturers({ navigation }: { navigation: any }) {
  return (
    <Stack.Navigator>
      <Stack.Screen name="list" component={ColoursListScreen} options={{ headerShown: false, title: 'Colours'  }} />
      <Stack.Screen name="manage" component={ColoursManageScreen} options={{ headerShown: false, title: 'Colours Manage'}}  />
    </Stack.Navigator>
  )
};