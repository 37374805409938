import { useState } from "react";
import { ConfirmModal } from "./ConfirmModal";
import { StyleSheet, Text, TextInput, View } from "react-native";

interface ConfirmScrapModalProps {
  onConfirm: (reason: string) => void;
  modalVisible: boolean;
  setModalVisible: (visible: boolean) => void;
  equipment?: string[];
}

export function ConfirmScrapModal({onConfirm, modalVisible, setModalVisible, equipment = []}: ConfirmScrapModalProps) {
  const [scrapReason, setScrapReason] = useState("");

  function handleConfirm() {
    onConfirm(scrapReason);
    closeModal();
  }

  function closeModal() {
    setModalVisible(false);
  }

  function buildMessage(): string {
    const plural = equipment?.length > 1 ? "these" : "this";
    return `Are you sure you want to scrap ${plural} equipment?`;
  }

  const showEquipment = equipment?.length >= 1;

  return (
    <ConfirmModal
      title="Scrap Equipment"
      visible={modalVisible}
      onCancel={closeModal}
      onConfirm={handleConfirm}
      message={buildMessage()} >
      {showEquipment && (
        <View
          style={styles.equipment__list}>
          {equipment?.map(equipment => (
            <Text>{equipment}</Text>
          ))}
        </View>
      )}

      <View style={styles.content__container}>
        <Text>Reason for Scrap</Text>
        <TextInput
          style={styles.form__input}
          multiline={true}
          onChangeText={setScrapReason}
          value={scrapReason}>
        </TextInput>
      </View>
    </ConfirmModal>
  )
}

const styles = StyleSheet.create({
  form__input: {
    fontFamily: 'OpenSans-Regular',
    padding: 5,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#CECECE',
    marginTop: 5,
  },
  equipment__list: {
    marginTop: 8,
  },
  content__container: {
    paddingVertical: 32
  },
})
