import * as SQLite from 'expo-sqlite';
import { Database } from './database';

export class ModulesDatabase extends Database {
  TABLE_NAME = "UserModules";

  initialise() : Promise<void> {
    return new Promise((resolve, reject) => {
      var sql = `CREATE TABLE IF NOT EXISTS UserModules ( 
        id TEXT PRIMARY KEY,
        moduleId TEXT,
        moduleName TEXT,
        enabled INTEGER
      )`;

      Database.getDb().transaction((tx) => {
        tx.executeSql(sql);
      }, (error) => {
        console.log(error)
        reject();
      }, () => {
        resolve();
      })
    });
  }

  insertOrUpdateList(entities: any): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME} (id, moduleId, moduleName, enabled)
            VALUES (?, ?, ?, ?)
          `

          tx.executeSql(sql, [entity.id, entity.moduleId, entity.moduleName, entity.enabled]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    });
  }

  getModules() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM ${this.TABLE_NAME}
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  // isDOCEnabled() : Promise<boolean> { return this.isModuleEnabled('DOC'); }
  // isBreakdownsEnabled() : Promise<boolean> { return this.isModuleEnabled('DOC'); }
  // isROTEEnabled() : Promise<boolean> { return this.isModuleEnabled('ROTE'); }

  // isModuleEnabled(moduleName: string) : Promise<boolean> {
  //   return new Promise((resolve, reject) => {
  //     var enabled = false;
  //     Database.getDb().transaction((tx) => {
  //       var sql = `SELECT enabled FROM ${this.TABLE_NAME} WHERE moduleName = ?`;
  //       tx.executeSql(sql, [moduleName], (_, { rows: { _array } }) => {
  //         //console.log(_array)
  //         if (_array.length > 0) {
  //           enabled = _array[0] == 1;
  //         }
  //       });
  //     }, (error) => {
  //       reject();
  //     }, () => {
  //       resolve(enabled);
  //     });
  //   });
  // }


}