import { baseApi, PagedTableDto } from './base-api'

export const purposeApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPurposes: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `purposes/getpurposes?pageNumber=${dto.page + 1}&filters=${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    syncPurposes: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `purposes/getpurposesforsync?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    getAllPurposes: build.query<any, any>({
      query: () => ({
        url: `purposes/getallpurposes`,
      })
    })
  }),
  overrideExisting: true,
})

export const { useGetPurposesQuery, useGetAllPurposesQuery } = purposeApi