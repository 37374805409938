import * as React from 'react';
import { useState, useEffect } from 'react';
import { Picker } from '@react-native-picker/picker';
import { useGetAllCompaniesForFilterQuery } from '@store/services/api/company-api';
import { useLazyGetLocationsByCompanyIdQuery, useLazyGetSubLocationsByLocationIdQuery } from '@store/services/api/location-api';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, ScrollView, Modal } from 'react-native';
import GlobalStyle from '../../../../../constants/GlobalStyle';
import { useGetAllCategoriesQuery } from '@store/services/api/category-api';
import { Foundation, SimpleLineIcons, Feather } from '@expo/vector-icons';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { PagedTableDto } from '@store/services/api/base-api';
import { DataTable, } from 'react-native-paper';
import { equipmentApi } from '@store/services/api/equipment-api';
import { useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { ReportableService } from '@store/services/reportable/reportable.service';
import { ReportKey } from '@web/components/ReportKey';
import Spinner from 'react-native-loading-spinner-overlay';
import { EquipmentFiles } from '../Management/Equipment/EquipmentFiles';
import { DataTableHeader } from '@web/components/table/DatatableHeader';
import PaginationComponent from '@web/components/Pagination';
import { MAX_ITEMS_BEFORE_ALL, FIRST_PAGE } from '../../../../../constants/Constants';
import moment from 'moment';
import DateTimePickerWeb from '@web/components/navigation/controls/DateTimePicker.web';
import Checkbox from "expo-checkbox";
import { EquipmentFileViewer } from './Inspections';


export default function AssetRegisterScreen({ navigation }: { navigation: any }) {
  const user = useSelector((state: RootState) => state.user.user);
  const modules = useSelector((state: RootState) => state.user.modules);
  const [loading, setLoading] = useState<any>(false);
  const [sortedColumn, setSortedColumn] = React.useState<string>("serialNumber");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);

  const { data: companies } = useGetAllCompaniesForFilterQuery(null);
  const [triggerLocations, { data: locations }] = useLazyGetLocationsByCompanyIdQuery();
  const [triggerSubLocations, { data: subLocations }] = useLazyGetSubLocationsByLocationIdQuery();
  const { data: categories } = useGetAllCategoriesQuery(null);

  const [page, setPage] = React.useState<number>(0);
  const [trigger, inspectionResult] = equipmentApi.useLazyGetAssetRegisterQuery();
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<number>(10);

  const [companyId, setCompanyId] = useState(user.companyId);
  const [locationId, setLocationId] = useState("");
  const [subLocationId, setSubLocationId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [statusId, setStatusId] = useState("-1");
  const [scrappedStatus, setScrappedStatus] = useState("-1");
  const [serialNumber, setSerialNumber] = useState("");
  const [assetNumber, setAssetNumber] = useState("");
  const [locationOptions, setLocationOptions] = useState([]);
  const [subLocationOptions, setSubLocationOptions] = useState([]);
  const [dateRange, setDateRange] = useState<any>("");
  const [startDate, setStartDate] = useState(moment().add(-6, 'months').toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [isDateRangeApplied, setIsDateRangeApplied] = useState<boolean>(false);
  const [selectedEquipmentId, setSelectedEquipmentId] = useState(null);

  const resetFilters = () => {
    setCompanyId(user.companyId);
    setLocationId("");
    setSubLocationId("");
    setCategoryId("");
    setStatusId("-1");
    setScrappedStatus("-1");
    setSerialNumber("");
    setAssetNumber("");
    setDateRange("");
    setStartDate(moment().add(-6, 'months').toDate());
    setEndDate(moment().toDate());
    setIsDateRangeApplied(false);
  }

  const loadTable = () => {
    const pageNumber = numberOfItemsPerPage > MAX_ITEMS_BEFORE_ALL ? FIRST_PAGE : page;
    var dto = new PagedTableDto(pageNumber, {
      companyId: companyId,
      locationId: locationId,
      subLocationId: subLocationId,
      categoryId: categoryId,
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString(),
      isDateRangeApplied: isDateRangeApplied,
      statusId: statusId,
      scrappedStatus: scrappedStatus,
      serialNumber: serialNumber,
      pageSize: numberOfItemsPerPage,
      assetNumber: assetNumber
    }, sortedColumn, sortedColumnAscending);

    trigger(dto).then(() => {
      setLoading(false);
    });
  }

  function applyFilters() {
    setPage((curr) => {
      if (curr == 0) loadTable();
      return 0;
    })
  }

  useEffect(() => {
    loadTable();
  }, [page, sortedColumn, sortedColumnAscending, numberOfItemsPerPage])

  useEffect(() => {
    if (companyId) {
      triggerLocations(companyId).then(response => {
        setLocationOptions(response.data);
      });
    } else {
      setLocationOptions([]);
    }

    setLocationId("");
    setSubLocationId("");
    setSubLocationOptions([]);
  }, [companyId]);

  useEffect(() => {
    if (locationId) {
      triggerSubLocations(locationId).then(response => setSubLocationOptions(response.data));
    } else {
      setSubLocationOptions([]);
    }
    setSubLocationId("");
  }, [locationId]);

  const downloadCert = async (equipment) => {
    setLoading(true);
    var reportableService = new ReportableService();
    var data = null;
    
    if (equipment.masterCategoryName == "PPE")
      data = await reportableService.downloadPPE(equipment.inspectionId);
    else if (equipment.masterCategoryName == "Marine 2006") {
      data = await reportableService.downloadMarine(equipment.inspectionId);
    }
    else if (equipment.masterCategoryName == "PUWER 1998") {
      data = await reportableService.downloadMachine(equipment.inspectionId);
    }
    else
      data = await reportableService.downloadRote(equipment.inspectionId);

    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data.data, data.filename);
      setLoading(false);
    }
  }

  const downloadRAMS = async (equipment) => {
    var reportableService = new ReportableService();
    var data = await reportableService.downloadRAMS(equipment.ramsId);
    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data.data, data.filename);
    }
  }

  const downloadDoC = async (equipment) => {
    setLoading(true);
    var reportableService = new ReportableService();
    var data = await reportableService.downloadDoC(equipment.id);
    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data.data, data.filename);
      setLoading(false);
    }
  }

  const downloadAssetRegister = async () => {
    var reportableService = new ReportableService();
    var data = await reportableService.downloadAssetRegister(locationId);
    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data.data, data.filename);
    }
  }

  const downloadAssetRegisterCSV = async () => {
    setLoading(true);
    const pageNumber = numberOfItemsPerPage > MAX_ITEMS_BEFORE_ALL ? FIRST_PAGE : page;
    var dto = new PagedTableDto(pageNumber, {
      companyId: companyId,
      locationId: locationId,
      subLocationId: subLocationId,
      categoryId: categoryId,
      statusId: statusId,
      scrappedStatus: scrappedStatus,
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString(),
      isDateRangeApplied: isDateRangeApplied,
      serialNumber: serialNumber,
      assetNumber: assetNumber,
      pageSize: numberOfItemsPerPage
    }, sortedColumn, sortedColumnAscending);
    var reportableService = new ReportableService();
    var data = await reportableService.downloadAssetRegisterCSV(dto);
    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data.data, data.filename);
      setLoading(false);
    }
  }

  const updateDateRange = (val) => {
    setDateRange(val);

    let startDate = moment();
    const endDate = moment();
    if (val == "-1") {
      startDate = moment().add(-1, 'week');
    } else {
      startDate = moment().add(-Number.parseInt(val), 'M');
    }

    setStartDate(startDate.toDate());
    setEndDate(endDate.toDate());
  }

  if (inspectionResult.isLoading) {
    return <LoadingIndicator />
  }

  return (
    <ScrollView>
      <Spinner
        visible={loading}
        textContent={'Loading...'}
        textStyle={GlobalStyle.spinnerTextStyle}
      />
      <Modal
        animationType="fade"
        transparent={true}
        visible={selectedEquipmentId != null}>

        <View style={styles.modal__block}>
          <View style={styles.modal}>
            <EquipmentFileViewer equipmentId={selectedEquipmentId} onCancel={() => setSelectedEquipmentId(null)} />
          </View>
        </View>

      </Modal>
      <View style={styles.container}>
      </View>
      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Company</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              selectedValue={companyId}
              enabled={user.role != 'User'}
              onValueChange={(itemValue, itemIndex) => { setCompanyId(itemValue); setLocationId(""); setSubLocationId("") }}>

              <Picker.Item label="Please Select" value="" />

              {companies != null && companies?.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>


          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Location</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              selectedValue={locationId}
              onValueChange={(itemValue) => {
                setLocationId(itemValue);
                setSubLocationId("");
              }}>

              <Picker.Item label="Please Select" value="" />

              {locationOptions && locationOptions.map((value) => (
                <Picker.Item key={value.id} label={value.name} value={value.id} />
              ))}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Sub-Location</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              selectedValue={subLocationId}
              onValueChange={(itemValue, itemIndex) => setSubLocationId(itemValue)}>

              <Picker.Item label="Please Select" value="" />

              {subLocationOptions && subLocationOptions.map((value) => (
                <Picker.Item key={value.id} label={value.name} value={value.id} />
              ))}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Categories</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              selectedValue={categoryId}
              onValueChange={(itemValue, itemIndex) => setCategoryId(itemValue)}>

              <Picker.Item label="Please Select" value="" />

              {categories != null && categories.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Serial #</Text>
            <TextInput
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              onChangeText={setSerialNumber}
              value={serialNumber}
            />
          </View>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Asset #</Text>
            <TextInput
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              onChangeText={setAssetNumber}
              value={assetNumber}
            />
          </View>

        </View>
        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Status</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              selectedValue={statusId}
              onValueChange={(itemValue, itemIndex) => setStatusId(itemValue)}>

              <Picker.Item label="All" value="-1" />
              <Picker.Item label="Failed" value="0" />
              <Picker.Item label="Safe For Use" value="1" />
              <Picker.Item label="Not Presented" value="2" />
            </Picker>
          </View>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Scrapped Status</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              selectedValue={scrappedStatus}
              onValueChange={(itemValue, itemIndex) => setScrappedStatus(itemValue)}>

            <Picker.Item label="All" value="-1" />
            <Picker.Item label="Not Scrapped" value="0" />
            <Picker.Item label="Scrapped" value="1" />
            </Picker>
          </View>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Date Range</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              selectedValue={dateRange}
              onValueChange={(itemValue, itemIndex) => updateDateRange(itemValue)}>

              <Picker.Item label="Predefined Date Range" value="" />
              <Picker.Item label="1 Week" value="-1" />
              <Picker.Item label="1 Month" value="1" />
              <Picker.Item label="3 Months" value="3" />
              <Picker.Item label="6 Months" value="6" />
              <Picker.Item label="12 Months" value="12" />

            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Start Date</Text>
            <DateTimePickerWeb
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              onChange={setStartDate}
              value={startDate}>
            </DateTimePickerWeb>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>End Date</Text>
            <DateTimePickerWeb
              style={[GlobalStyle.column, GlobalStyle.column__input,]}
              onChange={setEndDate}
              value={endDate}>
            </DateTimePickerWeb>
          </View>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Apply Date Range</Text>
            <Checkbox
              style={GlobalStyle.form__column__input}
              onValueChange={(newValue) => {
                setIsDateRangeApplied(newValue);
              }}
              value={isDateRangeApplied}>
            </Checkbox>
          </View>

          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={applyFilters}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: 'row' }}>
        <TouchableOpacity style={GlobalStyle.add__button} onPress={() => downloadAssetRegisterCSV()}>
          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 5, paddingRight: 10 }}>
            <Feather name="download" size={24} color="white" /><Text style={GlobalStyle.form__submit__button__text}>Download Asset Register CSV</Text>
          </View>
        </TouchableOpacity>
      </View>

      <View style={{ flexDirection: 'row' }}>
        <ReportKey showRote={true} showDoC={true} showChecklist={false} />
        <View style={{ justifyContent: 'flex-start', alignItems: 'center', paddingBottom: 10 }}>
        </View>
      </View>

      <DataTable style={GlobalStyle.table}>
        <DataTableHeader
          sortedColumn={sortedColumn}
          sortedColumnAscending={sortedColumnAscending}
          onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
          headers={[
            { text: 'Report', visible: true },
            { text: 'Documents', visible: true },
            { text: 'Description', sortedName: "description", visible: true },
            { text: 'Inspection Date', sortedName: "inspection.InspectionDate", visible: true },
            { text: 'Next Inspection Date', sortedName: "inspection.NextInspectionDate", visible: true },
            { text: 'Serial Number', sortedName: "serialNumber", visible: true },
            { text: 'Asset Number', sortedName: "assetNumber", visible: true },
            { text: 'Created', sortedName: "inspection.CreatedOn", visible: true },
            { text: 'Category', sortedName: "categoryName", visible: true },
            { text: 'Frequency', sortedName: "frequency", visible: true },
            { text: 'Safe For Use', sortedName: "safeForUse", visible: true }
          ]}
        />

        {inspectionResult.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>
                <View style={{ flexDirection: 'row' }}>
                  {element.inspectionId != null && <TouchableOpacity onPress={() => downloadCert(element)}><Foundation name="page-pdf" size={24} color="black" /></TouchableOpacity>}
                  {/* { element.ramsId != null && <TouchableOpacity style={{ marginLeft: 20 }} onPress={() => downloadRAMS(element)}><Foundation name="page-pdf" size={24} color="blue" /></TouchableOpacity> } */}
                  {element.doc == true && <TouchableOpacity style={{ marginLeft: 20 }} onPress={() => downloadDoC(element)}><Foundation name="page-pdf" size={24} color="green" /></TouchableOpacity>}
                </View>
              </DataTable.Cell>
              <DataTable.Cell>
                <TouchableOpacity onPress={() => setSelectedEquipmentId(element.id)}>
                  <EquipmentFiles equipmentId={element.id} />
                </TouchableOpacity>
              </DataTable.Cell>
              <DataTable.Cell>{element.description}</DataTable.Cell>
              <DataTable.Cell>{element.lastInspectionDate}</DataTable.Cell>
              <DataTable.Cell>{element.nextInspectionDate}</DataTable.Cell>
              <DataTable.Cell>{element.serialNumber}</DataTable.Cell>
              <DataTable.Cell>{element.assetNumber}</DataTable.Cell>
              <DataTable.Cell>{element.created}</DataTable.Cell>
              <DataTable.Cell>{element.categoryName}</DataTable.Cell>
              <DataTable.Cell>{element.frequency}</DataTable.Cell>
              <DataTable.Cell>{element.status}</DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>
      <PaginationComponent
        numberOfItemsPerPage={numberOfItemsPerPage}
        setNumberOfItemsPerPage={setNumberOfItemsPerPage}
        page={page}
        setPage={setPage}
        fetchResult={inspectionResult}
        loading={loading}
        setLoading={setLoading}
      />
    </ScrollView>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },

  modal__block: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },

  modal: {
    backgroundColor: '#FFF',
    padding: 10,
    width: 600,
    borderRadius: 5,

    flexDirection: 'column',
  }

});
