import * as React from 'react';
import { useState } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, ScrollView, Alert } from 'react-native';

import { DataTable } from 'react-native-paper';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { useIsFocused } from "@react-navigation/native";
import { Entypo, Feather, SimpleLineIcons, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { locationApi, useCreateSubLocationMutation, useDeleteSubLocationMutation, useGetAllLocationsQuery, useUpdateLocationMutation, useUpdateSubLocationMutation } from '@store/services/api/location-api';
import { PagedTableDto } from '@store/services/api/base-api';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { useGetAllCompaniesQuery } from '@store/services/api/company-api';
import { Picker } from '@react-native-picker/picker';
import { Controller, useForm } from 'react-hook-form';
import { DataTableHeader } from '@web/components/table/DatatableHeader';
import PaginationComponent from '@web/components/Pagination';
import { MAX_ITEMS_BEFORE_ALL, FIRST_PAGE } from '../../../../../../constants/Constants';
import Spinner from 'react-native-loading-spinner-overlay';
import { useTransferEquipmentMutation } from '@store/services/api/equipment-api';
import { EquipmentTransfer } from '@web/components/EquipmentTransfer';

export default function LocationsListScreen({ navigation }: { navigation: any }) {
  const isFocused = useIsFocused();

  const [loading, setLoading] = useState<any>(false);
  const [page, setPage] = React.useState<number>(0);
  const [sortedColumn, setSortedColumn] = React.useState<string>("name");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);
  const [isStatusChanged, setIsStatusChanged] = React.useState<boolean>(false);
  const [updateLocation, updateLocationResult] = useUpdateLocationMutation();
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [equipmentCount, setEquipmentCount] = React.useState<number>(0);
  const [locationToArchive, setlocationToArchive] = React.useState<any>(null);

  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);


  // Filters 
  const { data: companies } = useGetAllCompaniesQuery(null);
  const { data: locations } = useGetAllLocationsQuery(null);
  const [locationName, setLocationName] = useState("");
  const [address1, setAddress1] = useState("");
  const [companyId, setCompanyId] = useState("");

  const [selectedLocation, setSelectedLocation] = React.useState<any>(null);
  const [trigger, locationsResult] = locationApi.useLazyGetLocationsQuery();
  const [transferEquipment, transferEquipmentResult] = useTransferEquipmentMutation();

  React.useEffect(() => {
    if (isFocused) {
      loadTable();
    }
  }, [page, isFocused, sortedColumn, sortedColumnAscending, numberOfItemsPerPage, isStatusChanged]);

  const loadTable = () => {
    const pageNumber = numberOfItemsPerPage > MAX_ITEMS_BEFORE_ALL ? FIRST_PAGE : page;
    trigger(new PagedTableDto(pageNumber, {
      name: locationName,
      address1: address1,
      companyId: companyId,
      pageSize: numberOfItemsPerPage
    }, sortedColumn, sortedColumnAscending)).then(() => {
      setLoading(false);
    });
  }

  const resetFilters = () => {
    setLocationName("");
    setAddress1("");
    setCompanyId("");
  }

  function applyFilters() {
    setPage((curr) => {
      if (curr == 0) loadTable();
      return 0;
    })
  }

  if (locationsResult.isLoading) {
    return <LoadingIndicator />
  }
  const isLocationEmpty = ({ equipmentCount }) => {
    return equipmentCount === 0;
  }

  const archiveLocation = async (element) => {
    setEquipmentCount(element.equipmentCount)
    setlocationToArchive(element);

    setIsStatusChanged(true);
    if (isLocationEmpty(element)) {
      try {
        const isConfirmed = window.confirm('Are you sure you want to archive this location?')
        if (isConfirmed) {
          await updateLocation({ ...element, isArchived: !element.isArchived });
          setIsStatusChanged(false);
        } else {
          return;
        }
      } catch (error) {
        console.error("Error changing equipment status:", error);
      }
    } else {
      setIsModalVisible(true)
    }
  };

  const transfer = async (newLocation) => {
    try {

      const result = await transferEquipment({ locationToArchive: locationToArchive.id, newLocation }) as any;
      if (!result.error) {
        await updateLocation({ ...locationToArchive, isArchived: !locationToArchive.isArchived });
        setIsStatusChanged(false);
      } else {
        console.error("Error transferring equipment:", result.error);
      }
    } catch (error) {
      console.error("Error transferring equipment:", error);
    }
  };

  const renderEquipmentTransfer = () => {
    if (isModalVisible) {
      return (
        <EquipmentTransfer
          isVisible={isModalVisible}
          setVisible={setIsModalVisible}
          equipmentCount={equipmentCount}
          companies={companies}
          locations={locations}
          transferEquipment={transfer}
          oldLocationId={locationToArchive.id}
        />
      );
    }
    return null;
  };

  return (
    <ScrollView style={styles.container}>
      <Spinner
        visible={loading}
        textContent={'Loading...'}
        textStyle={GlobalStyle.spinnerTextStyle}
      />
      {renderEquipmentTransfer()}
      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Location Name</Text>
            <TextInput
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              placeholder="Location Name"
              onChangeText={setLocationName}
              value={locationName}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Address 1</Text>
            <TextInput
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              placeholder="Address 1"
              onChangeText={setAddress1}
              value={address1}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Company</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              selectedValue={companyId}
              onValueChange={(itemValue, itemIndex) => setCompanyId(itemValue)}>

              <Picker.Item label="All Companies" value="" />

              {companies != null && companies.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={applyFilters}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <TouchableOpacity style={GlobalStyle.add__button} onPress={() => navigation.navigate("manage")}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 5, paddingRight: 10 }}>
              <Ionicons name="add" size={24} color="white" /><Text style={GlobalStyle.form__submit__button__text}>Add New Location</Text>
            </View>
          </TouchableOpacity>

          <DataTable style={GlobalStyle.table}>
            <DataTableHeader
              sortedColumn={sortedColumn}
              sortedColumnAscending={sortedColumnAscending}
              onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
              headers={[
                { text: 'Name', sortedName: "name", visible: true },
                { text: 'Address 1', sortedName: "address1", visible: true },
                { text: 'Address 2', sortedName: "address2", visible: true },
                { text: 'Address 3', sortedName: "address3", visible: true },
                { text: 'Address 4', sortedName: "address4", visible: true },
                { text: 'Postcode', sortedName: "postcode", visible: true },
                { text: 'Company', sortedName: "companyName", visible: true },
                { text: 'Option', visible: true },
                { text: 'Sub Locations', visible: true },
              ]}
            />

            {locationsResult?.data?.list.map((element: any) => {
              return (
                <DataTable.Row key={element.id}>
                  <DataTable.Cell>{element.name}</DataTable.Cell>
                  <DataTable.Cell>{element.address1}</DataTable.Cell>
                  <DataTable.Cell>{element.address2}</DataTable.Cell>
                  <DataTable.Cell>{element.address3}</DataTable.Cell>
                  <DataTable.Cell>{element.address4}</DataTable.Cell>
                  <DataTable.Cell>{element.postcode}</DataTable.Cell>
                  <DataTable.Cell>{element.companyName}</DataTable.Cell>
                  <DataTable.Cell>
                    <TouchableOpacity onPress={() => navigation.navigate("manage", { locationId: element.id })}>
                      <Entypo name="pencil" size={24} color="black" />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => archiveLocation(element)}>
                      <MaterialCommunityIcons name={element.isArchived ? "archive-minus" : "archive-plus"} size={24} color="black" />
                    </TouchableOpacity>
                  </DataTable.Cell>
                  <DataTable.Cell>
                    <TouchableOpacity onPress={() => setSelectedLocation(element)}>
                      <Entypo name="location-pin" size={24} color="black" />
                    </TouchableOpacity>
                  </DataTable.Cell>
                </DataTable.Row>
              )
            })}
          </DataTable>

          <PaginationComponent
            numberOfItemsPerPage={numberOfItemsPerPage}
            setNumberOfItemsPerPage={setNumberOfItemsPerPage}
            page={page}
            setPage={setPage}
            fetchResult={locationsResult}
            loading={loading}
            setLoading={setLoading}
          />
        </View>

        {selectedLocation != null &&
          <View style={{ width: 450, marginLeft: 20 }}>
            <SubLocationsList location={selectedLocation} onClear={() => setSelectedLocation(null)} />
          </View>
        }
      </View>

    </ScrollView>
  );
}

export function SubLocationsList({ location, onClear }) {
  const [page, setPage] = useState<number>(0);
  const [sortedColumn, setSortedColumn] = React.useState<string>("name");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);
  const [loading, setLoading] = useState<any>(false);

  const [trigger, subLocationsResult] = locationApi.useLazyGetSubLocationsQuery();
  const [createSubLocation, subLocationResult] = useCreateSubLocationMutation();
  const [updateSubLocation, updateSubLocationResult] = useUpdateSubLocationMutation();
  const [deleteSubLocation, deleteSubLocationResult] = useDeleteSubLocationMutation();

  const { control, handleSubmit, getValues, setValue, reset, watch, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      locationId: location?.id,
      name: "",
    }
  });

  const id = watch("id");

  React.useEffect(() => {
    loadTable();
  }, [page, location, sortedColumn, sortedColumnAscending]);

  const loadTable = () => {
    const pageNumber = numberOfItemsPerPage > MAX_ITEMS_BEFORE_ALL ? FIRST_PAGE : page;
    trigger(new PagedTableDto(pageNumber, {
      locationId: location?.id,
      pageSize: numberOfItemsPerPage
    }, sortedColumn, sortedColumnAscending)).then(() => {
      setLoading(false);
    });
  }

  const onSubmit = async (data) => {
    data.locationId = location.id;
    var result = null;
    if (data.id != null) {
      result = await updateSubLocation(data) as any;
    } else {
      result = await createSubLocation(data) as any;
    }

    if (result.error) {
      alert(result.error)
      return;
    }

    reset();
    loadTable();
  }


  const edit = (subLocation) => {
    setValue("id", subLocation.id);
    setValue("locationId", subLocation.locationId);
    setValue("name", subLocation.name);
  }

  const remove = async (subLocation) => {
    if (confirm("Are you sure you want to delete this sub location?")) {
      var result = await deleteSubLocation(subLocation.id) as any;
      if (result.error) {
        alert(result.error)
        return;
      }

      loadTable();
    }
  }

  return (
    <View>
      <Spinner
        visible={loading}
        textContent={'Loading...'}
        textStyle={GlobalStyle.spinnerTextStyle}
      />
      <TouchableOpacity onPress={() => onClear()}>
        <Text style={GlobalStyle.add__new__link}>
          <Entypo name="chevron-left" size={12} color="black" style={{ marginRight: 10 }} />
          Sub Locations
        </Text>
      </TouchableOpacity>

      <View style={[GlobalStyle.form, styles.form]}>
        <View style={GlobalStyle.form__row}>
          <View style={[GlobalStyle.form__column, GlobalStyle.form__column__full]}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="name"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Sub Location Name (*)</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.name && <Text style={GlobalStyle.form__input__validation}>Name is required.</Text>}
          </View>

          <View style={styles.form__buttons}>
            <TouchableOpacity style={styles.form__add__btn} onPress={handleSubmit(onSubmit)}>
              <Text style={styles.form__add__btn__text}>{id == null ? 'Add' : 'Update'}</Text>
            </TouchableOpacity>

            {id != null &&
              <TouchableOpacity style={styles.form__add__btn} onPress={() => reset()}>
                <Text style={styles.form__add__btn__text}>Clear</Text>
              </TouchableOpacity>
            }
          </View>
        </View>
      </View>

      <DataTable style={GlobalStyle.table}>
        <DataTableHeader
          sortedColumn={sortedColumn}
          sortedColumnAscending={sortedColumnAscending}
          onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
          headers={[
            { text: 'Name', sortedName: "name", visible: true },
            { text: 'Actions', visible: true },
          ]}
        />

        {subLocationsResult?.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>{element.name}</DataTable.Cell>
              <DataTable.Cell>
                <View style={styles.table__actions}>
                  <TouchableOpacity style={styles.action} onPress={() => edit(element)}><Feather name="edit" size={20} color="black" /></TouchableOpacity>
                  <TouchableOpacity style={styles.action} onPress={() => remove(element)}><Feather name="trash" size={20} color="black" /></TouchableOpacity>
                </View>
              </DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>

      <PaginationComponent
        numberOfItemsPerPage={numberOfItemsPerPage}
        setNumberOfItemsPerPage={setNumberOfItemsPerPage}
        page={page}
        setPage={setPage}
        fetchResult={subLocationsResult}
        loading={loading}
        setLoading={setLoading}
      />
    </View>
  )
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },

  form: {
    borderBottomWidth: 1,
    borderColor: '#CECECE',
    paddingBottom: 20,
    marginBottom: 10
  },

  form__buttons: {
    width: 150,
    flexDirection: 'row',
  },

  form__add__btn: {
    backgroundColor: PRIMARY_COLOUR,
    flex: 1,
    height: 31,
    alignSelf: 'flex-end',
    textAlign: 'center',
    marginLeft: 10,
    marginBottom: 5,

    alignItems: 'center',
    justifyContent: 'center',
  },

  form__add__btn__text: {
    color: '#FFF',
  },

  table__actions: {
    flexDirection: 'row',
  },

  action: {
    paddingLeft: 5,
    paddingRight: 5,
  }
});
