import {View, Text, StyleSheet, Linking} from "react-native";

export function ReleaseNotesSection() {
  return (
    <View style={styles.container}>
      <Text>Read the latest updates and improvements on our Release Notes Page.</Text>
      <Text
        onPress={() => Linking.openURL('https://corerfid.release.page/whats-new')}
        style={{color: 'blue', textDecorationLine: 'underline'}}>Visit our Release Notes Website</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 18,
    paddingVertical: 8
  }
})
