import {User, UserRole} from "@store/slices/user-slice";

export const hasCategoryAccess = (user: any, permissions: any [], isSystemUser: boolean, category: string): boolean => {
    const isCategoryPermission = user.customerId !== null && permissions.filter(p => p.role === user.role && p.category === category).some(p => p.hasAccess);
    return isCategoryPermission || isSystemUser;
  };
  
export const hasFeatureAccess = (user: any, permissions: any [], isSystemUser: boolean, category: string, name: string): boolean => {
  const isMenuPermission = user.customerId !== null && permissions.some(p => p.role === user.role && p.category === category && p.formattedName === name && p.hasAccess);
  return isMenuPermission || isSystemUser;
};

export const hasAdminPermissions = (user: User): boolean => {
  return user.role === UserRole.ADMIN || user.role === UserRole.SYSTEM;
}
