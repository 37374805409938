import { baseApi } from './base-api'

export const permissionApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllPermissionsByRole: build.query<any, any>({
      query: (roleId) => ({
        url: `permissions/getallpermissionsbyrole/${roleId}`,
      }),
    }),
    getAllPermissions: build.query<any, any>({
      query: (roleid) => ({
        url: `permissions/getallpermissions`,
      }),
    }),
    updatePermissions: build.mutation<any, any>({
      query: (body) => ({
        url: `permissions/updatepermissions`,
        method: 'put',
        body: body,
      }),
    }),
  }),
  overrideExisting: true,
});

export const { useGetAllPermissionsByRoleQuery, useGetAllPermissionsQuery, useUpdatePermissionsMutation } = permissionApi