import { baseApi, PagedTableDto } from './base-api'

export const depotApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getDepots: build.query<any, PagedTableDto>({
            query: (dto) => ({
                url: `depots/getdepots?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
            }),
            transformResponse: (response: any, meta, arg) => {
                var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

                return {
                    list: response,
                    pagination: pagination
                };
            }
        }),
        createDepot: build.mutation<any, any>({
            query: (body) => ({
                url: 'depots/adddepot',
                method: 'post',
                body: body
            }),
        }),
        updateDepot: build.mutation<any, any>({
            query: (body) => ({
                url: `depots/updatedepot/${body.id}`,
                method: 'put',
                body: body
            }),
        }),
        getDepot: build.query<any, any>({
            query: (depotId) => ({
                url: `depots/getdepot/${depotId}`,
            }),
        }),
        getAllDepots: build.query<any, any>({
            query: () => ({
                url: `depots/getalldepots`,
            }),
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetDepotsQuery,
    useCreateDepotMutation,
    useUpdateDepotMutation,
    useGetDepotQuery,
    useGetAllDepotsQuery
} = depotApi