import { View, Text, StyleSheet, Image } from "react-native";
import { MaterialCommunityIcons, AntDesign, EvilIcons, MaterialIcons, FontAwesome, Feather } from '@expo/vector-icons';
import { useSelector } from "react-redux";
import { RootState } from "@store/index";
import { AdministrationPermissions, PermissionCategories, ReportsPermissions, UserManagementPermissions } from "@web/screens/AuthenticatedScreens/System/Permissions/permissionsEnums";
import { hasCategoryAccess, hasFeatureAccess } from "@web/screens/AuthenticatedScreens/System/Permissions/permissionsUtils";
import Constants from "expo-constants";

export const DrawerContent = (props) => {
  const user = useSelector((state: RootState) => state.user.user);
  const modules = useSelector((state: RootState) => state.user.modules);
  const permissions = useSelector((state: RootState) => state.user.user.permissions);
  const isSystemUser = user.role === "System";
  const isEngineer = user.role === "Engineer";
  const isUserPermissionsEnabled = modules.isUserPermissionsEnabled;

  // @ts-ignore
  const VERSION = Constants.expoConfig.versionString;

  const renderReportsMenu = () => {
    return (
      <View>
        {(!isUserPermissionsEnabled || (hasCategoryAccess(user, permissions, isSystemUser, PermissionCategories.Reports))) && (
          <View>
            <Text style={styles.title}>Reports</Text>
            <View>
              <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("reports-asset-register")}>
                <MaterialIcons style={styles.link__icon} name="web-asset" size={18} color="black" />
                Asset Register
              </Text>
            </View>
            {(modules.isRoteEnabled && !isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Reports, ReportsPermissions.Inspections))) && (
              <View>
                <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("reports-inspections")}>
                  <MaterialIcons style={styles.link__icon} name="check-circle" size={18} color="black" />
                  Inspections
                </Text>
              </View>
            )}
            {(modules.isBreakdownEnabled && !isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Reports, ReportsPermissions.RepairBreakdown))) && (
              <View>
                <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("reports-breakdowns")}>
                  <MaterialIcons style={styles.link__icon} name="check-circle" size={18} color="black" />
                  Repair/Breakdown
                </Text>
              </View>
            )}
            {(modules.isServiceEnabled && !isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Reports, ReportsPermissions.Servicing))) && (
              <View>
                <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("reports-servicing")}>
                  <MaterialIcons style={styles.link__icon} name="check-circle" size={18} color="black" />
                  Servicing
                </Text>
              </View>
            )}
            {(modules.isTimesheetEnabled && !isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Reports, ReportsPermissions.Erstr))) && (
              <View>
                <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("reports-erstr")}>
                  <MaterialIcons style={styles.link__icon} name="check-circle" size={18} color="black" />
                  ERSTR
                </Text>
              </View>
            )}
            {(modules.isPOWRAEnabled && !isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Reports, ReportsPermissions.Powra))) && (
              <View>
                <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("reports-rams")}>
                  <MaterialIcons style={styles.link__icon} name="check-circle" size={18} color="black" />
                  PoWRA
                </Text>
              </View>
            )}
            {(modules.isProofLoadEnabled && !isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Reports, ReportsPermissions.ProofLoads))) && (
              <View>
                <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("reports-proofloads")}>
                  <MaterialIcons style={styles.link__icon} name="check-circle" size={18} color="black" />
                  Proof Loads
                </Text>
              </View>
            )}
            {(modules.isInstallationEnabled && !isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Reports, ReportsPermissions.Installations))) && (
              <View>
                <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("reports-installations")}>
                  <MaterialIcons style={styles.link__icon} name="check-circle" size={18} color="black" />
                  Installations
                </Text>
              </View>
            )}
            {(modules.isPssrEnabled && !isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Reports, ReportsPermissions.Pssr))) && (
              <View>
                <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("reports-pssr")}>
                  <MaterialIcons style={styles.link__icon} name="check-circle" size={18} color="black" />
                  PSSR
                </Text>
              </View>
            )}
          </View>
        )}
      </View>
    );
  };

  const renderAdminMenu = () => {
    if (!isUserPermissionsEnabled && user.role == "User") {
      return null;
    }
    return (
      <View>
        {(!isUserPermissionsEnabled || hasCategoryAccess(user, permissions, isSystemUser, PermissionCategories.Administration)) && (
          <View>
            <Text style={styles.title}>Administration</Text>
            {(!isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Administration, AdministrationPermissions.JobManagement))) && (
              <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("job-management")}>
                <MaterialIcons style={styles.link__icon} name="web-asset" size={18} color="black" />
                Job Management
              </Text>
            )}
            {(!isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Administration, AdministrationPermissions.JobApproval))) && modules.isJobApprovalEnabled && (
              <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("job-approval")}>
                <MaterialIcons style={styles.link__icon} name="web-asset" size={18} color="black" />
                Job Approval
              </Text>
            )}
            {(!isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Administration, AdministrationPermissions.CompanyManagement))) && (
              <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("administration-companies")}>
                <AntDesign style={styles.link__icon} name="iconfontdesktop" size={18} color="black" />
                Company Management
              </Text>
            )}
            {(!isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Administration, AdministrationPermissions.LocationManagement))) && (
              <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("administration-locations")}>
                <EvilIcons style={styles.link__icon} name="location" size={18} color="black" />
                Location Management
              </Text>
            )}
            {(!isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Administration, AdministrationPermissions.LocationManagement))) && (
              <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("administration-depots")}>
                <EvilIcons style={styles.link__icon} name="location" size={18} color="black" />
                Depot Management
              </Text>
            )}
            {(!isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Administration, AdministrationPermissions.CategoryManagement))) && (
              <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("administration-categories")}>
                <AntDesign style={styles.link__icon} name="folder1" size={18} color="black" />
                Category Management
              </Text>
            )}
            {(!isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Administration, AdministrationPermissions.ManufacturersManagement))) && (
              <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("administration-manufacturers")}>
                <FontAwesome style={styles.link__icon} name="certificate" size={18} color="black" />
                Manufacturers Management
              </Text>
            )}
            {(!isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Administration, AdministrationPermissions.EquipmentManagement))) && (
              <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("administration-equipment")}>
                <FontAwesome style={styles.link__icon} name="cog" size={18} color="black" />
                Equipment Management
              </Text>
            )}
            {(!isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Administration, AdministrationPermissions.BreakdownParts))) && (
              <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("administration-breakdown-parts")}>
                <FontAwesome style={styles.link__icon} name="cog" size={18} color="black" />
                Breakdown Parts
              </Text>
            )}
            {(!isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Administration, AdministrationPermissions.BreakdownParts))) && (
              <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("administration-breakdown-faults")}>
                <FontAwesome style={styles.link__icon} name="cog" size={18} color="black" />
                Breakdown Faults
              </Text>
            )}
            {(!isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Administration, AdministrationPermissions.ServicingQuestions))) && (
              <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("administration-servicing-questions")}>
                <FontAwesome style={styles.link__icon} name="cog" size={18} color="black" />
                Servicing Questions
              </Text>
            )}
            {(!isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Administration, AdministrationPermissions.InstallationQuestions))) && (
              <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("administration-installation-questions")}>
                <FontAwesome style={styles.link__icon} name="cog" size={18} color="black" />
                Installation Questions
              </Text>
            )}
            {(!isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Administration, AdministrationPermissions.PssrParts))) && (
              <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("administration-pssr-parts")}>
                <FontAwesome style={styles.link__icon} name="cog" size={18} color="black" />
                PSSR Parts
              </Text>
            )}
            {(!isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Administration, AdministrationPermissions.ColoursManagement))) && (
              <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("administration-colours")}>
                <FontAwesome style={styles.link__icon} name="paint-brush" size={18} color="black" />
                Colours Management
              </Text>
            )}
            {(!isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Administration, AdministrationPermissions.EmailsManagement))) && modules.isNotificationsEnabled && (
              <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("administration-emails")}>
                <FontAwesome style={styles.link__icon} name="envelope" size={18} color="black" />
                Emails Management
              </Text>
            )}
          </View>
        )}
      </View>
    );
  };

  const renderUserManagementMenu = () => {
    if (!isUserPermissionsEnabled && user.role == "User") {
      return null;
    }
    return (
      <View>
        {(!isUserPermissionsEnabled || hasCategoryAccess(user, permissions, isSystemUser, PermissionCategories.UserManagement)) && (
          <View>
            <Text style={styles.title}>Users</Text>
            {(!isUserPermissionsEnabled || hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.UserManagement, UserManagementPermissions.WebUsersManagement)) && (
              <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("administration-web-users")}>
                <Feather style={styles.link__icon} name="users" size={18} color="black" />
                Web Users
              </Text>
            )}
            {(!isUserPermissionsEnabled || hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.UserManagement, UserManagementPermissions.EngineersManagement)) && (
              <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("administration-engineers")}>
                <Feather style={styles.link__icon} name="users" size={18} color="black" />
                Engineers
              </Text>
            )}
          </View>
        )}
      </View>
    );
  };

  const renderPermissionsMenu = () => {
    if (!isUserPermissionsEnabled) {
      return null;
    }
    return (<View>
      {(!isUserPermissionsEnabled || hasCategoryAccess(user, permissions, isSystemUser, PermissionCategories.Permissions)) && <View>
        <Text style={styles.title}>Permissions</Text>
        <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("permissions")}>
          <Feather style={styles.link__icon} name="toggle-left" size={18} color="black" />
          Permissions
        </Text>
      </View>}
    </View>)
  }

  const renderSupportMenu = () => {
    return (
      <View>
        <Text style={styles.title}>Support</Text>
        <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("help-centre")}>
          <Feather style={styles.link__icon} name="help-circle" size={18} color="black" />
          Help Centre
        </Text>
    </View>
  )}

  return (
    <View style={styles.navigation}>
      <Image style={styles.logo} source={{ uri: user.brandingImageBase64 }} />
      {isEngineer ? renderSupportMenu() : (
        <>
          <Text style={styles.link} onPress={() => props.navigation.navigate("dashboard")}>
            <MaterialCommunityIcons style={styles.link__icon} name="monitor-dashboard" size={18} color="black" />
            Dashboard
          </Text>
          {isSystemUser &&
            <View>
              <Text style={styles.title}>System</Text>
              <View>
                <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("customers")}>
                  <AntDesign style={styles.link__icon} name="customerservice" size={18} color="black" />
                  Customers
                </Text>
              </View>
            </View>
          }
          {renderReportsMenu()}
          {renderSupportMenu()}
          {renderAdminMenu()}
          {renderUserManagementMenu()}
          {renderPermissionsMenu()}
        </>
      )}
      <Text style={styles.version}>V: {VERSION}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  navigation: {
    height: '100%',
    overflowY: 'scroll',
    paddingBottom: 10
  },

  sub__menu: {
    paddingLeft: 20,
  },

  title: {
    fontFamily: 'OpenSans-ExtraBold',
    padding: 10,
  },

  link: {
    padding: 10,
    borderBottomColor: '#CECECE',
    borderBottomWidth: 1,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: 'OpenSans-Regular'
  },

  link__icon: {
    paddingRight: 10,
  },

  logo: {
    width: '100%',
    height: 60,
    resizeMode: 'contain',
    marginTop: 10,
  },

  version: {
    padding: 10,
    fontFamily: 'OpenSans-Regular',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
});
