import * as React from 'react';
import { useState, useEffect } from 'react';
import { Picker } from '@react-native-picker/picker';
import { useGetAllCompaniesQuery } from '@store/services/api/company-api';
import { useGetAllLocationsQuery, useGetAllSubLocationsQuery } from '@store/services/api/location-api';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, ScrollView } from 'react-native';
import GlobalStyle from '../../../../../constants/GlobalStyle';
import { useGetEngineersQuery } from '@store/services/api/user-api';
import moment from 'moment';
import DateTimePicker from '@web/components/navigation/controls/DateTimePicker';
import { useGetAllCategoriesQuery } from '@store/services/api/category-api';
import { Foundation, SimpleLineIcons } from '@expo/vector-icons';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { PagedTableDto } from '@store/services/api/base-api';
import { DataTable } from 'react-native-paper';
import { serviceApi } from '@store/services/api/service-api';
import { useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { ReportableService } from '@store/services/reportable/reportable.service';
import { Basket, BasketIcon } from '@web/components/Basket';
import { ImageViewer } from '@web/components/ImageViewer';
import { ReportKey } from '@web/components/ReportKey';
import Spinner from 'react-native-loading-spinner-overlay';
import { DataTableHeader } from '@web/components/table/DatatableHeader';
import PaginationComponent from '@web/components/Pagination';
import { MAX_ITEMS_BEFORE_ALL, FIRST_PAGE } from '../../../../../constants/Constants';

export default function ServiceInspectionScreen({ navigation }: { navigation: any }) {
  const user = useSelector((state: RootState) => state.user.user);
  const [loading, setLoading] = useState<any>(false);
  const [sortedColumn, setSortedColumn] = React.useState<string>("inspectionDate");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(false);
  const [onBasketClose, setOnBasketClose] = useState<boolean>(false);

  const { data: companies } = useGetAllCompaniesQuery(null);
  const { data: locations } = useGetAllLocationsQuery(null);
  const { data: engineers } = useGetEngineersQuery(null);
  const { data: categories } = useGetAllCategoriesQuery(null);
  const { data: subLocations } = useGetAllSubLocationsQuery(null);

  const [page, setPage] = React.useState<number>(0);
  const [trigger, inspectionResult] = serviceApi.useLazyGetServicingInspectionsQuery();
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);

  const [companyId, setCompanyId] = useState(user.companyId);
  const [locationId, setLocationId] = useState("");
  const [subLocationId, setSubLocationId] = useState("");
  const [engineerId, setEngineerId] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [startDate, setStartDate] = useState(moment().add(-6, 'months').toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [serialNumber, setSerialNumber] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [assetNumber, setAssetNumber] = useState("");

  const [imagesToView, setImagesToView] = useState([]);

  const updateDateRange = (val) => {
    setDateRange(val);

    let startDate = moment();
    const endDate = moment();
    if (val == "-1") {
      startDate = moment().add(-1, 'week');
    } else {
      startDate = moment().add(-Number.parseInt(val), 'M');
    }

    setStartDate(startDate.toDate());
    setEndDate(endDate.toDate());
  }

  const resetFilters = () => {
    setCompanyId(user.companyId);
    setLocationId("");
    setSubLocationId("");
    setEngineerId("");
    setDateRange("");
    setStartDate(moment().add(-6, 'months').toDate());
    setEndDate(moment().toDate());
    setSerialNumber("");
    setCategoryId("");
    setAssetNumber("");
  }

  function applyFilters() {
    setPage((curr) => {
      if (curr == 0) loadTable();
      return 0;
    })
  }

  const loadTable = () => {
    const pageNumber = numberOfItemsPerPage > MAX_ITEMS_BEFORE_ALL ? FIRST_PAGE : page;
    var dto = new PagedTableDto(pageNumber, {
      companyId: companyId,
      locationId: locationId,
      subLocationId: subLocationId,
      engineerId: engineerId,
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString(),
      serialNumber: serialNumber,
      categoryId: categoryId,
      pageSize: numberOfItemsPerPage,
      assetNumber: assetNumber
    }, sortedColumn, sortedColumnAscending);
    trigger(dto).then(() => {
      setLoading(false);
    });
  }

  const downloadCert = async (inspection) => {
    setLoading(true);
    var reportableService = new ReportableService();
    var data = await reportableService.downloadService(inspection.id);
    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data.data, data.filename);
      setLoading(false);
    }
  }

  const downloadRAMS = async (inspection) => {
    setLoading(true);
    var reportableService = new ReportableService();
    var data = await reportableService.downloadRAMS(inspection.ramsId);

    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data.data, data.filename);
      setLoading(false);
    }
  }

  useEffect(() => {
    loadTable();
  }, [page, sortedColumn, sortedColumnAscending, numberOfItemsPerPage, onBasketClose])

  if (inspectionResult.isLoading) {
    return <LoadingIndicator />
  }


  return (
    <ScrollView>
      <Spinner
        visible={loading}
        textContent={'Loading...'}
        textStyle={GlobalStyle.spinnerTextStyle}
      />
      <View style={styles.container}>
        {imagesToView.length > 0 && <ImageViewer images={imagesToView} onClosed={() => setImagesToView([])} />}
        <View style={GlobalStyle.filters}>
          <View style={GlobalStyle.filters__header}>
            <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
          </View>

          <View style={GlobalStyle.filter__columns}>
            <View style={GlobalStyle.column__flex}>
              <Text style={GlobalStyle.column__header}>Company</Text>
              <Picker
                style={[GlobalStyle.column, GlobalStyle.column__input]}
                selectedValue={companyId}
                enabled={user.role != 'User'}
                onValueChange={(itemValue, itemIndex) => { setCompanyId(itemValue); setLocationId(""); setSubLocationId(""); }}>

                <Picker.Item label="Please Select" value="" />

                {companies != null && companies.map((value, index) => {
                  return (
                    <Picker.Item key={value.id} label={value.name} value={value.id} />
                  )
                })}
              </Picker>
            </View>

            <View style={GlobalStyle.column__flex}>
              <Text style={GlobalStyle.column__header}>Location</Text>
              <Picker
                style={[GlobalStyle.column, GlobalStyle.column__input]}
                selectedValue={locationId}
                onValueChange={(itemValue, itemIndex) => setLocationId(itemValue)}>

                <Picker.Item label="Please Select" value="" />

                {locations != null && locations.filter(l => companyId == "" || l.companyId == companyId).map((value, index) => {
                  return (
                    <Picker.Item key={value.id} label={value.name} value={value.id} />
                  )
                })}
              </Picker>
            </View>

            <View style={GlobalStyle.column__flex}>
              <Text style={GlobalStyle.column__header}>Sub-Location</Text>
              <Picker
                style={[GlobalStyle.column, GlobalStyle.column__input]}
                selectedValue={subLocationId}
                onValueChange={(itemValue, itemIndex) => setSubLocationId(itemValue)}>

                <Picker.Item label="Please Select" value="" />

                {subLocations != null && subLocations.filter(sl => locationId == "" && companyId == "" || sl.locationId == locationId).map((value, index) => {
                  return (
                    <Picker.Item key={value.id} label={value.name} value={value.id} />
                  )
                })}
              </Picker>
            </View>
            <View style={GlobalStyle.column__flex}>
              <Text style={GlobalStyle.column__header}>Engineers</Text>
              <Picker
                style={[GlobalStyle.column, GlobalStyle.column__input]}
                selectedValue={engineerId}
                onValueChange={(itemValue, itemIndex) => setEngineerId(itemValue)}>

                <Picker.Item label="Please Select" value="" />

                {engineers != null && engineers.map((value, index) => {
                  return (
                    <Picker.Item key={value.id} label={value.name} value={value.id} />
                  )
                })}
              </Picker>
            </View>
          </View>
          <View style={GlobalStyle.filter__columns}>
            <View style={GlobalStyle.column__flex}>
              <Text style={GlobalStyle.column__header}>Date Range</Text>
              <Picker
                style={[GlobalStyle.column, GlobalStyle.column__input]}
                selectedValue={dateRange}
                onValueChange={(itemValue, itemIndex) => updateDateRange(itemValue)}>

                <Picker.Item label="Predefined Date Range" value="" />
                <Picker.Item label="1 Week" value="-1" />
                <Picker.Item label="1 Month" value="1" />
                <Picker.Item label="3 Months" value="3" />
                <Picker.Item label="6 Months" value="6" />
                <Picker.Item label="12 Months" value="12" />

              </Picker>
            </View>

            <View style={GlobalStyle.column__flex}>
              <Text style={GlobalStyle.column__header}>Start Date</Text>
              <DateTimePicker
                style={[GlobalStyle.column, GlobalStyle.column__input]}
                onChange={setStartDate}
                value={startDate}>
              </DateTimePicker>
            </View>

            <View style={GlobalStyle.column__flex}>
              <Text style={GlobalStyle.column__header}>End Date</Text>
              <DateTimePicker
                style={[GlobalStyle.column, GlobalStyle.column__input,]}
                onChange={setEndDate}
                value={endDate}>
              </DateTimePicker>
            </View>
          </View>

          <View style={GlobalStyle.filter__columns}>


            <View style={GlobalStyle.column__flex}>
              <Text style={GlobalStyle.column__header}>Categories</Text>
              <Picker
                style={[GlobalStyle.column, GlobalStyle.column__input]}
                selectedValue={categoryId}
                onValueChange={(itemValue, itemIndex) => setCategoryId(itemValue)}>

                <Picker.Item label="Please Select" value="" />

                {categories != null && categories.map((value, index) => {
                  return (
                    <Picker.Item key={value.id} label={value.name} value={value.id} />
                  )
                })}
              </Picker>
            </View>
            <View style={GlobalStyle.column__flex}>
              <Text style={GlobalStyle.column__header}>Serial #</Text>
              <TextInput
                style={[GlobalStyle.column, GlobalStyle.column__input]}
                onChangeText={setSerialNumber}
                value={serialNumber}
              />
            </View>
            <View style={GlobalStyle.column__flex}>
              <Text style={GlobalStyle.column__header}>Asset #</Text>
              <TextInput
                style={[GlobalStyle.column, GlobalStyle.column__input]}
                onChangeText={setAssetNumber}
                value={assetNumber}
              />
            </View>

            <View style={GlobalStyle.column__flex}>
              <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={applyFilters}>
                  <SimpleLineIcons name="magnifier" size={16} color="white" />
                </TouchableOpacity>

                <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                  <SimpleLineIcons name="ban" size={16} color="black" />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>

        <Basket onBasketClose={onBasketClose} setOnBasketClose={setOnBasketClose} />

        <View style={{ flexDirection: 'row' }}>
          <ReportKey showRote={true} showPowra={true} roteLabel={"Servicing"} showChecklist={false} />
          <View style={{ justifyContent: 'flex-start', alignItems: 'center', paddingBottom: 10 }}>
          </View>
        </View>

        <DataTable style={GlobalStyle.table}>
          <DataTableHeader
            sortedColumn={sortedColumn}
            sortedColumnAscending={sortedColumnAscending}
            onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
            headers={[
              { text: 'Basket', visible: true },
              { text: 'Report', visible: true },
              { text: 'Description', sortedName: "description", visible: true },
              { text: 'Service Date', sortedName: "inspectionDate", visible: true },
              { text: 'Next Service Date', sortedName: "nextInspectionDate", visible: true },
              { text: 'Safe For Use', sortedName: "safeForUse", visible: true },
              { text: 'Missing', sortedName: "missing", visible: true },
              { text: 'Serial Number', sortedName: "serialNumber", visible: true },
              { text: 'Asset Number', sortedName: "assetNumber", visible: true },
              { text: 'Manufacturer', sortedName: "manufacturerName", visible: true },
              { text: 'Category', sortedName: "categoryName", visible: true },
              { text: 'Location', sortedName: "locationName", visible: true },
              { text: 'Company', sortedName: "companyName", visible: true },
            ]}
          />
          {inspectionResult.data?.list.map((element: any) => {
            return (
              <DataTable.Row key={element.id}>
                <DataTable.Cell>
                  <BasketIcon inspectionId={element.id} serialNumber={element.serialNumber} type={"service"} masterCategoryName={null} />
                </DataTable.Cell>
                <DataTable.Cell>
                  <View style={{ flexDirection: 'row' }}>
                    {!element.isInstallationService && <TouchableOpacity onPress={() => downloadCert(element)}><Foundation name="page-pdf" size={24} color="black" /></TouchableOpacity>}
                    {element.ramsId != null && <TouchableOpacity style={{ marginLeft: 20 }} onPress={() => downloadRAMS(element)}><Foundation name="page-pdf" size={24} color="blue" /></TouchableOpacity>}
                    {element.images != null && element.images.length > 0 &&
                      <TouchableOpacity style={{ marginLeft: 10 }} onPress={() => setImagesToView(element.images)}>
                        <Foundation name="camera" size={24} color="black" />
                      </TouchableOpacity>
                    }
                  </View>
                </DataTable.Cell>
                <DataTable.Cell>{element.description}</DataTable.Cell>
                <DataTable.Cell>{!element.isInstallationService ? element.inspectionDateFormatted : "N/A"}</DataTable.Cell>
                <DataTable.Cell>{element.nextInspectionDateFormatted}</DataTable.Cell>
                <DataTable.Cell>{element.safeForUseFormatted}</DataTable.Cell>
                <DataTable.Cell>{element.missingFormatted}</DataTable.Cell>
                <DataTable.Cell>{element.serialNumber}</DataTable.Cell>
                <DataTable.Cell>{element.assetNumber}</DataTable.Cell>
                <DataTable.Cell>{element.manufacturerName}</DataTable.Cell>
                <DataTable.Cell>{element.categoryName}</DataTable.Cell>
                <DataTable.Cell>{element.locationName}</DataTable.Cell>
                <DataTable.Cell>{element.companyName}</DataTable.Cell>
              </DataTable.Row>
            )
          })}
        </DataTable>
      </View>
      <PaginationComponent
        numberOfItemsPerPage={numberOfItemsPerPage}
        setNumberOfItemsPerPage={setNumberOfItemsPerPage}
        page={page}
        setPage={setPage}
        fetchResult={inspectionResult}
        loading={loading}
        setLoading={setLoading}
      />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },

});
