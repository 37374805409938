import { ReportableService } from "./reportable.service";

const INSPECTION_JOBTYPE = 'ROTE';
const BREAKDOWN_JOBTYPE = 'Breakdown';
const SERVICE_JOBTYPE = 'Service';

export async function downloadCert(inspection: any): Promise<any> {
    const data = await getReportData(inspection);

    if (data != null) {
        const fileSaver = require('file-saver');
        fileSaver(data.data, data.filename);
    }
}

export async function downloadRAMS(inspection: any): Promise<any> {
    var reportableService = new ReportableService();
    var data = await reportableService.downloadRAMS(inspection.ramsId);
    
    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data.data, data.filename);
    }
}

function getReportData(inspection: any): Promise<any> {
    const reportableService = new ReportableService();

    if (inspection.jobType === INSPECTION_JOBTYPE) {
        if (inspection.masterCategoryName == "PPE") return reportableService.downloadPPE(inspection.id);
        if (inspection.masterCategoryName == "Marine 2006") return reportableService.downloadMarine(inspection.id);
        if (inspection.masterCategoryName == "PUWER 1998") return reportableService.downloadMachine(inspection.id);
        return reportableService.downloadRote(inspection.id);
    }
    if (inspection.jobType === BREAKDOWN_JOBTYPE) return reportableService.downloadBreakdown(inspection.id);
    if (inspection.jobType === SERVICE_JOBTYPE) return reportableService.downloadService(inspection.id);
    throw new Error("Couldn't download report: Unsupported JobType");
}