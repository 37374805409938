import * as React from 'react';
import { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity } from 'react-native';
import { useIsFocused } from "@react-navigation/native";
import { customerApi } from '@store/services/api/customer-api';
import { PagedTableDto } from '@store/services/api/base-api';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import GlobalStyle from '../../../../../../constants/GlobalStyle';
import { DataTable } from 'react-native-paper';
import { Entypo, MaterialIcons, Ionicons } from '@expo/vector-icons';
import { useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { useDispatch } from 'react-redux';
import { setCustomer, setUser, setUserModules } from '@store/slices/user-slice';
import { useUpdateCustomerIdMutation } from '@store/services/api/user-api';
import { DataTableHeader } from '@web/components/table/DatatableHeader';
import PaginationComponent from '@web/components/Pagination';
import { MAX_ITEMS_BEFORE_ALL, FIRST_PAGE } from '../../../../../../constants/Constants';
import Spinner from 'react-native-loading-spinner-overlay';

export default function CustomerListScreen({navigation}: {navigation: any}) {
  const user = useSelector((state: RootState) => state.user.user);
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const [updateUserCustomerId, updateUserCustomerIdResult] = useUpdateCustomerIdMutation();

  const [page, setPage] = React.useState<number>(0);
  const [sortedColumn, setSortedColumn] = React.useState("name")
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState(true)
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);
  const [loading, setLoading] = useState<any>(false);

  const [trigger, customersResult] = customerApi.useLazyGetCustomersQuery();

  const loadTable = () => {
    const pageNumber = numberOfItemsPerPage > MAX_ITEMS_BEFORE_ALL ? FIRST_PAGE : page;
    var dto = new PagedTableDto(pageNumber, {
      pageSize: numberOfItemsPerPage 
    }, sortedColumn, sortedColumnAscending);
    trigger(dto).then(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    if (isFocused) {
      loadTable();
    }
  }, [page, isFocused, sortedColumn, sortedColumnAscending,numberOfItemsPerPage]);

  const viewAsCustomer = async (customerId, customerName) => {
    var result = await updateUserCustomerId({
      id: user.id,
      customerId: customerId
    }) as any;

    if (result.error) {
      alert(result.error.data);
      return;
    }

    const returnedUser = result.data;
    dispatch(setUser(returnedUser));
    dispatch(setUserModules(returnedUser.modules));
  }

  if (customersResult.isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <View style={styles.container}>
      <Spinner
        visible={loading}
        textContent={'Loading...'}
        textStyle={GlobalStyle.spinnerTextStyle}
      />
      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>
      </View>

      <TouchableOpacity style={GlobalStyle.add__button} onPress={() => navigation.navigate("manage")}>
        <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 5, paddingRight: 10}}>
          <Ionicons name="add" size={24} color="white" /><Text style={GlobalStyle.form__submit__button__text}>Add New Customer</Text>
        </View>
      </TouchableOpacity>

      <DataTable style={GlobalStyle.table}>
        <DataTableHeader
          sortedColumn={sortedColumn}
          sortedColumnAscending={sortedColumnAscending}
          onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
          headers={[
            { text: 'Name', sortedName: "name", visible: true },
            { text: 'Address', sortedName: "address", visible: true },
            { text: 'Email Address', visible: true },
            { text: 'Edit', visible: true },
            { text: 'Manage', visible: true },
            { text: 'View', visible: true }
          ]}
        />

        { customersResult?.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>{element.name}</DataTable.Cell>
              <DataTable.Cell>{element.address}</DataTable.Cell>
              <DataTable.Cell>{element.emailAddress}</DataTable.Cell>
              <DataTable.Cell>
                <TouchableOpacity onPress={() => navigation.navigate("manage", { customerId: element.id })}>
                  <Entypo name="pencil" size={24} color="black" />
                </TouchableOpacity>
              </DataTable.Cell>
              <DataTable.Cell>
                <TouchableOpacity onPress={() => navigation.navigate("dashboard", { customerId: element.id })}>
                  <Entypo name="cog" size={24} color="black" />
                </TouchableOpacity>
              </DataTable.Cell>
              <DataTable.Cell>
                <TouchableOpacity onPress={() => viewAsCustomer(element.id, element.name)}>
                  <MaterialIcons name="preview" size={24} color="black" />
                </TouchableOpacity>
              </DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>
      <PaginationComponent
        numberOfItemsPerPage={numberOfItemsPerPage}
        setNumberOfItemsPerPage={setNumberOfItemsPerPage}
        page={page}
        setPage={setPage}
        fetchResult={customersResult}
        loading={loading}
        setLoading={setLoading}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
