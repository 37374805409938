import { View, StyleSheet, Modal, Text } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import GlobalStyle from '../../../constants/GlobalStyle';
import { Picker } from '@react-native-picker/picker';
import { useState } from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';

export const EquipmentTransfer = ({ isVisible, setVisible, equipmentCount, companies, locations, transferEquipment, oldLocationId }) => {
    const [companyId, setCompanyId] = useState("");
    const [locationId, setLocationId] = useState("");
    const [isError, setIsError] = useState(false);

    const handleTransfer = () => {
        if (!companyId || !locationId) {
            setIsError(true)
            return;
        }

        transferEquipment(locationId);
        setVisible(false);
    };
    return (
        <View>
            <Modal
                animationType="fade"
                transparent={true}>
                <View style={styles.modalContainer}>
                    <View style={styles.centeredContainer}>
                        <button onClick={() => setVisible(false)} style={styles.closeButton}>
                            <AntDesign name="closecircle" size={24} color="black" />
                        </button>
                        <Text style={styles.headerText}>Location is not empty, {equipmentCount} assets need to be transfered before archiving </Text>
                        <Text style={styles.infoText}>To proceed please transfer all equipment to the new location below </Text>
                        <View style={[GlobalStyle.column__flex, styles.picker]}>
                            <Text style={GlobalStyle.column__header}>Company</Text>
                            <Picker
                                style={[GlobalStyle.column, GlobalStyle.column__input]}
                                selectedValue={companyId}
                                onValueChange={(itemValue, itemIndex) => { setCompanyId(itemValue); setLocationId(""); setIsError(false) }}>

                                <Picker.Item label="Please Select" value="" />

                                {companies != null && companies.map((value, index) => {
                                    return (
                                        <Picker.Item key={value.id} label={value.name} value={value.id} />
                                    )
                                })}
                            </Picker>
                            {(isError && companyId === "") && <Text style={styles.errorMessage}>Please select the company</Text>}
                        </View>

                        <View style={[GlobalStyle.column__flex, styles.picker]}>
                            <Text style={GlobalStyle.column__header}>Location</Text>
                            <Picker
                                style={[GlobalStyle.column, GlobalStyle.column__input]}
                                selectedValue={locationId}
                                onValueChange={(itemValue, itemIndex) => { setLocationId(itemValue); setIsError(false) }}>

                                <Picker.Item label="Please Select" value="" />

                                {(locations != null && companyId !== "") && locations.filter(l => (companyId == "" || l.companyId == companyId) && l.id !== oldLocationId).map((value, index) => {
                                    return (
                                        <Picker.Item key={value.id} label={value.name} value={value.id} />
                                    )
                                })}
                            </Picker>
                            {(isError && locationId === "") && <Text style={styles.errorMessage}>Please select the location</Text>}
                        </View>
                        <View style={{ width: '95%' }}>
                            <TouchableOpacity
                                style={[GlobalStyle.add__button, { alignSelf: 'flex-end', marginTop: 10, justifyContent: 'center' }]}
                                onPress={() => { handleTransfer() }}>
                                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 5, paddingRight: 10 }}>
                                    <Text style={GlobalStyle.form__submit__button__text}>Transfer equipment</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>

            </Modal>
        </View>
    )
}


const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },

    centeredContainer: {
        flexDirection: 'column',
        backgroundColor: '#FFF',
        width: 420,
        height: 420,
        padding: 40,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'stretch'
    },

    closeButton: {
        position: 'absolute',
        top: 10,
        right: 10,
        padding: 10,
        borderWidth: 0,
        backgroundColor: 'white',
        borderRadius: 20,
        cursor: 'pointer'
    },
    headerText: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
        marginTop: 10,
        textAlign: 'center',
    },
    infoText: {
        fontSize: 16,
        marginBottom: 20,
        textAlign: 'center',
    },
    picker: {
        width: '100%',
    },
    errorMessage: {
        color: 'red',
        fontSize: 12,
        marginTop: 5,
        textAlign: 'left',
    }

});
