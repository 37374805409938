import {Dimensions, Modal, Pressable, StyleSheet, View} from "react-native";
import React from "react";
import {HelpCentreVideo} from "@store/services/api/help-centre-api";

interface VideoModalProps {
  video: HelpCentreVideo,
  onRequestClose: () => void,
  visible: boolean,
  onSuccess?: () => void
}

export function VideoViewModal({video, onRequestClose, visible}: VideoModalProps) {
  const videoHeight = Dimensions.get("window").height * 0.8;

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onRequestClose={onRequestClose}>
      <Pressable style={styles.modalContainer} onPress={onRequestClose}>
        <View style={{paddingHorizontal: 12, paddingVertical: 12}}>
          <video src={video?.url} controls autoPlay height={videoHeight}/>
        </View>
      </Pressable>
    </Modal>
  )
}
const styles = StyleSheet.create({
  modalContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  }
});
