import {Text, View, FlatList, StyleSheet, Dimensions, Pressable} from "react-native";
import {CompanyFile, useGetAllCompaniesQuery, useLazyGetCompanyFilesQuery} from "@store/services/api/company-api";
import {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "@store/index";
import {Feather} from "@expo/vector-icons";
import {convertBase64toBlob} from "@components/helpers/Extensions";
import {EmptyListIndicator} from "@components/FlatListComponents";
import {CompanyIdFilter} from "@web/components/search-filters/SearchFilters";
import * as React from "react";

export function CompanyDocumentsSection() {
  const user = useSelector((state: RootState) => state.user.user);
  const [fetchDocuments, {data: documents, isFetching, isError}] = useLazyGetCompanyFilesQuery();
  const { data: companies } = useGetAllCompaniesQuery(null);
  const [companyId, setCompanyId] = useState<string>();

  useEffect(() => {
    if (user?.companyId) {
      setCompanyId(user.companyId);
    }
  }, [user]);

  useEffect(() => {
    fetchDocuments(companyId);
  }, [companyId]);

  function downloadDocument(file: CompanyFile) {
    let blob = convertBase64toBlob(file.base64, file.mimeType);

    const fileSaver = require('file-saver');
    fileSaver(blob, file.fileName);
  }

  const renderDocumentItem = useCallback(({item}: { item: CompanyFile }) => {
    const size = Math.max(125, Dimensions.get('window').width / 12)
    const dimensionsStyle = {
      width: size,
      height: size
    };
    
    return (
      <Pressable
        onPress={() => downloadDocument(item)}
        style={{...styles.documentItem, ...dimensionsStyle}}>
        <Feather name="file-text" size={24} color="#333" style={styles.icon}/>
        <Text style={styles.documentTitle}>{item.fileName}</Text>
      </Pressable>
    )
  }, []);

  const emptyListText = isError ? 'There was an issue retrieving documents.' : 'No documents available.';

  return (
    <>
      {!user?.companyId && (
        <View style={styles.dropdownContainer}>
          <CompanyIdFilter value={companyId} onValueChange={setCompanyId} companies={companies} />
        </View>
      )}
      <FlatList
        data={documents}
        renderItem={renderDocumentItem}
        keyExtractor={(item, i) => i.toString()}
        contentContainerStyle={styles.container}
        horizontal
        ItemSeparatorComponent={() => <View style={styles.separator} />}
        ListEmptyComponent={<EmptyListIndicator isLoading={isFetching} emptyText={emptyListText} />}
      />
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  documentItem: {
    cursor: 'pointer',
    padding: 10,
    backgroundColor: "#f1f1f1",
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 4,
    shadowColor: '#000',
    shadowOffset: {width: 1, height: 2},
    shadowOpacity: 0.3,
    shadowRadius: 2,
    elevation: 5,
  },
  icon: {
    marginBottom: 5,
  },
  documentTitle: {
    fontSize: 12,
    fontWeight: "normal",
  },
  separator: {
    marginHorizontal: 12,
    width: 1,
    height: '100%',
    backgroundImage: 'linear-gradient(to bottom, #e1e1e1, #b1b1b1, #e1e1e1)',
  },
  dropdownContainer: {
    flexDirection: 'row',
    paddingHorizontal: 12,
    maxWidth: 360
  }
});
