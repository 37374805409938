import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {EngineerReport} from "@store/services/api/engineer-report";

const initialState: ErstrState = {
  report: {
    id: "",
    clientName: "",
    clientSignature: "",
    customerPoNumber: "",
    engineerId: "",
    furtherWorks: "",
    jobCompleted: false,
    jobId: "",
    parts: [],
    worksCarriedOut: "",
    timesheets: [],
    answeredQuestions: []
  },
  timesheetValidity: true
};

interface ErstrState {
  report: EngineerReport;
  timesheetValidity: boolean
}

const erstrSlice = createSlice({
  name: 'erstr',
  initialState,
  reducers: {
    updateTimesheet: (state, action: PayloadAction<TimesheetUpdate>) => {
      const timesheetIndex = state.report.timesheets.findIndex(t => t.id === action.payload.timesheetId);
      if (timesheetIndex !== -1) {
        state.report.timesheets[timesheetIndex] = {
          ...state.report.timesheets[timesheetIndex],
          [action.payload.field]: action.payload.value
        };
      }
    },
    setReport: (state, action: PayloadAction<EngineerReport>) => {
      state.report = action.payload;
      state.timesheetValidity = initialState.timesheetValidity;
    },
    updateWorksCarriedOut: (state, action: PayloadAction<string>) => {
      state.report.worksCarriedOut = action.payload;
    },
    updateFurtherWorks: (state, action: PayloadAction<string>) => {
      state.report.furtherWorks = action.payload;
    },
    updateCustomerPo: (state, action: PayloadAction<string>) => {
      state.report.customerPoNumber = action.payload;
    },
    setTimesheetValidity: (state, action: PayloadAction<boolean>) => {
      state.timesheetValidity = action.payload;
    }
  }
})

export interface TimesheetUpdate {
  timesheetId: string,
  field: string,
  value: string
}

export const {
  setReport,
  updateTimesheet,
  updateWorksCarriedOut,
  updateFurtherWorks,
  updateCustomerPo,
  setTimesheetValidity
} = erstrSlice.actions;

export default erstrSlice.reducer;
