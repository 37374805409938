import { createNativeStackNavigator } from '@react-navigation/native-stack';
import LocationManageScreen from './LocationManage';
import LocationsListScreen from './LocationsList';


const Stack = createNativeStackNavigator();

export default function Locations({ navigation }: { navigation: any }) {
  return (
    <Stack.Navigator>
      <Stack.Screen name="list" component={LocationsListScreen} options={{ headerShown: false, title: 'Locations'  }} />
      <Stack.Screen name="manage" component={LocationManageScreen} options={{ headerShown: false, title: 'Location Manage'}}  />
    </Stack.Navigator>
  )
};