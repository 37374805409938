import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export interface JobState {
  // Inspection Data
  jobId: string | null,
  equipmentId: string | null,
  categoryId: string | null,
  engineerId: string | null,
  locationId: string | null,
  companyId: string | null,
  colourId: string | null,
  jobTitle: string | null,
  jobType: number | null,
  ramsId: string | null,
  engineerReportId: string | null,
}

const initialState: JobState = {
  jobId: null,
  equipmentId: null,
  categoryId: null,
  engineerId: null,
  colourId: null,
  jobTitle: null,
  jobType: null,
  ramsId: null,
  engineerReportId: null,
  locationId: null,
  companyId: null,
}

export const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    setJobData: (state, action: PayloadAction<any>) => {
      if (action.payload.jobId != null) state.jobId = action.payload.jobId;
      if (action.payload.equipmentId != null) state.equipmentId = action.payload.equipmentId;
      if (action.payload.categoryId != null) state.categoryId = action.payload.categoryId;
      if (action.payload.engineerId != null) state.engineerId = action.payload.engineerId;
      if (action.payload.engineerReportId != null) state.engineerReportId = action.payload.engineerReportId;
      if (action.payload.jobTitle != null) state.jobTitle = action.payload.jobTitle;
      if (action.payload.jobType != null) state.jobType = action.payload.jobType;
      if (action.payload.ramsId != null) state.ramsId = action.payload.ramsId;
      if (action.payload.locationId != null) state.locationId = action.payload.locationId;
      if (action.payload.companyId != null) state.companyId = action.payload.companyId;
      if (action.payload.colourId != null) state.colourId = action.payload.colourId;
    },

    clearJobData: (state, action: PayloadAction) => {
      state.jobId = null;
      state.equipmentId = null;
      state.categoryId = null;
      state.engineerId = null;
      state.jobTitle = null;
      state.jobType = null;
      state.ramsId = null;
      state.engineerReportId = null;
      state.locationId = null;
      state.companyId = null;
      state.colourId = null;
    },
  }
})

export const { setJobData, clearJobData } = jobSlice.actions;

export default jobSlice.reducer;