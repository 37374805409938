import { Checkbox, DataTable } from 'react-native-paper';
import {useCallback, useState} from 'react';
import {StyleProp, ViewStyle, View, TextInput} from 'react-native';
import lodash from 'lodash';

type DataTableHeaderProps = {
  headers: any[];

  sortedColumn: string;
  sortedColumnAscending: boolean;
  onSelectAll?: () => void;
  onClearAll?: () => void;
  isSelectAllChecked?: boolean;
  isInspectionsPage?: boolean;
  onSorted: CallableFunction;
  style?: StyleProp<ViewStyle>;
  showColumnFilters?: boolean;
  onFiltersChanged?: (value: any) => void;
}

const FILTER_DEBOUNCE_TIME = 750;

export const DataTableHeader = (props: DataTableHeaderProps) => {
  const [sortedColumn, setSortedColumn] = useState(props.sortedColumn);
  const [sortedColumnAscending, setSortedColumnAscending] = useState(props.sortedColumnAscending);

  const initialFilters = props?.headers
    .filter((header) => header?.sortedName !== undefined)
    .reduce((acc, header) => ({...acc, [header.sortedName]: ''}), {});
  const [columnFilters, setColumnFilters] = useState<any>(initialFilters);

  const sortHeader = (header) => {
    if (header.preventSorting) return;

    const column = header.sortedName;
    if (column == null) return;
    let ascending = sortedColumnAscending;

    if (sortedColumn.toLowerCase() == column.toLowerCase()) {
      ascending = !sortedColumnAscending;
      setSortedColumnAscending(ascending);
    } else {
      ascending = true;
      setSortedColumnAscending(true);
    }

    setSortedColumn(column);
    props.onSorted(column, ascending);
  }

  const sortHeaderOrder = () => sortedColumnAscending ? 'ascending' : 'descending';

  const handleSelectAllToggle = () => {
    if (props.isSelectAllChecked) {
      props.onClearAll();
    } else {
      props.onSelectAll();
    }
  };

  const debouncedOnFiltersChanged = useCallback(
    lodash.debounce((newFilters) => props?.onFiltersChanged(newFilters), FILTER_DEBOUNCE_TIME), []
  );

  function handleOnFilterChange(columnName: string, value: string) {
    setColumnFilters((prevFilters: any) => {
      const newFilters = {...prevFilters, [columnName]: value};
      debouncedOnFiltersChanged(newFilters);
      return newFilters;
    });
  }

  return (
    <>
      <DataTable.Header style={[props.style, { alignItems: 'center' }]}>
        {props.headers.filter(h => h.visible).map((header, i) => {
          return (
            <View key={i} style={[{ flex: header.flex != null ? header.flex : 1, flexDirection: 'row', alignItems: 'center', margin: 0 }, props.style]}>
              {(header.text === "Inspect" || (props.isInspectionsPage && header.text === "Basket")) && <Checkbox
                status={props.isSelectAllChecked ? 'checked' : 'unchecked'}
                onPress={handleSelectAllToggle}
                color={'#2C89C6'}
              />
              }
              <DataTable.Title
                key={i}
                onPress={() => sortHeader(header)}
                sortDirection={sortedColumn?.toLowerCase() != header?.sortedName?.toLowerCase() ? null : sortHeaderOrder()}
                style={{ flex: header.flex != null ? header.flex : 1 }}>

                {header.text}

              </DataTable.Title>
            </View>
          )
        })}
      </DataTable.Header>
      {props?.showColumnFilters && (
        <DataTable.Header style={[props.style, { alignItems: 'center', height: 32 }]}>
          {props.headers.filter(h => h.visible).map((header, i) => {
            return (
              <View key={i} style={[{ flex: header.flex != null ? header.flex : 1, flexDirection: 'row', alignItems: 'center', margin: 0 }]}>
                {columnFilters.hasOwnProperty(header.sortedName) && (
                  <TextInput
                    placeholderTextColor={'#b1b1b1'}
                    placeholder={header.text}
                    style={{width: '90%', borderColor: '#999', borderWidth: 1, paddingHorizontal: 6}}
                    onChangeText={(text) => handleOnFilterChange(header.sortedName, text)}
                    value={columnFilters[header.sortedName]}
                  />
                )}
              </View>
            )
          })}
        </DataTable.Header>
      )}
    </>
  )
}
