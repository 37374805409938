import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface RoteInspectionState {
  // Step 1
  firstExamination: boolean | null;
  installedCorrectly: boolean | null;
  purposeId: string | null,
  purposeInterval: number | null,
  colourId: string | null,

  // Step 2
  answeredQuestions: Array<{
    questionId: string;
    response: number;
    comments: string;
  }>;

  // Step 3
  defects: string | null;
  immediateToPersons: boolean | null;
  becomeDangerToPersons: boolean | null;
  repairDate: string | null;
  repair: string | null;

  // Step 4
  identification: string | null;
  particulars: string | null;
  safeForUse: boolean | null;

  inspectionDate: string | null;
  nextInspectionDate: string | null;
  nextTestDate: string | null;
  isMarineDisabled: boolean | null

  //On Edit
  id: string | null
}

const initialState: RoteInspectionState = {
  firstExamination: null,
  installedCorrectly: false,
  purposeId: null,
  purposeInterval: null,
  colourId: null,

  defects: 'NONE',
  immediateToPersons: null,
  becomeDangerToPersons: false,
  repairDate: null,
  repair: null,

  identification: null,
  particulars: null,
  safeForUse: null,

  answeredQuestions: [],
  inspectionDate: null,
  nextInspectionDate: null,
  nextTestDate: null,
  isMarineDisabled: true,
  id: null
};

export const roteInspectionSlice = createSlice({
  name: 'roteinspection',
  initialState,
  reducers: {
    setInspectionData: (state, action: PayloadAction<any>) => {
      if (action.payload.firstExamination != null) state.firstExamination = action.payload.firstExamination;
      if (action.payload.installedCorrectly != null) state.installedCorrectly = action.payload.installedCorrectly;
      if (action.payload.purposeId != null) state.purposeId = action.payload.purposeId;
      if (action.payload.purposeInterval != null) state.purposeInterval = action.payload.purposeInterval;
      if (action.payload.colourId != null) state.colourId = action.payload.colourId;

      if (action.payload.defects != null) state.defects = action.payload.defects;
      if (action.payload.immediateToPersons != null) state.immediateToPersons = action.payload.immediateToPersons;
      if (action.payload.becomeDangerToPersons != null) state.becomeDangerToPersons = action.payload.becomeDangerToPersons;
      if (action.payload.repairDate != null) state.repairDate = action.payload.repairDate;
      if (action.payload.repair != null) state.repair = action.payload.repair;

      if (action.payload.identification != null) state.identification = action.payload.identification;
      if (action.payload.particulars != null) state.particulars = action.payload.particulars;
      if (action.payload.safeForUse != null) state.safeForUse = action.payload.safeForUse;

      if (action.payload.inspectionDate != null) state.inspectionDate = action.payload.inspectionDate;
      if (action.payload.nextInspectionDate != null) state.nextInspectionDate = action.payload.nextInspectionDate;
      if (action.payload.nextTestDate != null) state.nextTestDate = action.payload.nextTestDate;
      if (action.payload.isMarineDisabled != null) state.isMarineDisabled = action.payload.isMarineDisabled;
      if (action.payload.id != null) state.id = action.payload.id;
    },

    clearInspectionData: (state) => {
      state.firstExamination = null;
      state.installedCorrectly = null;
      state.purposeId = null;
      state.purposeInterval = null;
      state.colourId = null;
      state.defects = 'NONE';
      state.immediateToPersons = null;
      state.becomeDangerToPersons = null;
      state.repairDate = null;
      state.repair = null;
      state.identification = null;
      state.particulars = null;
      state.safeForUse = null;
      state.answeredQuestions = [];
      state.isMarineDisabled = true;
      state.id = null;
    },

    // New action to update answered questions
    updateAnsweredQuestion: (state, action: PayloadAction<{ questionId: string; response: number; comments: string }>) => {
      const { questionId, response, comments } = action.payload;

      // Update or add the answered question
      const existingQuestionIndex = state.answeredQuestions.findIndex((q) => q.questionId === questionId);

      if (existingQuestionIndex !== -1) {
        // If the question already exists, update it
        state.answeredQuestions[existingQuestionIndex] = { questionId, response, comments };
      } else {
        // If the question doesn't exist, add it to the array
        state.answeredQuestions.push({ questionId, response, comments });
      }
    },
  },
});

export const { setInspectionData, clearInspectionData, updateAnsweredQuestion } = roteInspectionSlice.actions;

export default roteInspectionSlice.reducer;
