import * as React from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert, Dimensions } from 'react-native';
import { FlashList } from "@shopify/flash-list";
import { Octicons, Ionicons, AntDesign, MaterialCommunityIcons, Entypo } from '@expo/vector-icons';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { clearJobData, setJobData } from '../../../../../store/slices/job-slice';
import { JobDatabase } from '../../../../../store/services/database/job-database';
import { RootState } from '@store/index';
import { clearBreakdownData } from '@store/slices/breakdown-slice';
import { colours } from '../../../../../config';
import { Card } from 'react-native-paper';

const isTabletScreen = Dimensions.get("screen").width < 960;
const isMobileScreen = Dimensions.get("screen").width < 480;

export function JobsList({ navigation, jobs }) {

  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const modules = useSelector((state: RootState) => state.user.modules);

  const inspectJob = async (jobViewModel) => {
    const jobDatabase = new JobDatabase();
    var job = await jobDatabase.getJobById(jobViewModel.id);

    dispatch(clearBreakdownData());
    dispatch(clearJobData());
    dispatch(setJobData({
      jobId: job.id,
      engineerId: user.id,
      jobTitle: `${job.companyName} - ${job.locationName} - ${moment(job.createdOn).format("DD/MM/YYYY")}`,
      jobType: job.jobType,
      ramsId: job.ramsId,
      engineerReportId: job.engineerReportId,
      locationId: job.locationId,
      companyId: job.companyId
    }))
    navigation.navigate("JobAssets");
  }

  const addJob = () => {
    navigation.navigate('JobManage')
  }

  const renderItemCard = ({ item, index }) => (
    <TouchableOpacity onPress={() => inspectJob(item)} style={{ borderBottomWidth: 0, marginBottom: 5 }}>
      <Card style={styles.card}>
        <Card.Content>
          <View style={styles.row}>
            <View style={styles.icon}>
              {item.jobType === 0 && (
                <Ionicons name="checkmark-circle-outline" size={16} color="#2B93F1" />
              )}
              {item.jobType === 1 && (
                <Ionicons name="cog-outline" size={16} color="#F7A931" />
              )}
              {item.jobType === 2 && (
                <Ionicons name="warning-outline" size={16} color="#FF4C4F" />
              )}
              {item.jobType === 3 && (
                <Ionicons name="ios-reload-circle-outline" size={16} color="#0D4487" />
              )}
              {item.jobType === 4 && (
                <MaterialCommunityIcons name="tools" size={15} color="#b565c4" />
              )}
              {item.jobType === 5 && (
                <Entypo name="gauge" size={15} color="#b5651d" />
              )}
            </View>
            <View style={styles.body}>
              <Text style={styles.cardText}><Text style={styles.boldText}>Company: </Text>{item.companyName}</Text>
              <Text style={styles.cardText}><Text style={styles.boldText}>Location: </Text>{item.locationName}</Text>
              <Text style={styles.cardText}><Text style={styles.boldText}>Job #: </Text>{item.jobNumber}</Text>
              <Text style={styles.cardText}>
                <Text style={styles.boldText}>Added On: </Text>{moment(item.createdOn).format('DD/MM/YYYY')}
              </Text>
              <Text style={styles.cardText}>
                <Text style={styles.boldText}>Scheduled Date: </Text>{moment(item.scheduledDate).format('DD/MM/YYYY')}
              </Text>
              <Text style={styles.cardText}><Text style={styles.boldText}>Job Type: </Text>{item.jobTypeFormatted}</Text>
              {item.yourReference !== null && item.yourReference !== '' && (
                <Text style={styles.cardText}>
                  <Text style={styles.boldText}>Your Reference: </Text>{item.yourReference}
                </Text>
              )}

              {item.notes != null && item.notes !== '' && (
                <Text style={styles.cardText}>
                  <Text style={styles.boldText}>Notes:</Text> <Text>{item.notes}</Text>
                </Text>
              )}
            </View>
            <View style={styles.rightIcon}>
              <AntDesign name="rightcircleo" size={isMobileScreen ? 18 : 24} color="black" style={styles.rightIconInner} />
            </View>
          </View>
        </Card.Content>
      </Card>
    </TouchableOpacity >
  );

  const renderItem = ({ item, index }) => (
    <TouchableOpacity onPress={() => inspectJob(item)} style={{ borderBottomColor: colours.primary, borderBottomWidth: 1, marginBottom: 10 }}>
      <View style={styles.row}>
        <View style={{ width: 60 }}>
          {item.jobType == 0 &&
            <View style={[styles.job__icon]}>
              <Ionicons name="checkmark-circle-outline" size={32} color="#2B93F1" />
            </View>
          }
          {item.jobType == 1 &&
            <View style={[styles.job__icon, styles.servicing__icon]}>
              <Ionicons name="cog-outline" size={32} color="#F7A931" />
            </View>
          }
          {item.jobType == 2 &&
            <View style={[styles.job__icon, styles.breakdown__icon]}>
              <Ionicons name="warning-outline" size={32} color="#FF4C4F" />
            </View>
          }
          {item.jobType == 3 &&
            <View style={[styles.job__icon, styles.proofload__icon]}>
              <Ionicons name="ios-reload-circle-outline" size={32} color="#0D4487" />
            </View>
          }
          {item.jobType == 4 &&
            <View style={[styles.job__icon, styles.proofload__icon]}>
              <MaterialCommunityIcons name="tools" size={32} color="#b565c4" />
            </View>
          }
          {item.jobType == 5 &&
            <View style={[styles.job__icon, styles.proofload__icon]}>
              <Entypo name="gauge" size={30} color="#b5651d" />
            </View>
          }
        </View>
        <View style={[styles.column, styles.body__column]}>
          <Text style={styles.job__text}>{item.companyName}</Text>
        </View>
        <View style={[styles.column, styles.body__column]}>
          <Text style={styles.job__text}>{item.locationName}</Text>
        </View>
        <View style={[styles.column, styles.body__column]}>
          <Text style={styles.job__text}>{item.jobNumber}</Text>
        </View>
        {!isTabletScreen &&
          <View style={[styles.column, styles.body__column]}>
            <Text style={styles.job__text}>{item.createdOn == null ? null : moment(item.createdOn).format("DD/MM/YYYY")}</Text>
          </View>
        }
        <View style={[styles.column, styles.body__column]}>
          <Text style={styles.job__text}>{item.scheduledDate == null ? null : moment(item.scheduledDate).format("DD/MM/YYYY")}</Text>
        </View>
        {!isTabletScreen &&
          <View style={[styles.column, styles.body__column]}>
            <Text style={styles.job__text}>{item.jobTypeFormatted}</Text>
          </View>
        }
        {!isTabletScreen &&
          <View style={[styles.column, styles.body__column]}>
            <Text style={styles.job__text}>{item.yourReference}</Text>
          </View>
        }
        <View style={{ width: 40, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', }}>
          <AntDesign name="rightcircleo" size={24} color="black" style={{ marginLeft: 10, }} />
        </View>
      </View>
      {item.notes != null && item.notes != "" &&
        <View style={{ padding: 10, backgroundColor: '#FFF', marginBottom: 10, marginLeft: 60, marginRight: 40 }}>
          <Text><Text style={{ fontWeight: 'bold' }}>Notes:</Text> <Text>{item.notes}</Text></Text>
        </View>
      }
    </TouchableOpacity>

  );

  return (
    <View style={styles.jobs__container}>
      <View style={styles.page__title__block}>
        <Text style={styles.page__title__text}>Jobs</Text>

        {modules.isAddJobMobileEnabled == true &&
          <TouchableOpacity onPress={() => addJob()} style={[styles.add__job__button]}>
            <Octicons style={{ marginRight: isTabletScreen ? 5 : 10 }} size={isTabletScreen ? 16 : 24} color={"#FFF"} name="checklist" />
            <Text style={styles.add__job__button__text}>ADD JOB</Text>
          </TouchableOpacity>
        }
      </View>

      {isMobileScreen ? (
        <FlashList
          data={jobs}
          renderItem={renderItemCard}
          estimatedItemSize={71}
        />
      ) :
        (
          <View style={styles.jobs__container}>
            <View style={[styles.head__row, styles.row]}>
              <View style={{ width: 60 }}></View>
              <Text style={[styles.column, styles.head__column]}>Company</Text>
              <Text style={[styles.column, styles.head__column]}>Location</Text>
              <Text style={[styles.column, styles.head__column]}>Job #</Text>
              <Text style={[styles.column, styles.head__column__optional]}>Added On</Text>
              <Text style={[styles.column, styles.head__column]}>Scheduled Date</Text>
              <Text style={[styles.column, styles.head__column__optional]}>Job Type</Text>
              <Text style={[styles.column, styles.head__column__optional]}>Your Reference</Text>
              <View style={{ width: 40 }}></View>
            </View>

            <FlashList
              data={jobs}
              renderItem={renderItem}
              estimatedItemSize={71}
            />
          </View>
        )}

    </View>



  )
}

const styles = StyleSheet.create({
  jobs__container: {
    borderRadius: 10,
    height: '100%',
    width: '100%',
    flex: 1,
    padding: isMobileScreen ? 0 : (isTabletScreen ? 5 : 20),
    margin: isMobileScreen ? 0 : (isTabletScreen ? 5 : 20),
    marginTop: 0,
  },

  page__title__block: {
    flexDirection: 'row',
    marginTop: 5,
    marginBottom: 5,
    alignItems: 'center',
  },

  page__title__text: {
    fontFamily: 'OpenSans-Bold',
    fontSize: isTabletScreen ? 18 : 28,

    flex: 1,
  },

  add__job__button: {
    backgroundColor: colours.primary,
    padding: 8,
    borderRadius: 10,
    width: isTabletScreen ? 'auto' : 150,
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  add__job__button__text: {
    fontFamily: 'OpenSans-Bold',
    color: '#FFF',
    fontSize: isTabletScreen ? 8 : 16
  },

  row: {
    flexDirection: 'row',
  },

  head__row: {
    display: 'flex'
  },

  head__column: {
    padding: 15,
    paddingTop: 0,
    fontFamily: 'OpenSans-Bold',
  },

  head__column__optional: {
    padding: 15,
    paddingTop: 0,
    fontFamily: 'OpenSans-Bold',
    display: isTabletScreen ? 'none' : 'flex',
  },

  column: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'stretch',
    padding: 10,
  },

  card: {
    flex: 1,
    margin: 5,
  },
  icon: {
    width: 20,
  },
  body: {
    flex: 1,
    marginLeft: 10,
    marginBottom: 10
  },
  cardText: {
    fontSize: 16,
  },
  rightIcon: {
    width: 30,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rightIconInner: {
    marginLeft: 5,
  },
  boldText: {
    fontWeight: 'bold',
  },
});
