import {ActivityIndicator, StyleSheet, Text, View} from "react-native";
import {PRIMARY_COLOUR} from "../constants/GlobalStyle";

export function EmptyListIndicator({isLoading, emptyText}: {isLoading: boolean, emptyText: string}) {
  return (
    <View style={styles.emptyListIndicator}>
      {isLoading ? (
        <ActivityIndicator animating={true} color={PRIMARY_COLOUR} />
      ): (
        <Text>{emptyText}</Text>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  emptyListIndicator: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 12,
  },
})
