import { createNativeStackNavigator } from '@react-navigation/native-stack';
import DepotsListScreen from './DepotsListScreen';
import DepotManageScreen from './DepotManageScreen';


const Stack = createNativeStackNavigator();

export default function Locations({ navigation }: { navigation: any }) {
    return (
        <Stack.Navigator>
            <Stack.Screen name="list" component={DepotsListScreen} options={{ headerShown: false, title: 'Depots' }} />
            <Stack.Screen name="manage" component={DepotManageScreen} options={{ headerShown: false, title: 'Depot Manage' }} />
        </Stack.Navigator>
    )
};