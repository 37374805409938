import { Database } from './database';
import uuid from 'react-native-uuid';

import { PagedTableDto } from "@store/services/api/base-api";
import { PagedResponse, Pagination } from "@store/models/pagination";

export enum EquipmentStatus {
  None = 0,
  Passed = 1,
  PassedWithComments = 2,
  Failed = 3,
  NotPresented = 4
}

export class EquipmentDatabase extends Database {
  TABLE_NAME = "Equipment";

  initialise(): Promise<void> {
    return new Promise((resolve, reject) => {
      var sql = `CREATE TABLE IF NOT EXISTS Equipment ( 
        id TEXT PRIMARY KEY,
        description TEXT,
        serialNumber TEXT,
        assetNumber TEXT,
        dateOfManufacture TEXT,
        proofLoad TEXT,
        swl TEXT,
        locationId TEXT,
        subLocationId TEXT,
        categoryId TEXT,
        manufacturerId TEXT,
        rfid TEXT,
        transposedStandards TEXT,
        nationalStandards TEXT,
        dateOfFirstUse TEXT,
        abusNo TEXT, 
        hoistType TEXT,
        numberOfHoists INT,
        yearOfCommissioning TEXT,
        isCraneRadio INT,
        DOC INT,
        scrapped INT,
        reasonForScrapped TEXT,
        toBeSynced INT,
        nextTestDate TEXT,
        nextInspectionDate TEXT,
        isArchived INT,
        safetyDeviceFitted TEXT,      
        lastKnownStatus INT
      )`;

      var sqlExtras = `CREATE TABLE IF NOT EXISTS EquipmentExtras (
        id TEXT PRIMARY KEY,
        serialNum TEXT,
        make TEXT,
        equipmentExtraType INT,
        equipmentId TEXT,
        make2 TEXT,
        serialNum2 TEXT,
        type TEXT,
        type2 TEXT
      )`;

      var sqlFields = `CREATE TABLE IF NOT EXISTS EquipmentFields (
        id TEXT PRIMARY KEY,
        equipmentId TEXT,
        fieldName TEXT,
        fieldValue TEXT,
        categoryFieldId TEXT
      )`;

      var sqlLoadTypes = `CREATE TABLE IF NOT EXISTS LoadTypes (
        id TEXT PRIMARY KEY,
        type TEXT
      )`;

      var sqlLoadUnits = `CREATE TABLE IF NOT EXISTS LoadUnits (
        id TEXT PRIMARY KEY,
        unit TEXT
      )`;

      Database.getDb().transaction((tx) => {
        tx.executeSql(sql);
        tx.executeSql(sqlExtras);
        tx.executeSql(sqlFields);
        tx.executeSql(sqlLoadTypes);
        tx.executeSql(sqlLoadUnits);

        var checkColumnSql = "PRAGMA table_info(Equipment)";
        tx.executeSql(checkColumnSql, [], (_, { rows: { _array } }) => {
          const columns = _array.map(column => column.name);

          if (!columns.includes("rfid")) {
            var alterSql = `ALTER TABLE Equipment ADD COLUMN rfid TEXT`;
            tx.executeSql(alterSql);
          }
          if (!columns.includes("nextTestDate")) {
            var alterSql = `ALTER TABLE Equipment ADD COLUMN nextTestDate TEXT`;
            tx.executeSql(alterSql);
          }
          if (!columns.includes("isArchived")) {
            var alterSql = `ALTER TABLE Equipment ADD COLUMN isArchived INT`;
            tx.executeSql(alterSql);
            tx.executeSql("UPDATE Equipment SET isArchived = 0 WHERE isArchived IS NULL OR isArchived = ''");
          }
          if (!columns.includes("nextInspectionDate")) {
            var alterSql = `ALTER TABLE Equipment ADD COLUMN nextInspectionDate TEXT`;
            tx.executeSql(alterSql);
          }
          if (!columns.includes("reasonForScrapped")) {
            var alterSql = `ALTER TABLE Equipment ADD COLUMN reasonForScrapped TEXT`;
            tx.executeSql(alterSql);
          }
          if (!columns.includes("safetyDeviceFitted")) {
            var alterSql = `ALTER TABLE Equipment ADD COLUMN safetyDeviceFitted TEXT`;
            tx.executeSql(alterSql);
          }
          if (!columns.includes("lastKnownStatus")) {
            var alterSql = `ALTER TABLE Equipment ADD COLUMN lastKnownStatus INT`;
            tx.executeSql(alterSql);
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      });
    });
  }

  insertOrUpdateLoadUnitsList(entities: any): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO LoadUnits (id, unit)
            VALUES (?, ?)
          `;

          tx.executeSql(sql, [
            entity.id, entity.unit
          ]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      });
    });
  }

  insertOrUpdateLoadTypesList(entities: any): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO LoadTypes (id, type)
            VALUES (?, ?)
          `;

          tx.executeSql(sql, [
            entity.id, entity.type
          ]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      });
    });
  }

  insertOrUpdateList(entities: any, toBeSynced: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          entity.isArchived = entity.isArchived ? entity.isArchived : 0;
          tx.executeSql(`DELETE FROM EquipmentExtras WHERE equipmentId = ?`, [entity.id]);
          tx.executeSql(`DELETE FROM EquipmentFields WHERE equipmentId = ?`, [entity.id]);

          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME} (
              id, description, serialNumber, assetNumber, dateOfManufacture, proofLoad, swl, locationId, subLocationId, categoryId, manufacturerId, rfid,
              DOC, transposedStandards, nationalStandards, dateOfFirstUse, scrapped, toBeSynced, abusNo, hoistType, numberOfHoists, 
              yearOfCommissioning, isCraneRadio, nextTestDate, nextInspectionDate, isArchived, reasonForScrapped, safetyDeviceFitted, lastKnownStatus
            )
            VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)
          `;
          tx.executeSql(sql, [
            entity.id, entity.description, entity.serialNumber, entity.assetNumber, entity.dateOfManufacture, entity.proofLoad,
            entity.swl, entity.locationId, entity.subLocationId, entity.categoryId, entity.manufacturerId, entity.rfid,
            entity.doc == true ? 1 : 0, entity.transposedStandards, entity.nationalStandards, entity.dateOfFirstUse, entity.scrapped,
            toBeSynced, entity.abusNo, entity.hoistType, entity.numberOfHoists, entity.yearOfCommissioning, entity.isCraneRadio, entity.nextTestDate,
            entity.nextInspectionDate, entity.isArchived, entity.reasonForScrapped, entity.safetyDeviceFitted, entity.lastKnownStatus
          ]);

          for (let extra of entity.equipmentExtras) {
            tx.executeSql(`INSERT INTO EquipmentExtras (id, serialNum, make, equipmentExtraType, equipmentId, make2, serialNum2, type, type2) VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?)`, [
              extra.id, extra.serialNum, extra.make, extra.equipmentExtraType, extra.equipmentId, extra.make2, extra.serialNum2, extra.type, extra.type2
            ]);
          }

          for (let field of entity.equipmentFields) {
            tx.executeSql(`INSERT INTO EquipmentFields (id, equipmentId, fieldName, fieldValue, categoryFieldId) VALUES (?, ?, ?, ?, ?)`, [
              uuid.v4(), entity.id, field.fieldName, field.fieldValue, field.categoryFieldId
            ]);
          }
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      });
    });
  }

  getById(equipmentId): Promise<any> {
    return new Promise((resolve, reject) => {
      var equipment = null;
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT 
          Equipment.*, 
          Manufacturers.name AS manufacturerName, 
          Categories.name AS categoryName,
          Categories.isServiceable as checklist,
          MasterCategories.name as masterCategoryName  
          FROM Equipment
          INNER JOIN Manufacturers ON Equipment.manufacturerId = Manufacturers.id
          INNER JOIN Categories ON Equipment.categoryId = Categories.id
          INNER JOIN MasterCategories ON MasterCategories.Id = Categories.masterCategoryId
          WHERE Equipment.id = ? 
        `;

        tx.executeSql(sql, [equipmentId], (_, { rows: { _array } }) => {
          if (_array.length > 0) {
            equipment = _array[0];
            var sqlExtras = `SELECT * FROM EquipmentExtras WHERE equipmentId = ?`;
            tx.executeSql(sqlExtras, [equipment.id], (_, { rows: { _array } }) => {
              equipment.equipmentExtras = _array;
            });

            var sqlFields = `SELECT * FROM EquipmentFields WHERE equipmentId = ?`;
            tx.executeSql(sqlFields, [equipment.id], (_, { rows: { _array } }) => {
              equipment.equipmentFields = _array;
            });
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(equipment);
      });
    });
  }

  getByLocationId(locationId, pssrOnly = false): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        let sql = `
          SELECT e.* FROM Equipment e
          INNER JOIN Categories c ON c.id = e.categoryId
          INNER JOIN MasterCategories mc ON mc.id = c.masterCategoryId
          WHERE locationId = ? AND scrapped = 0
        `;

        sql = pssrOnly ? sql + "AND mc.name = 'PSSR'" : sql;

        tx.executeSql(sql, [locationId], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      });
    });
  }

  getPageByLocationId(pageDto: PagedTableDto): Promise<any> {
    return new Promise((resolve, reject) => {
      let totalCount = 0;
      const pageSize = pageDto.filters.pageSize;
      const offset = pageDto.page * pageSize;
      const locationId = pageDto.filters.locationId;
      const pssrOnly = Number(pageDto.filters?.pssrOnly ?? false);
      const sort = pageDto.sortedColumn || 'id';
      const order = pageDto.sortedColumnAscending ? 'ASC' : 'DESC';

      Database.getDb().transaction((tx) => {
        let totalCountSql = `
          SELECT COUNT(*) as count
          FROM Equipment e
          INNER JOIN Categories c ON c.id = e.categoryId
          INNER JOIN MasterCategories mc ON mc.id = c.masterCategoryId
          WHERE locationId = ? AND scrapped = 0
          `;

        totalCountSql = pssrOnly ? totalCountSql + "AND mc.name = 'PSSR'" : totalCountSql;

        tx.executeSql(totalCountSql, [locationId], (_, {rows: {_array}}) => {
          if (_array.length > 0) {
            totalCount = _array[0].count;
          }
        });

        let sql = `
          SELECT e.id, e.serialNumber, e.description, e.assetNumber, c.name AS categoryName
          FROM Equipment e 
          INNER JOIN Categories c ON c.id = e.categoryId 
          INNER JOIN MasterCategories mc ON mc.id = c.masterCategoryId
          WHERE locationId = ? AND scrapped = 0
          `;

        sql = pssrOnly ? sql + "AND mc.name = 'PSSR'" : sql;
        sql += `
          ORDER BY ${sort} ${order} 
          LIMIT ? OFFSET ?
          `;

        tx.executeSql(sql, [locationId, pageSize, offset], (_, {rows: {_array}}) => {
          const totalPages = Math.ceil(totalCount / pageSize);
          const pageNumber = pageDto.page + 1;

          const pagination: Pagination = {
            totalCount,
            pageSize: pageSize,
            currentPageSize: _array.length,
            currentStartIndex: offset + 1,
            currentEndIndex: offset + _array.length,
            pageNumber: pageNumber,
            totalPages,
            hasPrevious: pageNumber > 1,
            hasNext: pageNumber < totalPages
          };
          const result: PagedResponse<any> = {
            list: _array,
            pagination
          };
          resolve({data: result});
        });
      }, (error) => {
        reject(error);
      });
    });
  }

  getForSync(): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM Equipment WHERE toBeSynced = 1
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
          for (var equipment of data) {
            var sqlExtras = `SELECT * FROM EquipmentExtras WHERE equipmentId = ?`;
            tx.executeSql(sqlExtras, [equipment.id], (_, { rows: { _array } }) => {
              equipment.equipmentExtras = _array;
            });

            var sqlFields = `SELECT * FROM EquipmentFields WHERE equipmentId = ?`
            tx.executeSql(sqlFields, [equipment.id], (_, { rows: { _array } }) => {
              equipment.equipmentFields = _array;
            });
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  setToBeSynced(equipmentId: any, toBeSynced: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
            UPDATE ${this.TABLE_NAME} SET toBeSynced = ? WHERE id = ?
          `;

        tx.executeSql(sql, [toBeSynced, equipmentId]);
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      });
    });
  }

  setScrapped(equipmentId: any, reason: string = ""): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `UPDATE ${this.TABLE_NAME} SET scrapped = 1, reasonForScrapped = ?, toBeSynced = 1 WHERE id = ?`;
        tx.executeSql(sql, [reason, equipmentId]);
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      });
    });
  }

  getLoadTypes(): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM LoadTypes ORDER BY Type
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  getLoadUnits(): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM LoadUnits ORDER BY Unit
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  unarchiveEquipment(equipmentId): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = null;
      Database.getDb().transaction((tx) => {
        var sql = `UPDATE ${this.TABLE_NAME} SET isArchived = 0, toBeSynced = 1 WHERE id = ?`;

        tx.executeSql(sql, [equipmentId], (_, { rows: { _array } }) => {
          if (_array.length > 0)
            data = _array[0];
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  archiveEquipment(equipmentId): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = null;
      Database.getDb().transaction((tx) => {
        var sql = `UPDATE ${this.TABLE_NAME} SET isArchived = 1, toBeSynced = 1 WHERE id = ?`;

        tx.executeSql(sql, [equipmentId], (_, { rows: { _array } }) => {
          if (_array.length > 0)
            data = _array[0];
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  moveToLocation(locationId, equipmentId): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = null;
      Database.getDb().transaction((tx) => {
        var sql = `UPDATE ${this.TABLE_NAME} SET locationId = ?, toBeSynced = 1 WHERE id = ?`;

        tx.executeSql(sql, [locationId, equipmentId], (_, { rows: { _array } }) => {
          if (_array.length > 0)
            data = _array[0];
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }
  moveToSublocation(equipmentId: any, sublocationId: any): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {

        var sql = `
            UPDATE ${this.TABLE_NAME} SET subLocationId = ?, toBeSynced = 1 WHERE id = ?
          `

        tx.executeSql(sql, [sublocationId, equipmentId]);
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }
}
