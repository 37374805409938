import { createNativeStackNavigator } from '@react-navigation/native-stack';
import EmailsManageScreen from './EmailsManage';

const Stack = createNativeStackNavigator();

export default function Emails({ navigation }: { navigation: any }) {
    return (
        <Stack.Navigator>
            <Stack.Screen name="manage" component={EmailsManageScreen} options={{ headerShown: false, title: 'Emails' }} />
        </Stack.Navigator>
    )
};