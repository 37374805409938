import { useState, useEffect, forwardRef } from 'react';
import { TitleBar } from '@app/components/TitleBar';
import { StyleSheet, Text, TextInput, View, TouchableOpacity, Alert, KeyboardAvoidingView, Image, ScrollView, Platform } from 'react-native';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { Ionicons } from '@expo/vector-icons';
import { BreakdownDatabase } from '@store/services/database/breakdown-database';
import { Picker } from '@react-native-picker/picker';
import { Controller, useForm } from 'react-hook-form';
import { colours } from '../../../../../config';
import { FlashList } from '@shopify/flash-list';
import { setBreakdownData } from '@store/slices/breakdown-slice';
import moment from 'moment';
import { CameraModal } from '@app/components/modals/CameraModal';
import RNPickerSelect from 'react-native-picker-select';
import uuid from 'react-native-uuid';
const FaultsTab = () => {
  const dispatch = useDispatch();

  const breakdown = useSelector((state: RootState) => state.breakdown);
  const job = useSelector((state: RootState) => state.job);

  const [commonFaults, setCommonFaults] = useState<any>([]);
  const [faults, setFaults] = useState<any>([]);

  const { control, handleSubmit, getValues, setValue, watch, reset, formState: { errors } } = useForm({
    defaultValues: {
      commonFaultId: null,
      details: "",
      action: "",
      hours: "",
    }
  });

  const breakdownDatabase = new BreakdownDatabase();

  const isDbInspectionDataEmpty = (data) => !data || data.length === 0;
  const hasBreakdownFaults = (faults) => faults && faults.length > 0;

  const loadFaults = async () => {
    try {
      const faults = await breakdownDatabase.getBreakdownCommonFaults();
      setCommonFaults(faults);
    } catch (error) {
      console.error("Error loading common faults:", error);
    }
  };

  const mergeFaults = (prevFaults, dbFaults) => {
    const uniqueFaultsMap = new Map();

    prevFaults.forEach(fault => uniqueFaultsMap.set(fault.inspectionFaultId, fault));

    dbFaults.forEach(fault => uniqueFaultsMap.set(fault.inspectionFaultId, fault));

    if (hasBreakdownFaults(breakdown.faults)) {
      breakdown.faults.forEach(fault => uniqueFaultsMap.set(fault.inspectionFaultId, fault));
    }

    return Array.from(uniqueFaultsMap.values());
  };

  const loadCompletedInspection = async () => {
    try {
      const dbInspectionData = await breakdownDatabase.getCompletedBreakdownInspection(job.jobId, job.equipmentId);

      if (isDbInspectionDataEmpty(dbInspectionData) && hasBreakdownFaults(breakdown.faults)) {
        setFaults(breakdown.faults);
        return;
      }

      if (isDbInspectionDataEmpty(dbInspectionData)) {
        return;
      }

      const [dbInspection] = dbInspectionData;
      const str = JSON.stringify(dbInspection, null, 4);
      dispatch(setBreakdownData({
        isEdit: true,
        id: dbInspection.id
      }));
      setFaults(prevFaults => mergeFaults(prevFaults, dbInspection.faults));
    } catch (error) {
      console.error("Error loading completed inspection:", error);
    }
  };

  useEffect(() => {
    loadFaults();
    loadCompletedInspection();
  }, []);

  useEffect(() => {
    if (faults.length > 0) {
      dispatch(setBreakdownData({
        faults: faults.map(fault => ({
          ...fault,
          inspectionFaultId: fault.inspectionFaultId || uuid.v4(),
        })),
      }));
    }
  }, [faults]);

  const onSubmit = data => {
    var newFault = data;
    newFault.commonFault = commonFaults.find(f => f.id == data.commonFaultId);
    setFaults(faults => { return [newFault, ...faults] });

    reset();
  };

  const removeFault = (id) => {
    setFaults(faults => faults.filter(fault => fault.inspectionFaultId !== id));
    dispatch(setBreakdownData({
      faults: faults.filter(fault => fault.inspectionFaultId !== id),
    }));
  };

  const renderItem = ({ item, index }) => (
    <View style={styles.row}>
      <Text style={styles.column}>{item.commonFault.fault}</Text>
      <Text style={styles.column}>{item.details}</Text>
      <Text style={styles.column}>{item.action}</Text>
      <Text style={styles.column}>{item.hours}</Text>
      <TouchableOpacity style={styles.column} onPress={() => removeFault(item.inspectionFaultId)}>
        <Ionicons name="trash-bin" size={24} color="red" />
      </TouchableOpacity>
    </View>
  );

  return (
    <ScrollView style={{ flex: 1 }}>
      <Controller
        control={control}
        name="commonFaultId"
        rules={{ required: true }}
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={styles.question}>
            <Text style={styles.question__label}>Fault Type</Text>

            {Platform.OS === "ios" ? (
              <View style={styles.form__input__IOS}>
                <RNPickerSelect
                  value={value}
                  onValueChange={(itemValue) => onChange(itemValue)}
                  items={commonFaults.map((value) => ({ label: value.fault, value: value.id }))}
                  style={styles}
                />
              </View>
            ) :

              (<Picker
                selectedValue={value}
                onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                itemStyle={{ height: 50 }}>

                <Picker.Item label="Please Select" value="" />

                {commonFaults != null && commonFaults.map((value, index) => {
                  return (
                    <Picker.Item key={value.id} label={value.fault} value={value.id} />
                  )
                })}
              </Picker>)
            }

            {errors.commonFaultId && <Text style={styles.input__validation}>Please select a response before continuing</Text>}
          </View>
        )}
      />

      <Controller
        control={control}
        name="details"
        rules={{ required: true }}
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={styles.question}>
            <Text style={styles.question__label}>Details of fault</Text>

            <TextInput
              style={styles.question__input}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
            />

            {errors.details && <Text style={styles.input__validation}>Please select a response before continuing</Text>}
          </View>
        )}
      />

      <View>
        <Controller
          control={control}
          name="action"
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={{ flex: 1, marginBottom: 10 }}>
              <View style={styles.question}>
                <Text style={styles.question__label}>Action taken</Text>

                <TextInput
                  style={styles.question__input}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                />

                {errors.action && <Text style={styles.input__validation}>Please select a response before continuing</Text>}
              </View>
            </View>
          )}
        />
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Controller
            control={control}
            name="hours"
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={{ flex: 2, marginBottom: 10 }}>
                <View style={styles.question}>
                  <Text style={styles.question__label}>Hours</Text>

                  <TextInput
                    style={styles.question__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    keyboardType={'decimal-pad'}
                  />

                  {errors.action && <Text style={styles.input__validation}>Enter</Text>}
                </View>
              </View>
            )}
          />
          <TouchableOpacity style={styles.add__button} onPress={handleSubmit(onSubmit)}>
            <Text style={styles.add__button__text}>ADD</Text>
          </TouchableOpacity>
        </View>
      </View>

      <FlashList
        data={faults}
        renderItem={renderItem}
        estimatedItemSize={100}
      />
    </ScrollView>
  )
}

const SaveTab = () => {
  const dispatch = useDispatch();
  const breakdown = useSelector((state: RootState) => state.breakdown);
  const job = useSelector((state: RootState) => state.job);

  const [partsList, setPartsList] = useState<any>([]);
  const [parts, setParts] = useState<any>([]);

  const [selectedPartId, setSelectedPartId] = useState<any>(null);

  const [showCamera, setShowCamera] = useState(false);
  const [images, setImages] = useState([]);

  const [hours, setHours] = useState<any>(null);
  const [hoursHalf, setHoursHalf] = useState<any>(null);
  const [hoursDouble, setHoursDouble] = useState<any>(null);
  const [errorText, setErrorText] = useState('');

  const isDbInspectionDataEmpty = (data) => !data || data.length === 0;
  const hasBreakdownParts = (parts) => parts && parts.length > 0;

  const mergeParts = (prevParts, dbParts) => {

    const uniquePartsMap = new Map();

    prevParts.forEach(part => uniquePartsMap.set(part.inspectionPartId, part));

    dbParts.forEach(part => uniquePartsMap.set(part.inspectionPartId, part));

    if (hasBreakdownParts(breakdown.parts)) {
      breakdown.parts.forEach(part => uniquePartsMap.set(part.inspectionPartId, part));
    }

    return Array.from(uniquePartsMap.values());
  };

  useEffect(() => {
    var breakdownDatabase = new BreakdownDatabase();

    const loadParts = async () => {
      var parts = await breakdownDatabase.getBreakdownParts();
      setPartsList([...parts]);
    }
    const loadCompletedInspection = async () => {
      try {
        const dbInspectionData = await breakdownDatabase.getCompletedBreakdownInspection(job.jobId, job.equipmentId);

        if (isDbInspectionDataEmpty(dbInspectionData) && hasBreakdownParts(breakdown.parts)) {
          setParts(breakdown.parts);
          return;
        }

        if (isDbInspectionDataEmpty(dbInspectionData)) {
          return;
        }

        const [dbInspection] = dbInspectionData;
        const str = JSON.stringify(dbInspection, null, 4);

        setParts(prevParts => mergeParts(prevParts, dbInspection.parts));
        setHours(dbInspection.hours ? dbInspection.hours.toString() : '');
        setHoursHalf(dbInspection.hoursHalf ? dbInspection.hoursHalf.toString() : '');
        setHoursDouble(dbInspection.hoursDouble ? dbInspection.hoursDouble.toString() : '');
        setImages(dbInspection.images)
      } catch (error) {
        console.error("Error loading completed inspection:", error);
      }
    };
    loadParts();
    loadCompletedInspection();
  }, []);

  useEffect(() => {
    var breakdownParts = parts;
    if (breakdownParts == null)
      breakdownParts = [];

    dispatch(setBreakdownData({
      parts: breakdownParts,
    }))

    dispatch(setBreakdownData({
      parts: breakdownParts.map(part => ({
        ...part,
        inspectionPartId: part.inspectionPartId || uuid.v4(),
      })),
    }));

  }, [parts]);

  useEffect(() => {
    dispatch(setBreakdownData({
      hours: hours,
      hoursHalf: hoursHalf,
      hoursDouble: hoursDouble,
    }))
  }, [hours, hoursHalf, hoursDouble]);

  useEffect(() => {
    dispatch(setBreakdownData({
      images: images,
    }))
  }, [images]);

  const addPart = () => {
    if (selectedPartId) {
      var part = partsList.find(p => p.id == selectedPartId);
      setParts(parts => { return [part, ...parts] });
    } else {
      setErrorText('Please select a part before adding')
    }


  }

  const addImage = (image) => {
    setImages([...images, image]);
    setShowCamera(false);
  }


  const removeImage = (image) => {
    Alert.alert("Images", "Are you sure you want to remove this image?", [
      {
        text: 'No',
        style: 'cancel'
      },
      { text: 'Yes', onPress: () => { setImages(images.filter(i => i !== image)) } }
    ])
  }

  const loadCamera = () => {
    if (images.length >= 3) {
      Alert.alert("You have reached the maximum images.")
      return;
    }

    setShowCamera(true);
  }


  if (showCamera) {
    return (
      <CameraModal onCancel={() => setShowCamera(false)} onPictureTaken={(image) => addImage(image)} />
    )
  }

  const removePart = (id) => {
    setParts(parts => parts.filter(part => part.inspectionPartId !== id));
    dispatch(setBreakdownData({
      parts: parts.filter(part => part.inspectionPartId !== id),
    }));
  };

  const renderItem = ({ item, index }) => (
    <View style={styles.row}>
      <Text style={styles.column}>{item.description}</Text>
      <TouchableOpacity style={styles.column} onPress={() => removePart(item.inspectionPartId)}>
        <Ionicons name="trash-bin" size={24} color="red" />
      </TouchableOpacity>
    </View>
  );

  return (
    <ScrollView style={{ flex: 1, }}>
      <View style={{ flexDirection: 'column' }}>
        <View style={{ flex: 1 }}>
          <View style={styles.question}>
            <Text style={styles.question__label}>Hours</Text>

            <TextInput
              style={styles.question__input}
              onChangeText={(text) => setHours(text)}
              value={hours}
            />
          </View>

          <View style={styles.question}>
            <Text style={styles.question__label}>Time & Half Hours</Text>

            <TextInput
              style={styles.question__input}
              onChangeText={(text) => setHoursHalf(text)}
              value={hoursHalf}
            />
          </View>

          <View style={styles.question}>
            <Text style={styles.question__label}>Double Hours</Text>

            <TextInput
              style={styles.question__input}
              onChangeText={(text) => setHoursDouble(text)}
              value={hoursDouble}
            />
          </View>

          <View style={styles.question}>
            <Text style={styles.question__label}>Parts</Text>

            {Platform.OS === "ios" ? (
              <View style={styles.form__input__IOS}>
                <RNPickerSelect
                  value={selectedPartId}
                  onValueChange={(itemValue) => { setSelectedPartId(itemValue); setErrorText('') }}
                  items={partsList.map((value) => ({ label: value.description, value: value.id }))}
                  style={styles}
                />
              </View>
            ) :
              (<Picker
                selectedValue={selectedPartId}
                onValueChange={(itemValue, itemIndex) => { setSelectedPartId(itemValue); setErrorText('') }}
                itemStyle={{ height: 50 }}>

                <Picker.Item label="Please Select" value="" />

                {partsList != null && partsList.map((value, index) => {
                  return (
                    <Picker.Item key={value.id} label={value.description} value={value.id} />
                  )
                })}
              </Picker>)
            }
            <Text style={{ color: 'red', marginTop: 5 }}>{errorText}</Text>
          </View>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'flex-end', alignSelf: 'flex-end' }}>
          <TouchableOpacity style={styles.add__button} onPress={addPart}>
            <Text style={styles.add__button__text}>ADD</Text>
          </TouchableOpacity>
        </View>
      </View>

      <View style={styles.image__container}>
        <TouchableOpacity onPress={() => loadCamera()} style={{ paddingLeft: 30, paddingRight: 30 }}>
          <Ionicons name="camera" size={92} color="black" />
        </TouchableOpacity>

        {images.map((image, idx) => {
          return (
            <TouchableOpacity key={idx} onPress={() => removeImage(image)}>
              <Image
                style={[styles.image]}
                resizeMode={"contain"}
                source={{
                  uri: image,
                }}
              />
            </TouchableOpacity>
          )
        })}
      </View>

      <FlashList
        data={parts}
        renderItem={renderItem}
        estimatedItemSize={100}
      />
    </ScrollView>
  )
}


export default function BreakdownInspect({ navigation }: { navigation: any }) {
  const breakdown = useSelector((state: RootState) => state.breakdown);
  const job = useSelector((state: RootState) => state.job);

  const [loading, setLoading] = useState<any>(false);

  const [tabs, setTabs] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<any>(null);
  const [tabIndex, setTabIndex] = useState<any>(0);

  useEffect(() => {
    var tabList = [];

    // Load our tabs
    tabList.push({ title: "Faults" })
    tabList.push({ title: "Save" })

    setTabs(tabList);
    setActiveTab(tabList[0]);
    setTabIndex(0);
  }, []);

  const previous = () => {
    if (tabIndex == 0)
      return;

    var idx = tabIndex - 1;
    setTabIndex(idx);

    updateActiveTab(idx);
  }

  const next = () => {
    if (tabIndex == tabs.length - 1) {
      save();
      return;
    };

    var idx = tabIndex + 1;
    setTabIndex(idx);

    updateActiveTab(idx);
  }

  const save = async () => {

    setLoading(true);

    var breakdownDatabase = new BreakdownDatabase();

    var inspectionDate = moment().format();
    var nextInspectionDate = moment().format("YYYY-MM-DD");
    if (breakdown.isEdit) {
      await breakdownDatabase.updateInspection(breakdown.id, inspectionDate, nextInspectionDate, job.equipmentId, job.engineerId, job.jobId, null, null, false,
        breakdown.parts, breakdown.faults, breakdown.images, breakdown.hours, breakdown.hoursHalf, breakdown.hoursDouble)
    } else {
      await breakdownDatabase.addInspection(
        inspectionDate, nextInspectionDate, job.equipmentId, job.engineerId, job.jobId, null, null, false,
        breakdown.parts, breakdown.faults, breakdown.images, breakdown.hours, breakdown.hoursHalf, breakdown.hoursDouble
      );
    }

    setLoading(false);

    navigation.navigate("JobAssets");
  }

  const updateActiveTab = (idx) => {
    var newTab = tabs[idx];
    setActiveTab(newTab);
  }

  if (tabs.length == 0 || activeTab == null || loading) {
    return <LoadingIndicator />
  }

  return (
    <View style={styles.container}>
      <View style={Platform.OS === "ios" && { marginTop: 10 }} >
        <TitleBar navigation={navigation} title={"Breakdown"} showBackButton={true} showSearchButton={false} onSynced={null} promptBack={true} onSearch={null} />
      </View>

      <View style={styles.tabs}>
        {tabs.map((screen: any, index: number) => {
          return (
            <TouchableOpacity key={index} style={[styles.tab, activeTab.title == screen.title && styles.tab__active]}>
              <Text style={[styles.tab__text, activeTab.title == screen.title && styles.tab__text__active]}>{screen.title}</Text>
            </TouchableOpacity>
          )
        })}
      </View>

      <View style={{ flex: 1 }}>
        {tabIndex == 0 && <FaultsTab />}
        {tabIndex == 1 && <SaveTab />}
      </View>

      <View style={styles.action__buttons}>
        <TouchableOpacity style={styles.action__button} onPress={() => previous()}>
          <Text style={styles.action__button__text}>PREVIOUS</Text>
        </TouchableOpacity>

        <TouchableOpacity style={styles.action__button} onPress={() => next()}>
          <Text style={styles.action__button__text}>
            {tabIndex == tabs.length - 1 ? 'SAVE' : 'NEXT'}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FAFAFA',
    padding: 20,
    paddingTop: 40,
    flexDirection: 'column',
  },

  tabs: {
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
  },

  tab: {
    flex: 1,
    backgroundColor: '#FFF',
    borderBottomWidth: 1,
    borderBottomColor: colours.primary,
  },

  tab__active: {
    backgroundColor: colours.primary
  },

  tab__text: {
    color: colours.primary,
    fontFamily: 'OpenSans-Bold',
    fontSize: 16,
    padding: 10,
    textAlign: 'center'
  },

  tab__text__active: {
    color: '#FFF'
  },

  action__buttons: {
    flexDirection: 'row',
    marginTop: 30,
  },

  action__button: {
    backgroundColor: colours.primary,
    flex: 1,
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 5
  },

  action__button__text: {
    color: '#FFF',
    padding: 10,
    textAlign: 'center',
  },

  row: {
    flexDirection: 'row',
    backgroundColor: '#FFF',
    marginBottom: 10,
  },

  column: {
    flex: 1,
    padding: 10,
    fontSize: 14,
    textAlign: 'center',
  },

  input__validation: {
    color: 'red',
    marginTop: 10
  },

  question: {
    margin: 5,
    backgroundColor: '#FFF',
  },

  question__label: {
    fontWeight: 'bold',
    fontSize: 20,
  },

  question__input: {
    borderWidth: 1,
    borderColor: '#CECECE',
    padding: 5,
    height: 35,
    marginTop: 3,
  },

  add__button: {
    backgroundColor: colours.primary,
    flex: 1,
    width: "25%",
    maxWidth: '45%',
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 5,
    borderRadius: 10,
  },

  add__button__text: {
    color: '#FFF',
    fontWeight: 'bold',
  },

  image__container: {
    flexDirection: 'row',
    padding: 10,
    marginBottom: 20,
    alignItems: 'center'
  },

  // image__block: {
  //   flex: 1,
  // },

  image: {
    height: 150,
    width: 150,
    marginRight: 20,
    marginLeft: 20,
  },

  empty__image: {
    borderWidth: 1,
    borderColor: '#CECECE'
  },
  inputIOS: {
    fontSize: 18,
    paddingVertical: 8,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: '#CECECE',
    paddingRight: 30 // to ensure the text is never behind the icon
  },
  form__input__IOS: {
    flex: 2,
    fontFamily: 'OpenSans-Regular',
    marginTop: 3,
  },
});

