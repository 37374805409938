import View from "@expo/html-elements/build/primitives/View";
import { Keyboard, Modal, StyleSheet, Text, TouchableOpacity } from "react-native";
import { colours } from "../../../../config";

interface ConfirmModalProps {
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  message?: string;
  children?: any;
}

export function ConfirmModal({visible, onConfirm, onCancel, title, message, children}: ConfirmModalProps) {
  return (
    <Modal
    visible={visible}
    onRequestClose={onCancel}
    >
      <View style={styles.modal__container} onTouchStart={Keyboard.dismiss}>
        <View style={styles.inner__container}>

          <View style={styles.header}>
            <Text style={styles.title__text}>{title}</Text>
            {message &&
              <Text style={styles.message__text}>{message}</Text>
            }
            {children}
          </View>


          <View style={styles.buttons__container}>
            <TouchableOpacity style={styles.button} onPress={onConfirm}>
              <Text style={styles.button__text}>Confirm</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.button} onPress={onCancel}>
              <Text style={styles.button__text}>Cancel</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  header: {
    marginBottom: 12
  },
  button__text: {
    textAlign: 'center',
    color: '#FFF',
    fontFamily: 'OpenSans-Bold',
    fontSize: 18
  },
  button: {
    backgroundColor: colours.primary,
    paddingVertical: 4,
    paddingHorizontal: 12,
    flexBasis: 120,
    flexShrink: 1
  },
  buttons__container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  title__text: {
    textAlign: 'left',
    fontFamily: 'OpenSans-Regular',
    fontSize: 32,
    marginBottom: 8
  },
  message__text: {
    textAlign: 'left',
    fontFamily: 'OpenSans-Regular',
    fontSize: 16
  },
  modal__container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  inner__container: {
    paddingTop: 48,
    paddingBottom: 24,
    paddingHorizontal: 24,
    width: '100%',
    maxWidth: 640,
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  }
})
