import * as React from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert } from 'react-native';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { EvilIcons } from '@expo/vector-icons';
import { Controller, useForm } from 'react-hook-form';

import { useIsFocused } from "@react-navigation/native";
import { Picker } from '@react-native-picker/picker';
import { breakdownApi, useCreateBreakdownPartMutation, useUpdateBreakdownPartMutation } from '@store/services/api/breakdown-api';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';

export default function PartManageScreen({navigation, route}: {navigation: any, route: any}) {
  const [createBreakdownPart, createBreakdownPartResult] = useCreateBreakdownPartMutation();
  const [updateBreakdownPart, updateBreakdownPartResult] = useUpdateBreakdownPartMutation();

  const [trigger, breakdownPartResult] =  breakdownApi.useLazyGetBreakdownPartQuery();

  const { control, handleSubmit, getValues, setValue, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      itemCode: "",
      description: "",
      unitCost: "",
      partNo: ""
    }
  });

  React.useEffect(() => {
    console.log(route.params);
    if (route.params?.breakdownPartId != null) {
      trigger(route.params?.breakdownPartId);
      console.log(route.params?.breakdownPartId);
    }
  }, [])

  React.useEffect(() => {
    if (breakdownPartResult.isSuccess) {
      var data = breakdownPartResult.data;
      setValue("id", data.id);
      setValue("itemCode", data.itemCode);
      setValue("description", data.description);
      setValue("unitCost", data.unitCost);
      setValue("partNo", data.partNo);
    }
  }, [breakdownPartResult]);

  const onSubmit = async (data) => {
    var result = null;

    if (data.id != null) {
      result = await updateBreakdownPart(data) as any;
    } else {
      result = await createBreakdownPart(data) as any;
    }

    if (result.error) {
      return;
    }

    navigation.goBack();
  };

  if (createBreakdownPartResult.isLoading || updateBreakdownPartResult.isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <View style={styles.container}>
      <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon}  onPress={() => navigation.goBack() }>
          <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>{getValues("id") == null ? "CREATE BREAKDOWN PART" : "UPDATE BREAKDOWN PART"}</Text>
      </View>
      
      <View style={GlobalStyle.form}>
        {/* ##### ROW ##### */}
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="itemCode"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Item Code (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.itemCode && <Text style={GlobalStyle.form__input__validation}>Item Code is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="description"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Description (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.description && <Text style={GlobalStyle.form__input__validation}>Description is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="unitCost"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Unit Cost (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    keyboardType={'decimal-pad'}>
                  </TextInput>
                </View>
              )}
            />

            {errors.unitCost && <Text style={GlobalStyle.form__input__validation}>Unit Cost is required.</Text>}
          </View>
        </View>

        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="partNo"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Part Number (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.partNo && <Text style={GlobalStyle.form__input__validation}>Part Number is required.</Text>}
          </View>
        </View>

        <TouchableOpacity style={GlobalStyle.form__submit__button} onPress={handleSubmit(onSubmit)}>
          <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
