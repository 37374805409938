import { createNativeStackNavigator } from '@react-navigation/native-stack';
import BreakdownFaultsList from './BreakdownFaultsList';
import BreakdownFaultsManageScreen from './BreakdownFaultsManage';


const Stack = createNativeStackNavigator();

export default function BreakdownFaults({ navigation }: { navigation: any }) {
    return (
        <Stack.Navigator>
            <Stack.Screen name="list" component={BreakdownFaultsList} options={{ headerShown: false, title: 'Faults' }} />
            <Stack.Screen name="manage" component={BreakdownFaultsManageScreen} options={{ headerShown: false, title: 'Faults Manage' }} />
        </Stack.Navigator>
    )
};