export function addColumnIfNotExist(tx, tableName, columnName, columnType) {
    var checkColumnSql = `PRAGMA table_info(${tableName})`;

    tx.executeSql(checkColumnSql, [], (_, { rows: { _array } }) => {
        const columnExists = _array.some((column) => column.name === columnName);
        
        if (!columnExists) {
        var alterSql = `ALTER TABLE ${tableName} ADD COLUMN ${columnName} ${columnType}`;
        tx.executeSql(alterSql);
        }
    });
}