import {
  View,
  StyleSheet
} from "react-native";
import React from "react";
import {VideoSection} from "@web/screens/AuthenticatedScreens/Support/HelpCentre/Components/VideoSection";
import {
  CompanyDocumentsSection
} from "@web/screens/AuthenticatedScreens/Support/HelpCentre/Components/CompanyDocumentsSection";
import {ReleaseNotesSection} from "@web/screens/AuthenticatedScreens/Support/HelpCentre/Components/ReleaseNotesSection";
import {HelpCentreSection} from "@web/screens/AuthenticatedScreens/Support/HelpCentre/Components/HelpCentreSection";

export function HelpCentre() {
  return (
    <View style={styles.container}>
      <View style={styles.sectionsContainer}>
        <HelpCentreSection
          title="What's New"
          collapsable={false}
          children={<ReleaseNotesSection/>}/>
        <HelpCentreSection
          collapsable={true}
          title='Company Documents'
          children={<CompanyDocumentsSection/>}/>
        <HelpCentreSection
          collapsable={true}
          title='Video Tutorials'
          children={<VideoSection/>}/>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    margin: 10
  },
  sectionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 18,
    flex: 1
  }
});
