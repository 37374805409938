import * as React from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert } from 'react-native';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { EvilIcons } from '@expo/vector-icons';
import { Controller, useForm } from 'react-hook-form';

import { useIsFocused } from "@react-navigation/native";
import { Picker } from '@react-native-picker/picker';
import { pssrApi, useCreatePssrPartMutation, useUpdatePssrPartMutation } from '@store/services/api/pssr-api';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';

export default function PssrPartManageScreen({ navigation, route }: { navigation: any, route: any }) {
  const [createPssrPart, createPssrPartResult] = useCreatePssrPartMutation();
  const [updatePssrPart, updatePssrPartResult] = useUpdatePssrPartMutation();

  const [trigger, pssrPartResult] = pssrApi.useLazyGetPssrPartQuery();

  const { control, handleSubmit, getValues, setValue, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      name: ""
    }
  });

  React.useEffect(() => {
    console.log(route.params);
    if (route.params?.pssrPartId != null) {
      trigger(route.params?.pssrPartId);
      console.log(route.params?.pssrPartId);
    }
  }, [])

  React.useEffect(() => {
    if (pssrPartResult.isSuccess) {
      var data = pssrPartResult.data;
      setValue("id", data.id);
      setValue("name", data.name);
    }
  }, [pssrPartResult]);

  const onSubmit = async (data) => {
    var result = null;

    if (data.id != null) {
      result = await updatePssrPart(data) as any;
    } else {
      result = await createPssrPart(data) as any;
    }

    if (result.error) {
      return;
    }

    navigation.goBack();
  };

  if (createPssrPartResult.isLoading || updatePssrPartResult.isLoading) {
    return <LoadingIndicator />
  }

  return (
    <View style={styles.container}>
      <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => navigation.goBack()}>
          <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>{getValues("id") == null ? "CREATE PSSR PART" : "UPDATE PSSR PART"}</Text>
      </View>

      <View style={GlobalStyle.form}>
        {/* ##### ROW ##### */}
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="name"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Part Name (*)</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.name && <Text style={GlobalStyle.form__input__validation}>Part Name is required.</Text>}
          </View>
        </View>

        <TouchableOpacity style={GlobalStyle.form__submit__button} onPress={handleSubmit(onSubmit)}>
          <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
