import * as SQLite from 'expo-sqlite';
import { Database } from './database';
import uuid from 'react-native-uuid';
import { addColumnIfNotExist } from '../../../helpers/database-helpers';

export class InstallationDatabase extends Database {
    TABLE_NAME = "InstallationInspections";
    TABLE_NAME_ANSWERED_QUESTIONS = "InstallationInspectionsAnsweredQuestions";
    TABLE_NAME_QUESTIONS = "InstallationQuestions";
    TABLE_NAME_QUESTIONS_RESPONSES = "InstallationQuestionsResponses";
    TABLE_NAME_QUESTION_CATEGORIES = "InstallationQuestionCategories";

    initialise(): Promise<void> {
        return new Promise((resolve, reject) => {
            var sql = `CREATE TABLE IF NOT EXISTS InstallationInspections ( 
        id TEXT PRIMARY KEY,
        installationDate TEXT,
        safeforUse INT,
        equipmentId TEXT,
        engineerId TEXT,
        jobId TEXT,
        missing INT,
        toBeSynced INT
      )`;

            var sqlAnsweredQuestions = `CREATE TABLE IF NOT EXISTS InstallationInspectionsAnsweredQuestions ( 
        id TEXT PRIMARY KEY,
        questionId TEXT,
        response INT,
        comments TEXT,
        installationInspectionId TEXT,
        imageBase64 TEXT,
        responseValue Text
      )`;

            var sqlQuestions = `CREATE TABLE IF NOT EXISTS InstallationQuestions ( 
        id TEXT PRIMARY KEY,
        position INT,
        question TEXT,
        suffix TEXT,
        installationQuestionCategoryId TEXT,
        isDeleted INT
      )`;

            var sqlQuestionsResponses = `CREATE TABLE IF NOT EXISTS InstallationQuestionsResponses ( 
        id TEXT PRIMARY KEY,
        response INT,
        commentMandatory INT,
        installationQuestionId TEXT,
        position INT,
        isDeleted INT,
        isPassDefault INT,
        type INT,
        numberResponse INT,
        lowerRange INT,
        upperRange INT,
        toBeSynced INT    
      )`;

            var sqlQuestionCategories = `CREATE TABLE IF NOT EXISTS InstallationQuestionCategories ( 
        id TEXT PRIMARY KEY,
        position INT,
        title TEXT,
        isDeleted INT,
        categoryId TEXT
      )`;

            Database.getDb().transaction((tx) => {
                tx.executeSql(sql);
                tx.executeSql(sqlAnsweredQuestions);
                tx.executeSql(sqlQuestions);
                tx.executeSql(sqlQuestionsResponses);
                tx.executeSql(sqlQuestionCategories);

                addColumnIfNotExist(tx, "InstallationInspections", 'toBeSynced', 'INT');
            }, (error) => {
                console.log(error)
                reject();
            }, () => {
                resolve();
            })
        });
    }

    addInspection(installationDate: any, safeForUse: any, equipmentId: any, engineerId: any, jobId: any,
        missing: any, questions: any, images: any): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                Database.getDb().transaction((tx) => {
                    var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME} (
              id, installationDate, safeForUse, equipmentId, engineerId, jobId, missing, toBeSynced
            )
            VALUES (?, ?, ?, ?, ?, ?, ?, 1)
          `
                    var installationId = uuid.v4();

                    tx.executeSql(sql, [
                        installationId, installationDate, safeForUse, equipmentId, engineerId, jobId, missing
                    ]);


                    //
                    for (let question of questions) {
                        var sql = `
              INSERT OR REPLACE INTO ${this.TABLE_NAME_ANSWERED_QUESTIONS} (
                id, questionId, response, comments, installationInspectionId, imageBase64, responseValue
              )
              VALUES (?, ?, ?, ?, ?, ?, ?)
            `;

                        tx.executeSql(sql, [uuid.v4(), question.id, question.response, question.comments, installationId, question.imageBase64, question.responseValue]);
                    }

                    // Update this asset to be completed 
                    sql = "UPDATE JobAssets SET completed = 1, toBeSynced = 1 WHERE jobId = ? and equipmentId = ?";
                    tx.executeSql(sql, [jobId, equipmentId]);

                }, (error) => {
                    console.log("ERROR");
                    console.log(error);
                    reject();
                }, () => {
                    console.log("UPDATED JOB Assets - JobId: " + jobId + " EquipId: " + equipmentId);
                    resolve();
                })
            } catch (e) {
                console.log("ERROR INSIDE SERVICING")
                reject();
                console.log(e)
            }
        })
    }

    insertOrUpdateQuestionList(entities: any): Promise<void> {
        return new Promise((resolve, reject) => {
            Database.getDb().transaction((tx) => {
                for (let entity of entities) {
                    var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME_QUESTIONS} (id, position, question, suffix, installationQuestionCategoryId, isDeleted)
            VALUES (?, ?, ?, ?, ?, ?)
          `

                    tx.executeSql(sql, [
                        entity.id, entity.position, entity.question, entity.suffix, entity.installationQuestionCategoryId, entity.isDeleted
                    ]);
                }
            }, (error) => {
                console.log(error);
                reject();
            }, () => {
                resolve();
            })
        })
    }

    insertOrUpdateQuestionResponsesList(entities: any): Promise<void> {
        return new Promise((resolve, reject) => {
            Database.getDb().transaction((tx) => {
                for (let entity of entities) {
                    var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME_QUESTIONS_RESPONSES} (id, response, commentMandatory, installationQuestionId, position, isPassDefault, 
            isDeleted, type, numberResponse, lowerRange, upperRange)
            VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)
          `

                    tx.executeSql(sql, [
                        entity.id, entity.response, entity.commentMandatory, entity.installationQuestionId, entity.position, entity.isPassDefault,
                        entity.isDeleted, entity.type, entity.numberResponse, entity.lowerRange, entity.upperRange
                    ]);
                }
            }, (error) => {
                console.log(error);
                reject();
            }, () => {
                resolve();
            })
        })
    }

    insertOrUpdateQuestionCategoryList(entities: any): Promise<void> {
        return new Promise((resolve, reject) => {
            Database.getDb().transaction((tx) => {
                for (let entity of entities) {
                    var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME_QUESTION_CATEGORIES} (id, position, title, isDeleted, categoryId)
            VALUES (?, ?, ?, ?, ?)
          `

                    tx.executeSql(sql, [
                        entity.id, entity.position, entity.title, entity.isDeleted, entity.categoryId
                    ]);
                }
            }, (error) => {
                console.log(error);
                reject();
            }, () => {
                resolve();
            })
        })
    }

    getInstallationQuestions(categoryId: any): Promise<any> {
        return new Promise((resolve, reject) => {
            var data = [];
            Database.getDb().transaction((tx) => {
                var sql = `
                    SELECT InstallationQuestions.id, InstallationQuestions.position as questionPosition, question, suffix, InstallationQuestionCategories.title,
                    InstallationQuestionCategories.position as questionCategoryPosition, InstallationQuestions.isDeleted
                    FROM InstallationQuestions
                    INNER JOIN InstallationQuestionCategories ON InstallationQuestions.installationQuestionCategoryId = InstallationQuestionCategories.id
                    WHERE InstallationQuestionCategories.isDeleted = 0 
                    AND InstallationQuestions.isDeleted = 0 
                    AND InstallationQuestionCategories.categoryId = ?
                    AND EXISTS (SELECT 1 FROM ${this.TABLE_NAME_QUESTIONS_RESPONSES} 
                                WHERE ${this.TABLE_NAME_QUESTIONS_RESPONSES}.installationQuestionId = InstallationQuestions.id 
                                AND ${this.TABLE_NAME_QUESTIONS_RESPONSES}.isDeleted = 0)
                `;

                tx.executeSql(sql, [categoryId], (_, { rows: { _array } }) => {
                    data = _array;

                    sql = `SELECT * FROM ${this.TABLE_NAME_QUESTIONS_RESPONSES} WHERE isDeleted = 0`;
                    tx.executeSql(sql, [], (_, { rows: { _array } }) => {
                        const responses = _array;
                        for (let question of data) {
                            question.responses = responses.filter(r => r.installationQuestionId == question.id);
                        }
                    });

                });
            }, (error) => {
                console.log(error);
                reject();
            }, () => {
                resolve(data);
            });
        });
    }

    getForSync(): Promise<any> {
        return new Promise((resolve, reject) => {
            var data = [];
            Database.getDb().transaction((tx) => {
                var sql = `
          SELECT * FROM ${this.TABLE_NAME} WHERE toBeSynced = 1
        `;

                tx.executeSql(sql, [], (_, { rows: { _array } }) => {
                    data = _array;

                    var sqlQuestions = `SELECT * FROM ${this.TABLE_NAME_ANSWERED_QUESTIONS} WHERE installationInspectionId = ?`;

                    for (let inspection of data) {
                        tx.executeSql(sqlQuestions, [inspection.id], (_, { rows: { _array } }) => {
                            inspection.questions = _array;
                        });

                    }
                });
            }, (error) => {
                console.log(error);
                reject();
            }, () => {
                resolve(data);
            })
        });
    }

    deleteInspection(inspectionId): Promise<boolean> {
        return new Promise((resolve, reject) => {
            Database.getDb().transaction((tx) => {
                var sql = `DELETE FROM ${this.TABLE_NAME_ANSWERED_QUESTIONS} WHERE installationInspectionId = ?`;
                tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => { });

                sql = `DELETE FROM ${this.TABLE_NAME} WHERE id = ?`;
                tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => { });
            }, (error) => {
                console.log(error);
                reject();
            }, () => {
                resolve(true);
            })
        });
    }

    getByJobId(jobId: string): Promise<any[]> {
        return new Promise((resolve, reject) => {
            Database.getDb().transaction((tx) => {
                tx.executeSql(
                    `SELECT * FROM InstallationInspections WHERE jobId = ? AND toBeSynced = 0`,
                    [jobId],
                    (_, { rows: { _array } }) => resolve(_array),
                    (_, error) => reject(error)
                );
            });
        });
    }

    updateInspectionToBeSynced(inspectionId: string, toBeSynced: boolean): Promise<void> {
        return new Promise((resolve, reject) => {
            Database.getDb().transaction((tx) => {
                tx.executeSql(
                    `UPDATE InstallationInspections SET toBeSynced = ? WHERE id = ?`,
                    [toBeSynced ? 1 : 0, inspectionId],
                    () => resolve(),
                    (_, error) => reject(error)
                );
            });
        });
    }

    updateInspection(inspectionId: any, installationDate: any, safeForUse: any, equipmentId: any, engineerId: any, jobId: any,
        missing: any, questions: any, images: any): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                Database.getDb().transaction((tx) => {
                    var sql = `
                        UPDATE ${this.TABLE_NAME}
                        SET
                            installationDate = ?, 
                            safeForUse = ?, 
                            equipmentId = ?, 
                            engineerId = ?, 
                            jobId = ?, 
                            missing = ?, 
                            toBeSynced = 1
                        WHERE id = ?
              `
                    tx.executeSql(sql, [
                        installationDate, safeForUse, equipmentId, engineerId, jobId, missing, inspectionId
                    ]);

                    const sqlQuestions = `DELETE FROM ${this.TABLE_NAME_ANSWERED_QUESTIONS} WHERE installationInspectionId = ?`;
                    tx.executeSql(sqlQuestions, [inspectionId]);
                    //
                    for (let question of questions) {
                        var sql = `
                            INSERT OR REPLACE INTO ${this.TABLE_NAME_ANSWERED_QUESTIONS} (
                            id, questionId, response, comments, installationInspectionId, imageBase64, responseValue
                            )
                            VALUES (?, ?, ?, ?, ?, ?, ?)
                        `;

                        tx.executeSql(sql, [uuid.v4(), question.id, question.response, question.comments, inspectionId, question.imageBase64, question.responseValue]);
                    }

                    // Update this asset to be completed 
                    sql = "UPDATE JobAssets SET completed = 1, toBeSynced = 1 WHERE jobId = ? and equipmentId = ?";
                    tx.executeSql(sql, [jobId, equipmentId]);

                }, (error) => {
                    console.log("ERROR");
                    console.log(error);
                    reject();
                }, () => {
                    console.log("UPDATED JOB Assets - JobId: " + jobId + " EquipId: " + equipmentId);
                    resolve();
                })
            } catch (e) {
                console.log("ERROR INSIDE INSTALLATION")
                reject();
                console.log(e)
            }
        })
    }

    getCompletedInspection(jobId, equipmentId): Promise<any> {
        return new Promise((resolve, reject) => {
            var data = [];
            Database.getDb().transaction((tx) => {
                var sql = `
              SELECT * FROM InstallationInspections
              WHERE jobId = ? AND equipmentId = ?
            `;

                tx.executeSql(sql, [jobId, equipmentId], (_, { rows: { _array } }) => {
                    data = _array;

                    var sqlQuestions = `SELECT * FROM ${this.TABLE_NAME_ANSWERED_QUESTIONS} WHERE installationInspectionId = ?`;

                    for (let inspection of data) {
                        tx.executeSql(sqlQuestions, [inspection.id], (_, { rows: { _array } }) => {
                            inspection.questions = _array;
                        });
                    }
                });
            }, (error) => {
                console.log(error);
                reject();
            }, () => {
                resolve(data);
            })
        });
    }
}