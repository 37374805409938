import React, { useRef } from 'react';
import { TouchableOpacity, Text, StyleSheet, Platform } from 'react-native';
import { useHover, useFocus, useActive } from 'react-native-web-hooks'

export function DefaultButton({text, onPressed}) {
  const ref = useRef(null);
  //const isHovered = useHover(ref);

  return (
    <TouchableOpacity style={[styles.button]} onPress={() => onPressed()}>
      <Text style={styles.button__text}>{text}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: '#FFF',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#CECECE',
  },

    button__hover: {
      backgroundColor: 'red'
    },


  button__text: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },

    
  
});