import * as React from 'react';
import { useState } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert, Platform } from 'react-native';
import { LoadingIndicator } from '../../../../components/navigation/controls/LoadingIndicator';

import { DataTable } from 'react-native-paper';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { useIsFocused } from "@react-navigation/native";
import { Entypo, Ionicons, SimpleLineIcons } from '@expo/vector-icons';
import { PagedTableDto } from '@store/services/api/base-api';
import { DataTableHeader } from '@web/components/table/DatatableHeader';
import PaginationComponent from '@web/components/Pagination';
import { FIRST_PAGE, MAX_ITEMS_BEFORE_ALL } from '../../../../../../constants/Constants';
import Spinner from 'react-native-loading-spinner-overlay';
import { pssrApi, useDeletePssrPartMutation } from '@store/services/api/pssr-api';


export default function PssrPartsListScreen({ navigation }: { navigation: any }) {

  const [deletePssrPart, deletepssrPartResult] = useDeletePssrPartMutation();
  const isFocused = useIsFocused()

  const [page, setPage] = React.useState<number>(0);
  const [sortedColumn, setSortedColumn] = React.useState<string>("name");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);
  const [loading, setLoading] = useState<any>(false);

  // Filters 
  const [partNo, setPartNo] = useState("");

  const [trigger, partsResult] = pssrApi.useLazyGetPssrPartsListQuery();

  const loadTable = () => {
    const pageNumber = numberOfItemsPerPage > MAX_ITEMS_BEFORE_ALL ? FIRST_PAGE : page;
    var dto = new PagedTableDto(pageNumber, {
      name: partNo,
      pageSize: numberOfItemsPerPage
    }, sortedColumn, sortedColumnAscending);
    trigger(dto).then(() => {
      setLoading(false);
    });
  }

  const resetFilters = () => {
    setPartNo("");
  }

  function applyFilters() {
    setPage((curr) => {
      if (curr == 0) loadTable();
      return 0;
    })
  }

  React.useEffect(() => {
    if (isFocused) {
      loadTable();
    }
  }, [page, isFocused, sortedColumn, sortedColumnAscending, numberOfItemsPerPage]);

  const remove = async (entityId) => {
    if (Platform.OS == 'web') {
      if (confirm("Are you sure you want to delete this part?")) {
        var result = await deletePssrPart(entityId);
        if (result.error) {
          Alert.alert("Error", "Something went wrong. Please try again");
          return;
        }

        refetch();
      }
    }
  }

  if (partsResult.isLoading || deletepssrPartResult.isLoading) {
    return <LoadingIndicator />
  }

  return (
    <View style={styles.container}>
      <Spinner
        visible={loading}
        textContent={'Loading...'}
        textStyle={GlobalStyle.spinnerTextStyle}
      />
      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Part name</Text>
            <TextInput
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              onChangeText={setPartNo}
              value={partNo}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={applyFilters}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

      <TouchableOpacity style={GlobalStyle.add__button} onPress={() => navigation.navigate("manage")}>
        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 5, paddingRight: 10 }}>
          <Ionicons name="add" size={24} color="white" /><Text style={GlobalStyle.form__submit__button__text}>Add New Part</Text>
        </View>
      </TouchableOpacity>

      <DataTable style={GlobalStyle.table}>
        <DataTableHeader
          sortedColumn={sortedColumn}
          sortedColumnAscending={sortedColumnAscending}
          onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
          headers={[
            { text: 'Name', sortedName: "name", visible: true },

          ]}
        />

        {partsResult?.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>{element.name}</DataTable.Cell>
              <DataTable.Cell>
                <View style={{ flexDirection: 'row' }}>
                  <TouchableOpacity onPress={() => navigation.navigate("manage", { pssrPartId: element.id })}>
                    <Entypo name="pencil" size={24} color="black" />
                  </TouchableOpacity>

                  <TouchableOpacity onPress={() => remove(element.id)} style={{ marginLeft: 20 }}>
                    <Ionicons name="ios-remove-circle" size={24} color="red" />
                  </TouchableOpacity>
                </View>

              </DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>

      <PaginationComponent
        numberOfItemsPerPage={numberOfItemsPerPage}
        setNumberOfItemsPerPage={setNumberOfItemsPerPage}
        page={page}
        setPage={setPage}
        fetchResult={partsResult}
        loading={loading}
        setLoading={setLoading}
      />

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
