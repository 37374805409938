import * as SQLite from 'expo-sqlite';
import { Database } from './database';

export class PurposeDatabase extends Database {
  TABLE_NAME = "Purposes";

  initialise() : Promise<void> {
    return new Promise((resolve, reject) => {
      var sql = `CREATE TABLE IF NOT EXISTS Purposes ( 
        id TEXT PRIMARY KEY,
        purpose TEXT,
        interval INT,
        position INT
      )`;

      Database.getDb().transaction((tx) => {
        tx.executeSql(sql);
      }, (error) => {
        console.log(error)
        reject();
      }, () => {
        resolve();
      })
    });
  }

  insertOrUpdateList(entities: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME} (id, purpose, interval, position)
            VALUES (?, ?, ?, ?)
          `

          tx.executeSql(sql, [entity.id, entity.purpose, entity.interval, entity.position]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  getAll() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM Purposes ORDER BY position
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          //console.log(_array)
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    })
  }

}