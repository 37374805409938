import { createNativeStackNavigator } from '@react-navigation/native-stack';
import JobsListScreen from './JobsList';
import JobsManageScreen from './JobsManage';

const Stack = createNativeStackNavigator();

export default function Jobs({ navigation }: { navigation: any }) {
  return (
    <Stack.Navigator>
      <Stack.Screen name="list" component={JobsListScreen} options={{ headerShown: false, title: 'Jobs'  }} />
      <Stack.Screen name="manage" component={JobsManageScreen} options={{ headerShown: false, title: 'Jobs Manage'}}  />
    </Stack.Navigator>
  )
};