import { View, Text, ScrollView, TouchableOpacity } from 'react-native';
import { jobApi } from "@store/services/api/job-api";
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { EvilIcons } from '@expo/vector-icons';
import { RootState, store } from '@store/index';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { inspectionApi } from '@store/services/api/inspection-api';
import { clearInspectionData, setInspectionData } from '@store/slices/rote-inspection-slice';
import RoteStep1 from '@components/management/Inspection/Rote/Step1';
import RoteStep3 from '@components/management/Inspection/Rote/Step3';
import { categoryApi } from '@store/services/api/category-api';
import RoteStep4 from '@components/management/Inspection/Rote/Step4';
import { purposeApi } from '@store/services/api/purpose-api';

export default function JobInspect({ navigation, route }: { navigation: any, route: any }) {
  const dispatch = useDispatch();
  const inspection = useSelector((state: RootState) => state.roteInspection);

  const [jobTrigger, jobResult] = jobApi.useLazyGetJobQuery();
  const [inspectionTrigger, inspectionResult] = inspectionApi.useLazyGetInspectionQuery();
  const [categoryTrigger, categoryResult] = categoryApi.useLazyGetCategoryQuery();
  const [purposeTrigger, purposeResult] = purposeApi.useLazyGetAllPurposesQuery();

  const [dbInspection, setDbInspection] = useState(null);
  const [category, setCategory] = useState(null);
  const [purposeInterval, setPurposeInterval] = useState(null);

  useEffect(() => {
    // Load the job
    if (route.params?.jobId && route.params?.inspectionId) {
      jobTrigger(route.params?.jobId);
      inspectionTrigger(route.params?.inspectionId);
    }
    if (route.params?.categoryId) {
      categoryTrigger(route.params?.categoryId);
    }
    purposeTrigger("");

  }, []);

  useEffect(() => {
    if (jobResult.isSuccess && inspectionResult.isSuccess && categoryResult.isSuccess) {
      setCategory(categoryResult.data);

      const getPurposeInterval = (purposeId) => {
        return purposeResult?.currentData?.find(p => p.id === purposeId).interval;
      }

      setPurposeInterval(getPurposeInterval(inspectionResult?.currentData?.purposeId))

      let dbInspection = { ...inspectionResult.data };
      const jobData = jobResult.data;

      if (!dbInspection.colourId) {
        dbInspection.colourId = jobData.colourId
      }
      setDbInspection(dbInspection);

      // Need to manually set our inspection data
      dispatch(setInspectionData({
        id: dbInspection.id,
        firstExamination: dbInspection.firstExamination,
        installedCorrectly: dbInspection.installedCorrectly,
        defects: dbInspection.defects,
        immediateToPersons: dbInspection.immediateToPersons,
        becomeDangerToPersons: dbInspection.becomeDangerToPersons,
        repairDate: dbInspection.repairDate,
        repair: dbInspection.repair,
        identification: dbInspection.identification ?? "",
        particulars: dbInspection.particulars ?? "",
        safeForUse: dbInspection.safeForUse,
        inspectionDate: dbInspection.inspectionDate,
        nextInspectionDate: dbInspection.nextInspectionDate,
        nextTestDate: dbInspection.nextTestDate,
        colourId: dbInspection.colourId,
        purposeId: dbInspection.purposeId
      }));
    }
  }, [jobResult, inspectionResult, categoryResult, purposeResult]);

  const goBack = () => {
    navigation.navigate("job-approval-assets", { jobId: route.params?.jobId })
  }

  const onSubmit = async () => {
    var inspectionToEdit = { ...dbInspection };
    inspectionToEdit.firstExamination = inspection.firstExamination;
    inspectionToEdit.installedCorrectly = inspection.installedCorrectly;
    inspectionToEdit.defects = inspection.defects;
    inspectionToEdit.immediateToPersons = inspection.immediateToPersons;
    inspectionToEdit.becomeDangerToPersons = inspection.becomeDangerToPersons;
    inspectionToEdit.repairDate = inspection.repairDate;
    inspectionToEdit.repair = inspection.repair;
    inspectionToEdit.identification = inspection.identification;
    inspectionToEdit.particulars = inspection.particulars;
    inspectionToEdit.safeForUse = inspection.safeForUse;
    inspectionToEdit.inspectionDate = inspection.inspectionDate;
    inspectionToEdit.nextInspectionDate = inspection.nextInspectionDate;
    inspectionToEdit.nextTestDate = !inspection.isMarineDisabled ? inspection.nextTestDate : null;
    inspectionToEdit.colourId = inspection.colourId;
    inspectionToEdit.purposeId = inspection.purposeId;

    var result = await store.dispatch(inspectionApi.endpoints.updateInspection.initiate(inspectionToEdit)) as any;
    dispatch(clearInspectionData());
    goBack();
  }

  if (dbInspection == null) {
    return <LoadingIndicator />
  }

  return (
    <ScrollView>
      <View>
        <View style={GlobalStyle.sub__title}>
          <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => goBack()}>
            <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
          </TouchableOpacity>
          <Text style={GlobalStyle.sub__title__text}>Inspection</Text>
        </View>

        <RoteStep1 navigation={navigation} route={route} />
        {/* Step 2 is not needed on the web for now */}
        <RoteStep3 navigation={navigation} route={route} checklist={null} />
        <RoteStep4 navigation={navigation} route={route} purposeInterval={purposeInterval} checklist={null} submitHandler={onSubmit} isMarineEquipment={null} marineEquipmentId={null} context={"job approval"} isModal={true} />
      </View>
    </ScrollView>
  )
};