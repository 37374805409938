import { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { Controller, useForm } from 'react-hook-form';
import { setInspectionData } from '@store/slices/rote-inspection-slice';
import { setJobData } from '../../../../store/slices/job-slice';
import RadioButtonGroup, { RadioButtonItem } from "expo-radio-button";
import { TitleBar } from '@app/components/TitleBar';
import { colours as importedColours } from '../../../../config';
import { JobDatabase } from '@store/services/database/job-database';
import { PurposeDatabase } from '@store/services/database/purpose-database';
import { ColourDatabase } from '@store/services/database/colour-database';
import { Picker } from '@react-native-picker/picker';
import RNPickerSelect from 'react-native-picker-select';
import { InspectionDatabase } from '@store/services/database/inspection-database';

export default function RoteStep1({ navigation, route }: { navigation: any, route: any }) {
  const dispatch = useDispatch();
  const inspection = useSelector((state: RootState) => state.roteInspection);
  const job = useSelector((state: RootState) => state.job);
  const isWeb = Platform.OS === 'web';

  const [purposes, setPurposes] = useState([]);
  const [colours, setColours] = useState([]);
  const [defaultColourId, setDefaultColourId] = useState(null);

  const { checklist } = route?.params || {};

  const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      purposeId: '',
      colourId: '',
      firstExamination: null,
      installedCorrectly: null
    }
  });
  let jobDatabase = null;
  let inspectionDatabase = null;

  if (!isWeb) {
    jobDatabase = new JobDatabase();
    inspectionDatabase = new InspectionDatabase();
  }
  const loadPurposes = async () => {
    const purposeDatabase = new PurposeDatabase();
    var purposes = await purposeDatabase.getAll();
    setPurposes(purposes);
  }

  const loadColours = async () => {
    const coloursDatabase = new ColourDatabase();
    var colours = await coloursDatabase.getAll();
    setColours(colours);
  }

  const data = watch();

  useEffect(() => {

    const loadData = async () => {
      if (!isWeb) {
        loadPurposes();
        loadColours();
      }
      let dbInspectionData = null;
      let dbInspection = null

      if (!isWeb) {
        dbInspectionData = await inspectionDatabase.getCompletedInspection(job.jobId, job.equipmentId);
        [dbInspection] = dbInspectionData;
      }

      if (dbInspectionData !== null && dbInspectionData.length > 0) {
        setValue("purposeId", dbInspection.purposeId);
        setValue("colourId", dbInspection.colourId || job.colourId);
        setDefaultColourId(inspection.colourId);
        setValue("firstExamination", dbInspection.firstExamination);
        setValue("installedCorrectly", dbInspection.installedCorrectly);
      } else {
        setValue("purposeId", inspection.purposeId);
        setValue("colourId", inspection.colourId || job.colourId);
        setDefaultColourId(inspection.colourId);
        setValue("firstExamination", inspection.firstExamination);
        setValue("installedCorrectly", inspection.installedCorrectly);
      }
    }

    loadData();
  }, [])

  const firstExamination = watch("firstExamination");


  if (isWeb) {
    useEffect(() => {
      setData(data);
    }, [data]);
  }

  const setData = (data) => {
    dispatch(setInspectionData({
      firstExamination: data.firstExamination,
      installedCorrectly: data.installedCorrectly,
    }));
  };

  const onSubmit = async data => {
    if (!inspection.colourId) {
      await jobDatabase.updateJobData(job.jobId, data.purposeId, data.colourId);
    } else {
      await jobDatabase.updateJobData(job.jobId, data.purposeId, defaultColourId);
    }

    var purpose = purposes.find(p => p.id == data.purposeId);

    dispatch(setInspectionData({
      purposeId: data.purposeId,
      colourId: data.colourId,
      purposeInterval: purpose.interval
    }));

    if (!job.colourId) {
      dispatch(setJobData({
        colourId: data.colourId
      }))
    }
    setData(data);
    navigation.navigate(checklist == 1 ? "Step2" : "Step3");
  };

  return (
    <View style={styles.container}>
      {!isWeb ? <TitleBar navigation={navigation} title={`Step 1 of ${checklist === 1 ? '4' : '3'}`} showSearchButton={false} showBackButton={true} onSynced={null} onSearch={null} /> : null}
      {!isWeb && <View>
        <Controller
          control={control}
          rules={{ required: true }}
          name="purposeId"
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={styles.step__question}>
              <Text style={styles.step__question__label}>Purpose of Examination</Text>

              {Platform.OS === 'ios' ? (
                <View>
                  <RNPickerSelect
                    value={value}
                    onValueChange={(itemValue) => onChange(itemValue)}
                    items={purposes.map((value) => ({ label: value.purpose, value: value.id }))}
                    style={styles}
                  />
                </View>
              ) : (
                <Picker
                  selectedValue={value}
                  onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                  itemStyle={{ height: 50 }}>

                  <Picker.Item label="Please Select" value="" />

                  {purposes != null && purposes.map((value, index) => {
                    return (
                      <Picker.Item key={value.id} label={value.purpose} value={value.id} />
                    )
                  })}
                </Picker>
              )}

              {errors.purposeId && <Text style={styles.input__validation}>Please select a response before continuing</Text>}
            </View>
          )}
        />

        <Controller
          control={control}
          rules={{ required: true }}
          name="colourId"
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={styles.step__question}>
              <Text style={styles.step__question__label}>Colours</Text>

              {Platform.OS === 'ios' ? (
                <View>
                  <RNPickerSelect
                    value={value}
                    onValueChange={(itemValue) => onChange(itemValue)}
                    items={colours.map((value) => ({ label: value.name, value: value.id }))}
                    style={styles}
                  />
                </View>
              ) : (
                <Picker
                  selectedValue={value}
                  onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                  itemStyle={{ height: 50 }}>

                  <Picker.Item label="Please Select" value="" />

                  {colours != null && colours.map((value, index) => {
                    return (
                      <Picker.Item key={value.id} label={value.name} value={value.id} />
                    )
                  })}
                </Picker>
              )}

              {errors.purposeId && <Text style={styles.input__validation}>Please select a response before continuing</Text>}
            </View>
          )}
        />
      </View>}
      <View style={styles.form}>
        <Controller
          control={control}
          rules={{ required: true }}
          name="firstExamination"
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={styles.step__question}>
              <Text style={styles.step__question__label}>Is this the first examination after installation or assembly at a new site or location?</Text>

              <RadioButtonGroup
                containerStyle={{ marginTop: 10, flexDirection: 'row' }}
                radioBackground={importedColours.primary}
                selected={value}
                onSelected={onChange}>

                <RadioButtonItem value={0} label="No" />
                <RadioButtonItem value={1} style={{ marginLeft: 20 }} label="Yes" />
              </RadioButtonGroup>

              {errors.firstExamination ? <Text style={styles.input__validation}>Please select a response before continuing</Text> : null}
            </View>
          )}
        />

        {firstExamination == true ?
          <Controller
            control={control}
            rules={{ required: true }}
            name="installedCorrectly"
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={styles.step__question}>
                <Text style={styles.step__question__label}>Has the equipment been installed correctly?</Text>

                <RadioButtonGroup
                  containerStyle={{ marginTop: 10, flexDirection: 'row' }}
                  radioBackground={importedColours.primary}
                  selected={value}
                  onSelected={onChange}>

                  <RadioButtonItem value={0} label="No" />
                  <RadioButtonItem value={1} style={{ marginLeft: 20 }} label="Yes" />
                </RadioButtonGroup>

                {errors.installedCorrectly && <Text style={styles.input__validation}>Please select a response before continuing</Text>}
              </View>
            )}
          /> : null
        }
      </View>

      {Platform.OS != 'web' ?
        <TouchableOpacity style={styles.proceed__button} onPress={handleSubmit(onSubmit)}>
          <Text style={styles.proceed__button__text}>NEXT</Text>
        </TouchableOpacity> : null
      }

    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    ...Platform.select({
      android: { flex: 1, padding: 40, },
      ios: { flex: 1, padding: 20, paddingTop: 60 },
      web: { padding: 20, paddingBottom: 0, paddingTop: 0 },
    }),
    backgroundColor: '#FAFAFA',
  },

  form: {
    flex: 1,
    marginTop: 10,
  },

  step__question: {
    padding: 20,
    backgroundColor: '#FFF',
    marginBottom: 20,
  },

  step__question__label: {
    fontFamily: 'OpenSans-Light',
    fontSize: 18,
    marginBottom: 5,
  },

  input__validation: {
    color: 'red',
    marginTop: 10
  },

  proceed__button: {
    padding: 10,
    backgroundColor: importedColours.primary,
    marginTop: 20,
    borderRadius: 7,
    height: 45,


    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  proceed__button__text: {
    fontFamily: 'OpenSans-Bold',
    color: '#FFF',
    textAlign: 'center',
    fontSize: 18
  },
  inputIOS: {
    fontSize: 15,
    paddingVertical: 9,
    paddingHorizontal: 9,
    borderWidth: 1,
    marginRight: 10,
    borderColor: 'gray',
    borderRadius: 4,
    color: 'black',
    paddingRight: 30 // to ensure the text is never behind the icon
  }
});
function loadData() {
  throw new Error('Function not implemented.');
}

