import { baseApi, PagedTableDto } from './base-api'

export const pssrApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getPssrInspection: build.query<any, any>({
            query: (id) => ({
                url: `pressureinspections/getinspection/${id}`,
            }),
        }),
        getPssrInspections: build.query<any, PagedTableDto>({
            query: (dto) => ({
                url: `pressureinspections/getpressureinspectionslist?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
            }),
            transformResponse: (response: any, meta, arg) => {
                var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

                return {
                    list: response,
                    pagination: pagination
                };
            }
        }),
        sendPssrInspection: build.mutation<any, any>({
            query: (body) => ({
                url: 'pressureInspections/addPressureInspection',
                method: 'post',
                body: body
            }),
        }),
        updatePssrInspection: build.mutation<any, any>({
            query: (body) => ({
                url: 'pressureinspections/updateinspection',
                method: 'put',
                body: body
            }),
        }),
        archivePssrInspection: build.mutation<any, { id: number }>({
            query: (id) => ({
                url: `pressureinspections/archiveinspection/${id}`,
                method: 'put'
            }),
        }),
        getPssrPartsList: build.query<any, PagedTableDto>({
            query: (dto) => ({
                url: `pressureinspections/getpssrpartslist?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
            }),
            transformResponse: (response: any, meta, arg) => {
                var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

                return {
                    list: response,
                    pagination: pagination
                };
            }
        }),
        syncPssrParts: build.mutation<any, any>({
            query: (lastModifiedOn) => ({
                url: `pressureinspections/getPssrParts?lastmodifiedon=${lastModifiedOn}`,
            })
        }),
        createPssrPart: build.mutation<any, any>({
            query: (body) => ({
                url: 'pressureinspections/addpssrpart',
                method: 'post',
                body: body
            }),
        }),
        updatePssrPart: build.mutation<any, any>({
            query: (body) => ({
                url: `pressureinspections/updatepssrpart/${body.id}`,
                method: 'put',
                body: body
            }),
        }),
        getPssrPart: build.query<any, any>({
            query: (partId) => ({
                url: `pressureinspections/getpssrpart/${partId}`,
            }),
        }),
        deletePssrPart: build.mutation<any, any>({
            query: (partId) => ({
                url: `pressureinspections/deletepssrpart/${partId}`,
                method: 'DELETE',
            }),
        }),
    }),
    overrideExisting: true,
})

export const {
    useArchivePssrInspectionMutation,
    useGetPssrInspectionQuery,
    useGetPssrInspectionsQuery,
    useUpdatePssrInspectionMutation,
    useSendPssrInspectionMutation,
    useGetPssrPartsListQuery,
    useCreatePssrPartMutation,
    useDeletePssrPartMutation,
    useGetPssrPartQuery,
    useSyncPssrPartsMutation,
    useUpdatePssrPartMutation

} = pssrApi