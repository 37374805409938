import { DateRangeFilter, DateRangeOptions } from "./SearchFilters";

export {
    NextTestDateRangeFilter,
    InspectionDateRangeFilter
}

function NextTestDateRangeFilter({value, onValueChange}) {
    const options: DateRangeOptions[] = [
        { label: "Predefined Date Range", value: "" },
        { label: "1 Week", value: "-1" },
        { label: "1 Month", value: "1" },
        { label: "3 Months", value: "3" },
        { label: "6 Months", value: "6" },
        { label: "12 Months", value: "12" }
    ]

    return <DateRangeFilter title="Next Test Date Range" value={value} onValueChange={onValueChange} options={options} />
}

function InspectionDateRangeFilter({value, onValueChange}) {
    const options: DateRangeOptions[] = [
        { label: "Predefined Date Range", value: "" },
        { label: "1 Week", value: "-1" },
        { label: "1 Month", value: "1" },
        { label: "3 Months", value: "3" },
        { label: "6 Months", value: "6" },
        { label: "12 Months", value: "12" }
    ]

    return <DateRangeFilter title="Inspection Date Range" value={value} onValueChange={onValueChange} options={options} />
}