import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export interface UpdaterState {
  totalCount: number;
  currentCount: number;
  percentage: number;
}

export interface UpdaterSlice {
  // Inspection Data
  totalCount: number;
  currentCount: number;
  percentage: number;
}

const initialState: UpdaterState = {
  totalCount: 0,
  currentCount: 0,
  percentage: 0
}

export const updaterSlice = createSlice({
  name: 'updater',
  initialState,
  reducers: {
    setTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload;
    },

    addToCurrentCount: (state, action: PayloadAction<number>) => {
      state.currentCount += action.payload;

      if (state.totalCount == 0) state.percentage = 0;
      else state.percentage = (state.currentCount / state.totalCount) * 100;
    }
  }
})

export const { setTotalCount, addToCurrentCount } = updaterSlice.actions;

export default updaterSlice.reducer;