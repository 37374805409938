import * as React from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity } from 'react-native';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { EvilIcons } from '@expo/vector-icons';
import { Controller, useForm } from 'react-hook-form';
import { LoadingIndicator } from '../../../../components/navigation/controls/LoadingIndicator';
import { depotApi, useCreateDepotMutation, useUpdateDepotMutation } from '@store/services/api/depot-api';


export default function DepotManageScreen({ navigation, route }: { navigation: any, route: any }) {
    const [createDepot, createDepotResult] = useCreateDepotMutation();
    const [updateDepot, updateDepotResult] = useUpdateDepotMutation();

    const [trigger, depotResult] = depotApi.useLazyGetDepotQuery();

    const { control, handleSubmit, getValues, setValue, formState: { errors } } = useForm({
        defaultValues: {
            id: null,
            name: "",
            address1: "",
            address2: "",
            address3: "",
            address4: "",
            postcode: "",
        }
    });

    React.useEffect(() => {
        if (route.params?.depotId != null) {
            trigger(route.params?.depotId);
        }
    }, [])

    React.useEffect(() => {
        if (depotResult.isSuccess) {
            var data = depotResult.data;
            setValue("id", data.id);
            setValue("name", data.name);
            setValue("address1", data.address1);
            setValue("address2", data.address2);
            setValue("address3", data.address3);
            setValue("address4", data.address4);
            setValue("postcode", data.postcode);
        }
    }, [depotResult]);

    const onSubmit = async (data) => {
        var result = null;

        if (data.id != null) {
            result = await updateDepot(data) as any;
        } else {
            result = await createDepot(data) as any;
        }

        if (result.error) {
            alert(result.error.data);
            return;
        }

        navigation.goBack();
    };

    if (createDepotResult.isLoading || updateDepotResult.isLoading) {
        return <LoadingIndicator />
    }

    return (
        <View style={styles.container}>
            <View style={GlobalStyle.sub__title}>
                <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => navigation.goBack()}>
                    <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
                </TouchableOpacity>
                <Text style={GlobalStyle.sub__title__text}>{getValues("name") == null ? "CREATE DEPOT" : "UPDATE DEPOT"}</Text>
            </View>

            <View style={GlobalStyle.form}>
                {/* ##### ROW ##### */}
                <View style={GlobalStyle.form__row}>
                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name="name"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Depot Name (*)</Text>
                                    <TextInput
                                        style={GlobalStyle.form__column__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}>
                                    </TextInput>
                                </View>
                            )}
                        />

                        {errors.name && <Text style={GlobalStyle.form__input__validation}>Name is required.</Text>}
                    </View>

                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name="address1"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Address Line 1 (*)</Text>
                                    <TextInput
                                        style={GlobalStyle.form__column__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}>
                                    </TextInput>
                                </View>
                            )}
                        />

                        {errors.address1 && <Text style={GlobalStyle.form__input__validation}>Address Line 1 is required.</Text>}
                    </View>

                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="address2"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Address Line 2</Text>
                                    <TextInput
                                        style={GlobalStyle.form__column__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}>
                                    </TextInput>
                                </View>
                            )}
                        />
                    </View>
                </View>

                {/* ##### ROW ##### */}
                <View style={GlobalStyle.form__row}>
                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="address3"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Address Line 3</Text>
                                    <TextInput
                                        style={GlobalStyle.form__column__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}>
                                    </TextInput>
                                </View>
                            )}
                        />
                    </View>

                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="address4"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Address Line 4</Text>
                                    <TextInput
                                        style={GlobalStyle.form__column__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}>
                                    </TextInput>
                                </View>
                            )}
                        />
                    </View>

                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="postcode"
                            rules={{ required: true }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Postcode (*)</Text>
                                    <TextInput
                                        style={GlobalStyle.form__column__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}>
                                    </TextInput>
                                </View>
                            )}
                        />

                        {errors.postcode && <Text style={GlobalStyle.form__input__validation}>Postcode is required.</Text>}
                    </View>
                </View>

                <TouchableOpacity style={GlobalStyle.form__submit__button} onPress={handleSubmit(onSubmit)}>
                    <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        position: 'relative',
        padding: 10,
    },
});
