import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import DateTimePicker from 'react-native-ui-datepicker';

import { useEffect, useState } from "react";
import { Platform, TextInput, TouchableOpacity, View } from "react-native";
import dayjs from "dayjs";

const InlinePicker = ({ date, onDateChange }) => {
  const [isVisible, setVisible] = useState(false);

  return (
    <View style={{ position: 'relative' }}>
      <TouchableOpacity onPress={() => setVisible(!isVisible)}>
        <TextInput editable={false} value={dayjs(date).format("DD/MM/YYYY")}/>
      </TouchableOpacity>
      {isVisible && 
        <DateTimePicker
          mode="single"
          date={new Date(date)}
          onChange={(params) => {onDateChange(params.date.toDate()); setVisible(false)}}
        />
      }
      
    </View>
  )
}

const DialogPicker = ({ date, onDateChange }) => {
  if (Platform.OS === "web") {
    const options = {
      enableTime: false,
      dateFormat: 'd/m/Y',
      allowInput: true,
      utc: true,
      appendTo: document.body,
      onReady: function () {
        const customStyleTag = document.createElement("style");
        customStyleTag.innerHTML = customStyles;
        document.head.appendChild(customStyleTag);
      },
    };

    const customStyles = `
    .flatpickr-input {
        border: 1px solid #CECECE !important;
        padding: 7px !important;
        font-family: 'OpenSans-Light' !important;
        margin-right: 10px !important;
    }
  `;

    return (
      <Flatpickr
        value={new Date(date)}
        onChange={(selectedDate) => onDateChange(selectedDate)}
        onClose={(selectedDate) => onDateChange(selectedDate)}
        options={options}
      />
    );
  }
}

export default function DateTimePickerWeb({ value, onChange, style, inline = false }: Props) {
  const [date, setDate] = useState(value);

  useEffect(() => {
    setDate(value);
  }, [value]);

  const handleDateChange = (selectedDate) => {
    const parsedDate = new Date(selectedDate + "GMT");
    setDate(parsedDate);
    onChange(parsedDate);
  };

  if (date == null) return;

  if (inline) {
    return <InlinePicker date={date} onDateChange={(date) => handleDateChange(date)}/>
  } else {
    return <DialogPicker date={date} onDateChange={(date) => handleDateChange(date)}/>
  } 
}