import { EvilIcons, MaterialIcons } from '@expo/vector-icons';
import * as React from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert } from 'react-native';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import * as DocumentPicker from 'expo-document-picker';
import { baseApi, baseUrl } from '@store/services/api/base-api';
import Constants from "expo-constants";
import { useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { DataTable } from 'react-native-paper';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { A } from '@expo/html-elements';
import { hasFeatureAccess } from '../../System/Permissions/permissionsUtils';
import { JobManagementPermissions, PermissionCategories } from '../../System/Permissions/permissionsEnums';

export default function MassUploadScreen({ navigation }: { navigation: any }) {
  const user = useSelector((state: RootState) => state.user.user);
  const permissions = useSelector((state: RootState) => state.user.user.permissions);
  const isSystemUser = user.role === "System";
  const modules = useSelector((state: RootState) => state.user.modules);
  const isUserPermissionsEnabled = modules.isUserPermissionsEnabled;
  
  const [equipment, setEquipment] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const loadFile = async () => {
    var result = await DocumentPicker.getDocumentAsync({
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    }) as any;


    setLoading(true);

    if (result.assets.length == 0) return;
    const file = result.assets[0].file;
    
    const formData = new FormData();
    formData.append('file', file, result.name);
   
    const response = await fetch(`${baseUrl}api/equipment/postupload`, {
      method: "POST",
      body: formData,
      headers: new Headers({
        'Authorization': `Bearer ${user.token}`
      })
    });
    
    setLoading(false);

    if (response.status == 200) {
      var data = await response.json();
      if (data.filter(d => d.success == false).length > 0) {
        data = data.filter(d => d.success == false);
      } else {
        alert("Successfully uploaded equipment");
      }
      setEquipment(data);
    } else if(response.status == 400) {
      const errorResponse = await response.json();
      alert(errorResponse.message);
    } else {
      alert("Something went wrong, try again.");
    }
  }

  if (loading) {
    return <LoadingIndicator/>
  }

  return (
    <View style={styles.container}>
      <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => navigation.goBack()}>
          <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>MASS UPLOAD</Text>
      </View>
      {(!isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Administration, JobManagementPermissions.AddJob))) && <View>
        <TouchableOpacity style={styles.upload__button} href={`${Constants.expoConfig.blobStorageUrl}templates/MasterTemplate.xlsx`}>
          <Text style={styles.upload__button__text__file}>DOWNLOAD MASTER FILE</Text>
        </TouchableOpacity>

        <TouchableOpacity style={styles.upload__button} onPress={() => loadFile()}>
          <Text style={styles.upload__button__text}>SELECT FILE</Text>
        </TouchableOpacity>

        <View style={{ flexDirection: 'row', marginBottom: 10 }}>
          <View style={{ flexDirection: 'row', backgroundColor: '#dc3545', padding: 10, borderRadius: 5, alignItems: 'center', justifyContent: 'center' }}>
            <MaterialIcons name="error-outline" size={18} color="white" />
            <Text style={{ color: '#FFF', marginLeft: 10 }}>{equipment.filter(e => !e.success).length} Errors</Text>
          </View>
        </View>

        <DataTable style={GlobalStyle.table}>
          <DataTable.Header>
            <DataTable.Title>Company</DataTable.Title>
            <DataTable.Title>Company Address</DataTable.Title>
            <DataTable.Title>Location</DataTable.Title>
            <DataTable.Title>Location Address</DataTable.Title>
            <DataTable.Title>SubLocation</DataTable.Title>
            <DataTable.Title>Master Category</DataTable.Title>
            <DataTable.Title>Category</DataTable.Title>
            <DataTable.Title>Description</DataTable.Title>
            <DataTable.Title>Serial Number</DataTable.Title>
            <DataTable.Title>Asset Number</DataTable.Title>
            <DataTable.Title>Manufacturer</DataTable.Title>
            <DataTable.Title>Date of Manufacture</DataTable.Title>
            <DataTable.Title>SWL</DataTable.Title>
            <DataTable.Title>Status</DataTable.Title>
            <DataTable.Title>Error</DataTable.Title>
          </DataTable.Header>

          {equipment.map((element: any, index: number) => {
            return (
              <DataTable.Row key={index} style={[!element.success && styles.row__error]}>
                <View style={styles.row__wrap}><Text textBreakStrategy="balanced" numberOfLines={4}>{element.company}</Text></View>
                <View style={styles.row__wrap}><Text textBreakStrategy="balanced" numberOfLines={4}>{element.companyAddress}</Text></View>
                <View style={styles.row__wrap}><Text textBreakStrategy="balanced" numberOfLines={4}>{element.location}</Text></View>
                <View style={styles.row__wrap}><Text textBreakStrategy="balanced" numberOfLines={4}>{element.locationAddress}</Text></View>
                <View style={styles.row__wrap}><Text textBreakStrategy="balanced" numberOfLines={4}>{element.subLocation}</Text></View>
                <View style={styles.row__wrap}><Text textBreakStrategy="balanced" numberOfLines={4}>{element.masterCategory}</Text></View>
                <View style={styles.row__wrap}><Text textBreakStrategy="balanced" numberOfLines={4}>{element.category}</Text></View>
                <View style={styles.row__wrap}><Text textBreakStrategy="balanced" numberOfLines={4}>{element.description}</Text></View>
                <View style={styles.row__wrap}><Text textBreakStrategy="balanced" numberOfLines={4}>{element.serialNumber}</Text></View>
                <View style={styles.row__wrap}><Text textBreakStrategy="balanced" numberOfLines={4}>{element.assetNumber}</Text></View>
                <View style={styles.row__wrap}><Text textBreakStrategy="balanced" numberOfLines={4}>{element.manufacturer}</Text></View>
                <View style={styles.row__wrap}><Text textBreakStrategy="balanced" numberOfLines={4}>{element.dateOfManufacture.split(' ')[0]}</Text></View>
                <View style={styles.row__wrap}><Text textBreakStrategy="balanced" numberOfLines={4}>{element.swl}</Text></View>
                <DataTable.Cell>
                  {!element.success && <MaterialIcons name="error-outline" size={24} color="#dc3545" />}
                  {element.success && <MaterialIcons name="check-circle-outline" size={24} color="#28a745" />}
                </DataTable.Cell>
                <View style={styles.row__error}>
                  <Text textBreakStrategy="balanced" style={[!element.success && styles.row__error__text]} numberOfLines={4}>{element.error}</Text>
                </View>
              </DataTable.Row>
            )
          })}
        </DataTable>
      </View>}
    </View>
  )
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },

  upload__button: {
    marginBottom: 10,
    backgroundColor: PRIMARY_COLOUR,
    width: 250,
  },

  upload__button__text: {
    fontFamily: 'OpenSans-Bold',
    padding: 10,
    color: '#FFF',
    textAlign: 'center',
    fontSize: 18,
  },

  upload__button__text__file: {
    fontFamily: 'OpenSans-Bold',
    padding: 10,
    color: '#FFF',
    textAlign: 'center',
    fontSize: 18,
  },

  row__error: {
    flex:1,
    minHeight: 60
  },

  row__wrap: {
    flex: 1
  },

  row__error__text: {
    flexWrap: 'wrap'
  },

});
