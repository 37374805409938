import * as React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from "react-redux";
import { RootState } from "@store/index";
import { EvilIcons } from '@expo/vector-icons';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import PermissionSwitch from './PermissionSwitch';
import { permissionApi, useUpdatePermissionsMutation } from '@store/services/api/permission-api';
import { ScrollView } from 'react-native-gesture-handler';
import { hasFeatureAccess } from './permissionsUtils';
import { JobManagementPermissions, PermissionCategories } from './permissionsEnums';

export default function PermissionsDashboard({ navigation, route }: { navigation: any, route: any }) {
  const user = useSelector((state: RootState) => state.user.user);
  const userPermissions = useSelector((state: RootState) => state.user.user.permissions);
  const isSystemUser = user.role === "System";
  const modules = useSelector((state: RootState) => state.user.modules);
  const isUserPermissionsEnabled = modules.isUserPermissionsEnabled;

  const [permissions, setPermissions] = React.useState<any>([]);
  const [updatePermissions, updatePermissionsResult] = useUpdatePermissionsMutation();

  const { data: permissionsData, isLoading, isError } = permissionApi.useGetAllPermissionsQuery(null)

  React.useEffect(() => {
    if (!isLoading && !isError) {
      const groupedPermissionsByRoleAndCategory = permissionsData.reduce((grouped, permission) => {
        if (user.role === "System" || (user.role === "Admin" && permission.role !== "System" && permission.role !== "Admin")) {
          const role = permission.role;
          const category = permission.category;

          if (role && category) {
            if (!grouped[role]) {
              grouped[role] = {};
            }

            if (!grouped[role][category]) {
              grouped[role][category] = [];
            }

            grouped[role][category].push(permission);
          }
        }
        return grouped;
      }, {});

      const permissionsArray = Object.entries(groupedPermissionsByRoleAndCategory).map(([role, categories]) => ({
        role,
        categories: Object.entries(categories).map(([category, permissions]) => ({
          category,
          permissions,
        })),
      }));

      setPermissions(permissionsArray);
    }
  }, [isLoading, isError, permissionsData]);

  const togglePermission = async (permission) => {
    const oldPermissions = permissions;
  
    const updatedPermissions = permissions.map(rolePermissions => {
      const updatedCategories = rolePermissions.categories.map(category => {
        const updatedPermissions = category.permissions.map(item => {
          if (permission.id === item.id) {
            return { ...item, hasAccess: !item.hasAccess };
          }
          return item;
        });
  
        return { ...category, permissions: updatedPermissions };
      });
  
      return { ...rolePermissions, categories: updatedCategories };
    });
  
    
    setPermissions(updatedPermissions);
    
    var result = await updatePermissions(updatedPermissions.flatMap(role => role.categories.flatMap(category => category.permissions))) as any;
  
    if (result.error) {
      alert(result.error);
      setPermissions(oldPermissions);
      return;
    }
  }

  return (
    <ScrollView style={styles.container}>
      {(!isUserPermissionsEnabled || (hasFeatureAccess(user, userPermissions, isSystemUser, PermissionCategories.Administration, JobManagementPermissions.AddJob))) &&<View>
        <View style={GlobalStyle.sub__title}>
          <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => navigation.goBack()}>
            <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
          </TouchableOpacity>
          <Text style={GlobalStyle.sub__title__text}>{user.role == "Admin" ? "Administrator" : user.role} - Dashboard</Text>
        </View>

        <View style={styles.role__columns}>
          {permissions.map((rolePermissions, roleIndex) => (
            <View key={roleIndex} style={styles.role__column}>
              <Text style={styles.role__text}>{rolePermissions.role == "Admin" ? "Administrator" : rolePermissions.role}</Text>
              {rolePermissions.categories.map((category, categoryIndex) => (
                <View key={categoryIndex} style={styles.category__row}>
                  <Text style={styles.category__text}>{category.category}</Text>
                  <View style={styles.permissions__row}>
                    {category.permissions.map(permission => (
                      <PermissionSwitch key={permission.id} permission={permission} togglePermission={togglePermission} />
                    ))}
                  </View>
                </View>
              ))}
            </View>
          ))}
        </View>
      </View>}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
    height: '100%',
  },

  role__columns: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginVertical: 10,
  },

  role__column: {
    flex: 1,
    marginRight:10,
  },

  role__text: {
    textAlign: 'center',
    marginBottom: 10,
    fontSize: 18,
    fontWeight: 'bold',
  },

  category__row: {
    marginBottom: 20,
  },

  category__text: {
    width: '100%',
    textAlign: 'center',
    padding: 10,
    backgroundColor: PRIMARY_COLOUR,
    color: '#FFF',
    fontFamily: 'OpenSans-Bold',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },

  permissions__row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
});
