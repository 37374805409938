import { baseApi, PagedTableDto } from './base-api'

export const updateApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getTotalsForSync: build.query<any, any>({
      query: (params) => ({
        url: `updater/gettotalsforsync?${params}`,
      }),
    }),
  }),
  overrideExisting: true,
})

export const { useGetTotalsForSyncQuery } = updateApi