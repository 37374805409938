import * as React from 'react';
import { useState } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, ScrollView } from 'react-native';

import { DataTable } from 'react-native-paper';
import { useIsFocused } from "@react-navigation/native";
import GlobalStyle from '../../../../../../constants/GlobalStyle';
import { useGetAllCompaniesQuery } from '@store/services/api/company-api';
import { useGetAllLocationsQuery } from '@store/services/api/location-api';
import { jobApi, useApproveJobMutation, useCompleteJobMutation, useGetJobsQuery, useDeleteJobMutation } from '@store/services/api/job-api';
import { PagedTableDto } from '@store/services/api/base-api';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { Picker } from '@react-native-picker/picker';
import { SimpleLineIcons, Ionicons, MaterialCommunityIcons, AntDesign} from '@expo/vector-icons';
import { useSelector } from "react-redux";
import { RootState } from "@store/index";
import { hasFeatureAccess } from '../../System/Permissions/permissionsUtils';
import { JobManagementPermissions, PermissionCategories } from '../../System/Permissions/permissionsEnums';
import { DataTableHeader } from '@web/components/table/DatatableHeader';
import PaginationComponent from '@web/components/Pagination';
import { FIRST_PAGE, MAX_ITEMS_BEFORE_ALL } from '../../../../../../constants/Constants';
import Spinner from 'react-native-loading-spinner-overlay';

export default function JobApprovalScreen({navigation}: {navigation: any}) {
  const user = useSelector((state: RootState) => state.user.user);
  const modules = useSelector((state: RootState) => state.user.modules);
  const permissions = useSelector((state: RootState) => state.user.user.permissions);
  const isSystemUser = user.role === "System";
  const isUserPermissionsEnabled = modules.isUserPermissionsEnabled;
  const isFocused = useIsFocused();
  const [approveJob, approveJobResult] = useApproveJobMutation();
  const [deleteJob, deleteJobResult] = useDeleteJobMutation();
  const { data: companies } = useGetAllCompaniesQuery(null);
  const { data: locations } = useGetAllLocationsQuery(null);
  const [sortedColumn, setSortedColumn] = React.useState<string>("createdOn");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(false);
  const [loading, setLoading] = useState<any>(false);

  // Filters 
  const [companyId, setCompanyId] = useState("");
  const [locationId, setLocationId] = useState("");
  const [jobNumber, setJobNumber] = useState("");

  const [page, setPage] = React.useState<number>(0);
  const [trigger, jobsResult] = jobApi.useLazyGetJobsQuery();
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);

  React.useEffect(() => {
    if (isFocused) {
      loadTable();
    }
  }, [page, isFocused, sortedColumn, sortedColumnAscending, numberOfItemsPerPage]);

  const loadTable = () => {
    const pageNumber = numberOfItemsPerPage > MAX_ITEMS_BEFORE_ALL ? FIRST_PAGE : page;
    trigger(new PagedTableDto(pageNumber, {
      companyId: companyId, 
      locationId: locationId,
      jobNumber: jobNumber, 
      statusId: 4,
      pageSize: numberOfItemsPerPage
    }, sortedColumn, sortedColumnAscending)).then(() => {
      setLoading(false);
    });
  }

  const resetFilters = () => {
    setCompanyId("");
    setLocationId("");
    setJobNumber("");
  }

  function applyFilters() {
    setPage((curr) => {
      if (curr == 0) loadTable();
      return 0;
    })
  }

  const markJobApproved = async (id) => {
    if (confirm("Are you sure you want to approve this job?")) {
      var result = await approveJob(id) as any;
      if (result.error) {
        alert("Error approving job. Please try again.")
        return;
      }

      loadTable();
    }
  }

  const markJobDeleted = async (id) => {
    if (confirm("Are you sure you want to delete this job?")) {
      var result = await deleteJob(id) as any;
      if (result.error) {
        alert("Error deleting job. Please try again.")
        return;
      }

      loadTable();
    }
  }


  if (jobsResult.isLoading) {
    return <LoadingIndicator/>
  }


  return (
    <ScrollView style={styles.container}>
        <Spinner
          visible={loading}
          textContent={'Loading...'}
          textStyle={GlobalStyle.spinnerTextStyle}
        />
        <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}> 
            <Text style={GlobalStyle.column__header}>Company</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={companyId}
              onValueChange={(itemValue, itemIndex) => { setCompanyId(itemValue); }}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="All Companies" value="" />

              { companies != null && companies.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Location</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={locationId}
              onValueChange={(itemValue, itemIndex) => setLocationId(itemValue)}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="All Locations" value="" />

              { locations != null && companyId != "" && locations.filter(l => l.companyId == companyId).map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Job #</Text>
            <TextInput 
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              placeholder="Job #"
              value={jobNumber}
              onChangeText={setJobNumber}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={applyFilters}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

      <DataTable style={GlobalStyle.table}>
      <DataTableHeader
          sortedColumn={sortedColumn}
          sortedColumnAscending={sortedColumnAscending}
          onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
          headers={[
            { text: 'Company', sortedName: "companyName", visible: true },
            { text: 'Location', sortedName: "locationName", visible: true },
            { text: 'Job Number', sortedName: "jobNumber", visible: true },
            { text: 'Created', sortedName: "createdOn", visible: true },
            { text: 'Job Type', visible: true },
            { text: 'Options', visible: true },
          ]}
        />
        { jobsResult?.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>{element.companyName}</DataTable.Cell>
              <DataTable.Cell>{element.locationName}</DataTable.Cell>
              <DataTable.Cell>{element.jobNumber}</DataTable.Cell>
              <DataTable.Cell>{element.createdOnFormatted}</DataTable.Cell>
              <DataTable.Cell>{element.jobTypeFormatted}</DataTable.Cell>
              <DataTable.Cell>
                <View style={{ flexDirection: 'row'}}>
                  {(!isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Administration, JobManagementPermissions.ApproveJob))) && <TouchableOpacity onPress={() => markJobApproved(element.id)} style={{ marginRight: 20 }}>
                    <Ionicons name="checkmark-done-circle-outline" size={24} color="green" />
                  </TouchableOpacity>}

                  <TouchableOpacity onPress={() => navigation.navigate("job-approval-assets", { jobId: element.id })}>
                    <MaterialCommunityIcons name="view-list-outline" size={24} color="black" />
                  </TouchableOpacity>

                  {!element.isDeleted &&
                    <TouchableOpacity onPress={() => markJobDeleted(element.id)} style={{ marginLeft: 20 }}>
                      <AntDesign name="delete" size={24} color="red" />
                    </TouchableOpacity>
                  }
                </View>
              </DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>

      <PaginationComponent
        numberOfItemsPerPage={numberOfItemsPerPage}
        setNumberOfItemsPerPage={setNumberOfItemsPerPage}
        page={page}
        setPage={setPage}
        fetchResult={jobsResult}
        loading={loading}
        setLoading={setLoading}
      />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
