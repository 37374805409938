import * as React from 'react';
import { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Alert, Dimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { TitleBar } from '../../components/TitleBar';
import { useIsFocused } from '@react-navigation/native';
import { setInspectionData } from '../../../../store/slices/rote-inspection-slice';
import { colours } from '../../../../config';
import { EquipmentDatabase } from '@store/services/database/equipment-database';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { CategoryDatabase } from '@store/services/database/category-database';
import { PurposeDatabase } from '@store/services/database/purpose-database';
import { JobDatabase } from '@store/services/database/job-database';
import { ConfirmScrapModal } from '@app/components/modals/ConfirmScrapModal';
import { clearPssrData, setPssrData } from '@store/slices/pssr-slice';

const isMobileScreen = Dimensions.get("screen").width < 480;

export default function EquipmentDetailsScreen({ navigation, route }: { navigation: any, route: any }) {

  const { pssrInspectionId } = route?.params || {};

  const isFocused = useIsFocused();
  const dispatch = useDispatch();
  const job = useSelector((state: RootState) => state.job);
  const [category, setCategory] = useState(null);
  const [purposes, setPurposes] = useState(null);
  const [equipment, setEquipment] = useState(null);
  const [scrapModalVisible, setScrapModalVisible] = useState(false);

  const loadEquipmentDetails = async () => {
    const equipmentDatabase = new EquipmentDatabase();
    const equip = await equipmentDatabase.getById(job.equipmentId);
    setEquipment(equip);

    const purposeDatabase = new PurposeDatabase();
    const categoryDatabase = new CategoryDatabase();
    const jobDatabase = new JobDatabase();

    const dbJob = await jobDatabase.getJobById(job.jobId);
    const colourId = dbJob.colourId;

    const purposes = await purposeDatabase.getAll();
    setPurposes(purposes);

    const category = await categoryDatabase.getById(equip?.categoryId);
    setCategory(category);

    const purposeId = purposes?.find(p => p.interval === category?.inspectionInterval)?.id;

    dispatch(setInspectionData({
      nextTestDate: equip?.nextTestDate,
      purposeId: purposeId,
      colourId: colourId
    }));
  }

  const viewFiles = () => {
    navigation.navigate("EquipmentViewFiles");
  }

  const edit = () => {
    navigation.navigate("EquipmentManage", { equipmentId: job.equipmentId })
  }

  const scrapAsset = (reason) => {
    const equipmentDatabase = new EquipmentDatabase();
    equipmentDatabase.setScrapped(job.equipmentId, reason).then(() => {
      navigation.goBack();
    });
  }

  const inspectAsset = async () => {
    switch (job.jobType) {
      case 0:
        navigation.navigate("RoteInspect", { categoryChecklist: equipment.checklist });
        return;
      case 1:
        navigation.navigate("ServiceInspect", { categoryId: category.id });
        return;
      case 2:
        navigation.navigate("BreakdownInspect");
        return;
      case 3:
        navigation.navigate("ProofLoadInspect");
        return;
      case 4:
        navigation.navigate("InstallationInspect", { categoryId: category.id });
        return;
      case 5:
        if (pssrInspectionId) {
          dispatch(setPssrData({
            id: pssrInspectionId
          }))
        } else {
          dispatch(clearPssrData());
        }
        navigation.navigate("PssrInspect", { equipment: equipment });
        return;
    }
  }


  useEffect(() => {
    loadEquipmentDetails();

    return () => {
      dispatch(clearPssrData());
    };
  }, [isFocused])

  if (equipment == null) {
    return <LoadingIndicator />
  }


  return (
    <>
      <ConfirmScrapModal
        modalVisible={scrapModalVisible}
        setModalVisible={setScrapModalVisible}
        onConfirm={scrapAsset}
        equipment={[equipment.description]} />
      <View style={styles.container}>
        <TitleBar navigation={navigation} title="Equipment Details" showBackButton={true} showSearchButton={false} onSynced={null} onSearch={null} />

        {isMobileScreen ? (
          <View style={styles.form}>
            <View style={styles.form__row}>
              <Text style={styles.column__label}>Description </Text>
              <Text style={styles.column__value}>{equipment.description}</Text>
            </View>

            <View style={styles.form__row}>
              <Text style={styles.column__label}>Serial # </Text>
              <Text style={styles.column__value}>{equipment.serialNumber}</Text>
            </View>

            <View style={styles.form__row}>
              <Text style={styles.column__label}>Asset # </Text>
              <Text style={styles.column__value}>{equipment.assetNumber}</Text>
            </View>

            <View style={styles.form__row}>
              <Text style={styles.column__label}>SWL </Text>
              <Text style={styles.column__value}>{equipment.swl}</Text>
            </View>

            <View style={styles.form__row}>
              <Text style={styles.column__label}>Manufacturer </Text>
              <Text style={styles.column__value}>{equipment.manufacturerName}</Text>
            </View>

            <View style={styles.form__row}>
              <Text style={styles.column__label}>Category </Text>
              <Text style={styles.column__value}>{equipment.categoryName}</Text>
            </View>

            <View style={styles.form__row}>
              <Text style={styles.column__label}>RFID/QR </Text>
              <Text style={styles.column__value}>{equipment.rfid}</Text>
            </View>

            <View style={styles.form__row}>
              <Text style={styles.column__label}>Other Details </Text>
              <Text style={styles.column__value}>{equipment.otherDetails}</Text>
            </View>
          </View>
        ) : (
          <View style={styles.form}>
            <View style={styles.form__row}>
              <View style={styles.column}>
                <Text style={styles.column__label}>Description</Text>
                <Text style={styles.column__value}>{equipment.description}</Text>
              </View>

              <View style={styles.column}>
                <Text style={styles.column__label}>Serial #</Text>
                <Text style={styles.column__value}>{equipment.serialNumber}</Text>
              </View>
            </View>

            <View style={styles.form__row}>
              <View style={styles.column}>
                <Text style={styles.column__label}>Asset #</Text>
                <Text style={styles.column__value}>{equipment.assetNumber}</Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.column__label}>SWL</Text>
                <Text style={styles.column__value}>{equipment.swl}</Text>
              </View>
            </View>

            <View style={styles.form__row}>
              <View style={styles.column}>
                <Text style={styles.column__label}>Manufacturer</Text>
                <Text style={styles.column__value}>{equipment.manufacturerName}</Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.column__label}>Category</Text>
                <Text style={styles.column__value}>{equipment.categoryName}</Text>
              </View>
            </View>

            <View style={styles.form__row}>
              <View style={styles.column}>
                <Text style={styles.column__label}>RFID</Text>
                <Text style={styles.column__value}>{equipment.rfid}</Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.column__label}>Other Details</Text>
                <Text style={styles.column__value}>{equipment.otherDetails}</Text>
              </View>
            </View>
          </View>
        )}
        <View style={[styles.form__row, styles.form__row__margin]}>
          {/* <TouchableOpacity style={[styles.column, styles.button]} onPress={() => viewFiles()}>
            <Text style={styles.button__text}>VIEW FILES</Text>
          </TouchableOpacity> */}

          <TouchableOpacity style={[styles.column, styles.button]} onPress={() => edit()}>
            <Text style={styles.button__text}>EDIT</Text>
          </TouchableOpacity>

          <TouchableOpacity style={[styles.column, styles.button]} onPress={() => setScrapModalVisible(true)}>
            <Text style={styles.button__text}>SCRAP</Text>
          </TouchableOpacity>

          <TouchableOpacity style={[styles.column, styles.button]} onPress={() => inspectAsset()}>
            <Text style={styles.button__text}>INSPECT</Text>
          </TouchableOpacity>
        </View>

        <View style={[styles.form__row, styles.form__row__margin]}>
          <TouchableOpacity style={[styles.button, styles.button__full__width]} onPress={() => navigation.navigate("EquipmentViewInspections", { equipmentId: job.equipmentId })}>
              <Text style={styles.button__text}>VIEW INSPECTIONS</Text>
            </TouchableOpacity>
          </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FAFAFA',
    padding: isMobileScreen ? 20 : 40,
    paddingTop: 60,
  },

  form: {
    marginTop: 10,
  },

  form__row: {
    flexDirection: 'row',
    alignItems: "center"
  },

  form__row__margin: {
    marginTop: 20,
  },

  column: {
    flex: 1,
    margin: 5,
    flexDirection: 'column'
  },

  column__label: {
    flex: isMobileScreen ? 1 : 0,
    fontWeight: 'bold',
    fontSize: 18,
    alignItems: isMobileScreen ? 'stretch' : "center",
  },

  column__value: {
    flex: isMobileScreen ? 1 : 0,
    padding: 10,
    backgroundColor: '#ebe8e8',
    borderRadius: 5,
    marginTop: 5,
    fontSize: isMobileScreen ? 16 : 24,
    borderWidth: 1,
    borderColor: '#cecece'
  },

  button: {
    backgroundColor: colours.primary,
    padding: 15,
  },

  
  button__text: {
    textAlign: 'center',
    color: '#FFF',
    fontFamily: 'OpenSans-Bold',
    //fontSize: 32
  },

    button__full__width: {
      flex: 1,
    }

});
