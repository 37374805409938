import {equipmentApi} from "@store/services/api/equipment-api";
import {INSTALLATION_JOB_TYPE, PRESSURE_JOB_TYPE} from "../../constants/Constants";
import React, {memo, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {PagedTableDto} from "@store/services/api/base-api";
import {ActivityIndicator, Dimensions, Platform, ScrollView, StyleSheet, Text, View} from "react-native";
import GlobalStyle, {PRIMARY_COLOUR} from "../../constants/GlobalStyle";
import Checkbox from "expo-checkbox";
import {DataTable} from "react-native-paper";
import {DataTableHeader} from "@web/components/table/DatatableHeader";
import PaginationComponent from "@web/components/Pagination";
import {EquipmentDatabase} from "@store/services/database/equipment-database";

export interface JobManagementEquipmentSelectionContext {
  showEquipmentSelection: boolean;
  locationId: string;
  styles: any;
  equipmentSelectionText: string;
  handleOnIncludeEquipmentCheck: (value: boolean) => void;
  includeEquipmentChecked: boolean;
  selectedEquipment: any[];
  toggleEquipment: (equipmentId: string) => void;
}

const JobManagementEquipmentSelectionContext = React.createContext<JobManagementEquipmentSelectionContext>({} as JobManagementEquipmentSelectionContext);

export const JobManageEquipmentSelection = memo(({form, styles, locationId, jobType}: any) => {
  const { setValue } = form;
  const isMobileDevice = Platform.OS !== "web";
  const [includeEquipmentChecked, setIncludeEquipmentCheck] = useState(false);
  const [showEquipmentSelection, setShowEquipmentSelection] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState([]);

  const isInstallationJob = parseInt(jobType) === INSTALLATION_JOB_TYPE;

  const equipmentSelectionText = isInstallationJob ? 'Include Equipment: ' : 'Include ALL Equipment: '

  const handleOnIncludeEquipmentCheck = (value: boolean) => {
    const includeAll = isInstallationJob ? false : value;
    setIncludeEquipmentCheck(value);
    setValue("includeAllEquipment", includeAll);

    handleClearSelectedEquipment();
  }

  const handleClearSelectedEquipment = useCallback(() => {
    setSelectedEquipment([]);
    setValue("equipmentIds", []);
  }, [setSelectedEquipment, setValue]);

  useMemo(() => {
    setShowEquipmentSelection(false);
    setIncludeEquipmentCheck(!isInstallationJob);
    setValue("includeAllEquipment", !isInstallationJob);
    handleClearSelectedEquipment();
  }, [jobType,
    setShowEquipmentSelection,
    setIncludeEquipmentCheck,
    setValue,
    handleClearSelectedEquipment,
    isInstallationJob, locationId]);

  useEffect(() => {
    const show = isInstallationJob ? includeEquipmentChecked : !includeEquipmentChecked;
    setShowEquipmentSelection(show);
  }, [includeEquipmentChecked]);

  const toggleEquipment = useCallback((id) => {
    setSelectedEquipment((prevSelectedEquipment) => {
      const newArray = [...prevSelectedEquipment];
      const index = newArray.indexOf(id);

      if (index !== -1) {
        newArray.splice(index, 1);
      } else {
        newArray.push(id);
      }

      setValue("equipmentIds", newArray);
      return newArray;
    });
  }, [setValue]);

  return (
    <JobManagementEquipmentSelectionContext.Provider
      value={{
        toggleEquipment,
        showEquipmentSelection,
        includeEquipmentChecked,
        handleOnIncludeEquipmentCheck,
        locationId,
        selectedEquipment,
        styles,
        equipmentSelectionText
      }}>

      <View style={localStyles.container}>
        <View style={[styles.form__rows, {padding: 6, marginBottom: 12}]}>
          <Text style={styles.form__column__text}>{equipmentSelectionText}</Text>
          <Checkbox
            value={includeEquipmentChecked}
            onValueChange={handleOnIncludeEquipmentCheck}
            style={{ marginLeft: 8, padding: isMobileDevice ? 12 : 4 }}
          />
        </View>
        {showEquipmentSelection &&
          <EquipmentSelection
            jobType={jobType}
            locationId={locationId}
            isMobileDevice={isMobileDevice} />
        }
      </View>
    </JobManagementEquipmentSelectionContext.Provider>
  )
});

const EquipmentSelection = memo(({locationId, isMobileDevice, jobType}: any) =>
  isMobileDevice
    ? <MobileEquipmentSelection locationId={locationId} jobType={jobType} />
    : <DesktopEquipmentSelection locationId={locationId} jobType={jobType} />
);

const MobileEquipmentSelection = memo(({locationId, jobType}: any) => {
  const [page, setPage] = useState(0);
  const [sortedColumn, setSortedColumn] = React.useState<string>("serialNumber");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [equipmentResult, setEquipmentResult] = useState<any>();

  useEffect(() => {
    if (!locationId) return;
    setTableLoading(true);
    getEquipmentPage(new PagedTableDto(page, {
      pageSize: 10,
      locationId: locationId,
      pssrOnly: parseInt(jobType) === PRESSURE_JOB_TYPE
    }, sortedColumn, sortedColumnAscending))
      .then(() => {
        setTableLoading(false)
      });
  }, [locationId, page, sortedColumn, sortedColumnAscending]);

  async function getEquipmentPage(dto: PagedTableDto) {
    const equipmentDb = new EquipmentDatabase();
    const equipment = await equipmentDb.getPageByLocationId(dto);

    setEquipmentResult(equipment)
  }

  return (
    <EquipmentSelectionTable
      equipmentResult={equipmentResult}
      page={page}
      setPage={setPage}
      tableLoading={tableLoading}
      itemsPerPage={10}
      setItemsPerPage={null}
      setSortedColumn={setSortedColumn}
      setSortedColumnAscending={setSortedColumnAscending} />
  )
});

const DesktopEquipmentSelection = memo(({locationId, jobType}: any) => {
  const [triggerEquipment, equipmentResult] = equipmentApi.useLazyGetEquipmentByCompanyIdQuery();
  const [page, setPage] = useState(0);
  const [sortedColumn, setSortedColumn] = React.useState<string>("serialNumber");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);
  const [headerFilters, setHeaderFilters] = useState({});

  useEffect(() => {
    if (!(locationId)) return;

    triggerEquipment(new PagedTableDto(page, {
      pageSize: numberOfItemsPerPage,
      locationId: locationId,
      pssrOnly: parseInt(jobType) === PRESSURE_JOB_TYPE,
      ...headerFilters,
    }, sortedColumn, sortedColumnAscending));
  }, [locationId, page, sortedColumn, sortedColumnAscending, numberOfItemsPerPage, headerFilters]);

  function onSetFilters(filters: any) {
    setHeaderFilters(filters);
    setPage(0);
  }

  return (
    <EquipmentSelectionTable
      tableLoading={equipmentResult.isFetching}
      equipmentResult={equipmentResult}
      page={page}
      setPage={setPage}
      itemsPerPage={numberOfItemsPerPage}
      setItemsPerPage={setNumberOfItemsPerPage}
      setSortedColumn={setSortedColumn}
      setSortedColumnAscending={setSortedColumnAscending}
      setFilters={onSetFilters}
      showFilters={true} />
  )
});

const EquipmentSelectionTable = memo((props: any) => {
  const {
    styles,
    selectedEquipment,
    toggleEquipment,
  } = useContext(JobManagementEquipmentSelectionContext);

  const {
    page,
    setPage,
    itemsPerPage,
    setItemsPerPage,
    setSortedColumn,
    setSortedColumnAscending,
    equipmentResult,
    sortedColumn,
    sortedColumnAscending,
    tableLoading,
    setFilters,
    showFilters
  } = props;

  const tableMaxHeight = Dimensions.get("screen").height * 0.2;
  const showAdditionalFields = Dimensions.get("screen").width > 480;

  const equipmentList = equipmentResult?.data?.list ?? [];

  const toggleItem = useCallback((id) => {
    toggleEquipment(id);
  }, [toggleEquipment]);

  return (
    <View style={localStyles.table}>
      <DataTable style={GlobalStyle.table}>
        <DataTableHeader
          onFiltersChanged={setFilters}
          showColumnFilters={showFilters}
          sortedColumn={sortedColumn}
          sortedColumnAscending={sortedColumnAscending}
          onSorted={(column, ascending) => {
            setSortedColumn(column);
            setSortedColumnAscending(ascending);
          }}
          headers={[
            {text: 'Select', visible: true},
            {text: 'Serial Number', sortedName: "serialNumber", visible: true},
            {text: 'Description', sortedName: "description", visible: true},
            {text: 'Asset Number', sortedName: "assetNumber", visible: showAdditionalFields},
            {text: 'Category', sortedName: "categoryName", visible: showAdditionalFields},
          ]}
        />
        <ScrollView style={{maxHeight: tableMaxHeight}}>
          {tableLoading ? (
            <View style={[{minHeight: tableMaxHeight}, localStyles.fetchingContainer]}>
              <ActivityIndicator style={{padding: 16}} size="large" color={PRIMARY_COLOUR}/>
            </View>
          ) : equipmentList.map((item) => (
            <DataRow
              showAdditionalFields={showAdditionalFields}
              key={item.id}
              styles={styles}
              item={item}
              initialState={selectedEquipment.includes(item.id)}
              toggleItem={toggleItem} />
          ))}
        </ScrollView>
      </DataTable>
      <PaginationComponent
        numberOfItemsPerPage={itemsPerPage}
        setNumberOfItemsPerPage={setItemsPerPage}
        page={page}
        setPage={setPage}
        fetchResult={equipmentResult}
        setLoading={() => null}
      />
    </View>
  )
});

const DataRow = memo(({ item, toggleItem, styles, initialState, showAdditionalFields }: any) => {
  const [selected, setSelected] = useState(initialState);

  const handlePress = useCallback(() => {
    setSelected((curr) => !curr);
    toggleItem(item.id);
  }, [item.id, toggleItem]);

  return (
    <DataTable.Row
      key={item.id}
      style={selected ? styles.selectedRow : null}
      onPress={handlePress}>
      <DataTable.Cell>
        <Checkbox
          style={[GlobalStyle.form__column__input]}
          value={selected}
        />
      </DataTable.Cell>
      <DataTable.Cell>{item.serialNumber}</DataTable.Cell>
      <DataTable.Cell>{item.description}</DataTable.Cell>
      {showAdditionalFields &&
        <>
          <DataTable.Cell>{item.assetNumber}</DataTable.Cell>
          <DataTable.Cell>{item.categoryName}</DataTable.Cell>
        </>
      }
    </DataTable.Row>
  );
});

const localStyles = StyleSheet.create({
  fetchingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  table: {
    borderWidth: 1,
    borderColor: '#e3e3e3'
  },
  container: {
    paddingVertical: 12
  }
})
