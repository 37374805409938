import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export interface BasketSlice {
  // Inspection Data
  inspections: Array<any> | [],
}

const initialState: BasketState = {
  inspections: [],
}

export const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    addBasketInspection: (state, action: PayloadAction<any>) => {
      if (state.inspections.filter(i => i.id == action.payload.id).length > 0)
        return;

      state.inspections = [...state.inspections, action.payload]
    },

    addMultipleBasketInspections: (state, action: PayloadAction<any[]>) => {
      const newInspections = action.payload.filter(newEquipment =>
        !state.inspections.some(inspection => inspection.id === newEquipment.id));
      state.inspections.push(...newInspections);
    },

    removeBasketInspection: (state, action: PayloadAction<any>) => {
      state.inspections = state.inspections.filter(i => i.id != action.payload);
    },

    removeMultipleBasketInspections: (state, action: PayloadAction<any>) => {
      state.inspections = state.inspections.filter(existingInspection =>
        !action.payload.some(inspection => inspection.id === existingInspection.id));
    },

    clearBasket: (state, action: PayloadAction) => {
      state.inspections = [];
    },
  }
})

export const {
  addBasketInspection, removeBasketInspection, clearBasket, addMultipleBasketInspections, removeMultipleBasketInspections
} = basketSlice.actions;

export default basketSlice.reducer;