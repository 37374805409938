import * as React from 'react';
import { View, Text, StyleSheet, ImageBackground, TextInput, TouchableOpacity, ActivityIndicator, Platform, Alert } from 'react-native';
import { useForm, Controller } from "react-hook-form";
import { useState } from 'react';
import GlobalStyle from '../constants/GlobalStyle';
import config, { colours } from '../config.js';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../store/slices/user-slice';
import { UserService } from '../store/services/user-service';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Updates from 'expo-updates';
import * as Sharing from 'expo-sharing';
import * as FileSystem from 'expo-file-system';
import { Database } from '@store/services/database/database';
import Constants from "expo-constants";
import { RootState } from '@store/index';

// @ts-ignore
const VERSION = Constants.expoConfig.versionString;

export default function LoginScreen({ navigation }: { navigation: any }) {
  const dispatch = useDispatch();
  const userService = new UserService();
  const user = useSelector((state: RootState) => state.user.user);

  const ACTIVATION_CODE = 'ELIT084507109850';
  const [showActivation, setShowActivation] = useState(false);
  const [activationCode, setActivationCode] = useState("");

  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [loginResult, setLoginResult] = useState(null);
  const [loginError, setLoginError] = useState("");

  const { control, handleSubmit, getValues, formState: { errors } } = useForm({
    defaultValues: {
      email: '',
      password: '',
    }
  });

  React.useEffect(() => {
    if (!user || Platform.OS != 'web') return;
    navigation.navigate("web");
  }, [user])

  const checkForUpdateAsync = async () => {
    try {
      const update = await Updates.checkForUpdateAsync();
      if (update.isAvailable) {
        setUpdating(true);
        await AsyncStorage.setItem("appUpdated", "true");
        await Updates.fetchUpdateAsync();
        await Updates.reloadAsync();
        setUpdating(false);
      }
    } catch (e) {
    }
  }

  React.useEffect(() => {
    const activation = async () => {
      var activationCode = await AsyncStorage.getItem("activationCode");
      if (activationCode != ACTIVATION_CODE) {
        setShowActivation(true);
        return;
      }

      setShowActivation(false);

      getLoggedInUser();
    }


    const getLoggedInUser = async () => {
      if (Platform.OS != 'web')
        await checkForUpdateAsync();

      var userJson = await AsyncStorage.getItem("user");
      if (userJson != null && userJson != "") {
        var user = JSON.parse(userJson);
        dispatch(setUser(user));

        await navigateToDashboard(user);
      }
    }

    // See if we have a local user stored
    if (Platform.OS == "android" || Platform.OS == "ios") {
      //activation();
      getLoggedInUser();
    }
  }, []);

  const activate = async () => {
    if (activationCode.toLowerCase() != ACTIVATION_CODE.toLowerCase()) {
      Alert.alert("Activation", "Invalid activation code");
      return;
    }

    await AsyncStorage.setItem("activationCode", ACTIVATION_CODE);
    setShowActivation(false);

    Alert.alert("Activation", "Successfully activated");
  }

  const onSubmit = async (data) => {
    const email = data.email;
    const password = data.password;

    setLoading(true);
    var loginResult = await userService.login(email, password);

    setLoading(false);
    setLoginResult(loginResult);
    if (loginResult.error) {
      return;
    }

    var user = loginResult.data;

    if (Platform.OS !== 'web' && user.role !== 'Engineer') {
      setLoginError("You're trying to login as a web user");
      return;
    }

    setLoginError("");

    dispatch(setUser(user));

    await navigateToDashboard(user);
  }

  const navigateToDashboard = async (user) => {
    if (Platform.OS == "android" || Platform.OS == "ios") {
      // Store the user in local 
      await AsyncStorage.setItem("user", JSON.stringify(user));
      navigation.navigate('app');
    } else {
      navigation.navigate('web');
    }
  }

  const exportDb = async () => {
    var path = `${FileSystem.documentDirectory}/SQLite/database.db`;
    await Sharing.shareAsync(path, { dialogTitle: "Export DB" });
  }

  const deleteDb = async () => {
    Database.deleteDb();
  }

  const resetUpdateTimes = async () => {
    Database.resetUpdateTimes();
  }

  const downloadDb = async () => {
    var path = `${FileSystem.documentDirectory}/SQLite/database.db`;
    Alert.alert('Download DB', 'Are you sure you want to download this DB? It will overwrite your existing DB.', [
      { text: 'No', style: 'cancel', },
      {
        text: 'Yes', onPress: async () => {
          setUpdating(true);

          const currentDate = new Date();
          const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;

          const downloadResumable = FileSystem.createDownloadResumable(
            `https://checkedokmultiapi.corerfid.net/database/database.db?dt=${formattedDate}`, path, {}, (downloadProgress) => {
              console.log(downloadProgress);
            });

          try {
            const { uri } = await downloadResumable.downloadAsync();
            console.log('Finished downloading to ', uri);
            await Updates.reloadAsync();
          } catch (e) {
            console.error(e);
          }

          setUpdating(false);
        }
      }
    ]);
    return;
  }

  const dbPrompt = async () => {
    if (Platform.OS != "web") {
      Alert.alert("DB", "Choose option", [
        { text: 'Export DB', onPress: () => { exportDb(); } },
        { text: 'Clear DB', onPress: () => { deleteDb(); } },
        { text: 'Cancel' },
      ],
        {
          cancelable: true,
        })
    }
  }

  if (updating) {
    return (
      <View
        style={{ flex: 1, padding: 20, justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator size="large" color={colours.primary} />

        <Text style={{ fontWeight: 'bold', marginTop: 10, fontSize: 20 }}>Updating App. Please wait...</Text>
      </View>
    );
  }

  return (
    <ImageBackground source={require("../assets/images/login.png")} resizeMode="cover" style={styles.container}>
      {/* <View style={styles.container}> */}
      {showActivation &&
        <View style={styles.login}>
          <View style={styles.login__input}>
            <TextInput
              style={styles.login__input__text}
              onChangeText={(val) => setActivationCode(val)}
              autoCorrect={false}
              value={activationCode}
              placeholder="Activation Code"
            />
          </View>

          <TouchableOpacity style={styles.login__button} onPress={() => activate()} >
            <Text style={styles.login__button__text}>ACTIVATE</Text>
          </TouchableOpacity>
        </View>
      }
      {!showActivation &&
        <View style={styles.login}>
          {loading && <View style={styles.loader}><ActivityIndicator size="large" color={config.colours.primary} /></View>}

          <View style={styles.login__input}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={styles.login__input__text}
                  onBlur={onBlur}
                  autoCorrect={false}
                  onChangeText={onChange}
                  value={value}
                  placeholder="Email"
                />
              )}
              name="email"
            />
            {errors.email && <Text style={GlobalStyle.required}>Email is required.</Text>}
          </View>

          <View style={styles.login__input}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={styles.login__input__text}
                  onBlur={onBlur}
                  autoCorrect={false}
                  onChangeText={onChange}
                  value={value}
                  onSubmitEditing={handleSubmit(onSubmit)}
                  secureTextEntry={true}
                  placeholder="Password"
                />
              )}
              name="password"
            />
            {errors.password && <Text style={GlobalStyle.required}>Password is required.</Text>}
          </View>

          {loginResult?.error && <Text style={GlobalStyle.required}>Invalid credentials. Please try again.</Text>}

          <TouchableOpacity style={styles.login__button} onPress={handleSubmit(onSubmit)} >
            <Text style={styles.login__button__text}>LOGIN</Text>
          </TouchableOpacity>
          {loginError && <Text style={GlobalStyle.required}>{loginError}</Text>}
          <TouchableOpacity onPress={() => dbPrompt()}>
            <Text style={{ marginTop: 10, textAlign: 'center' }}>V: {VERSION}</Text>
          </TouchableOpacity>

          {/* {Platform.OS == 'ios' || Platform.OS == 'android' && (
            <TouchableOpacity style={styles.sync__button} onPress={sync} >
              <Text style={styles.sync__button__text}>SYNC</Text>
            </TouchableOpacity>
          )} */}
        </View>
      }
    </ImageBackground>
  );
}



const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },

  login: {
    backgroundColor: '#FFF',
    padding: 20,
    width: Platform.OS == 'web' ? 400 : '80%',
    borderRadius: 5,
    position: 'relative'
  },

  loader: {
    position: 'absolute',
    left: 0, top: 0, right: 0, bottom: 0,
    backgroundColor: '#FFF',
    zIndex: 999,
    alignItems: 'center',
    justifyContent: 'center'
  },

  login__input: {
    marginBottom: 10,
  },

  login__input__text: {
    borderWidth: 1,
    borderColor: '#CECECE',
    padding: 7,
    borderRadius: 5,
    fontFamily: 'OpenSans-Regular'
  },

  login__button: {
    backgroundColor: config.colours.primary,
    marginTop: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: config.colours.primary,
  },

  login__button__text: {
    padding: 5,
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'OpenSans-ExtraBold'
  },

  sync__button: {
    backgroundColor: "#FFF",
    marginTop: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: config.colours.primary,
  },

  sync__button__text: {
    padding: 5,
    color: config.colours.primary,
    textAlign: 'center',
    fontFamily: 'OpenSans-ExtraBold'
  }

});
