import * as React from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert, Image } from 'react-native';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { EvilIcons } from '@expo/vector-icons';
import { Controller, useForm } from 'react-hook-form';
import { LoadingIndicator } from '../../../../components/navigation/controls/LoadingIndicator';
import { Picker } from '@react-native-picker/picker';
import { customerApi, useCreateCustomerMutation, useUpdateCustomerMutation } from '@store/services/api/customer-api';

import * as DocumentPicker from 'expo-document-picker';

export default function CustomerManageScreen({ navigation, route }: { navigation: any, route: any }) {
  const [createCustomer, createCustomerResult] = useCreateCustomerMutation();
  const [updateCustomer, updateCustomerResult] = useUpdateCustomerMutation();

  const [trigger, customerResult] = customerApi.useLazyGetCustomerQuery();

  const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      name: "",
      url: "",
      address: "",
      vatNumber: "",
      emailAddress: "",
      brandingImageBase64: "",
      primaryColour: "",
      accentColour: "",
      noOfLicenses: null,
      noOfDepots: 0,
      smtpHost: "",
      smtpUsername: "",
      smtpPassword: "",
      smtpPort: null,
      leeaType: null
    }
  });

  const brandingImageBase64 = watch("brandingImageBase64");

  React.useEffect(() => {
    if (route.params?.customerId != null) {
      trigger(route.params?.customerId);
    }
  }, [])


  React.useEffect(() => {
    if (customerResult.isSuccess) {
      var data = customerResult.data;
      setValue("id", data.id);
      setValue("name", data.name);
      setValue("url", data.url);
      setValue("address", data.address);
      setValue("vatNumber", data.vatNumber);
      setValue("emailAddress", data.emailAddress);
      setValue("brandingImageBase64", data.brandingImageBase64);
      setValue("primaryColour", data.primaryColour);
      setValue("accentColour", data.accentColour);
      setValue("noOfLicenses", data.noOfLicenses);
      setValue("noOfDepots", data.noOfDepots);
      setValue("smtpHost", data.smtpHost);
      setValue("smtpUsername", data.smtpUsername);
      setValue("smtpPassword", data.smtpPassword);
      setValue("smtpPort", data.smtpPort);
      setValue("leeaType", data.leeaType);
    }
  }, [customerResult]);

  const onSubmit = async (data) => {
    var result = null;

    if (data.id != null) {
      result = await updateCustomer(data) as any;
    } else {
      result = await createCustomer(data) as any;
    }

    if (result.error) {
      return;
    }

    navigation.goBack();
  };

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const loadFile = async () => {
    var result = await DocumentPicker.getDocumentAsync({
      type: ".png"
    }) as any;

    if (result.assets.length == 0) return;
    const file = result.assets[0].file;

    var base64 = (await toBase64(file)) as string;

    setValue("brandingImageBase64", base64);
  }

  if (createCustomerResult.isLoading || updateCustomerResult.isLoading) {
    return <LoadingIndicator />
  }

  return (
    <View style={styles.container}>
      <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => navigation.goBack()}>
          <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>{getValues("id") == null ? "CREATE CUSTOMER" : "UPDATE CUSTOMER"}</Text>
      </View>

      <View style={GlobalStyle.form}>
        {/* ##### ROW ##### */}
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="name"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Customer Name (*)</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.name && <Text style={GlobalStyle.form__input__validation}>Name is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="address"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Address (*)</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.address && <Text style={GlobalStyle.form__input__validation}>Address is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="url"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>URL (*)</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.url && <Text style={GlobalStyle.form__input__validation}>URL is required.</Text>}
          </View>
        </View>

        {/* ##### ROW ##### */}
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="noOfLicenses"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}># of Licenses (*)</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.noOfLicenses && <Text style={GlobalStyle.form__input__validation}># Of Licenses is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="noOfDepots"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}># of Depots</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value.toString()}>
                  </TextInput>
                </View>
              )}
            />
            </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="emailAddress"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Email Address</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>
        </View>

        {/* ##### ROW ##### */}
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="primaryColour"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Primary Colour</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="accentColour"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Accent Colour</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="smtpHost"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>SMTP Host</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>
        </View>

        {/* ##### ROW ##### */}
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="smtpUsername"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>SMTP Username</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="smtpPassword"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>SMTP Password</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>


          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="smtpPort"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>SMTP Password</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>
        </View>

        <View style={GlobalStyle.form__row}>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="vatNumber"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>VAT Number</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="leeaType"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>LEEA Type (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                    itemStyle={{ height: 50 }}>

                    <Picker.Item key={0} label="Please Select" value="" />
                    <Picker.Item key={1} label={"None"} value={0} />
                    <Picker.Item key={2} label={"Full"} value={1} />
                    <Picker.Item key={3} label={"Associate"} value={2} />
                  </Picker>
                </View>
              )}
            />
            {errors.leeaType && <Text style={GlobalStyle.form__input__validation}>LEEA type is required.</Text>}
          </View>
        </View>
        <View style={GlobalStyle.form__row}>

          <View style={GlobalStyle.form__column}>
            <View>
              {brandingImageBase64 != null && brandingImageBase64 != "" &&
                <Image
                  style={{ width: 320, height: 320, marginBottom: 10, marginTop: 10 }}
                  resizeMode={"contain"}
                  source={{
                    uri: brandingImageBase64,
                  }}
                />
              }
            </View>
            <TouchableOpacity style={styles.upload__button} onPress={() => loadFile()}>
              <Text style={styles.upload__button__text}>Upload Branding</Text>
            </TouchableOpacity>
          </View>
        </View>



        <TouchableOpacity style={GlobalStyle.form__submit__button} onPress={handleSubmit(onSubmit)}>
          <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },

  upload__button: {
    marginBottom: 10,
    backgroundColor: PRIMARY_COLOUR,
    width: 200,
  },

  upload__button__text: {
    fontFamily: 'OpenSans-Bold',
    padding: 5,
    color: '#FFF',
    textAlign: 'center',
    fontSize: 14,
  },
});
