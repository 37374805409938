import React from 'react';
import { ActivityIndicator, View } from 'react-native';

export function LoadingIndicator() {
  return (
    <View style={{ height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
      <ActivityIndicator color='#0099ff' />
    </View>
  )
}
