import * as React from 'react';
import { useEffect, useState } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Modal } from 'react-native';

import { DataTable } from 'react-native-paper';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { useIsFocused } from "@react-navigation/native";
import { Entypo, AntDesign, Ionicons, Feather, MaterialCommunityIcons } from '@expo/vector-icons';
import { useGetAllCompaniesQuery } from '@store/services/api/company-api';
import { useGetAllLocationsQuery, useLazyGetSubLocationsByLocationIdQuery } from '@store/services/api/location-api';
import { useGetAllCategoriesQuery } from '@store/services/api/category-api';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import {
  equipmentApi,
  useUpdateEquipmentMutation
} from '@store/services/api/equipment-api';
import { PagedTableDto } from '@store/services/api/base-api';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { EquipmentInspectionIcon, EquipmentInspections } from '@web/components/EquipmentInspections';
import EquipmentInspection from './EquipmentInspection';
import { RootState } from '@store/index';
import { hasFeatureAccess } from '../../System/Permissions/permissionsUtils';
import { EquipmentManagementPermissions, PermissionCategories } from '../../System/Permissions/permissionsEnums';
import { DataTableHeader } from '@web/components/table/DatatableHeader';
import PaginationComponent from '@web/components/Pagination';
import { MAX_ITEMS_BEFORE_ALL, FIRST_PAGE } from '../../../../../../constants/Constants';
import Spinner from 'react-native-loading-spinner-overlay';
import { addMultipleEquipmentInspections, removeMultipleEquipmentInspection } from '@store/slices/equipment-inspection-slice';
import { ArchivedStatusFilter, CategoryFilter, CompanyIdFilter, DescriptionFilter, LocationFilter, ScrappedStatusFilter, SearchFilterButtons, SearchFilters, SearchFilterRow, SerialFilter, AssetFilter, SubLocationFilter } from '@web/components/search-filters/SearchFilters';
import {EquipmentFileManager} from "@components/management/Files/EquipmenFileManager";

export default function EquipmentsListScreen({ navigation }: { navigation: any }) {
  const user = useSelector((state: RootState) => state.user.user);
  const permissions = useSelector((state: RootState) => state.user.user.permissions);
  const modules = useSelector((state: RootState) => state.user.modules);
  const isUserPermissionsEnabled = modules.isUserPermissionsEnabled;
  const isSystemUser = user.role === "System";
  const isFocused = useIsFocused();
  const [loading, setLoading] = useState<any>(false);
  const dispatch = useDispatch();


  const { data: companies } = useGetAllCompaniesQuery(null);
  const { data: locations } = useGetAllLocationsQuery(null);
  const { data: categories } = useGetAllCategoriesQuery(null);
  const [triggerSubLocations, { data: subLocations }] = useLazyGetSubLocationsByLocationIdQuery();

  const [page, setPage] = React.useState<number>(0);
  const [sortedColumn, setSortedColumn] = React.useState<string>("companyName");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);
  const [selectedEquipmentId, setSelectedEquipmentId] = useState(null);
  const [isStatusChanged, setIsStatusChanged] = useState<any>(false)
  const [updateEquipment, updateEquipmentResult] = useUpdateEquipmentMutation();
  const [subLocationOptions, setSubLocationOptions] = useState([]);


  // Filters 
  const [companyId, setCompanyId] = useState("");
  const [locationId, setLocationId] = useState("");
  const [description, setDescription] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [archivedStatus, setArchivedStatus] = useState("-1");
  const [scrappedStatus, setScrappedStatus] = useState("-1");
  const [assetNumber, setAssetNumber] = useState("");
  const [subLocationId, setSubLocationId] = useState("");

  const [trigger, equipmentResult] = equipmentApi.useLazyGetEquipmentsQuery();

  const showEquipmentInspectionModal = useSelector((state: RootState) => state.equipmentInspection.showEquipmentInspectionModal);
  const selectedEquipments = useSelector((state: RootState) => state.equipmentInspection.equipment);

  const loadTable = () => {
    const pageNumber = numberOfItemsPerPage > MAX_ITEMS_BEFORE_ALL ? FIRST_PAGE : page;
    var dto = new PagedTableDto(pageNumber, {
      companyId: companyId,
      locationId: locationId,
      categoryId: categoryId,
      serialNumber: serialNumber,
      description: description,
      scrappedStatus: scrappedStatus,
      archivedStatus: archivedStatus,
      pageSize: numberOfItemsPerPage,
      assetNumber: assetNumber,
      subLocationId: subLocationId,
    }, sortedColumn, sortedColumnAscending);
    trigger(dto).then(() => {
      setLoading(false);
    });
  }

  const resetFilters = () => {
    setCompanyId("");
    setLocationId("");
    setDescription("");
    setSerialNumber("");
    setCategoryId("");
    setScrappedStatus("-1");
    setArchivedStatus("-1");
    setAssetNumber("");
    setSubLocationId("");
  }

  function applyFilters() {
    setPage((curr) => {
      if (curr == 0) loadTable();
      return 0;
    })
  }

  const toggleEquipmentStatus = async (element, isArchived) => {
    setIsStatusChanged(true);
    if (confirm(`Are you sure you want to ${isArchived ? "archive" : "unarchive"} this equipment?`)) {
      try {
        await updateEquipment({ ...element, isArchived: isArchived });
        setIsStatusChanged(false);
      } catch (error) {
        console.error("Error changing equipment status:", error);
      }
    }
  };

  React.useEffect(() => {
    if (isFocused) {
      loadTable();
    }
  }, [page, isFocused, sortedColumn, sortedColumnAscending, numberOfItemsPerPage, isStatusChanged]);

  useEffect(() => {
    if (locationId) {
      triggerSubLocations(locationId).then(response => setSubLocationOptions(response.data));
    } else {
      setSubLocationOptions([]);
    }
    setSubLocationId("");
  }, [locationId]);

  const activeEquipment = equipmentResult?.data?.list
    .filter(equipment => !equipment.isArchived)

  const addAllToBasket = async () => {
    const equipmentList = (activeEquipment && activeEquipment.length > 0) ? activeEquipment
      .map(({ id, serialNumber, masterCategoryName, nextTestDate, inspectionInterval }) => ({
        id,
        serialNumber,
        masterCategoryName,
        nextTestDate,
        inspectionInterval
      })) : [];

    dispatch(addMultipleEquipmentInspections(equipmentList));
  }

  const removeSelectedPageFromBasket = async () => {
    dispatch(removeMultipleEquipmentInspection((activeEquipment && activeEquipment.length > 0) ? activeEquipment : []))
  }

  const isSelectAllChecked = (activeEquipment && activeEquipment.length > 0) && activeEquipment.every((item) =>
    selectedEquipments.some(selectedItem => selectedItem.id === item.id)
  );

  if (equipmentResult.isLoading) {
    return <LoadingIndicator />
  }

  return (
    <View style={styles.container}>
      <Spinner
        visible={loading}
        textContent={'Loading...'}
        textStyle={GlobalStyle.spinnerTextStyle}
      />
      <Modal
        animationType="fade"
        transparent={true}
        visible={showEquipmentInspectionModal == true}>

        <View style={styles.modal__block}>
          <View style={styles.equipment__modal}>
            <EquipmentInspection />
          </View>
        </View>
      </Modal>
      <EquipmentFileManager
        equipmentId={selectedEquipmentId}
        onClose={() => setSelectedEquipmentId(null)} />

      <SearchFilters>
        <CompanyIdFilter value={companyId} onValueChange={(itemValue) => { setCompanyId(itemValue); setLocationId(""); }} companies={companies} />
        <LocationFilter companyId={companyId} value={locationId} onValueChange={setLocationId} locations={locations} />
        <SubLocationFilter companyId={companyId} locationId={locationId} value={subLocationId} onValueChange={setSubLocationId} subLocations={subLocationOptions} />
        <CategoryFilter value={categoryId} onValueChange={setCategoryId} categories={categories} />
        <DescriptionFilter value={description} onValueChange={setDescription} />
        <SearchFilterRow>
          <SerialFilter value={serialNumber} onValueChange={setSerialNumber} />
          <AssetFilter value={assetNumber} onValueChange={setAssetNumber} />
          <ArchivedStatusFilter value={archivedStatus} onValueChange={setArchivedStatus} />
          <ScrappedStatusFilter value={scrappedStatus} onValueChange={setScrappedStatus} />
          <SearchFilterButtons onSubmit={applyFilters} onClearFilters={resetFilters} />
        </SearchFilterRow>
      </SearchFilters>

      <View style={{ flexDirection: 'row' }}>
        {(!isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Administration, EquipmentManagementPermissions.AddEquipment))) && <TouchableOpacity style={GlobalStyle.add__button} onPress={() => navigation.navigate("manage")}>
          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 5, paddingRight: 10 }}>
            <Ionicons name="add" size={24} color="white" /><Text style={GlobalStyle.form__submit__button__text}>Add New Equipment</Text>
          </View>
        </TouchableOpacity>}

        {(!isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Administration, EquipmentManagementPermissions.MassUpload))) && <TouchableOpacity style={GlobalStyle.upload__button} onPress={() => navigation.navigate("massupload")}>
          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 5, paddingRight: 10 }}>
            <Feather name="upload" size={24} color="white" /><Text style={GlobalStyle.form__submit__button__text}>Upload from Excel</Text>
          </View>
        </TouchableOpacity>}
      </View>
      <View style={styles.basket__row}>
        <EquipmentInspections />
      </View>

      <DataTable style={GlobalStyle.table}>
        <DataTableHeader
          sortedColumn={sortedColumn}
          sortedColumnAscending={sortedColumnAscending}
          onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
          headers={[
            { text: 'Inspect', visible: true },
            { text: 'Upload', visible: true },
            { text: 'Company', sortedName: "companyName", visible: true },
            { text: 'Location', sortedName: "locationName", visible: true },
            { text: 'Description', sortedName: "description", visible: true },
            { text: 'Serial Number', sortedName: "serialNumber", visible: true },
            { text: 'Asset Number', sortedName: "assetNumber", visible: true },
            { text: 'Category', sortedName: "categoryName", visible: true },
            { text: 'Date Of Manufacture', sortedName: "dateOfManufacture", visible: true },
            { text: 'Manufacturer', sortedName: "manufacturerName", visible: true },
            { text: 'Options', visible: true },
          ]}
          isSelectAllChecked={isSelectAllChecked}
          onSelectAll={addAllToBasket}
          onClearAll={removeSelectedPageFromBasket}

        />

        {equipmentResult?.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>
                {!element.isArchived && <EquipmentInspectionIcon
                  equipmentId={element.id}
                  serialNumber={element.serialNumber}
                  masterCategoryName={element.masterCategoryName}
                  nextTestDate={element.nextTestDate}
                  inspectionInterval={element.inspectionInterval} />}
              </DataTable.Cell>
              <DataTable.Cell>
                <TouchableOpacity onPress={() => setSelectedEquipmentId(element.id)}>
                  <AntDesign name="folder1" size={24} color="black" />
                </TouchableOpacity>
              </DataTable.Cell>
              <DataTable.Cell>{element.companyName}</DataTable.Cell>
              <DataTable.Cell>{element.locationName}</DataTable.Cell>
              <DataTable.Cell>{element.description}</DataTable.Cell>
              <DataTable.Cell>{element.serialNumber}</DataTable.Cell>
              <DataTable.Cell>{element.assetNumber}</DataTable.Cell>
              <DataTable.Cell>{element.categoryName}</DataTable.Cell>
              <DataTable.Cell>{element.dateOfManufacture == "" || element.dateOfManufacture == null ? "N/A" : moment(element.dateOfManufacture, "DD/MM/YYYY").format("DD/MM/YYYY")}</DataTable.Cell>
              <DataTable.Cell>{element.manufacturerName}</DataTable.Cell>
              <DataTable.Cell>
                <View style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                  <TouchableOpacity onPress={() => navigation.navigate("manage", { equipmentId: element.id })}>
                    <Entypo name="pencil" size={24} color="black" />
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => toggleEquipmentStatus(element, !element.isArchived)}>
                    <MaterialCommunityIcons name={element.isArchived ? "archive-minus" : "archive-plus"} size={24} color={element.isArchived ? "green" : "red"} />
                  </TouchableOpacity>
                </View>

              </DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>

      <PaginationComponent
        numberOfItemsPerPage={numberOfItemsPerPage}
        setNumberOfItemsPerPage={setNumberOfItemsPerPage}
        page={page}
        setPage={setPage}
        fetchResult={equipmentResult}
        loading={loading}
        setLoading={setLoading}
      />

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },

  modal__block: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },

  modal: {
    backgroundColor: '#FFF',
    padding: 10,
    width: 600,
    borderRadius: 5,

    flexDirection: 'column',
  },

  upload__button: {
    marginBottom: 10,
    backgroundColor: PRIMARY_COLOUR,
    width: 200,
    marginTop: 10,
  },

  upload__button__text: {
    fontFamily: 'OpenSans-Bold',
    padding: 5,
    color: '#FFF',
    textAlign: 'center',
    fontSize: 14,
  },

  equipment__modal: {
    backgroundColor: '#FFF',
    padding: 10,
    width: 900,
    borderRadius: 5,

    flexDirection: 'column',
  },
  basket__row: {
    flexDirection: 'row',
    zIndex: 9999999
  },
  select__all__button: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 20,
    marginBottom: 10,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: '#CECECE',
    padding: 3
  },
  select__all__button__icon: {
    margin: 5
  },
  select__all__button__text: {
    fontWeight: 'bold',
    color: 'black',
    margin: 5
  }

});
