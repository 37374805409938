import { TitleBar } from '@app/components/TitleBar';
import { FlashList } from '@shopify/flash-list';
import { RAMSDatabase } from '@store/services/database/rams-database';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ImageBackground, TextInput, TouchableOpacity, ActivityIndicator, Alert, Dimensions } from 'react-native';
import RadioButtonGroup, { RadioButtonItem } from "expo-radio-button";
import Checkbox from 'expo-checkbox';
import { colours } from '../../../../../config';
import { Picker } from '@react-native-picker/picker';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { JobDatabase } from '@store/services/database/job-database';
import { setJobData } from '@store/slices/job-slice';

const isMobileScreen = Dimensions.get("screen").width < 480;
const isPortrait8Inch = Dimensions.get("screen").width > 480 && Dimensions.get("screen").width <= 641;

export default function RAMSScreen({navigation, route}: {navigation: any, route: any}) {
  const dispatch = useDispatch();
  const job = useSelector((state: RootState) => state.job);

  const [questions, setQuestions] = useState([]);
  const [ref, setRef] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const ramsDatabase = new RAMSDatabase();

  useEffect(() => {
    // Load our RAMS Questions
    const loadQuestions = async () => {
      var questions = await ramsDatabase.getRAMSQuestions();
      for (var question of questions) {
        question.response = null;
        question.priority = 0;
        question.severity = 0;
        question.comments = null;
      }

      // Load answered questions and update the priority, comments and severity

      setQuestions(questions);
    }

    loadQuestions();
  }, [])

  const updateQuestionResponse = (question, response) => {
    question.response = response;
    setQuestions([...questions]);
  }

  const updateQuestionPriority = (question, priority) => {
    question.priority = priority;
    setQuestions([...questions]);
  }

  const updateQuestionComments = (question, comments) => {
    question.comments = comments;
    setQuestions([...questions]);
  }

  const updateQuestionSeverity = (question, severity) => {
    question.severity = severity;
    setQuestions([...questions]);
  }

  const save = async () => {
    for (var i = 0; i < questions.length; i++) {
      var question = questions[i];
      if (question.questionType == 0 && question.response == null) {
        ref.scrollToIndex({ animated: true, index: i, viewPosition: 0 });
        Alert.alert("Validation", "Select a response for: " + question.question);
        return;
      }

      if (question.questionType == 1 && question.response == 1 && (question.comments == null || question.comments == "")) {
        ref.scrollToIndex({ animated: true, index: i, viewPosition: 0 });
        Alert.alert("Validation", "Enter an action for: " + question.question);
        return;
      }
    }

    setLoading(true);
    try {
      var ramsId = await ramsDatabase.addRams(questions, job.engineerId, job.jobId);
      const jobDatabase = new JobDatabase();

      await jobDatabase.updateJobRams(job.jobId, ramsId);

      dispatch(setJobData({
        ramsId: ramsId,
      }))
    } catch (e) {
      setLoading(false);
      Alert.alert("Error saving PoWRA. Please try again");
      return;
    }

    setLoading(false);

    Alert.alert("Success", "Successfully saved PoWRA");

    navigation.goBack();
  }

  const renderMobileItem = ({ item, index }) => (
      <View style={styles.row}>
      <View >
        {item.questionType == 1 &&
        <View style={[styles.row__header]}>
          <Checkbox style={{ marginRight: 10 }} value={item.response == 1} onValueChange={(val) => updateQuestionResponse(item, val)} color={colours.primary} />
          <Text style={{ flex: 1 }}>{item.question}</Text>
        </View>
        }
        <View style={[styles.row__header]}>
          {item.questionType == 0 &&
          <View>
          <Text style={{ flex: 1 }}>{item.question}</Text>
            <View style={styles.radio__group__row}>
              <RadioButtonGroup
                containerStyle={{ flexDirection: 'row' }}
                radioBackground={colours.primary}
                selected={item.response}
                onSelected={(val) => updateQuestionResponse(item, val)}
              >
                <RadioButtonItem value={0} label="No" />
                <RadioButtonItem value={1} style={{ marginLeft: 20 }} label="Yes" />
              </RadioButtonGroup>
            </View>
            </View>
          }
        </View>
      </View>

      {item.questionType == 1 && item.response == 1 &&
        <View>
          <View style={styles.risk__selector}>
            <View style={styles.risk__response}>
              <Text style={styles.risk__response__label}>Before:</Text>
              <Picker
                style={{ minWidth: 200 }}
                selectedValue={item.priority}
                onValueChange={(itemValue, itemIndex) => updateQuestionPriority(item, itemValue)}
                itemStyle={{ height: 50 }}>

                <Picker.Item label="Low" value={0} />
                <Picker.Item label="Medium" value={1} />
                <Picker.Item label="High" value={2} />
              </Picker>
            </View>
            <View style={styles.risk__response}>
              <Text style={styles.risk__response__label}>Action:</Text>
              <TextInput style={styles.risk__comments} value={item.comments} multiline={true} onChangeText={(text) => updateQuestionComments(item, text)}></TextInput>
            </View>
            <View style={styles.risk__response}>
              <Text style={styles.risk__response__label}>After:</Text>
              <Picker
                style={{ minWidth: 200 }}
                selectedValue={item.severity}
                onValueChange={(itemValue, itemIndex) => updateQuestionSeverity(item, itemValue)}
                itemStyle={{ height: 50 }}>

                <Picker.Item label="Low" value={0} />
                <Picker.Item label="Medium" value={1} />
                <Picker.Item label="High" value={2} />
              </Picker>
            </View>
          </View>
        </View>
      }
    </View>
  );

  const renderItem = ({ item, index }) => (
    <View style={styles.row}>
      <View style={styles.row__header}>
        {item.questionType == 1 &&
          <Checkbox style={{ marginRight: 10 }} value={item.response == 1} onValueChange={(val) => updateQuestionResponse(item, val)} color={colours.primary} />
        }

        <Text style={{ flex: 5 }}>{item.question}</Text>

        <View style={{ flex: 1 }}></View>
        {item.questionType == 0 &&
          <RadioButtonGroup
            containerStyle={{ flexDirection: 'row' }}
            radioBackground={colours.primary}
            selected={item.response}
            onSelected={(val) => updateQuestionResponse(item, val)}>

            <RadioButtonItem value={0} label="No" />
            <RadioButtonItem value={1} style={{ marginLeft: 20 }} label="Yes" />
          </RadioButtonGroup>
        }
      </View>

      {item.questionType == 1 && item.response == 1 && (
        <View>
          <View style={styles.risk__selector}>
            {isPortrait8Inch ? (
              <>
                <View style={styles.risk__response}>
                  <Text style={styles.risk__response__label}>Before:</Text>
                  <Picker
                    style={{ minWidth: 200 }}
                    selectedValue={item.priority}
                    onValueChange={(itemValue, itemIndex) => updateQuestionPriority(item, itemValue)}
                    itemStyle={{ height: 50 }}
                  >
                    <Picker.Item label="Low" value={0} />
                    <Picker.Item label="Medium" value={1} />
                    <Picker.Item label="High" value={2} />
                  </Picker>
                </View>
                <View style={styles.risk__response}>
                  <Text style={styles.risk__response__label}>Action:</Text>
                  <TextInput
                    style={styles.risk__comments}
                    value={item.comments}
                    multiline={true}
                    onChangeText={(text) => updateQuestionComments(item, text)}
                  />
                </View>
                <View style={styles.risk__response}>
                  <Text style={styles.risk__response__label}>After:</Text>
                  <Picker
                    style={{ minWidth: 200 }}
                    selectedValue={item.severity}
                    onValueChange={(itemValue, itemIndex) => updateQuestionSeverity(item, itemValue)}
                    itemStyle={{ height: 50 }}
                  >
                    <Picker.Item label="Low" value={0} />
                    <Picker.Item label="Medium" value={1} />
                    <Picker.Item label="High" value={2} />
                  </Picker>
                </View>
              </>
            ) : (
              <>
                <Text>Before:</Text>
                <Picker
                  style={{ minWidth: 200 }}
                  selectedValue={item.priority}
                  onValueChange={(itemValue, itemIndex) => updateQuestionPriority(item, itemValue)}
                  itemStyle={{ height: 50 }}
                >
                  <Picker.Item label="Low" value={0} />
                  <Picker.Item label="Medium" value={1} />
                  <Picker.Item label="High" value={2} />
                </Picker>

                <Text style={{ marginRight: 10 }}>Action:</Text>
                <TextInput
                  style={styles.risk__comments}
                  value={item.comments}
                  multiline={true}
                  onChangeText={(text) => updateQuestionComments(item, text)}
                />

                <Text style={{ marginLeft: 10 }}>After:</Text>
                <Picker
                  style={{ minWidth: 200 }}
                  selectedValue={item.severity}
                  onValueChange={(itemValue, itemIndex) => updateQuestionSeverity(item, itemValue)}
                  itemStyle={{ height: 50 }}
                >
                  <Picker.Item label="Low" value={0} />
                  <Picker.Item label="Medium" value={1} />
                  <Picker.Item label="High" value={2} />
                </Picker>
              </>
            )}
          </View>
        </View>
      )}
    </View>
  )

  if (isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <View style={styles.container}>
      <TitleBar navigation={navigation} title="PoWRA" showBackButton={true} onSynced={null} showSearchButton={false} onSearch={false}/>

      <FlashList
        data={questions.sort((a, b) => a.position - b.position)}
        renderItem={isMobileScreen ? renderMobileItem : renderItem}
        estimatedItemSize={23}
        ref={(ref) => { setRef(ref); }}
      />

      <TouchableOpacity style={styles.proceed__button} onPress={() => save()}>
        <Text style={styles.proceed__button__text}>SAVE</Text>
      </TouchableOpacity>

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FAFAFA',
    padding: isMobileScreen? 20 : 40,
    paddingTop: 60,
  },

  row: {
    padding: 15,
    backgroundColor: '#FFF',
    marginBottom: 5,
    
  },

    row__header: {
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: isMobileScreen ? "wrap" : "nowrap"
    },

    risk__selector: {
      marginTop: 20,
      flexDirection: isMobileScreen || isPortrait8Inch ? 'column' : 'row',
      alignItems: 'center',
    },

    risk__comments: {
      borderBottomWidth: 1,
      borderBottomColor: '#000',
      flex: 1,
      minWidth: 150
    },

    risk__response: {
      flexDirection: "row",
      alignItems: "center"
    },

    risk__response__label: {
      flex: 1,
      fontWeight: "bold"
      
    },

    proceed__button: {
      padding: 10,
      backgroundColor: colours.primary,
      marginTop: 20,
      borderRadius: 7,
      height: 45,
  
  
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  
      proceed__button__text: {
        fontFamily: 'OpenSans-Bold',
        color: '#FFF',
        textAlign: 'center',
        fontSize: 18
      },

      radio__group__row: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 10, 
      },
});
