import * as React from 'react';
import { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Alert, Dimensions, TextInput, Modal, Platform, ScrollView } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { JobDatabase } from '@store/services/database/job-database';
import { FlashList } from "@shopify/flash-list";
import { TitleBar } from '../../components/TitleBar';
import { AntDesign, MaterialCommunityIcons, Entypo, EvilIcons, Ionicons, MaterialIcons, FontAwesome5 } from '@expo/vector-icons';
import moment from "moment";
import { setJobData } from '../../../../store/slices/job-slice';
import { useIsFocused } from '@react-navigation/native';
import { clearInspectionData } from '../../../../store/slices/rote-inspection-slice';
import config, { colours } from '../../../../config';
import { InspectionDatabase } from '@store/services/database/inspection-database';
import { LocationDatabase } from '@store/services/database/location-database';
import { clearBreakdownData } from '@store/slices/breakdown-slice';
import { Picker } from '@react-native-picker/picker';
import { Controller, useForm } from 'react-hook-form';
import uuid from 'react-native-uuid';
import { CategoryDatabase } from '@store/services/database/category-database';
import { PurposeDatabase } from '@store/services/database/purpose-database';
import { ColourDatabase } from '@store/services/database/colour-database';
import { DatePicker } from '@app/components/DatePicker';
import RNPickerSelect from 'react-native-picker-select';
import Checkbox from 'expo-checkbox';
import { EquipmentDatabase, EquipmentStatus } from '@store/services/database/equipment-database';
import { ConfirmScrapModal } from "@app/components/modals/ConfirmScrapModal";

const screenWidth = Dimensions.get("screen").width;

const isTabletScreen = screenWidth >= 641 && screenWidth < 960;
const isMobileScreen = screenWidth < 480;
const isPortrait8Inch = screenWidth > 480 && screenWidth <= 641;

export default function JobAssetsScreen({ navigation, route }: { navigation: any, route: any }) {
  const isFocused = useIsFocused();
  const dispatch = useDispatch();
  const job = useSelector((state: RootState) => state.job);
  const inspection = useSelector((state: RootState) => state.roteInspection);
  const { user } = useSelector((state: RootState) => state.user);
  const [jobAssets, setJobAssets] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [sublocationFilterApplied, setSublocationFilterApplied] = useState(false);
  const [sublocationName, setSublocationName] = useState("");
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [purposes, setPurposes] = useState([]);
  const [pickercolours, setColours] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [purpose, setPurpose] = useState(0);
  const [isMarineDisabled, setIsMarineDisabled] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeButton, setActiveButton] = useState('todo');
  const { isPOWRAEnabled } = useSelector((state: RootState) => state.user.modules);
  const [scrapModalVisible, setScrapModalVisible] = useState(false);
  const [isEquipmentFromOtherLocation, setIsEquipmentFromOtherLocation] = useState(false);
  const [subLocationModalId, setsubLocationModalId] = useState("");

  const isRote = job.jobType == 0;
  const isPssr = job.jobType == 5;


  const loadPurposes = async () => {
    const purposeDatabase = new PurposeDatabase();
    const purposes = await purposeDatabase.getAll();
    setPurposes(purposes);
  }

  const loadColours = async () => {
    const coloursDatabase = new ColourDatabase();
    const colours = await coloursDatabase.getAll();
    setColours(colours);
  }

  const { control, handleSubmit, getValues, setValue, watch, formState: { errors }, reset } = useForm({
    defaultValues: {
      subLocationId: null,
      subLocation: "",
      inspectionDate: moment().toDate(),
      nextInspectionDate: null,
      purposeId: null,
      colourId: job.colourId,
      nextTestDate: moment().add(5, 'years').toDate(),
      particulars: "",
    }
  });

  const purposeInterval = () => {
    const selectedPurposeId = getValues("purposeId");
    if (selectedPurposeId !== null) {
      let purpose = purposes.find(p => p.id === selectedPurposeId);
      setPurpose(purpose.interval);
    }
  }

  const inspDate = watch("inspectionDate");

  const setDates = async (categoryId) => {
    var date = moment(inspDate);

    if (inspection.safeForUse == true || inspection.safeForUse == null) {
      try {
        if (purpose === 6) { date = date.add(6, 'M'); }
        else if (purpose == 12) { date = date.add(12, 'M') }
        else {
          // Get the category and use that interval
          var categoryDatabase = new CategoryDatabase();
          var category = await categoryDatabase.getById(categoryId);
          date = date.add(category.inspectionInterval, 'M');
        }
        setValue("nextInspectionDate", date.toDate());
      } catch (error) {
        console.error('Error in setDates:', error);
      }
    }
  }

  useEffect(() => {
    if (selectedAssets.length > 0) {
      setDates(selectedAssets[0].categoryId);
    }
  }, [selectedAssets, inspDate, inspection.safeForUse, purpose]);

  const locationDatabase = new LocationDatabase();

  const loadSubLocations = async () => {
    const subLocations = await locationDatabase.getAllSubLocationsByLocation(job.locationId);
    setSubLocations(subLocations);
  }

  useEffect(() => {
    loadSubLocations();
    loadPurposes();
    loadColours();
    loadJob();
    setValue("colourId", job.colourId)
  }, []);

  const jobDatabase = new JobDatabase();

  const loadJobAssets = async (searchQuery = null, exactSN = false, completed = 0, isMissing = 0) => {
    setIsEquipmentFromOtherLocation(false);
    let jobAssets = await fetchJobAssets(searchQuery, exactSN, completed, isMissing);

    if (searchQuery && jobAssets.length === 0) {
      handleNoResults(searchQuery, exactSN, completed, isMissing);
    } else {
      setJobAssets(jobAssets);
    }
  };

  const fetchJobAssets = async (searchQuery, exactSN, completed, isMissing, withArchived = 0) => {
    return await jobDatabase.getJobAssetsWithSublocations(job.jobId, searchQuery, exactSN, completed, isMissing, withArchived);
  };

  const handleNoResults = (searchQuery, exactSn, completed, isMissing) => {
    setSearchQuery(searchQuery);
    Alert.alert('No results', 'Select the option below', [
      { text: 'Cancel', style: 'cancel' },
      {
        text: 'Search Archived', onPress: async () => {
          const archivedAssets = await fetchJobAssets(searchQuery, exactSn, completed, isMissing, 1);
          if (archivedAssets.length > 0) {
            setJobAssets(archivedAssets);
          } else {
            handleNotFound('archived');
          }
        }
      },
      {
        text: 'Search Other Locations', onPress: async () => {
          const equipmentFromOtherLocation = await fetchEquipmentFromOtherLocation(searchQuery, exactSn);
          if (equipmentFromOtherLocation.length > 0) {
            handleEquipmentFound(equipmentFromOtherLocation);
          } else {
            handleNotFound("other locations");
          }
        }
      }
    ]);
  };

  const fetchEquipmentFromOtherLocation = async (searchQuery, exactSN) => {
    return await jobDatabase.getAllEquipmentWithSerialForTheCompany(job.companyId, searchQuery, exactSN);
  };

  const handleEquipmentFound = (equipmentFromOtherLocation) => {
    Alert.alert('Assets found', 'Press arrows icon to transfer', [
      { text: 'Ok', style: 'cancel' }
    ]);
    setIsEquipmentFromOtherLocation(true);
    setJobAssets(equipmentFromOtherLocation);
  };

  const handleNotFound = (typeOfSearch: string) => {
    Alert.alert('No Assets found', `No assets found with this search criteria in ${typeOfSearch === 'archived' ? 'archived items' : 'other locations'} `, [
      { text: 'Ok', style: 'cancel' }
    ]);
  };

  const loadJob = async () => {
    const dbJob = await jobDatabase.getJobById(job.jobId);
    const colourId = dbJob.colourId;
    dispatch(setJobData({
      colourId: colourId
    }))
  }

  const addSublocation = async () => {
    try {
      if (!sublocationName.trim()) {
        Alert.alert('Error', 'Sublocation name cannot be empty.');
        return;
      }

      const locationId = job.locationId;

      const existingSublocations = await locationDatabase.getAllSubLocationsByLocation(locationId);

      const sublocationExists = existingSublocations.some(sublocation => sublocation.name.toLowerCase() === sublocationName.toLowerCase());

      if (!sublocationExists) {
        const sublocationId = uuid.v4()
        await locationDatabase.insertOrUpdateSubLocationsList([{ id: sublocationId, name: sublocationName, locationId, toBeSynced: 1 }]);
        setSublocationName("");
        loadSubLocations();
        Alert.alert('Success', 'Sublocation added');
      } else {
        Alert.alert('Error', 'Sublocation with the same name already exists.');
      }
    } catch (error) {
      console.error('Error adding sublocation:', error);
    }
  };

  const selectAsset = (viewModel) => {
    dispatch(clearInspectionData());
    dispatch(clearBreakdownData());
    dispatch(setJobData({ equipmentId: viewModel.id, categoryId: viewModel.categoryId }))
    navigation.navigate("EquipmentDetails", { pssrInspectionId: viewModel.pssrInspectionId });
  }
  const applySublocationFilter = () => {
    const selectedSubLocationId = getValues("subLocationId");
    if (selectedSubLocationId !== null) {
      const filteredAssets = jobAssets.filter((asset) => asset.subLocationId === selectedSubLocationId);

      setFilteredAssets(filteredAssets);
      setSublocationFilterApplied(true);
    } else {
      setSublocationFilterApplied(false);
    }
  };

  const rams = () => {
    navigation.navigate("RAMS");
  }

  const erstr = () => {
    navigation.navigate("ERSTR");
  }

  const addEquipment = () => {
    navigation.navigate("EquipmentManage", { equipmentId: null })
  }

  const toggleAssetSelection = (asset) => {
    setSelectedAssets((prevSelectedAssets) => {
      const isSelected = prevSelectedAssets.some((selectedAsset) => selectedAsset.id === asset.id);

      if (isSelected) {
        return prevSelectedAssets.filter((selectedAsset) => selectedAsset.id !== asset.id);
      } else {
        return [...prevSelectedAssets, asset];
      }
    });
  };

  const loadPurposeId = async (categoryId) => {
    var categoryDatabase = new CategoryDatabase();
    var category = await categoryDatabase.getById(categoryId);
    return purposes?.find(p => p.interval === category?.inspectionInterval)?.id;
  }

  const unarchiveItem = async (equipment) => {
    Alert.alert('Asset archived', 'Unarchive asset?', [
      { text: 'No', style: 'cancel', },
      {
        text: 'Yes', onPress: async () => {
          const equipmentDatabase = new EquipmentDatabase();
          await equipmentDatabase.unarchiveEquipment(equipment.id);
          loadJobAssets(searchQuery);
        }
      }
    ]);
  }

  const moveItem = async (equipment) => {
    Alert.alert('Asset from different location', 'Assign asset to this location and job?', [
      { text: 'No', style: 'cancel', },
      {
        text: 'Yes', onPress: async () => {
          const equipmentDatabase = new EquipmentDatabase();
          await equipmentDatabase.moveToLocation(job.locationId, equipment.id);
          if (equipment.currentJobs.length > 0) {
            for (const jobId of equipment.currentJobs) {
              await jobDatabase.removeAssetFromJob(jobId, equipment.id);
              await jobDatabase.updateJobToBeSynced(jobId, true);
            }
          }
          await jobDatabase.createJobAsset(equipment.id, job.jobId);
          setIsEquipmentFromOtherLocation(false);
          loadJobAssets();
        }
      }
    ]);
  }

  useEffect(() => {
    if (selectedAssets.length > 0) {
      const fetchPurposeId = async () => {
        const purposeId = await loadPurposeId(selectedAssets[0].categoryId);
        setValue("purposeId", purposeId);
        dispatch(setJobData({ categoryId: selectedAssets[0].categoryId }));
      };
      fetchPurposeId();
      setValue("colourId", job.colourId)
    }
  }, [selectedAssets]);

  const hideModal = () => {
    setModalVisible(false);
    setsubLocationModalId("");
    reset();
  };

  useEffect(() => {
    if (!isModalVisible) {
      if (selectedAssets.length === 0) {
        loadJobAssets();
        loadJob();
      }
    }
  }, [isModalVisible, selectedAssets]);

  const isMarineMasterCategoryEquipment = () => {
    return selectedAssets.every(a => a.masterCategoryName === "Marine 2006")
  }

  const handlePassAll = async () => {

    const loadCategoryQuestions = async (categoryId) => {
      const categoryDatabase = new CategoryDatabase();
      return await categoryDatabase.getCategoryQuestions(categoryId);
    };

    const inspectionDate = getValues("inspectionDate");
    const nextInspectionDate = getValues("nextInspectionDate");
    const purposeId = getValues("purposeId");
    const colourId = getValues("colourId");
    const inspectionDatabase = new InspectionDatabase();
    const inspectionData = { ...inspection };
    inspectionData.firstExamination = false;
    inspectionData.installedCorrectly = true;
    inspectionData.defects = 'NONE';
    inspectionData.immediateToPersons = false;
    inspectionData.becomeDangerToPersons = false;
    inspectionData.repairDate = null;
    inspectionData.repair = null;
    inspectionData.identification = null;
    inspectionData.particulars = getValues("particulars");
    inspectionData.safeForUse = true;
    inspectionData.answeredQuestions = [];
    inspectionData.nextTestDate = null;
    const nextTestDate = getValues("nextTestDate");

    if (!purposeId || !colourId) {
      return;
    }

    for (const asset of selectedAssets) {
      const loadedQuestions = await loadCategoryQuestions(asset.categoryId);
      let passDefultResponses = [];

      for (const question of loadedQuestions) {
        passDefultResponses.push(...question.responses.filter(response => response.isPassDefault));
      }

      const formattedResponses = passDefultResponses.map(response => ({
        questionId: response.categoryQuestionId,
        response: response.id,
        comments: "",
      }));

      inspectionData.answeredQuestions = [...inspectionData.answeredQuestions, ...formattedResponses];
      inspectionData.firstExamination = false;
      inspectionData.safeForUse = true;
      const images = [];

      await inspectionDatabase.addInspection(
        moment(inspectionDate).format(),
        moment(nextInspectionDate).format("YYYY-MM-DD"),
        inspectionData.firstExamination,
        inspectionData.installedCorrectly,
        inspectionData.defects,
        inspectionData.immediateToPersons,
        inspectionData.becomeDangerToPersons,
        inspectionData.repairDate,
        inspectionData.repair,
        inspectionData.identification,
        inspectionData.particulars,
        inspectionData.safeForUse,
        asset.id,
        job.engineerId,
        job.jobId,
        purposeId,
        colourId,
        false,
        images,
        inspectionData.answeredQuestions,
        !isMarineDisabled ? moment(nextTestDate).format("YYYY-MM-DD") : null,
      );
      if (!job.colourId) {
        await jobDatabase.updateJobData(job.jobId, purposeId, colourId);
      }
      inspectionData.answeredQuestions = [];
    };
    if (subLocationModalId) {
      await moveToSublocation(subLocationModalId);
    }
    setSelectedAssets([]);
    setsubLocationModalId("");
    loadJobAssets();
    hideModal();
  }
  const isSingleEquipmentMarine = selectedAssets.length === 1 && selectedAssets[0].masterCategoryName === "Marine 2006";

  const passSelectedAssets = async () => {

    setValue("inspectionDate", moment().toDate());

    const isSameInterval = selectedAssets.every((asset, index, array) => {
      if (index === 0) {
        return true;
      }
      return asset.interval === array[index - 1].interval;
    });

    if (isSingleEquipmentMarine && !isMarineDisabled) {
      setValue("nextTestDate", selectedAssets[0].nextTestDate ? moment(selectedAssets[0].nextTestDate).toDate() : moment().add(5, 'years').toDate())
    }

    if (!isSameInterval) {
      Alert.alert('Error', 'Selected assets must have the same inspection interval.');
      return;
    }

    setModalVisible(true)
  }
  const confirmationAlert = (header, alertText, action) => {
    Alert.alert(`${header}`, `${alertText}`, [
      { text: 'No', style: 'cancel', },
      {
        text: 'Yes', onPress: async () => {
          await action();
        }
      }
    ]);
  }

  const archiveSelectedAssets = async () => {
    const equipmentDatabase = new EquipmentDatabase();
    confirmationAlert("Archive", "Are you sure you want to archive selected assets?", async () => {
      for (const asset of selectedAssets) {
        await equipmentDatabase.archiveEquipment(asset.id);
      }
      setSelectedAssets([]);
    });
  }

  const scrapSelectedAssets = async (reason: string) => {
    const equipmentDatabase = new EquipmentDatabase();
    for (const asset of selectedAssets) {
      await equipmentDatabase.setScrapped(asset.id, reason);
    }
    setSelectedAssets([]);
  }

  const setMissingSelectedAssets = async () => {
    var inspectionDatabase = new InspectionDatabase();
    const purposeId = await loadPurposeId(selectedAssets[0].categoryId);
    confirmationAlert("Set Not Presented", "Are you sure you want to set selected assets as not presented?", async () => {
      for (let asset of selectedAssets) {
        await inspectionDatabase.addMissingInspection(moment()
          .format("YYYY-MM-DD"), asset.id, job.engineerId, job.jobId, purposeId, job.colourId);
      }
      setSelectedAssets([]);
    });
  }

  const complete = async (promptEngineerReport: boolean = true) => {
    // Prompt for erstr
    if (job.engineerReportId == null && promptEngineerReport) {
      Alert.alert('Complete Job', 'Do you want to carry out your engineer report now?', [
        { text: 'No', style: 'cancel', onPress: () => complete(false) },
        { text: 'Yes', onPress: () => erstr() }
      ]);
      return;
    }

    if (jobAssets.length == 0) {
      await jobDatabase.completeJob(job.jobId);
      Alert.alert("Success", "Successfully completed Job");
      navigation.navigate('Dashboard');
      return;
    }

    if (isRote) {
      Alert.alert('Complete Job', 'Completing this job will set the remaining assets as missing. Are you sure you want to do this?', [
        {
          text: 'No', style: 'cancel', onPress: async () => {

          }
        },
        {
          text: 'Yes', onPress: async () => {
            var inspectionDatabase = new InspectionDatabase();
            for (let jobAsset of jobAssets) {
              await inspectionDatabase.addMissingInspection(moment()
                .format("YYYY-MM-DD"), jobAsset.id, job.engineerId, job.jobId, getValues("purposeId"), getValues("colourId"));
            }

            await jobDatabase.completeJob(job.jobId);
            Alert.alert("Success", "Successfully completed Job");
            navigation.navigate('Dashboard');
          }
        }
      ]);
    } else {
      await jobDatabase.completeJob(job.jobId);
      Alert.alert("Success", "Successfully completed Job");
      navigation.navigate('Dashboard');
    }
    return;

  }

  const updateMarine = async (disable) => {
    setIsMarineDisabled(disable);
    if (disable) {
      setValue("nextTestDate", moment().toDate());
    } else {
      setValue("nextTestDate", isSingleEquipmentMarine && selectedAssets[0].nextTestDate
        ? moment(selectedAssets[0].nextTestDate).toDate()
        : moment().add(5, 'years').toDate());
    }
  }

  useEffect(() => {
    loadJobAssets();
    setActiveButton('todo')
  }, [isFocused])

  const loadInspected = () => {
    setActiveButton('inspected');
    const completed = 1;
    const searchQuery = null;
    loadJobAssets(searchQuery, false, completed)
  }

  const loadTodo = () => {
    setActiveButton('todo');
    loadJobAssets();
  }

  const loadNotPresented = () => {
    setActiveButton('notPresented');
    const completed = 1;
    const isMissing = 1;
    const searchQuery = null;
    loadJobAssets(searchQuery, false, completed, isMissing);
  }

  const handleSubLocationIdChange = (itemValue) => {
    setsubLocationModalId(itemValue);
  }

  const moveToSublocation = async (subLocationId) => {
    const equipmentDatabase = new EquipmentDatabase();
    return new Promise<void>((resolve, reject) => {
      confirmationAlert("Move", "Are you sure you want to move selected assets to sublocation?", async () => {
        try {
          for (const asset of selectedAssets) {
            if (asset.subLocationId !== subLocationId) {
              await equipmentDatabase.moveToSublocation(asset.id, subLocationId);
            }
          }
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    });
  };


  const renderItem = ({ item, index }) => {
    const isThoroughInService = item?.pssrInspections?.some(inspection => inspection.examinationType === "Thorough In Service") || false;
    const isThoroughOutOfService = item?.pssrInspections?.some(inspection => inspection.examinationType === "Thorough Out of Service") || false;

    return (
      <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1, backgroundColor: 'white', borderBottomWidth: 1, marginBottom: 2 }}>
        <TouchableOpacity
          key={item.id}
          style={[styles.row, selectedAssets.some(selectedAsset => selectedAsset.id === item.id) && styles.selected__item]}
          onPress={() => {
            if (!item.isArchived && !isEquipmentFromOtherLocation) {
              const action = isRote ? () => toggleAssetSelection(item) : () => selectAsset(item);
              const inspectedAction = () => selectAsset(item);
              if (activeButton !== "todo") {
                confirmationAlert("Inspected equipment", "This equipment is already inspected. Do you want to edit it?", inspectedAction);
              } else {
                action();
              }
            }
          }}
        >
          <View style={[styles.column, styles.body__column]}>
            <Text style={styles.job__text}>{item.description}</Text>
          </View>
          {isEquipmentFromOtherLocation && <View style={[styles.column, styles.body__column]}>
            <Text style={styles.job__text}>{item.location}</Text>
          </View>}
          <View style={[styles.column, styles.body__column]}>
            <Text style={styles.job__text}>{item.categoryName + ` (${item.interval})`}</Text>
          </View>
          <View style={[styles.column, styles.body__column]}>
            <Text style={styles.job__text}>{item.serialNumber}</Text>
          </View>
          <View style={[styles.column, styles.body__column]}>
            <Text style={styles.job__text}>{item.swl}</Text>
          </View>
          <View style={[styles.column, styles.body__column]}>
            <Text style={styles.job__text}>{item.nextInspectionDate ? moment(item.nextInspectionDate).format("DD/MM/YYYY") : ""}</Text>
          </View>
          {isPssr && (
            <View style={[styles.column, styles.body__column]}>
              <Text style={styles.job__text}>Thorough In Service: {isThoroughInService ? "Yes" : "No"}</Text>
              <Text style={styles.job__text}>Thorough Out of Service: {isThoroughOutOfService ? "Yes" : "No"}</Text>
            </View>
          )}
          {isEquipmentFromOtherLocation ? (
            <TouchableOpacity onPress={() => moveItem(item)}>
              <View style={{ width: 40, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', }}>
                <MaterialCommunityIcons name="swap-horizontal-bold" size={24} color="black" />
              </View>
            </TouchableOpacity>
          ) : (
            !item.isArchived ? (
              <TouchableOpacity
                onPress={() => {
                  const action = () => selectAsset(item);
                  if (activeButton !== "todo") {
                    confirmationAlert("Inspected equipment", "This equipment is already inspected. Do you want to edit it?", action);
                  } else {
                    action();
                  }
                }}>
                <View style={{ width: 40, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', }}>
                  <AntDesign name="rightcircleo" size={24} color="black" style={{ marginLeft: 15, }} />
                </View>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity onPress={() => unarchiveItem(item)}>
                <View style={{ width: 40, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', }}>
                  <MaterialCommunityIcons style={{ marginLeft: 15 }} name="archive-minus" size={24} color="black" />
                </View>
              </TouchableOpacity>
            )
          )}
          <View>
            {renderItemStatus(item)}
          </View>
        </TouchableOpacity>
      </View>)
  };

  const renderItemStatus = (item) => {
    return (
      <TouchableOpacity style={styles.status__container} onPress={() => { navigation.navigate("EquipmentViewInspections", { equipmentId: item.id }) }}>
        {item.lastKnownStatus == EquipmentStatus.Passed && <MaterialCommunityIcons name="check-decagram" size={24} color="#4CAF50" /> } 
        {item.lastKnownStatus == EquipmentStatus.PassedWithComments && <MaterialCommunityIcons name="check-decagram-outline" size={24} color="#4CAF50" /> } 
        {item.lastKnownStatus == EquipmentStatus.Failed && <MaterialCommunityIcons name="cancel" size={24} color="#E57373" /> }
        {item.lastKnownStatus == EquipmentStatus.NotPresented && <MaterialCommunityIcons name="progress-question" size={24} color="#9575CD" /> } 
      </TouchableOpacity>
    )
   
    return null;
  }

  const renderItemCard = ({ item, index }) => {
    const isThoroughInService = item?.pssrInspections?.some(inspection => inspection.examinationType === "Thorough In Service") || false;
    const isThoroughOutOfService = item?.pssrInspections?.some(inspection => inspection.examinationType === "Thorough Out of Service") || false;

    return (
      <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: 'white' }}>
        <TouchableOpacity
          style={[
            styles.row,
            selectedAssets.some(selectedAsset => selectedAsset.id === item.id)
              ? styles.selected__item
              : styles.unselected__item, { flex: 1, marginRight: 5 }
          ]}
          disabled={Boolean(item.isArchived)}
          onPress={() => {
            if (!item.isArchived && !isEquipmentFromOtherLocation) {
              const action = isRote ? () => toggleAssetSelection(item) : () => selectAsset(item);
              const inspectedAction = () => selectAsset(item);
              if (activeButton !== "todo") {
                confirmationAlert("Inspected equipment", "This equipment is already inspected. Do you want to edit it?", inspectedAction);
              } else {
                action();
              }
            }
          }}
        >
          <View style={styles.body}>
            <Text style={styles.card__text}><Text style={styles.boldt__text}>Description: </Text>{item.description}</Text>
            {isEquipmentFromOtherLocation && <Text style={styles.card__text}><Text style={styles.boldt__text}>Current Location: </Text>{item.location}</Text>}
            <Text style={styles.card__text}><Text style={styles.boldt__text}>Category: </Text>{item.categoryName + ` (${item.interval})`}</Text>
            <Text style={styles.card__text}><Text style={styles.boldt__text}>Serial #: </Text>{item.serialNumber}</Text>
            <Text style={styles.card__text}><Text style={styles.boldt__text}>SWL: </Text>{item.swl}</Text>
            {item.rfid && <Text style={styles.card__text}><Text style={styles.boldt__text}>RFID/QR: </Text>{item.rfid}</Text>}
            <Text style={styles.card__text}>
              <Text style={styles.boldt__text}>Next Inspection: </Text>
              {item.nextInspectionDate ? moment(item.nextInspectionDate).format("DD/MM/YYYY") : ""}
	          </Text>
            {isPssr && <>
              <Text style={styles.boldt__text}>PSSR examinations completed: </Text>
              <Text style={styles.card__text}> Thorough In Service: {isThoroughInService ? "Yes" : "No"}</Text>
              <Text style={styles.card__text}> Thorough Out of Service: {isThoroughOutOfService ? "Yes" : "No"}</Text>
            </>}
	          <Text style={styles.card__text}>
              <Text style={styles.boldt__text}>Last Known Status: </Text>
              {item.lastKnownStatusFormatted}
            </Text>
          </View>
        </TouchableOpacity>
        {renderItemStatus(item)}
        {isEquipmentFromOtherLocation ? (
          <TouchableOpacity onPress={() => moveItem(item)}>
            <MaterialCommunityIcons name="swap-horizontal-bold" size={24} color="black" />
          </TouchableOpacity>
        ) : (
          !item.isArchived ? (
            <TouchableOpacity onPress={() => {
              const action = () => selectAsset(item);
              if (activeButton !== "todo") {
                confirmationAlert("Inspected equipment", "This equipment is already inspected. Do you want to edit it?", action);
              } else {
                action();
              }
            }}>
              <AntDesign name="rightcircleo" size={24} color="black" />
            </TouchableOpacity>
          ) : (
            <TouchableOpacity onPress={() => unarchiveItem(item)}>
              <MaterialCommunityIcons name="archive-minus" size={24} color="black" />
            </TouchableOpacity>
          )
        )}
      </View>
    );
  };

  return (
    <>
      <ConfirmScrapModal
        modalVisible={scrapModalVisible}
        setModalVisible={setScrapModalVisible}
        onConfirm={scrapSelectedAssets}
        equipment={selectedAssets?.map(asset => asset.description)} />
      <View style={styles.container}>
        <TitleBar
          navigation={navigation}
          title="Job Assets"
          showBackButton={true}
          showSearchButton={true}
          onSynced={() => loadJobAssets()}
          onSearch={(searchQuery, exactSN) => loadJobAssets(searchQuery, exactSN)}
          showExactSn={true} />
        <Controller
          control={control}
          rules={{ required: true }}
          name="subLocationId"
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={styles.sublocation__filter}>
              <Text style={styles.sublocation__text}>Sublocations</Text>
              <View style={styles.sublocation__picker__container}>

                {Platform.OS === 'ios' ? (
                  <RNPickerSelect
                    value={value}
                    onValueChange={(itemValue) => onChange(itemValue)}
                    items={subLocations.map((value) => ({ label: value.name, value: value.id }))}
                    style={styles}
                  />
                ) : (
                  <Picker
                    style={styles.picker__style}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                    itemStyle={{ height: 30 }}
                  >
                    <Picker.Item label="All" value={null} />
                    {subLocations != null &&
                      subLocations.map((value, index) => (
                        <Picker.Item key={value.id} label={value.name} value={value.id} />
                      ))
                    }
                  </Picker>
                )}

              </View>
              <TouchableOpacity onPress={applySublocationFilter} style={styles.filter__button}>
                <EvilIcons name="search" size={(isMobileScreen || isPortrait8Inch) ? 16 : 24} color="#FFF" />
              </TouchableOpacity>

            </View>
          )}
        />
        <View style={styles.sublocation__input__container}>
          <TextInput
            style={styles.sublocation__input}
            placeholder="Add Sublocation"
            value={sublocationName}
            onChangeText={(text) => setSublocationName(text)}
          />
          <TouchableOpacity onPress={addSublocation} style={styles.add__sublocation__button}>
            <Entypo size={24} color={"#FFF"} name="plus" />
          </TouchableOpacity>
        </View>



        {job != null &&
          <View style={styles.job__title}>
            <Text style={styles.job__title__text}>{job.jobTitle}</Text>
          </View>
        }

        {
          job != null &&
          <View>
            <View style={[styles.job__title, { marginBottom: 3 }]}>
              <TouchableOpacity onPress={() => addEquipment()} style={[styles.action__button, { backgroundColor: colours.primary }]}>
                <Entypo style={{ marginRight: isMobileScreen ? 5 : 10 }} size={isMobileScreen ? 16 : 24} color={"#FFF"} name="cog" />
                {isMobileScreen ? (
                  <Entypo style={{ marginRight: isMobileScreen ? 5 : 10 }} size={24} color={"#FFF"} name="plus" />
                ) : (
                  <Text style={styles.action__button__text}>Add Equipment</Text>
                )}
              </TouchableOpacity>

              <TouchableOpacity
                onPress={() => erstr()}
                style={[styles.action__button, job.engineerReportId == null
                  ? styles.rams__button__red
                  : styles.rams__button__green]}>
                {job.engineerReportId == null && <MaterialCommunityIcons
                  style={{ marginRight: isMobileScreen ? 2 : 10 }}
                  name="cancel" size={isMobileScreen ? 16 : 24}
                  color={"#FFF"} />}
                {job.engineerReportId != null && <MaterialCommunityIcons
                  style={{ marginRight: isMobileScreen ? 2 : 10 }}
                  name="check"
                  size={isMobileScreen ? 16 : 24}
                  color={"#FFF"} />}
                <Text style={styles.action__button__text}>ERSTR</Text>
              </TouchableOpacity>
              {isPOWRAEnabled == true &&
                <TouchableOpacity
                  onPress={() => rams()} style={[styles.action__button, job.ramsId == null
                    ? styles.rams__button__red
                    : styles.rams__button__green]}>
                  {job.ramsId == null && <MaterialCommunityIcons
                    style={{ marginRight: isMobileScreen ? 2 : 10 }}
                    name="cancel"
                    size={isMobileScreen ? 16 : 24}
                    color={"#FFF"} />}
                  {job.ramsId != null && <MaterialCommunityIcons
                    style={{ marginRight: isMobileScreen ? 2 : 10 }}
                    name="check"
                    size={isMobileScreen ? 16 : 24}
                    color={"#FFF"} />}
                  <Text style={styles.action__button__text}>PoWRA</Text>
                </TouchableOpacity>
              }
            </View>
            <View style={[styles.job__title, { marginBottom: 3 }]}>
              <TouchableOpacity onPress={() => loadTodo()} style={[
                styles.action__button,
                activeButton === 'todo' && styles.activeButton,
                { backgroundColor: '#3498db' },
              ]}>
                <FontAwesome5 style={{ marginRight: (isMobileScreen || isPortrait8Inch) ? 5 : 10 }} name="tasks" size={(isMobileScreen || isPortrait8Inch) ? 16 : 24} color={"#FFF"} />
                <Text style={styles.action__button__text}>To do</Text>
              </TouchableOpacity>

              <TouchableOpacity onPress={() => loadInspected()} style={[
                styles.action__button,
                activeButton === 'inspected' && styles.activeButton,
                { backgroundColor: '#1abb9c' },
              ]}>
                <AntDesign style={{ marginRight: (isMobileScreen || isPortrait8Inch) ? 5 : 10 }} size={(isMobileScreen || isPortrait8Inch) ? 16 : 24} color={"#FFF"} name="checkcircle" />
                <Text style={styles.action__button__text}>Inspected</Text>
              </TouchableOpacity>

              <TouchableOpacity onPress={() => loadNotPresented()} style={[
                styles.action__button,
                activeButton === 'notPresented' && styles.activeButton,
                { backgroundColor: '#9b59b6' },
              ]}>
                <AntDesign style={{ marginRight: (isMobileScreen || isPortrait8Inch) ? 5 : 10 }} name="questioncircle" size={(isMobileScreen || isPortrait8Inch) ? 16 : 24} color={"#FFF"} />
                <Text style={styles.action__button__text}>Not Presented</Text>
              </TouchableOpacity>
            </View>
            {selectedAssets.length > 0 && (
              <View style={styles.job__title}>
                <TouchableOpacity
                  style={styles.pass__button}
                  onPress={passSelectedAssets}
                >
                  <Text style={styles.pass__or__unselect__button__text}>Pass ({selectedAssets.length})</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[styles.pass__button, { backgroundColor: 'grey' }]}
                  onPress={archiveSelectedAssets}
                >
                  {(isMobileScreen || isPortrait8Inch) ?
                    <MaterialCommunityIcons style={{ marginRight: 5 }} name="archive-plus" size={20} color="white" /> :
                    <Text style={styles.pass__or__unselect__button__text}>Archive </Text>}
                  <Text style={styles.pass__or__unselect__button__text}>({selectedAssets.length})</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[styles.pass__button, { backgroundColor: 'grey' }]}
                  onPress={() => setScrapModalVisible(true)}
                >
                  {(isMobileScreen || isPortrait8Inch) ?
                    <Ionicons style={{ marginRight: 5 }} name="trash-bin" size={20} color="white" /> :
                    <Text style={styles.pass__or__unselect__button__text}>Scrap </Text>}
                  <Text style={styles.pass__or__unselect__button__text}>({selectedAssets.length})</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[styles.pass__button, { backgroundColor: 'grey' }]}
                  onPress={setMissingSelectedAssets}
                >
                  {(isMobileScreen || isPortrait8Inch) ?
                    <AntDesign style={{ marginRight: 5 }} name="questioncircle" size={22} color="white" /> :
                    <Text style={styles.pass__or__unselect__button__text}>Not presented</Text>}
                  <Text style={styles.pass__or__unselect__button__text}>({selectedAssets.length})</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.unselect_button}
                  onPress={() => setSelectedAssets([])}
                >
                  {(isMobileScreen || isPortrait8Inch) ? <Entypo name="circle-with-cross" size={20} color="white" /> :
                    <Text style={styles.pass__or__unselect__button__text}>Remove selection</Text>
                  }

                </TouchableOpacity>
              </View>
            )}
          </View>
        }
        {
          isMobileScreen ? (
            <FlashList
              data={sublocationFilterApplied ? filteredAssets : jobAssets}
              renderItem={renderItemCard}
              estimatedItemSize={57}
              extraData={selectedAssets}
            />
          ) : (
            <View style={styles.assets__container} >
              <View style={{ marginTop: 5, marginBottom: 5 }}>
                <View style={styles.row}>
                  <Text style={[styles.column, styles.head__column]}>Description</Text>
                  {isEquipmentFromOtherLocation && <Text style={[styles.column, styles.head__column]}>Current Location</Text>}
                  <Text style={[styles.column, styles.head__column]}>Category</Text>
                  <Text style={[styles.column, styles.head__column]}>Serial #</Text>
                  <Text style={[styles.column, styles.head__column]}>SWL</Text>
                  <Text style={[styles.column, styles.head__column]}>Next Inspection</Text>
                  {isPssr && <Text style={[styles.column, styles.head__column]}>Pssr inspections</Text>}
                  <View style={{ width: 40 }}></View>
                  <View style={{ width: 40 }}></View>
                </View>
              </View>
              <FlashList
                data={sublocationFilterApplied ? filteredAssets : jobAssets}
                renderItem={renderItem}
                estimatedItemSize={57}
                extraData={selectedAssets}
              />
            </View>
          )
        }
        {isModalVisible && (
          <Modal
            animationType="slide"
            transparent={false}
            visible={isModalVisible}
          >
            <ScrollView
              automaticallyAdjustKeyboardInsets={true}
            >
              <View style={[styles.step__question_first]}>
                <Text style={styles.step__question__label}>Move To Sub-Location</Text>
                {Platform.OS === 'ios' ? (
                  <RNPickerSelect
                    value={subLocationModalId}
                    onValueChange={handleSubLocationIdChange}
                    items={subLocations.map((value) => ({ label: value.name, value: value.id }))}
                    style={styles}
                  />
                ) : (
                  <Picker
                    style={styles.picker__style}
                    selectedValue={subLocationModalId}
                    onValueChange={(itemValue, itemIndex) => handleSubLocationIdChange(itemValue)}
                    itemStyle={{ height: 30 }}
                  >
                    <Picker.Item label="Please select " value={null} />
                    {subLocations != null &&
                      subLocations.map((value, index) => (
                        <Picker.Item key={value.id} label={value.name} value={value.id} />
                      ))
                    }
                  </Picker>
                )}
              </View>
              <Controller
                control={control}
                name="inspectionDate"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View style={[styles.step__question]}>
                    <Text style={styles.step__question__label}>Inspection Date:</Text>
                    <View style={styles.step__question__input}>
                      <DatePicker value={value} mode="date" onChanged={onChange} maximumDate={moment().toDate()} />
                    </View>
                  </View>
                )}
              />
              <Controller
                control={control}
                name="nextInspectionDate"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View style={[styles.step__question]}>
                    <Text style={styles.step__question__label}>Next Inspection Date:</Text>
                    <View style={styles.step__question__input}>
                      <DatePicker value={value} mode="date" onChanged={onChange} />
                    </View>
                  </View>
                )}
              />
              {isMarineMasterCategoryEquipment() && <Controller
                control={control}
                name="nextTestDate"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View style={[styles.step__question]}>
                    <View style={{ flexDirection: 'row', alignItems: 'stretch', justifyContent: 'space-between' }}>
                      <Text style={styles.step__question__label}>Next Test Date:</Text>
                      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={styles.step__question__label}>N/A:</Text>
                        <Checkbox
                          style={{ marginLeft: 10 }}
                          value={isMarineDisabled ? true : false}
                          onValueChange={(val) => updateMarine(val)}
                          color={colours.primary} />
                      </View>
                    </View>
                    {!isMarineDisabled && <View style={styles.step__question__input}>
                      <DatePicker value={value} mode="date" disabled={isMarineDisabled} onChanged={onChange} minimumDate={new Date()} />
                    </View>}
                  </View>
                )}
              />}
              <Controller
                control={control}
                rules={{ required: true }}
                name="purposeId"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View style={styles.step__question}>
                    <Text style={styles.step__question__label}>Purpose of Examination</Text>

                    {Platform.OS === 'ios' ? (
                      <RNPickerSelect
                        value={value}
                        onValueChange={(itemValue) => {
                          onChange(itemValue)
                          purposeInterval()
                        }}
                        items={purposes.map((value) => ({ label: value.purpose, value: value.id }))}
                        style={styles}
                      />
                    ) :
                      (<Picker
                        selectedValue={value}
                        onValueChange={(itemValue, itemIndex) => {
                          onChange(itemValue)
                          purposeInterval()
                        }}
                        itemStyle={{ height: 50 }}>

                        <Picker.Item label="Please Select" value="" />

                        {purposes != null && purposes.map((value, index) => {
                          return (
                            <Picker.Item key={value.id} label={value.purpose} value={value.id} />
                          )
                        })}
                      </Picker>)
                    }

                    {!getValues("purposeId") && <Text style={styles.input__validation}>Please select a response before continuing</Text>}
                  </View>
                )}
              />

              <Controller
                control={control}
                rules={{ required: true }}
                name="colourId"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View style={styles.step__question}>
                    <Text style={styles.step__question__label}>Colours</Text>

                    {Platform.OS === 'ios' ? (
                      <RNPickerSelect
                        value={value}
                        onValueChange={(itemValue) => onChange(itemValue)}
                        items={pickercolours.map((value) => ({ label: value.name, value: value.id }))}
                        style={styles}
                      />
                    ) :
                      (<Picker
                        selectedValue={value}
                        onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                        itemStyle={{ height: 50 }}>

                        <Picker.Item label="Please Select" value="" />

                        {pickercolours != null && pickercolours.map((value, index) => {
                          return (
                            <Picker.Item key={value.id} label={value.name} value={value.id} />
                          )
                        })}
                      </Picker>)
                    }

                    {!getValues("colourId") && <Text style={styles.input__validation}>Please select a response before continuing</Text>}
                  </View>
                )}
              />
              <Controller
                control={control}
                name="particulars"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View style={styles.step__question}>
                    <Text style={styles.step__question__label}>Particulars of any test carried out as part of this examination:</Text>

                    <TextInput
                      multiline={true}
                      style={styles.step__question__input}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}
                    />
                  </View>
                )}
              />
              <TouchableOpacity style={styles.complete__button} onPress={() => handlePassAll()}>
                <Text style={styles.complete__button__text}>Complete inspections</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.cancel__button} onPress={() => hideModal()}>
                <Text style={styles.complete__button__text}>Cancel</Text>
              </TouchableOpacity>
            </ScrollView>
          </Modal>
        )}

      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FAFAFA',
    padding: isMobileScreen ? 20 : 40,
    paddingTop: 60,
  },

  assets__container: {
    borderRadius: 10,
    height: '100%',
    width: '100%',
    flex: 1,
    padding: isTabletScreen ? 5 : 0,
    margin: isTabletScreen ? 5 : 0,
    marginTop: 0,
  },

  row: {
    marginBottom: 5,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  body: {
    flex: 0.85,
    marginLeft: 10,
    marginRight: 5,
    marginBottom: 10
  },


  card: {
    margin: 5,
  },
  card__text: {
    fontSize: 16,
  },

  boldt__text: {
    fontWeight: 'bold',
    fontSize: 16,
  },

  column: {
    flex: 1,
    flexDirection: 'row',
    padding: 7,
  },

  head__column: {
    padding: 7,
    paddingTop: 0,
    fontFamily: 'OpenSans-Bold',
    fontSize: 12,
  },

  body__column: {
    marginBottom: 5,
    backgroundColor: '#FFF',
    flexDirection: 'column',
  },

  job__title: {
    marginTop: (isMobileScreen || isPortrait8Inch) ? 0 : 2,
    marginBottom: (isMobileScreen || isPortrait8Inch) ? 1 : 2,
    backgroundColor: '#FFF',
    padding: (isMobileScreen || isPortrait8Inch) ? 0 : 5,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },

  job__title__text: {
    fontFamily: 'OpenSans-Bold',
    fontSize: 16,
    flex: 1,
  },

  spacer: {
    flex: 1,
  },

  job__text: {
    fontSize: 13,
  },

  form: {
    flex: 1,
    marginTop: 10,
  },

  sublocation__filter: {
    flexDirection: "row",
    padding: (isMobileScreen || isPortrait8Inch) ? 2 : 10,
    backgroundColor: '#FFF',
    marginBottom: (isMobileScreen || isPortrait8Inch) ? 2 : 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  sublocation__picker__container: {
    flex: 2,
  },
  picker__style: {
    flex: 1
  },
  sublocation__text: {
    flex: 1,
    fontFamily: 'OpenSans-Regular',
    fontSize: (isMobileScreen || isPortrait8Inch) ? 13 : 18,
    marginBottom: 5,
  },

  filter__button: {
    padding: 0,
    backgroundColor: config.colours.primary,
    height: (isMobileScreen || isPortrait8Inch) ? 25 : 35,
    width: (isMobileScreen || isPortrait8Inch) ? 25 : 35,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 0,
    paddingBottom: (isMobileScreen || isPortrait8Inch) ? 0 : 5,
  },

  add__equipment__button: {
    backgroundColor: colours.primary,
    padding: 10,
    borderRadius: 10,
    width: 190,
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
  },

  rams__button: {
    backgroundColor: '#CECECE',
    padding: 10,
    borderRadius: 10,
    width: 100,
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
  },

  rams__button__red: {
    backgroundColor: 'red'
  },

  rams__button__green: {
    backgroundColor: 'green'
  },

  action__button: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    justifyContent: "center",
    borderRadius: 10,
    width: (isMobileScreen || isPortrait8Inch) ? 120 : 190,
    height: (isMobileScreen || isPortrait8Inch) ? 25 : 40,
    textAlign: 'center',
    marginVertical: (isMobileScreen || isPortrait8Inch) ? 5 : 10,
    marginLeft: (isMobileScreen || isPortrait8Inch) ? 5 : 10,
  },

  action__button__text: {
    color: '#FFF',
    fontFamily: 'OpenSans-Bold',
    flexShrink: 1,
    fontSize: (isMobileScreen || isPortrait8Inch) ? 10 : 14,
  },

  complete__button: {
    backgroundColor: colours.primary,
    padding: 10,
    borderRadius: 10,
    textAlign: 'center',
    margin: 10
  },
  cancel__button: {
    backgroundColor: "red",
    padding: 10,
    borderRadius: 10,
    textAlign: 'center',
    margin: 10
  },

  complete__button__text: {
    fontFamily: 'OpenSans-Bold',
    fontSize: 22,
    color: '#FFF',
    textAlign: 'center',
  },

  sublocation__input: {
    flex: 2,
    paddingHorizontal: 10,
    height: (isMobileScreen || isPortrait8Inch) ? 25 : 30,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#888',
    marginRight: (isMobileScreen || isPortrait8Inch) ? 3 : 10,
  },
  sublocation__input__container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  add__sublocation__button: {
    padding: 0,
    backgroundColor: config.colours.primary,
    height: (isMobileScreen || isPortrait8Inch) ? 25 : 35,
    width: (isMobileScreen || isPortrait8Inch) ? 25 : 35,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: (isMobileScreen || isPortrait8Inch) ? 2 : 10,
  },
  pass__button: {
    flexDirection: 'row',
    backgroundColor: 'green',
    padding: (isMobileScreen || isPortrait8Inch) ? 3 : 5,
    borderRadius: 10,
    marginLeft: 6,
    marginTop: (isMobileScreen || isPortrait8Inch) ? 2 : 10,
    textAlign: 'center',
    alignSelf: 'center',
  },
  unselect_button: {
    backgroundColor: 'red',
    paddingVertical: isMobileScreen ? 4 : 5,
    paddingHorizontal: 18,
    borderRadius: 10,
    marginTop: isMobileScreen ? 2 : 10,
    marginLeft: 6,
    textAlign: 'center',
    alignSelf: 'center',
  },

  pass__or__unselect__button__text: {
    fontFamily: 'OpenSans-Bold',
    fontSize: isMobileScreen ? 14 : 16,
    color: '#FFF',
    textAlign: 'center',
  },
  selected__item: {
    borderBottomWidth: 2,
    marginBottom: 5,
    backgroundColor: '#EAEAEA',
    borderWidth: 2,
    borderColor: 'black',
  },

  unselected__item: {
    borderBottomWidth: 0,
    marginBottom: 5,
    backgroundColor: 'white',
    borderWidth: 0,
  },
  step__question_first: {
    padding: 20,
    paddingTop: 60,
    backgroundColor: '#FFF',
  },

  step__question: {
    padding: 20,
    backgroundColor: '#FFF',
  },

  step__question_flex: {
    flex: 1
  },

  step__question__label: {
    fontFamily: 'OpenSans-Light',
    fontSize: 18,
    marginBottom: 5,
  },

  step__question__input: {
    borderWidth: 1,
    borderColor: '#CECECE',
    padding: 10,
    marginTop: 5,
  },
  input__validation: {
    color: 'red',
    marginTop: 10
  },

  inputIOS: {
    fontSize: 15,
    paddingVertical: 9,
    paddingHorizontal: 9,
    borderWidth: 1,
    marginRight: 10,
    borderColor: 'gray',
    borderRadius: 4,
    color: 'black',
    paddingRight: 30 // to ensure the text is never behind the icon
  },
  disabled: {
    padding: 10,
    marginTop: 5,
    backgroundColor: '#CECECE',
    border: 'none'
  },
  activeButton: {
    borderWidth: 2,
    borderColor: 'black',
  },

  status__container: {
    padding: 5,
    paddingRight: 10
  }
})


