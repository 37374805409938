import { ScrollView, StyleSheet, Text, TextInput, View, TouchableOpacity, Alert, Image, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { Controller, useForm } from 'react-hook-form';
import { colours as importedColours } from '../../../../../../config';
import RNPickerSelect from 'react-native-picker-select';
import { Picker } from '@react-native-picker/picker';
import { TitleBar } from '@app/components/TitleBar';
import { setPssrData } from '@store/slices/pssr-slice';
import { useEffect, useState } from 'react';
import { PssrDatabase } from '@store/services/database/pssr-database';
import { RadioButtonGroup, RadioButtonItem } from 'expo-radio-button';


export default function PssrStep1({ navigation, route }: { navigation: any, route: any }) {
    const dispatch = useDispatch();
    const inspection = useSelector((state: RootState) => state.pssr);
    const job = useSelector((state: RootState) => state.job);
    const [pssrParts, setPssrParts] = useState(null);

    const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
        defaultValues: {
            examinationType: inspection.examinationType || '',
            testsMade: inspection.testsMade || '',
            protectiveDevicesState: inspection.protectiveDevicesState || '',
            protectiveDevicesStateComment: inspection.protectiveDevicesStateComment || '',
            pressureInspectionPartId: inspection.pressureInspectionPartId || '',
            conditionOfParts: inspection.conditionOfParts || 'In Order',
            conditionOfPartsDefect: inspection.conditionOfPartsDefect || ''
        }
    });

    useEffect(() => {
        if (inspection) {
            setValue("examinationType", inspection.examinationType || '');
            setValue("testsMade", inspection.testsMade || '');
            setValue("protectiveDevicesState", inspection.protectiveDevicesState || '');
            setValue("protectiveDevicesStateComment", inspection.protectiveDevicesStateComment || '');
            setValue("pressureInspectionPartId", inspection.pressureInspectionPartId || '');
            setValue("conditionOfParts", inspection.conditionOfParts || 'In Order');
            setValue("conditionOfPartsDefect", inspection.conditionOfPartsDefect || '');
        }
    }, [inspection, setValue]);

    useEffect(() => {
        const pssrDatabase = new PssrDatabase();

        const loadParts = async () => {
            var parts = await pssrDatabase.getPssrParts();
            setPssrParts(parts);
        }

        loadParts();
    }, [])

    const protectiveDevicesState = watch("protectiveDevicesState");
    const conditionOfParts = watch("conditionOfParts");
    const pressureInspectionPartId = watch("pressureInspectionPartId");


    const onSubmit = async data => {
        dispatch(setPssrData({
            examinationType: data.examinationType,
            testsMade: data.testsMade,
            protectiveDevicesState: data.protectiveDevicesState,
            protectiveDevicesStateComment: data.protectiveDevicesStateComment,
            pressureInspectionPartId: data.pressureInspectionPartId ? data.pressureInspectionPartId : null,
            conditionOfParts: data.conditionOfParts,
            conditionOfPartsDefect: data.conditionOfPartsDefect && conditionOfParts === "Defect" ? data.conditionOfPartsDefect : null
        }));

        navigation.navigate("PssrStep2");
    };


    return (
        <View style={styles.container}>

            <ScrollView
                automaticallyAdjustKeyboardInsets={true}
                style={styles.form}>
                <View style={{ alignContent: 'space-between' }}>
                    <View style={{ flex: 1 }}>
                        <TitleBar navigation={navigation} title={'Step 1 of 3'} showSearchButton={false} showBackButton={true} onSynced={null} onSearch={null} />
                        <Controller
                            control={control}
                            name="examinationType"
                            rules={{ required: true }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View style={styles.step__question}>
                                    <Text style={styles.step__question__label}>Test Type:</Text>

                                    {Platform.OS === "ios" ? (
                                        <View style={styles.form__input__IOS}>
                                            <RNPickerSelect
                                                value={value}
                                                onValueChange={(itemValue) => onChange(itemValue)}
                                                items={[
                                                    { label: "Thorough In Service", value: "Thorough In Service" },
                                                    { label: "Thorough Out of Service", value: "Thorough Out of Service" },
                                                    { label: "Both In Service and Out of Service", value: "Both In Service and Out of Service" },
                                                ]}
                                                style={styles}
                                            />
                                        </View>
                                    ) :
                                        (<Picker
                                            selectedValue={value}
                                            onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                                            itemStyle={{ height: 50 }}>

                                            <Picker.Item label="Please Select" value="" />
                                            <Picker.Item label="Thorough In Service" value="Thorough In Service" />
                                            <Picker.Item label="Thorough Out of Service" value="Thorough Out of Service" />
                                            <Picker.Item label="Both In Service and Out of Service" value="Both In Service and Out of Service" />
                                        </Picker>)
                                    }

                                    {errors.examinationType && <Text style={styles.input__validation}>Please select a response before continuing</Text>}
                                </View>
                            )}
                        />
                        <Controller
                            control={control}
                            name="testsMade"
                            rules={{ required: true }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View style={styles.step__question}>
                                    <Text style={styles.step__question__label}>Tests Made:</Text>
                                    <TextInput
                                        multiline={true}
                                        style={styles.step__question__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}
                                    />

                                    {errors.testsMade && <Text style={styles.input__validation}>Enter details</Text>}
                                </View>
                            )}
                        />
                        <Controller
                            control={control}
                            name="protectiveDevicesState"
                            rules={{ required: true }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View style={styles.step__question}>
                                    <Text style={styles.step__question__label}>Protective Devices: </Text>

                                    {Platform.OS === "ios" ? (
                                        <View style={styles.form__input__IOS}>
                                            <RNPickerSelect
                                                value={value}
                                                onValueChange={(itemValue) => onChange(itemValue)}
                                                items={[
                                                    { label: "In Order", value: "In Order" },
                                                    { label: "Removed and Tested", value: "Removed and Tested" },
                                                    { label: "Other", value: "Other" },
                                                ]}
                                                style={styles}
                                            />
                                        </View>
                                    ) :
                                        (<Picker
                                            selectedValue={value}
                                            onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                                            itemStyle={{ height: 50 }}>

                                            <Picker.Item label="Please Select" value="" />
                                            <Picker.Item label="In Order" value="In Order" />
                                            <Picker.Item label="Removed and Tested" value="Removed and Tested" />
                                            <Picker.Item label="Other" value="Other" />
                                        </Picker>)
                                    }

                                    {errors.examinationType && <Text style={styles.input__validation}>Please select a response before continuing</Text>}
                                </View>
                            )}
                        />
                        {protectiveDevicesState === "Other" && <Controller
                            control={control}
                            name="protectiveDevicesStateComment"
                            rules={{ required: true }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View style={styles.step__question}>
                                    <Text style={styles.step__question__label}>Protective Devices Comment:</Text>
                                    <TextInput
                                        multiline={true}
                                        style={styles.step__question__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}
                                    />

                                    {errors.protectiveDevicesStateComment && <Text style={styles.input__validation}>Enter details</Text>}
                                </View>
                            )}
                        />}
                        <Controller
                            control={control}
                            name="pressureInspectionPartId"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View style={styles.step__question}>
                                    <Text style={styles.step__question__label}>{"What Parts (If Any) Were Inaccessible?"}</Text>

                                    {Platform.OS === "ios" ? (
                                        <View style={styles.form__input__IOS}>
                                            <RNPickerSelect
                                                value={value}
                                                onValueChange={(itemValue) => onChange(itemValue)}
                                                items={pssrParts != null && pssrParts.map((value) => ({ label: value.name, value: value.id }))}
                                                style={styles}
                                            />
                                        </View>
                                    ) : (
                                        <Picker
                                            selectedValue={value}
                                            onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                                            itemStyle={{ height: 50 }}>

                                            <Picker.Item label="None" value="" />

                                            {pssrParts != null && pssrParts.map((value, index) => {
                                                return (
                                                    <Picker.Item key={value.id} label={value.name} value={value.id} />
                                                )
                                            })}
                                        </Picker>
                                    )}
                                </View>
                            )}
                        />
                        {pressureInspectionPartId &&
                            <>
                                <Controller
                                    control={control}
                                    name="conditionOfParts"
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value } }) => (
                                        <View style={styles.step__question}>
                                            <Text style={styles.step__question__label}>Condition of Parts:</Text>
                                            <RadioButtonGroup
                                                containerStyle={{ marginTop: 10, flexDirection: 'row' }}
                                                radioBackground={importedColours.primary}
                                                selected={value}
                                                onSelected={onChange}>
                                                <RadioButtonItem value="In Order" label="In Order" />
                                                <RadioButtonItem value="Defect" label="Defect" style={{ marginLeft: 20 }} />
                                            </RadioButtonGroup>
                                            {errors.conditionOfParts && <Text style={styles.input__validation}>Please select a response</Text>}
                                        </View>
                                    )}
                                />

                                {conditionOfParts === 'Defect' && (
                                    <Controller
                                        control={control}
                                        name="conditionOfPartsDefect"
                                        rules={{ required: conditionOfParts === 'Defect' }}
                                        render={({ field: { onChange, value } }) => (
                                            <View style={styles.step__question}>
                                                <Text style={styles.step__question__label}>Provide details for the defect:</Text>
                                                <TextInput
                                                    multiline
                                                    style={styles.step__question__input}
                                                    onChangeText={onChange}
                                                    value={value}
                                                    placeholder="Enter details about the defect"
                                                />
                                                {errors.conditionOfPartsDefect && <Text style={styles.input__validation}>This field is required</Text>}
                                            </View>
                                        )}
                                    />
                                )}</>
                        }

                    </View>
                    <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                        <TouchableOpacity style={[styles.proceed__button, {}]} onPress={handleSubmit(onSubmit)}>
                            <Text style={styles.proceed__button__text}>NEXT</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        ...Platform.select({
            android: { flex: 1, padding: 20, paddingTop: 60 },
            ios: { flex: 1, padding: 20, paddingTop: 60 },
            web: { padding: 20, paddingBottom: 0, paddingTop: 0 },
        }),
        backgroundColor: '#FAFAFA'
    },

    form: {
        flex: 1,
        marginTop: 10,
    },

    step__question: {
        padding: 20,
        backgroundColor: '#FFF',
        marginBottom: 20,
    },

    step__question__label: {
        fontFamily: 'OpenSans-Light',
        fontSize: 18,
        marginBottom: 5,
    },
    step__question__input: {
        borderWidth: 1,
        borderColor: '#CECECE',
        padding: 10,
        marginTop: 5,
    },

    input__validation: {
        color: 'red',
        marginTop: 10
    },

    proceed__button: {
        padding: 10,
        backgroundColor: importedColours.primary,
        marginTop: 20,
        borderRadius: 7,
        height: 45,
        alignSelf: 'flex-end',


        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },

    proceed__button__text: {
        fontFamily: 'OpenSans-Bold',
        color: '#FFF',
        textAlign: 'center',
        fontSize: 18
    },
    inputIOS: {
        fontSize: 15,
        paddingVertical: 9,
        paddingHorizontal: 9,
        borderWidth: 1,
        marginRight: 10,
        borderColor: 'gray',
        borderRadius: 4,
        color: 'black',
        paddingRight: 30 // to ensure the text is never behind the icon
    },

    question: {
        margin: 5,
        padding: 10,
        backgroundColor: '#FFF',
    },

    question__label: {
        fontWeight: 'bold',
        fontSize: 20,
    },

    question__input: {
        borderWidth: 1,
        borderColor: '#CECECE',
        padding: 10,
        marginTop: 5,
    },

    image__container: {
        flexDirection: 'row',
        padding: 10,
        marginBottom: 20,
        alignItems: 'center'
    },

    // image__block: {
    //   flex: 1,
    // },

    image: {
        height: 150,
        width: 150,
        marginRight: 20,
        marginLeft: 20,
    },

    empty__image: {
        borderWidth: 1,
        borderColor: '#CECECE'
    },
    form__input__IOS: {
        flex: 2,
        fontFamily: 'OpenSans-Regular',
        padding: 5,
        marginTop: 5,
    },
});
