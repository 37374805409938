import * as React from "react";
import * as DocumentPicker from "expo-document-picker";
import {convertBase64toBlob} from "@components/helpers/Extensions";
import {ActivityIndicator, FlatList, Modal, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {Ionicons, SimpleLineIcons} from "@expo/vector-icons";
import {DataTable} from "react-native-paper";
import GlobalStyle, {PRIMARY_COLOUR} from "../../../constants/GlobalStyle";
import {EmptyListIndicator} from "@components/FlatListComponents";

interface FileManagerProps {
  idName: string
  entityId: string,
  onCancel: any,
  useAddFileMutation: any,
  useRemoveFileMutation: any,
  useLazyGetFilesQuery: any
}

export const FileManager = (
  { idName,
    entityId,
    onCancel,
    useAddFileMutation,
    useRemoveFileMutation,
    useLazyGetFilesQuery }: FileManagerProps) => {

  const [trigger, {data: loadedFiles, isFetching: isFilesFetching}] = useLazyGetFilesQuery;
  const [addFile, {error: addingError, isError: isAddingError, isLoading: isAdding}] = useAddFileMutation;
  const [removeFile, removeFileResult] = useRemoveFileMutation;

  React.useEffect(() => {
    if (entityId == null) return;
    trigger(entityId);
  }, [entityId])

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve({
      base64: reader.result,
      fileName: file.name
    });
    reader.onerror = error => reject(error);
  });

  const uploadFile = async () => {
    if (isAdding) return;
    var result = await DocumentPicker.getDocumentAsync({
      type: ".pdf"
    }) as any;

    const file = result.assets[0].file;

    const maxSizeInBytes = 1 * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      alert("File size exceeds 1MB limit");
      return;
    }

    var base64File = await toBase64(file) as any;

    var dto = {
      [idName]: entityId,
      fileName: base64File.fileName,
      base64: base64File.base64
    }

    var result = null;
    result = await addFile(dto) as any;
    if (result.error) {
      console.log(result);
      return;
    }

    trigger(entityId);
  }

  const download = (file) => {
    let blob = convertBase64toBlob(file.base64, file.mimeType);

    const fileSaver = require('file-saver');
    fileSaver(blob, file.fileName);
  }

  const remove = async (file) => {
    if (confirm("Are you sure you want to delete this file?")) {
      var result = null;
      result = await removeFile({[idName]: entityId, fileName: file.fileName}) as any;
      if (result.error) {
        console.log(result);
        return;
      }

      trigger(entityId);
    }
  }

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={entityId != null}>

      <View style={styles.modal__block}>
        <View style={styles.modal}>
          <View style={{flexDirection: 'row', borderBottomColor: '#CECECE', borderBottomWidth: 1, paddingBottom: 5}}>
            <Text style={{fontWeight: 'bold', fontSize: 18, flex: 1}}>File Manager</Text>
            <TouchableOpacity onPress={onCancel}>
              <Ionicons name="close-circle-sharp" size={24} color="black"/>
            </TouchableOpacity>
          </View>

          <TouchableOpacity
            style={styles.upload__button}
            onPress={() => uploadFile()}>
            {isAdding ? (
              <View style={{paddingVertical: 4}}>
                <ActivityIndicator size="small" color='#fff'/>
              </View>
            ) : (
              <Text style={styles.upload__button__text}>Upload File</Text>
            )}
          </TouchableOpacity>


          {isAddingError && (
            <Text style={styles.errorText}>{addingError?.data}</Text>
          )}

          <DataTable style={GlobalStyle.table}>
            <DataTable.Header>
              <DataTable.Title>Name</DataTable.Title>
              <DataTable.Title>Download</DataTable.Title>
              <DataTable.Title>Delete</DataTable.Title>
            </DataTable.Header>

            <FlatList
              refreshing={isFilesFetching}
              data={loadedFiles}
              ListEmptyComponent={
                <EmptyListIndicator
                  isLoading={isFilesFetching}
                  emptyText='There are no files.' />
              }
              renderItem={(item =>
                <DataTable.Row key={item.item.fileName}>
                  <DataTable.Cell>{item.item.fileName}</DataTable.Cell>
                  <DataTable.Cell>
                    <TouchableOpacity onPress={() => download(item.item)}>
                      <SimpleLineIcons name="cloud-download" size={18} color="black"/>
                    </TouchableOpacity>
                  </DataTable.Cell>
                  <DataTable.Cell>
                    <TouchableOpacity onPress={() => remove(item.item)}>
                      <SimpleLineIcons name="trash" size={18} color="black"/>
                    </TouchableOpacity>
                  </DataTable.Cell>
                </DataTable.Row>
              )} />
          </DataTable>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  upload__button: {
    marginBottom: 10,
    backgroundColor: PRIMARY_COLOUR,
    width: 200,
    marginTop: 10,
  },
  upload__button__text: {
    fontFamily: 'OpenSans-Bold',
    padding: 5,
    color: '#FFF',
    textAlign: 'center',
    fontSize: 14,
  },
  modal__block: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  modal: {
    backgroundColor: '#FFF',
    padding: 10,
    width: 600,
    borderRadius: 5,

    flexDirection: 'column',
  },
  errorText: {
    color: 'red',
    marginBottom: 8
  },
});
