import { ScrollView, StyleSheet, Text, View, TouchableOpacity, Platform, Dimensions, Alert, Image } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { Controller, useForm } from 'react-hook-form';
import { colours as importedColours } from '../../../../../../config';
import { TitleBar } from '@app/components/TitleBar';
import { addCompletedExamination, setPssrData } from '@store/slices/pssr-slice';
import { DatePicker } from '@app/components/DatePicker';
import moment from 'moment';
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { useEffect, useState } from 'react';
import { CameraModal } from '@app/components/modals/CameraModal';
import { CategoryDatabase } from '@store/services/database/category-database';


const isMobileScreen = Dimensions.get("screen").width < 480;

export default function PssrStep3({ navigation, route, submitHandler }: { navigation: any, route: any, submitHandler: any }) {
    const dispatch = useDispatch();
    const inspection = useSelector((state: RootState) => state.pssr);
    const job = useSelector((state: RootState) => state.job);
    const [showCamera, setShowCamera] = useState(false);
    const [images, setImages] = useState([]);

    const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
        defaultValues: {
            safeToUse: inspection.safeToUse,
            inServiceInspectionDate: inspection.inServiceInspectionDate ? moment(inspection.inServiceInspectionDate).toDate() : moment().toDate(),
            inServiceNextInspectionDate: inspection.inServiceNextInspectionDate ? moment(inspection.inServiceNextInspectionDate).toDate() : moment().toDate(),
            outOfServiceInspectionDate: inspection.outOfServiceInspectionDate ? moment(inspection.outOfServiceInspectionDate).toDate() : moment().toDate(),
            outOfServiceNextInspectionDate: inspection.outOfServiceNextInspectionDate ? moment(inspection.outOfServiceNextInspectionDate).toDate() : moment().toDate(),
        }
    });


    useEffect(() => {
        if (inspection.id !== null) {
            setValue("safeToUse", inspection.safeToUse || true);
            setValue("inServiceInspectionDate", moment(inspection.inServiceInspectionDate).toDate() || moment().toDate());
            setValue("inServiceNextInspectionDate", moment(inspection.inServiceNextInspectionDate).toDate() || moment().toDate());
            setValue("outOfServiceInspectionDate", moment(inspection.outOfServiceInspectionDate).toDate() || moment().toDate());
            setValue("outOfServiceNextInspectionDate", moment(inspection.outOfServiceNextInspectionDate).toDate() || moment().toDate());
            setImages([...inspection.images]);
        }
    }, [inspection]);



    const isBothExaminationType = inspection?.examinationType == "Both In Service and Out of Service";
    const isInServiceInspection = inspection?.examinationType == "Thorough In Service";

    const safeToUse = watch("safeToUse");

    const inspectionDate = isInServiceInspection ? watch('inServiceInspectionDate') : watch('outOfServiceInspectionDate');

    const onSubmit = async data => {
        if (safeToUse == null) {
            Alert.alert('Inspection', `Are you sure this is a ${safeToUse ? 'PASS' : 'FAIL'}`, [
                {
                    text: 'NO',
                },
                { text: 'YES', onPress: () => saveInspection(data) },
            ]);
        } else {
            saveInspection(data);
        }
    };

    const saveInspection = async (data) => {
        var inspectionData = { ...inspection };

        if (isBothExaminationType) {
            inspectionData.inServiceInspectionDate = moment(data.inServiceInspectionDate).toISOString();
            inspectionData.inServiceNextInspectionDate = moment(data.inServiceNextInspectionDate).toISOString();
            inspectionData.outOfServiceInspectionDate = moment(data.outOfServiceInspectionDate).toISOString();
            inspectionData.outOfServiceNextInspectionDate = moment(data.outOfServiceNextInspectionDate).toISOString();
        } else {
            inspectionData.inServiceInspectionDate = isInServiceInspection ? moment(data.inServiceInspectionDate).toISOString() : null;
            inspectionData.inServiceNextInspectionDate = isInServiceInspection ? moment(data.inServiceNextInspectionDate).toISOString() : null;
            inspectionData.outOfServiceInspectionDate = !isInServiceInspection ? moment(data.outOfServiceInspectionDate).toISOString() : null;
            inspectionData.outOfServiceNextInspectionDate = !isInServiceInspection ? moment(data.outOfServiceNextInspectionDate).toISOString() : null;
        }

        inspectionData.safeToUse = data.safeToUse;

        dispatch(addCompletedExamination(inspectionData.examinationType));

        submitHandler(inspectionData, images);
    }

    useEffect(() => {
        const updateNextInspectionDates = () => {
            let nextInspectionDate = moment(inspectionDate);
            const categoryDatabase = new CategoryDatabase();

            categoryDatabase.getById(job.categoryId).then(category => {
                if (isBothExaminationType) {
                    if (category.inServiceInterval) {
                        setValue('inServiceNextInspectionDate', nextInspectionDate.add(category.inServiceInterval, 'M').toDate());
                    } else {
                        setValue('inServiceNextInspectionDate', nextInspectionDate.add(category.inspectionInterval, 'M').toDate());
                    }

                    if (category.outOfServiceInterval) {
                        nextInspectionDate = moment(inspectionDate);
                        setValue('outOfServiceNextInspectionDate', nextInspectionDate.add(category.outOfServiceInterval, 'M').toDate());
                    } else {
                        nextInspectionDate = moment(inspectionDate);
                        setValue('outOfServiceNextInspectionDate', nextInspectionDate.add(category.inspectionInterval, 'M').toDate());
                    }
                } else if (isInServiceInspection) {
                    if (category.inServiceInterval) {
                        setValue('inServiceNextInspectionDate', nextInspectionDate.add(category.inServiceInterval, 'M').toDate());
                    } else {
                        setValue('inServiceNextInspectionDate', nextInspectionDate.add(category.inspectionInterval, 'M').toDate());
                    }
                } else {
                    if (category.outOfServiceInterval) {
                        setValue('outOfServiceNextInspectionDate', nextInspectionDate.add(category.outOfServiceInterval, 'M').toDate());
                    } else {
                        setValue('outOfServiceNextInspectionDate', nextInspectionDate.add(category.inspectionInterval, 'M').toDate());
                    }
                }
            });
        };

        if (safeToUse !== null && !safeToUse) {
            if (isBothExaminationType) {
                setValue("inServiceNextInspectionDate", moment(inspectionDate).toDate());
                setValue("outOfServiceNextInspectionDate", moment(inspectionDate).toDate());
            } else if (isInServiceInspection) {
                setValue("inServiceNextInspectionDate", moment(inspectionDate).toDate());
            } else {
                setValue("outOfServiceNextInspectionDate", moment(inspectionDate).toDate());
            }
        } else {
            updateNextInspectionDates();
        }
    }, [safeToUse, inspectionDate]);


    const addImage = (image) => {
        setImages([...images, image]);
        setShowCamera(false);
    }

    const removeImage = (image) => {
        Alert.alert("Images", "Are you sure you want to remove this image?", [
            {
                text: 'No',
                style: 'cancel'
            },
            { text: 'Yes', onPress: () => { setImages(images.filter(i => i !== image)) } }
        ])
    }

    const loadCamera = () => {
        if (images.length >= 3) {
            Alert.alert("You have reached the maximum images.")
            return;
        }

        setShowCamera(true);
    }

    if (showCamera) {
        return (
            <CameraModal onCancel={() => setShowCamera(false)} onPictureTaken={(image) => addImage(image)} />
        )
    }

    return (
        <View style={styles.container}>
            <ScrollView
                automaticallyAdjustKeyboardInsets={true}
                style={styles.form}>
                <View style={{ flex: 1 }}>
                    <TitleBar navigation={navigation} title={'Step 3 of 3'} showSearchButton={false} showBackButton={true} onSynced={null} onSearch={null} />
                    {isBothExaminationType ? (
                        <View>
                            <Controller
                                control={control}
                                name="inServiceInspectionDate"
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <View style={[styles.step__question]}>
                                        <Text style={styles.step__question__label}>In Service Inspection Date</Text>
                                        <View style={styles.step__question__input}>
                                            <DatePicker value={value} mode="date" onChanged={onChange} maximumDate={moment().toDate()} />
                                        </View>
                                    </View>
                                )}
                            />
                            <Controller
                                control={control}
                                name="inServiceNextInspectionDate"
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <View style={[styles.step__question]}>
                                        <Text style={styles.step__question__label}>In Service Next Inspection Date</Text>
                                        <View style={styles.step__question__input}>
                                            <DatePicker value={value} mode="date" onChanged={onChange} minimumDate={moment().toDate()} />
                                        </View>
                                    </View>
                                )}
                            />
                            <Controller
                                control={control}
                                name="outOfServiceInspectionDate"
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <View style={[styles.step__question]}>
                                        <Text style={styles.step__question__label}>Out Of Service Inspection Date</Text>
                                        <View style={styles.step__question__input}>
                                            <DatePicker value={value} mode="date" onChanged={onChange} maximumDate={moment().toDate()} />
                                        </View>
                                    </View>
                                )}
                            />
                            <Controller
                                control={control}
                                name="outOfServiceNextInspectionDate"
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <View style={[styles.step__question]}>
                                        <Text style={styles.step__question__label}>Out Of Service Next Inspection Date</Text>
                                        <View style={styles.step__question__input}>
                                            <DatePicker value={value} mode="date" onChanged={onChange} minimumDate={moment().toDate()} />
                                        </View>
                                    </View>
                                )}
                            />
                        </View>
                    ) : (
                        <View>
                            {isInServiceInspection ? (
                                <View>
                                    <Controller
                                        control={control}
                                        name="inServiceInspectionDate"
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <View style={[styles.step__question]}>
                                                <Text style={styles.step__question__label}>In Service Inspection Date</Text>
                                                <View style={styles.step__question__input}>
                                                    <DatePicker value={value} mode="date" onChanged={onChange} maximumDate={moment().toDate()} />
                                                </View>
                                            </View>
                                        )}
                                    />
                                    <Controller
                                        control={control}
                                        name="inServiceNextInspectionDate"
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <View style={[styles.step__question]}>
                                                <Text style={styles.step__question__label}>In Service Next Inspection Date</Text>
                                                <View style={styles.step__question__input}>
                                                    <DatePicker value={value} mode="date" onChanged={onChange} minimumDate={moment().toDate()} />
                                                </View>
                                            </View>
                                        )}
                                    />
                                </View>
                            ) : (
                                <View>
                                    <Controller
                                        control={control}
                                        name="outOfServiceInspectionDate"
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <View style={[styles.step__question]}>
                                                <Text style={styles.step__question__label}>Out Of Service Inspection Date</Text>
                                                <View style={styles.step__question__input}>
                                                    <DatePicker value={value} mode="date" onChanged={onChange} maximumDate={moment().toDate()} />
                                                </View>
                                            </View>
                                        )}
                                    />
                                    <Controller
                                        control={control}
                                        name="outOfServiceNextInspectionDate"
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <View style={[styles.step__question]}>
                                                <Text style={styles.step__question__label}>Out Of Service Next Inspection Date</Text>
                                                <View style={styles.step__question__input}>
                                                    <DatePicker value={value} mode="date" onChanged={onChange} minimumDate={moment().toDate()} />
                                                </View>
                                            </View>
                                        )}
                                    />
                                </View>
                            )}
                        </View>
                    )}
                    <View style={styles.image__container}>
                        <TouchableOpacity onPress={() => loadCamera()} style={{ paddingLeft: 30, paddingRight: 30 }}>
                            <Ionicons name="camera" size={92} color="black" />
                        </TouchableOpacity>

                        {images.map((image, idx) => {
                            return (
                                <TouchableOpacity key={idx} onPress={() => removeImage(image)}>
                                    <Image
                                        style={[styles.image]}
                                        resizeMode={"contain"}
                                        source={{
                                            uri: image,
                                        }}
                                    />
                                </TouchableOpacity>
                            )
                        })}
                    </View>
                    <Controller
                        control={control}
                        name="safeToUse"
                        render={({ field: { onChange, onBlur, value } }) => (
                            <View style={styles.safe__block}>
                                <TouchableOpacity style={[styles.safe__button, styles.not_safe, safeToUse == false && styles.unsafe_checked]} onPress={() => onChange(false)}>
                                    <MaterialCommunityIcons name="cancel" size={isMobileScreen ? 32 : 44} color={safeToUse == false ? 'white' : 'black'} />
                                    <Text style={[styles.safe__button__text, safeToUse == false && styles.safe__button__text__checked]}>FAIL</Text>
                                </TouchableOpacity>

                                <TouchableOpacity style={[styles.safe__button, styles.safe, safeToUse == true && styles.safe_checked]} onPress={() => onChange(true)}>
                                    <Ionicons name="checkmark-circle-outline" size={isMobileScreen ? 32 : 44} color={safeToUse == true ? 'white' : 'black'} />
                                    <Text style={[styles.safe__button__text, safeToUse == true && styles.safe__button__text__checked]}>PASS</Text>
                                </TouchableOpacity>
                            </View>
                        )}
                    />

                </View>
            </ScrollView>
            <View style={{ justifyContent: 'flex-end' }}>
                <TouchableOpacity style={[styles.proceed__button, {}]} onPress={handleSubmit(onSubmit)}>
                    <Text style={styles.proceed__button__text}>Save</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        ...Platform.select({
            android: { flex: 1, padding: 20, paddingTop: 60 },
            ios: { flex: 1, padding: 20, paddingTop: 60 },
            web: { padding: 20, paddingBottom: 0, paddingTop: 0 },
        }),
        backgroundColor: '#FAFAFA'
    },

    form: {
        flex: 1,
        height: "100%",
        marginTop: 10,
    },

    step__question: {
        padding: 20,
        backgroundColor: '#FFF',
        marginBottom: 20,
    },

    step__question__label: {
        fontFamily: 'OpenSans-Light',
        fontSize: 18,
        marginBottom: 5,
    },
    step__question__input: {
        borderWidth: 1,
        borderColor: '#CECECE',
        padding: 10,
        marginTop: 5,
    },

    input__validation: {
        color: 'red',
        marginTop: 10
    },

    proceed__button: {
        padding: 10,
        backgroundColor: importedColours.primary,
        marginTop: 20,
        borderRadius: 7,
        height: 45,
        alignSelf: 'flex-end',


        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },

    proceed__button__text: {
        fontFamily: 'OpenSans-Bold',
        color: '#FFF',
        textAlign: 'center',
        fontSize: 18
    },
    inputIOS: {
        fontSize: 15,
        paddingVertical: 9,
        paddingHorizontal: 9,
        borderWidth: 1,
        marginRight: 10,
        borderColor: 'gray',
        borderRadius: 4,
        color: 'black',
        paddingRight: 30 // to ensure the text is never behind the icon
    },

    question: {
        margin: 5,
        padding: 10,
        backgroundColor: '#FFF',
    },

    question__label: {
        fontWeight: 'bold',
        fontSize: 20,
    },

    question__input: {
        borderWidth: 1,
        borderColor: '#CECECE',
        padding: 10,
        marginTop: 5,
    },

    image__container: {
        flexDirection: 'row',
        padding: 10,
        marginBottom: 20,
        alignItems: 'center'
    },

    // image__block: {
    //   flex: 1,
    // },

    image: {
        height: 150,
        width: 150,
        marginRight: 20,
        marginLeft: 20,
    },

    empty__image: {
        borderWidth: 1,
        borderColor: '#CECECE'
    },
    form__input__IOS: {
        flex: 2,
        fontFamily: 'OpenSans-Regular',
        padding: 5,
        marginTop: 5,
    },
    safe__block: {
        flexDirection: 'row',
    },

    safe__button: {
        flex: 1,
        height: 140,
        backgroundColor: '#edebeb',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        borderRadius: 5,
        padding: isMobileScreen ? 10 : 0
    },

    safe__button__text: {
        fontFamily: 'OpenSans-Bold',
        marginLeft: 10,
        fontSize: isMobileScreen ? 20 : 38
    },

    not_safe: {
        marginRight: 10,
    },

    safe: {
        marginLeft: 10,
    },

    unsafe_checked: {
        backgroundColor: 'red',
    },

    safe_checked: {
        backgroundColor: 'green',
    },

    safe__button__text__checked: {
        color: '#FFF'
    },
});
