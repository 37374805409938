import { createNativeStackNavigator } from '@react-navigation/native-stack';
import CustomersListScreen from './CustomersList';
import CustomerManageScreen from './CustomerManage';
import CustomerDashboardScreen from './Dashboard/CustomerDashboard';

const Stack = createNativeStackNavigator();

export default function Customers({ navigation }: { navigation: any }) {
  return (
    <Stack.Navigator>
      <Stack.Screen name="list" component={CustomersListScreen} options={{ headerShown: false, title: 'Customers'  }} />
      <Stack.Screen name="manage" component={CustomerManageScreen} options={{ headerShown: false, title: 'Customer Manage'}}  />
      <Stack.Screen name="dashboard" component={CustomerDashboardScreen} options={{ headerShown: false, title: 'Customer Manage'}}  />
    </Stack.Navigator>
  )
};