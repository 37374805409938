import { baseApi, PagedTableDto } from './base-api'

export const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getEngineers: build.query<any, any>({
      query: () => ({
        url: `users/getengineers`,
      }),
    }),
    getOrderedEngineers: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `users/getengineers?${dto.getFilters()}`,
      }),
    }),
    getUsers: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `users/getUsers?${dto.getFilters()}`,
      }),
    }),
    getUser: build.query<any, any>({
      query: (userId) => ({
        url: `users/getuser/${userId}`,
      }),
    }),
    createUser: build.mutation<any, any>({
      query: (body) => ({
        url: 'users/adduser',
        method: 'post',
        body: body
      }),
    }),
    updateUser: build.mutation<any, any>({
      query: (body) => ({
        url: `users/updateuser/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    updateCustomerId: build.mutation<any, any>({
      query: (body) => ({
        url: `users/updatecustomerid/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    deleteUser: build.mutation<any, any>({
      query: (partId) => ({
        url: `users/deleteuser/${partId}`,
        method: 'DELETE',
      }),
    }),
    getCurrentDocUser: build.query<any, any>({
      query: () => ({
        url: "users/getcurrentdocuser",
      }),
    }),
    updateDocUser: build.mutation<any, any>({
      query: (body) => ({
        url: `users/updateDocUser/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
  }),
  overrideExisting: true,
})

export const {
  useGetEngineersQuery,
  useGetOrderedEngineersQuery,
  useGetUsersQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useUpdateCustomerIdMutation,
  useGetCurrentDocUserQuery,
  useUpdateDocUserMutation
} = userApi