import * as SQLite from 'expo-sqlite';
import { Database } from './database';
import uuid from 'react-native-uuid';
import { addColumnIfNotExist } from '../../../helpers/database-helpers';

export class ServiceDatabase extends Database {
  TABLE_NAME = "ServiceInspections";
  TABLE_NAME_ANSWERED_QUESTIONS = "ServiceInspectionsAnsweredQuestions";
  TABLE_NAME_QUESTIONS = "ServiceQuestions";
  TABLE_NAME_QUESTIONS_RESPONSES = "ServiceQuestionsResponses";
  TABLE_NAME_QUESTION_CATEGORIES = "ServiceQuestionCategories";
  // TABLE_NAME_IMAGES = "ServiceInspectionImages";

  initialise(): Promise<void> {
    return new Promise((resolve, reject) => {
      var sql = `CREATE TABLE IF NOT EXISTS ServiceInspections ( 
        id TEXT PRIMARY KEY,
        inspectionDate TEXT,
        nextInspectionDate TEXT,
        safeforUse INT,

        equipmentId TEXT,
        engineerId TEXT,
        jobId TEXT,
        purposeId TEXT,
        colourId TEXT,
        missing INT,
        toBeSynced INT
      )`;

      var sqlAnsweredQuestions = `CREATE TABLE IF NOT EXISTS ServiceInspectionsAnsweredQuestions ( 
        id TEXT PRIMARY KEY,
        questionId TEXT,
        response INT,
        comments TEXT,
        serviceInspectionId TEXT,
        imageBase64 TEXT
      )`;

      var sqlQuestions = `CREATE TABLE IF NOT EXISTS ServiceQuestions ( 
        id TEXT PRIMARY KEY,
        position INT,
        question TEXT,
        suffix TEXT,
        serviceQuestionCategoryId TEXT,
        isDeleted INT
      )`;

      var sqlQuestionsResponses = `CREATE TABLE IF NOT EXISTS ServiceQuestionsResponses ( 
        id TEXT PRIMARY KEY,
        response INT,
        commentMandatory INT,
        serviceQuestionId TEXT,
        position INT,
        isDeleted INT,
        isPassDefault INT
      )`;

      var sqlQuestionCategories = `CREATE TABLE IF NOT EXISTS ServiceQuestionCategories ( 
        id TEXT PRIMARY KEY,
        position INT,
        title TEXT,
        isDeleted INT,
        categoryId TEXT
      )`;

      var sqlImages = `CREATE TABLE IF NOT EXISTS InspectionImages (
        id TEXT PRIMARY KEY,
        inspectionId TEXT,
        base64 TEXT
      )`

      Database.getDb().transaction((tx) => {
        tx.executeSql(sql);
        tx.executeSql(sqlAnsweredQuestions);
        tx.executeSql(sqlQuestions);
        tx.executeSql(sqlQuestionsResponses);
        tx.executeSql(sqlQuestionCategories);
        tx.executeSql(sqlImages);

        var checkColumnSql = "PRAGMA table_info(ServiceQuestionCategories)";
        tx.executeSql(checkColumnSql, [], (_, { rows: { _array } }) => {
          const columns = _array.map(column => column.name);

          const columnsToAdd = [];
          if (!columns.includes("categoryId")) {
            columnsToAdd.push("categoryId TEXT");
          }

          if (!columns.includes("toBeSynced")) {
            columnsToAdd.push("toBeSynced INT");
          }

          if (columnsToAdd.length > 0) {
            const alterSql = `ALTER TABLE ServiceQuestionCategories ADD COLUMN ${columnsToAdd.length === 1 ? columnsToAdd[0] : columnsToAdd.join(", ")}`;
            tx.executeSql(alterSql, [], () => {
              console.log("Columns added successfully.");
            }, (tx, error) => {
              console.error("Error adding columns:", error);
            });
          }

        });

        addColumnIfNotExist(tx, "ServiceInspections", 'toBeSynced', 'INT');
      }, (error) => {
        console.log(error)
        reject();
      }, () => {
        resolve();
      })
    });
  }

  addInspection(inspectionDate: any, nextInspectionDate: any, safeForUse: any, equipmentId: any, engineerId: any, jobId: any,
    purposeId: any, colourId: any, missing: any, questions: any, images: any): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        Database.getDb().transaction((tx) => {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME} (
              id, inspectionDate, nextInspectionDate, safeForUse, equipmentId, engineerId, jobId, purposeId, colourId, missing, toBeSynced
            )
            VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, 1)
          `
          var serviceId = uuid.v4();

          tx.executeSql(sql, [
            serviceId, inspectionDate, nextInspectionDate, safeForUse, equipmentId, engineerId, jobId, purposeId, colourId, missing
          ]);


          //
          for (let question of questions) {
            var sql = `
              INSERT OR REPLACE INTO ${this.TABLE_NAME_ANSWERED_QUESTIONS} (
                id, questionId, response, comments, serviceInspectionId, imageBase64
              )
              VALUES (?, ?, ?, ?, ?, ?)
            `;

            tx.executeSql(sql, [uuid.v4(), question.id, question.response, question.comments, serviceId, question.imageBase64]);
          }

          // if (images.length > 0) {
          //   sql = `INSERT INTO ${this.TABLE_NAME_IMAGES} (id, inspectionId, base64) VALUES (?, ?, ?)`;
          //   for (let image of images) {
          //     tx.executeSql(sql, [uuid.v4(), serviceId, image]);
          //   }
          // }

          // Update this asset to be completed 
          sql = "UPDATE JobAssets SET completed = 1, toBeSynced = 1 WHERE jobId = ? and equipmentId = ?";
          tx.executeSql(sql, [jobId, equipmentId]);

        }, (error) => {
          console.log("ERROR");
          console.log(error);
          reject();
        }, () => {
          console.log("UPDATED JOB Assets - JobId: " + jobId + " EquipId: " + equipmentId);
          resolve();
        })
      } catch (e) {
        console.log("ERROR INSIDE SERVICING")
        reject();
        console.log(e)
      }
    })
  }

  insertOrUpdateQuestionList(entities: any): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME_QUESTIONS} (id, position, question, suffix, serviceQuestionCategoryId, isDeleted)
            VALUES (?, ?, ?, ?, ?, ?)
          `

          tx.executeSql(sql, [
            entity.id, entity.position, entity.question, entity.suffix, entity.serviceQuestionCategoryId, entity.isDeleted
          ]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  insertOrUpdateQuestionResponsesList(entities: any): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME_QUESTIONS_RESPONSES} (id, response, commentMandatory, serviceQuestionId, position, isPassDefault, isDeleted)
            VALUES (?, ?, ?, ?, ?, ?, ?)
          `

          tx.executeSql(sql, [
            entity.id, entity.response, entity.commentMandatory, entity.serviceQuestionId, entity.position, entity.isPassDefault, entity.isDeleted
          ]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  insertOrUpdateQuestionCategoryList(entities: any): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME_QUESTION_CATEGORIES} (id, position, title, isDeleted, categoryId)
            VALUES (?, ?, ?, ?, ?)
          `

          tx.executeSql(sql, [
            entity.id, entity.position, entity.title, entity.isDeleted, entity.categoryId
          ]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  getServiceQuestions(categoryId: any): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT ServiceQuestions.id, ServiceQuestions.position as questionPosition, question, suffix, ServiceQuestionCategories.title,
          ServiceQuestionCategories.position as questionCategoryPosition, ServiceQuestions.isDeleted
          FROM ServiceQuestions
          INNER JOIN ServiceQuestionCategories ON ServiceQuestions.serviceQuestionCategoryId = ServiceQuestionCategories.id
          WHERE ServiceQuestionCategories.isDeleted = 0 AND ServiceQuestions.isDeleted = 0 AND ServiceQuestionCategories.categoryId = ?
        `;

        tx.executeSql(sql, [categoryId], (_, { rows: { _array } }) => {
          // Load all the questions and assign where they relate
          data = _array;

          sql = `SELECT * FROM ${this.TABLE_NAME_QUESTIONS_RESPONSES} WHERE isDeleted = 0`;
          tx.executeSql(sql, [], (_, { rows: { _array } }) => {
            const responses = _array;

            for (let question of data) {
              question.responses = responses.filter(r => r.serviceQuestionId == question.id);
            }
          });


        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  getForSync(): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM ${this.TABLE_NAME} WHERE toBeSynced = 1
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;

          var sqlQuestions = `SELECT * FROM ${this.TABLE_NAME_ANSWERED_QUESTIONS} WHERE serviceInspectionId = ?`;

          for (let inspection of data) {
            tx.executeSql(sqlQuestions, [inspection.id], (_, { rows: { _array } }) => {
              inspection.questions = _array;
            });

            // sql = `SELECT base64 FROM ${this.TABLE_NAME_IMAGES} WHERE inspectionId = ?`;
            // tx.executeSql(sql, [inspection.id], (_, { rows: { _array } }) => {
            //   inspection.images = [];
            //   for (let image of _array) {
            //     inspection.images.push(image.base64)
            //   }
            // });
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  deleteInspection(inspectionId): Promise<boolean> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `DELETE FROM ${this.TABLE_NAME_ANSWERED_QUESTIONS} WHERE serviceInspectionId = ?`;
        tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => { });

        // sql = `DELETE FROM ${this.TABLE_NAME_IMAGES} WHERE inspectionId = ?`;
        // tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => { });

        sql = `DELETE FROM ${this.TABLE_NAME} WHERE id = ?`;
        tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => { });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(true);
      })
    });
  }

  getByJobId(jobId: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        tx.executeSql(
          `SELECT * FROM ServiceInspections WHERE jobId = ? AND toBeSynced = 0`,
          [jobId],
          (_, { rows: { _array } }) => resolve(_array),
          (_, error) => reject(error)
        );
      });
    });
  }

  updateInspectionToBeSynced(inspectionId: string, toBeSynced: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        tx.executeSql(
          `UPDATE ServiceInspections SET toBeSynced = ? WHERE id = ?`,
          [toBeSynced ? 1 : 0, inspectionId],
          () => resolve(),
          (_, error) => reject(error)
        );
      });
    });
  }

  getCompletedInspection(jobId, equipmentId): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM ServiceInspections
          WHERE jobId = ? AND equipmentId = ?
        `;

        tx.executeSql(sql, [jobId, equipmentId], (_, { rows: { _array } }) => {
          data = _array;

          var sqlQuestions = `SELECT * FROM ${this.TABLE_NAME_ANSWERED_QUESTIONS} WHERE serviceInspectionId = ?`;

          for (let inspection of data) {
            tx.executeSql(sqlQuestions, [inspection.id], (_, { rows: { _array } }) => {
              inspection.questions = _array;
            });
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  updateInspection(inspectionId: any, inspectionDate: any, nextInspectionDate: any, safeForUse: any, equipmentId: any, engineerId: any, jobId: any,
    purposeId: any, colourId: any, missing: any, questions: any, images: any): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        Database.getDb().transaction((tx) => {
          var sql = `
            UPDATE ${this.TABLE_NAME}
            SET
                inspectionDate = ?, 
                nextInspectionDate = ?, 
                safeForUse = ?, 
                equipmentId = ?, 
                engineerId = ?, 
                jobId = ?, 
                purposeId = ?, 
                colourId = ?, 
                missing = ?, 
                toBeSynced = 1
            WHERE id = ?
          `
          tx.executeSql(sql, [
            inspectionDate, nextInspectionDate, safeForUse, equipmentId, engineerId, jobId, purposeId, colourId, missing, inspectionId
          ]);

          const sqlQuestions = `DELETE FROM ${this.TABLE_NAME_ANSWERED_QUESTIONS} WHERE serviceInspectionId = ?`;
          tx.executeSql(sqlQuestions, [inspectionId]);
          //
          for (let question of questions) {
            var sql = `
              INSERT OR REPLACE INTO ${this.TABLE_NAME_ANSWERED_QUESTIONS} (
                id, questionId, response, comments, serviceInspectionId, imageBase64
              )
              VALUES (?, ?, ?, ?, ?, ?)
            `;

            tx.executeSql(sql, [uuid.v4(), question.id, question.response, question.comments, inspectionId, question.imageBase64]);
          }

          sql = "UPDATE JobAssets SET completed = 1, toBeSynced = 1 WHERE jobId = ? and equipmentId = ?";
          tx.executeSql(sql, [jobId, equipmentId]);

        }, (error) => {
          console.log("ERROR");
          console.log(error);
          reject();
        }, () => {
          console.log("UPDATED JOB Assets - JobId: " + jobId + " EquipId: " + equipmentId);
          resolve();
        })
      } catch (e) {
        console.log("ERROR INSIDE SERVICING")
        reject();
        console.log(e)
      }
    })
  }
}