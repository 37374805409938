import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@store/index";
import {EngineerTimesheet} from "@store/services/api/engineer-report";
import React, {useEffect, useState} from "react";
import {setTimesheetValidity, updateTimesheet} from "@store/slices/erstr-slice";
import {StyleSheet, Text, TextInput, View} from "react-native";

export function ErstrTimesheetForm() {
  const dispatch = useDispatch();
  const timesheets = useSelector((state: RootState) => state.erstr.report.timesheets);

  const handleInputChange = (timesheetId: string, field: keyof EngineerTimesheet, value: any) => {
    dispatch(updateTimesheet({ timesheetId, field, value }));
  };

  const sortedTimesheets = [...timesheets].sort((a, b) => {
    const dayA = a.dayIdx === 0 ? 7 : a.dayIdx;
    const dayB = b.dayIdx === 0 ? 7 : b.dayIdx;

    return dayA - dayB;
  });

  const headers: string[] = [
    'Day', 'Normal Time', 'Over Time', 'Double Time', 'Start Time', 'End Time', 'Miles'
  ]

  return (
    <View>
      <View style={styles.dayRow}>
        {headers.map((header: string, index: number) =>
            <Text key={index} style={index == 0 ? styles.day__headerCell : [styles.headerCell, styles.column]}>{header}</Text>
        )}
      </View>
      {sortedTimesheets.map((timesheet) => (
        <TimeLogRow
          key={timesheet.id}
          timesheet={timesheet}
          handleInputChange={handleInputChange}
        />
      ))}
    </View>
  );
}

interface TimeLogRowProps {
  timesheet: EngineerTimesheet,
  handleInputChange: (timesheetId: string, field: keyof EngineerTimesheet, value: any) => void;
}

const TimeLogRow: React.FC<TimeLogRowProps> = React.memo(({ timesheet, handleInputChange }) => {
  const [touchedFields, setTouchedFields] = useState({});
  const dispatch = useDispatch();
  const [validity, setValidity] = useState({
    normalTime: true,
    overTime: true,
    doubleTime: true,
    startTime: true,
    endTime: true,
    miles: true,
  });

  useEffect(() => {
    const anyInvalid = Object.values(validity).some(valid => !valid);
    dispatch(setTimesheetValidity(!anyInvalid))
  }, [validity, dispatch]);

  const handleChange = (field: keyof EngineerTimesheet) => (value: string) => {
    let validValue = value;
    let isValid = true;

    if (field === 'normalTime' || field === 'overTime' || field === 'doubleTime' || field === 'miles') {
      validValue = value.replace(/[^0-9.]/g, '');
      if (validValue === '') {
        validValue = '0';
      }
    } else if (field === 'startTime' || field === 'endTime') {
      const timePattern = /^(2[0-3]|[01]?[0-9]):[0-5][0-9]$/;
      isValid = timePattern.test(value) || value === '';
    }

    setTouchedFields((prev) => ({ ...prev, [field]: true }));
    setValidity((prev) => ({ ...prev, [field]: isValid }));
    handleInputChange(timesheet.id, field, validValue);
  };

  const hasTouched = (field: keyof EngineerTimesheet) => {
    return touchedFields[field] === true;
  };

  const columns = [
    { field: 'normalTime', inputMode: 'decimal', keyboardType: 'numeric' },
    { field: 'overTime', inputMode: 'decimal', keyboardType: 'numeric' },
    { field: 'doubleTime', inputMode: 'decimal', keyboardType: 'numeric' },
    { field: 'startTime', inputMode: 'none', keyboardType: 'default' },
    { field: 'endTime', inputMode: 'none', keyboardType: 'default' },
    { field: 'miles', inputMode: 'decimal', keyboardType: 'numeric' },
  ] as const;

  return (
    <View style={styles.dayRow}>
      <Text style={[styles.dayCell]}>{timesheet.day}</Text>
      {columns.map(({field, inputMode, keyboardType}) => (
        <TextInput
          key={field}
          style={[
            hasTouched(field) ? styles.updatedInput : styles.input,
            !validity[field] ? styles.invalidInput : null,
            styles.column
          ]}
          inputMode={inputMode}
          keyboardType={keyboardType}
          value={String(timesheet[field])}
          onChangeText={handleChange(field)}
          placeholder={field === 'startTime' || field === 'endTime' ? "HH:MM" : undefined}
        />
      ))}
    </View>
  );
});

const styles = StyleSheet.create({
  dayRow: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  headerCell: {
    textAlign: 'center'
  },
  day__headerCell: {
    flex: 0,
    textAlign: 'center',
    margin: 5,
    padding: 5,
    flexBasis: 96,
  },
  dayCell: {
    flex: 0,
    fontWeight: 'bold',
    textAlign: 'center',
    margin: 5,
    padding: 5,
    flexBasis: 96,
  },
  column: {
    flex: 1,
    margin: 5,
    padding: 5,
    flexBasis: 0,
    minWidth: 60,
  },
  input: {
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 4,
    padding: 5,
    flex: 1,
    minWidth: 60,
  },
  updatedInput: {
    borderColor: 'green',
    borderWidth: 1,
    borderRadius: 4
  },
  invalidInput: {
    borderColor: 'red',
    borderWidth: 1,
  },
  errorText: {
    color: 'red',
    fontSize: 12,
  }
});
