import React, {useEffect} from "react";
import {View, Text, StyleSheet, Platform, TextInput, TouchableOpacity, ScrollView, Alert} from "react-native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {RouteProp} from "@react-navigation/native";
import {useGetEngineerReportQuery, useUpdateEngineerReportMutation} from "@store/services/api/engineer-report";
import {LoadingIndicator} from "@web/components/navigation/controls/LoadingIndicator";
import {useDispatch, useSelector} from "react-redux";
import {setReport, updateCustomerPo, updateFurtherWorks, updateWorksCarriedOut} from "@store/slices/erstr-slice";
import {ErstrTimesheetForm} from "@web/screens/AuthenticatedScreens/Jobs/ERSTR/ErstrTimesheetForm";
import {RootState} from "@store/index";
import {colours as importedColours} from "../../../../../../config";
import GlobalStyle, {PRIMARY_COLOUR} from "../../../../../../constants/GlobalStyle";
import {EvilIcons} from "@expo/vector-icons";

export function ErstrEdit({navigation, route}) {
  const {data, isFetching, isError: loadingError} = useGetEngineerReportQuery(route.params.id);
  const [updateReport, {isLoading: isUpdateLoading, isError: updateError, isSuccess: updateSuccess}]
    = useUpdateEngineerReportMutation();
  const erstrFormData = useSelector((state: RootState) => state.erstr.report);
  const formValid = useSelector((state: RootState) => state.erstr.timesheetValidity);
  const dispatch = useDispatch();

  useEffect(() => {
    if (loadingError) goBack();
    if (data == undefined) return;
    dispatch(setReport(data));
  }, [isFetching]);

  useEffect(() => {
    if (!updateSuccess) return;
    goBack();
  }, [updateSuccess]);

  function handleSubmit() {
    updateReport(erstrFormData);
  }

  const goBack = () => {
    navigation.navigate("reports-erstr")
  }

  if (isFetching || isUpdateLoading) {
    return <LoadingIndicator/>
  }

  return (
    <ScrollView>
      <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={goBack}>
          <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>ERSTR</Text>
      </View>
      <View style={styles.container}>
        {updateError &&
          <View style={styles.question}>
            <Text style={styles.errorTitle}>There was an issue updating the engineer report</Text>
          </View>
        }

        <View style={styles.question}>
          <Text style={styles.question__label}>Customer PO Number:</Text>
          <TextInput
            multiline={false}
            value={erstrFormData.customerPoNumber}
            onChangeText={(value) => dispatch(updateCustomerPo(value))}
            style={styles.text__input}
          />
        </View>

        <View style={styles.question}>
          <Text style={styles.question__label}>Works carried out:</Text>
          <TextInput
            multiline={true}
            value={erstrFormData.worksCarriedOut}
            onChangeText={(value) => dispatch(updateWorksCarriedOut(value))}
            style={styles.text__input}
          />
        </View>

        <View style={styles.question}>
          <Text style={styles.question__label}>Further Works:</Text>
          <TextInput
            multiline={true}
            value={erstrFormData.furtherWorks}
            onChangeText={(value) => dispatch(updateFurtherWorks(value))}
            style={styles.text__input}
          />
        </View>

        <View style={styles.question}>
          <Text style={styles.question__label}>Timesheet:</Text>
          <ErstrTimesheetForm />
        </View>

        <TouchableOpacity
          disabled={!formValid}
          style={[
            GlobalStyle.form__submit__button,
            !formValid ? styles.button__disabled : null, styles.button__margin
          ]}
          onPress={handleSubmit}>
          <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
        </TouchableOpacity>
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 10
  },
  text__input: {
    borderWidth: 1,
    borderColor: '#CECECE',
    padding: 10,
    marginTop: 5,
  },
  question: {
    padding: 20,
    backgroundColor: '#FFF',
    marginBottom: 20,
  },
  question__label: {
    fontFamily: 'OpenSans-Light',
    fontSize: 18,
    marginBottom: 5,
  },
  button__disabled: {
    backgroundColor: '#a5a5a5',
  },
  button__margin: {
    marginBottom: 20
  },
  errorMessage: {
    color: 'red',
    fontSize: 12,
    textAlign: 'left',
  },
  errorTitle: {
    color: 'red',
    fontFamily: 'OpenSans-Light',
    fontSize: 18,
    marginBottom: 5,
    textAlign: 'left',
  }
});
