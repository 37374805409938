import { baseApi, PagedTableDto } from './base-api'

export const locationApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getLocations: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `locations/getlocations?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    getLocation: build.query<any, any>({
      query: (locationId) => ({
        url: `locations/getlocation/${locationId}`,
      }),
    }),
    getAllLocations: build.query<any, any>({
      query: (companyId) => ({
        url: `locations/getalllocations`,
      }),
    }),
    createLocation: build.mutation<any, any>({
      query: (body) => ({
        url: 'locations/addlocation',
        method: 'post',
        body: body
      }),
    }),
    updateLocation: build.mutation<any, any>({
      query: (body) => ({
        url: `locations/updatelocation/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    syncLocations: build.mutation<any, any>({
      query: (dto) => ({
        url: `locations/getlocationsforsync?lastmodifiedon=${dto.lastModifiedOn}&downloaded=${dto.downloaded}&amountToFetch=${dto.amountToFetch}`,
      })
    }),
    syncSubLocations: build.mutation<any, any>({
      query: (dto) => ({
        url: `locations/getsublocationsforsync?lastmodifiedon=${dto.lastModifiedOn}&downloaded=${dto.downloaded}&amountToFetch=${dto.amountToFetch}`,
      })
    }),
    getSubLocations: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `locations/getsublocations?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    createSubLocation: build.mutation<any, any>({
      query: (body) => ({
        url: 'locations/addsublocation',
        method: 'post',
        body: body
      }),
    }),
    updateSubLocation: build.mutation<any, any>({
      query: (body) => ({
        url: `locations/updatesublocation/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    deleteSubLocation: build.mutation<any, any>({
      query: (id) => ({
        url: `locations/deleteSubLocation/${id}`,
        method: 'delete',
      }),
    }),
    getAllSubLocations: build.query<any, any>({
      query: (companyId) => ({
        url: `locations/getallsublocations`,
      }),
    }),
    getLocationsByCompanyId: build.query<any, any>({
      query: (companyId) => ({
        url: `locations/getlocationsbycompany/${companyId}`,
      }),
    }),
    getSubLocationsByLocationId: build.query<any, any>({
      query: (locationId) => ({
        url: `locations/getsublocationsbylocation/${locationId}`,
      }),
    }),
  }),
  overrideExisting: true,
})

export const {
  useGetLocationsQuery,
  useGetLocationQuery,
  useGetAllLocationsQuery,
  useCreateLocationMutation,
  useUpdateLocationMutation,

  useGetSubLocationsQuery,
  useGetAllSubLocationsQuery,
  useCreateSubLocationMutation,
  useUpdateSubLocationMutation,
  useDeleteSubLocationMutation,
  useLazyGetLocationsByCompanyIdQuery,
  useLazyGetSubLocationsByLocationIdQuery
} = locationApi