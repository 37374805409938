import { useState, useEffect } from 'react';
import { TitleBar } from '@app/components/TitleBar';
import { StyleSheet, Text, TextInput, View, TouchableOpacity, Alert, Image, Dimensions } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { FlashList } from '@shopify/flash-list';
import RadioButtonGroup, { RadioButtonItem } from "expo-radio-button";
import config, { colours } from '../../../../../config';
import { DateTimePickerAndroid } from '@react-native-community/datetimepicker';
import moment from 'moment';
import { MaterialIcons, MaterialCommunityIcons, Ionicons } from '@expo/vector-icons';
import { useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { CategoryDatabase } from '@store/services/database/category-database';
import { CameraModal } from '@app/components/modals/CameraModal';
import { Keyboard } from 'react-native';
import { InstallationDatabase } from '@store/services/database/installation-database';
import { ServiceDatabase } from '@store/services/database/service-database';
import Checkbox from 'expo-checkbox';

const screenWidth = Dimensions.get("screen").width;
const isMobileScreen = screenWidth < 480;
const isPortrait8Inch = screenWidth > 480 && screenWidth <= 641;

export default function InstallationInspect({ navigation, route }: { navigation: any, route: any }) {
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);
  const categoryId = route.params?.categoryId;

  const job = useSelector((state: RootState) => state.job);
  const [ref, setRef] = useState(null);
  const [loading, setLoading] = useState<any>(false);

  const [tabs, setTabs] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<any>(null);
  const [index, setIndex] = useState<any>(0);

  const [questions, setQuestions] = useState<any>([]);

  const [inspectionDate, setInspectionDate] = useState<any>(moment());
  const [serviceDueDate, setServiceDueDate] = useState<any>(moment());

  const [safeForUse, setSafeForUse] = useState<any>(null);
  const [installationDbInspection, setInstallationDbInspection] = useState(null);
  const [serviceDbInspection, setServiceDbInspection] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [isServiceDueDateEnabled, setIsServiceDueDateEnabled] = useState<boolean>(false);

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow', () => { setKeyboardVisible(true); }
    );
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide', () => { setKeyboardVisible(false); }
    );

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
  }, [])

  useEffect(() => {
    const sortAndGroupQuestions = (questions) => {
      var groupedQuestions = questions.sort((a, b) => a.questionCategoryPosition - b.questionCategoryPosition).reduce((r, a) => {
        r[a.title] = r[a.title] || [];
        r[a.title].push(a);
        return r;
      }, Object.create(null));

      return groupedQuestions;
    }

    const initTabs = (groupedQuestions) => {
      var tabs = [];

      for (let group of Object.entries(groupedQuestions)) {
        var key = group[0];
        var questions = (group[1] as any).sort((a, b) => a.questionPosition - b.questionPosition);

        tabs.push({
          title: key,
          questions: questions,
          isSave: false,
        });
      }

      // Add our save tab
      tabs.push({
        title: "Save",
        isSave: true,
        questions: [],
      })

      setTabs(tabs);
    }

    const loadInstallationQuestions = async () => {
      const installationDatabase = new InstallationDatabase();
      var questions = await installationDatabase.getInstallationQuestions(categoryId);
      const serviceDatabase = new ServiceDatabase();
      const dbInspectionData = await installationDatabase.getCompletedInspection(job.jobId, job.equipmentId);
      const [dbInspection] = dbInspectionData;

      const serviceDbInspectionData = await serviceDatabase.getCompletedInspection(job.jobId, job.equipmentId);
      const [serviceDbInspection] = serviceDbInspectionData;


      if (dbInspectionData.length > 0 && dbInspection.questions.length > 0) {
        setInstallationDbInspection(dbInspection);
        questions = questions.map((question) => {
          const historicalAnswer = dbInspection.questions.find((aq) => aq.questionId === question.id);
          return historicalAnswer
            ? {
              ...question,
              response: historicalAnswer.response,
              responseValue: historicalAnswer.responseValue,
              comments: historicalAnswer.comments,
              imageBase64: historicalAnswer.imageBase64
            }
            : question;
        });

        setInspectionDate(moment(dbInspection.installationDate).toDate());
      }

      if (serviceDbInspectionData.length > 0 && serviceDbInspection) {
        setServiceDbInspection(serviceDbInspection);
        setServiceDueDate(moment(serviceDbInspection.nextInspectionDate).toDate());
        setIsServiceDueDateEnabled(true);
      }

      // Group our questions by title and order by position
      var groupedQuestions = sortAndGroupQuestions(questions);

      // Initialise our screens
      initTabs(groupedQuestions);
    }

    const updateServiceDueDate = async () => {
      // Get the category and use that interval
      var categoryDatabase = new CategoryDatabase();
      var category = await categoryDatabase.getById(job.categoryId);
      setServiceDueDate(moment().add(category.inspectionInterval, 'M'));
    }

    loadInstallationQuestions();

    // Update our next inspection date
    updateServiceDueDate();

    setLoading(false);
  }, [])

  useEffect(() => {
    if (activeTab == null && tabs != null && tabs.length > 0) {
      updateActiveTab(0);
    }
  }, [tabs])

  const previous = () => {
    if (index == 0)
      return;

    var idx = index - 1;
    setIndex(idx);

    updateActiveTab(idx);
  }

  const next = () => {
    if (!validateQuestions(activeTab)) {
      return;
    }

    if (index == tabs.length - 1) {
      save();
      return;
    };

    var idx = index + 1;
    setIndex(idx);

    updateActiveTab(idx);
  }

  const updateActiveTab = (idx) => {
    var newTab = tabs[idx];
    setActiveTab(newTab);
    // Update our questions
    setQuestions([...newTab.questions]);
  }

  const passRemaining = () => {
    for (let question of activeTab.questions) {
      if (question.response == null) {
        var defaultPassResponse = question.responses.find(r => r.isPassDefault);
        if (defaultPassResponse != null) {
          question.response = defaultPassResponse.id;
          question.responseValue = defaultPassResponse.response
        }
      }
    }

    setQuestions([...questions]);
    next();
  }

  const passRemainingPrompt = () => {
    Alert.alert('Pass Remaining', 'Are you sure you want to pass the remaining questions?', [
      { text: 'No', style: 'cancel', },
      { text: 'Yes', onPress: () => passRemaining() }
    ]);
  }

  const updateQuestionResponse = (question, responseId) => {
    question.response = responseId;

    const response = question.responses.find(({ id }) => id === responseId);
    question.responseValue = response.response

    setQuestions([...questions]);
  }

  const updateQuestionNumericResponse = (question, response) => {
    question.responseValue = response
    question.response = question.responses[0].id;
    setQuestions([...questions]);
  }

  const updateQuestionComments = (question, comments) => {
    question.comments = comments;
    setQuestions([...questions]);
  }

  const onPictureTaken = (base64) => {
    selectedQuestion.imageBase64 = base64;
    setQuestions([...questions]);
    setShowCamera(false);
  }

  const removeImage = (question) => {
    Alert.alert('Image', 'Are you sure you want to remove this image?', [
      { text: 'No', style: 'cancel', },
      { text: 'Yes', onPress: () => { question.imageBase64 = null; setQuestions([...questions]); } }
    ]);

  }

  const takePicture = (question) => {
    setSelectedQuestion(question);
    setShowCamera(true);
  }

  const validateQuestions = (tab) => {
    var questions = tab.questions;
    for (var i = 0; i < questions.length; i++) {
      var question = questions[i];
      if (question.response == null) {
        ref.scrollToIndex({ animated: true, index: i, viewPosition: 0 });
        Alert.alert("Validation", "Please select response for: " + question.question);
        return false;
      }

      if (question.responses[0].type === 1) {
        const response = parseFloat(question.responseValue);
        if (isNaN(response)) {
          ref.scrollToIndex({ animated: true, index: i, viewPosition: 0 });
          Alert.alert("Validation", `Response for: ${question.question} can't be blank`);
          return false;
        }
      } else if (question.responses[0].type === 2) {
        const numberResponse = parseFloat(question.responseValue);

        const lowerRange = parseFloat(question.responses[0].lowerRange);
        const upperRange = parseFloat(question.responses[0].upperRange);

        if (isNaN(numberResponse) || numberResponse < lowerRange || numberResponse > upperRange) {
          ref.scrollToIndex({ animated: true, index: i, viewPosition: 0 });
          Alert.alert("Validation", `Response for: ${question.question} must be a value between ${lowerRange} and ${upperRange}`);
          return false;
        }
      }

      var response = question.responses.find(r => r.id == question.response);
      if (response != null && response.commentMandatory == true && (question.comments == "" || question.comments == null)) {
        ref.scrollToIndex({ animated: true, index: i, viewPosition: 0 });
        Alert.alert("Validation", "Enter comments for: " + question.question);
        return false;
      }


    }

    return true;
  }


  const setInspectionDatePicker = () => {
    DateTimePickerAndroid.open({
      mode: "date",
      value: moment(inspectionDate).toDate(),
      onChange: (evt, date) => { setInspectionDate(moment(date)); }
    });
  }

  const setServiceDueDatePicker = () => {
    DateTimePickerAndroid.open({
      mode: "date",
      value: moment(serviceDueDate).toDate(),
      onChange: (evt, date) => { setServiceDueDate(moment(date)); }
    });
  }

  const save = async () => {
    if (safeForUse == null) {
      Alert.alert("Validation", "Select status");
      return;
    }

    setLoading(true);

    var questionList = [];
    for (let tab of tabs) {
      questionList = questionList.concat(tab.questions);
    }

    const installationDatabase = new InstallationDatabase();
    const serviceDatabase = new ServiceDatabase();

    if (installationDbInspection) {
      await installationDatabase.updateInspection(installationDbInspection.id,
        moment(inspectionDate).format('YYYY-MM-DDTHH:mm:ss'), safeForUse,
        job.equipmentId, job.engineerId, job.jobId, false, questionList, []
      );

      if (serviceDbInspection) {
        await serviceDatabase.updateInspection(serviceDbInspection.id,
          moment(inspectionDate).format(), moment(serviceDueDate).format("YYYY-MM-DD"), safeForUse,
          job.equipmentId, job.engineerId, job.jobId, null, null, false, [], []
        );

      } else if (isServiceDueDateEnabled) {
        await serviceDatabase.addInspection(
          moment(inspectionDate).format(), serviceDueDate.format("YYYY-MM-DD"), safeForUse,
          job.equipmentId, job.engineerId, job.jobId, null, null, false, [], []
        );
      }
    } else {
      await installationDatabase.addInspection(
        moment(inspectionDate).format(), safeForUse,
        job.equipmentId, job.engineerId, job.jobId, false, questionList, []
      );

      if (isServiceDueDateEnabled) {
        await serviceDatabase.addInspection(
          moment(inspectionDate).format(), serviceDueDate.format("YYYY-MM-DD"), safeForUse,
          job.equipmentId, job.engineerId, job.jobId, null, null, false, [], []
        );
      }
    }


    setLoading(false);

    navigation.navigate("JobAssets");
  }

  const renderItem = ({ item, index }) => {
    return (
      <View style={styles.row}>
        <View>
          <Text style={styles.question__title}>{item.question}</Text>
        </View>

        {item.responses[0].type === 0 && (
          <View>
            <RadioButtonGroup
              containerStyle={{ marginRight: 40, marginTop: 10, marginBottom: 10, flexDirection: 'column' }}
              radioBackground={colours.primary}
              selected={item.response}
              onSelected={(val) => updateQuestionResponse(item, val)}
            >
              {item.responses.sort((a, b) => a.position - b.position).map(response => (
                <RadioButtonItem key={response.id} style={{ marginLeft: 20 }} value={response.id} label={response.response} />
              ))}
            </RadioButtonGroup>
          </View>
        )}

        {item.responses[0].type === 1 && (
          <View>
            <Text>Response</Text>
            <TextInput
              style={styles.comments}
              value={item.responseValue}
              keyboardType="numeric"
              onChangeText={(response) => updateQuestionNumericResponse(item, response)}
            />
          </View>
        )}

        {item.responses[0].type === 2 && (
          <View>
            <Text>Response</Text>
            <TextInput
              style={styles.comments}
              value={item.responseValue}
              keyboardType="numeric"
              onChangeText={(response) => updateQuestionNumericResponse(item, response)}
            />
            <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
              <Text>Lower Bound: {item.responses[0].lowerRange}</Text>
              <Text>Upper Bound: {item.responses[0].upperRange}</Text>
            </View>
          </View>
        )}

        <View>
          <Text>Comments</Text>
          <TextInput
            style={styles.comments}
            value={item.comments}
            onChangeText={(text) => updateQuestionComments(item, text)}
          />
        </View>

        <View style={{ flexDirection: 'row' }}>
          <TouchableOpacity style={styles.picture__button} onPress={() => takePicture(item)}>
            <Text style={styles.picture__button__text}>Take Image</Text>
          </TouchableOpacity>

          {item.imageBase64 && (
            <TouchableOpacity onPress={() => removeImage(item)}>
              <Image
                style={styles.image}
                resizeMode="contain"
                source={{ uri: item.imageBase64 }}
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
    );
  };



  if (tabs.length == 0 || activeTab == null || loading) {
    return <LoadingIndicator />
  }

  if (showCamera) {
    return (
      <CameraModal onCancel={() => setShowCamera(false)} onPictureTaken={(image) => onPictureTaken(image)} />
    )
  }

  return (
    <View style={styles.container}>
      <TitleBar navigation={navigation} title={"Installation"} showBackButton={true} showSearchButton={false} onSynced={null} promptBack={true} onSearch={null} />

      {!isKeyboardVisible &&
        <View style={styles.tabs}>
          {tabs.map((screen: any, index: number) => {
            return (
              <TouchableOpacity key={index} style={[styles.tab, activeTab.title == screen.title && styles.tab__active]}>
                <Text style={[styles.tab__text, activeTab.title == screen.title && styles.tab__text__active]}>{screen.title}</Text>
              </TouchableOpacity>
            )
          })}
        </View>
      }

      {!activeTab.isSave && <Text style={styles.questions__header}>QUESTIONS</Text>}

      {!activeTab.isSave &&
        <FlashList
          data={questions}
          renderItem={renderItem}
          estimatedItemSize={100}
          ref={(ref) => { setRef(ref); }}
          automaticallyAdjustKeyboardInsets={true}
        />
      }

      {activeTab.isSave &&
        <View style={{ flex: 1 }}>
          <View style={{ flexDirection: "row", alignItems: "center", paddingTop: 10 }}>
            <Text>Set Service Date?</Text>
            <Checkbox
              value={isServiceDueDateEnabled}
              onValueChange={setIsServiceDueDateEnabled}
              color={isServiceDueDateEnabled ? colours.green : undefined}
              style={{ marginLeft: 10 }}
              disabled={serviceDbInspection !== null}
            />
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1, backgroundColor: '#FFF', borderWidth: 1, borderColor: '#CECECE', padding: 10, marginTop: 10, marginRight: 5 }}>
              <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>Installation Date</Text>

              <TouchableOpacity
                style={{ flexDirection: 'row', alignItems: 'center', padding: 10, borderWidth: 1, borderColor: '#cecece' }}
                onPress={() => setInspectionDatePicker()}>
                <Text style={{ flex: 1 }}>{moment(inspectionDate).format("DD/MM/YYYY")}</Text>
                <MaterialIcons name="date-range" size={24} color="black" />
              </TouchableOpacity>
            </View>


            {isServiceDueDateEnabled && <View style={{ flex: 1, backgroundColor: '#FFF', borderWidth: 1, borderColor: '#CECECE', padding: 10, marginTop: 10, marginLeft: 5 }}>
              <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>Service Due</Text>

              <TouchableOpacity
                style={{ flexDirection: 'row', alignItems: 'center', padding: 10, borderWidth: 1, borderColor: '#cecece' }}
                onPress={() => setServiceDueDatePicker()}>
                <Text style={{ flex: 1 }}>{moment(serviceDueDate).format("DD/MM/YYYY")}</Text>
                <MaterialIcons name="date-range" size={24} color="black" />
              </TouchableOpacity>
            </View>}
          </View>

          <View style={styles.safe__block}>
            <TouchableOpacity style={[styles.safe__button, styles.not_safe, safeForUse == false && styles.unsafe_checked]} onPress={() => setSafeForUse(false)}>
              <MaterialCommunityIcons name="cancel" size={isMobileScreen ? 24 : isPortrait8Inch ? 36 : 44} color={safeForUse == false ? 'white' : 'black'} />
              <Text style={[styles.safe__button__text, safeForUse == false && styles.safe__button__text__checked]}>REMOVED FROM INSTALLATION</Text>
            </TouchableOpacity>

            <TouchableOpacity style={[styles.safe__button, styles.safe, safeForUse == true && styles.safe_checked]} onPress={() => setSafeForUse(true)}>
              <Ionicons name="checkmark-circle-outline" size={isMobileScreen ? 24 : isPortrait8Inch ? 36 : 44} color={safeForUse == true ? 'white' : 'black'} />
              <Text style={[styles.safe__button__text, safeForUse == true && styles.safe__button__text__checked]}>INSTALLED</Text>
            </TouchableOpacity>
          </View>
        </View>
      }

      {!isKeyboardVisible &&
        <View style={styles.action__buttons}>
          <TouchableOpacity style={styles.action__button} onPress={() => previous()}>
            <Text style={styles.action__button__text}>PREVIOUS</Text>
          </TouchableOpacity>

          <TouchableOpacity style={styles.action__button} onPress={() => passRemainingPrompt()}>
            <Text style={styles.action__button__text}>PASS REMAINING</Text>
          </TouchableOpacity>

          <TouchableOpacity style={styles.action__button} onPress={() => next()}>
            <Text style={styles.action__button__text}>
              {index == tabs.length - 1 ? 'SAVE' : 'NEXT'}
            </Text>
          </TouchableOpacity>
        </View>
      }
    </View>
  )
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FAFAFA',
    padding: isMobileScreen ? 20 : 40,
    paddingTop: 60,
  },

  row: {
    marginBottom: 5,
    backgroundColor: '#FFF',
    padding: 10,
    borderWidth: 1,
    borderColor: '#CECECE',
  },

  question__title: {
    fontFamily: 'OpenSans-Bold',
    flex: 1,
  },

  questions__header: {
    fontFamily: 'OpenSans-Bold',
    padding: 10,
    fontSize: 22,
    backgroundColor: '#e8e8e8',
    marginBottom: 20,
    marginTop: 20,
    borderRadius: 5,
  },

  comments: {
    borderBottomWidth: 1,
    borderBottomColor: '#CECECE',
    fontSize: 20,
    padding: 5,
  },

  action__buttons: {
    flexDirection: 'row',
    marginTop: 30,
  },

  action__button: {
    backgroundColor: colours.primary,
    flex: 1,
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 5,
    justifyContent: 'center'
  },

  action__button__text: {
    color: '#FFF',
    padding: 10,
    textAlign: 'center',
    fontSize: isMobileScreen ? 14 : isPortrait8Inch ? 18 : 22
  },

  tabs: {
    flexDirection: 'row',
    marginTop: 10,
  },

  tab: {
    flex: 1,
    backgroundColor: '#FFF',
    borderBottomWidth: 1,
    borderBottomColor: colours.primary,
  },

  tab__active: {
    backgroundColor: colours.primary
  },

  tab__text: {
    color: colours.primary,
    fontFamily: 'OpenSans-Bold',
    fontSize: 16,
    padding: 10,
    textAlign: 'center'
  },

  tab__text__active: {
    color: '#FFF'
  },

  safe__block: {
    flexDirection: 'row',
    marginTop: 20,
  },

  safe__button: {
    flex: 1,
    height: isMobileScreen ? 90 : isPortrait8Inch ? 100 : 140,
    backgroundColor: '#edebeb',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderRadius: 5,
  },

  safe__button__text: {
    fontFamily: 'OpenSans-Bold',
    marginLeft: isMobileScreen ? 5 : 10,
    fontSize: isMobileScreen ? 14 : isPortrait8Inch ? 20 : 28
  },

  not_safe: {
    marginRight: 5,
  },

  safe: {
    marginLeft: 5,
  },

  unsafe_checked: {
    backgroundColor: 'red',
  },

  safe_checked: {
    backgroundColor: 'green',
  },

  safe__button__text__checked: {
    color: '#FFF'
  },

  picture__button: {
    backgroundColor: "#FFF",
    marginTop: 20,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: config.colours.primary,
    width: 200,
    alignSelf: 'flex-start'
  },

  picture__button__text: {
    padding: 5,
    color: config.colours.primary,
    textAlign: 'center',
    fontFamily: 'OpenSans-ExtraBold'
  },

  image: {
    height: 150,
    width: 150,
    marginRight: 20,
    marginLeft: 20,
  },
})