import * as SQLite from 'expo-sqlite';
import { Database } from './database';
import uuid from 'react-native-uuid';
import moment from 'moment';
import { addColumnIfNotExist } from '../../../helpers/database-helpers';

export class PssrDatabase extends Database {
    TABLE_NAME = "PssrInspections";
    TABLE_NAME_IMAGES = "PssrImages";
    TABLE_NAME_PARTS = "PssrInspectionParts";

    initialise(): Promise<void> {
        return new Promise((resolve, reject) => {

            var sql = `CREATE TABLE IF NOT EXISTS PssrInspections ( 
            id TEXT PRIMARY KEY,
            examinationType TEXT,
            testsMade TEXT,
            protectiveDevicesState TEXT,
            protectiveDevicesStateComment TEXT,
            isSafeOperatingLimitChanged INT,
            isSafeOperatingLimitChangedDate TEXT,
            repairsRequired TEXT,
            observations TEXT,
            writtenSchemeChanges INT,
            safeToUse INT,
            inServiceInspectionDate TEXT,
            inServiceNextInspectionDate TEXT,
            outOfServiceInspectionDate TEXT,
            outOfServiceNextInspectionDate TEXT,
            inspectionEquipmentId TEXT,
            engineerId TEXT,
            jobId TEXT,
            missing INT,
            jobAssetId TEXT,
            pressureInspectionPartId TEXT,
            conditionOfParts TEXT,
            conditionOfPartsDefect TEXT,
            toBeSynced
            )`;

            var sqlImages = `CREATE TABLE IF NOT EXISTS PssrImages (
            id TEXT PRIMARY KEY,
            inspectionId TEXT,
            base64 TEXT
            )`;

            var sqlPssrParts = `CREATE TABLE IF NOT EXISTS PssrInspectionParts ( 
                id TEXT PRIMARY KEY,
                name TEXT
              )`;

            Database.getDb().transaction((tx) => {
                tx.executeSql(sql);
                tx.executeSql(sqlImages);
                tx.executeSql(sqlPssrParts);
                addColumnIfNotExist(tx, "PssrInspections", 'toBeSynced', 'INT');
                addColumnIfNotExist(tx, "PssrInspections", 'pressureInspectionPartId', 'TEXT');
                addColumnIfNotExist(tx, "PssrInspections", 'conditionOfParts', 'TEXT');
                addColumnIfNotExist(tx, "PssrInspections", 'conditionOfPartsDefect', 'TEXT');
            }, (error) => {
                console.log(error)
                reject();
            }, () => {
                resolve();
            })
        });
    }

    async getJobAssetId(jobId: any, inspectionEquipmentId: any): Promise<any | null> {
        return new Promise((resolve, reject) => {
            Database.getDb().transaction((tx) => {
                const jobAssetSql = "SELECT * FROM JobAssets WHERE jobId = ? and equipmentId = ?";
                tx.executeSql(jobAssetSql, [jobId, inspectionEquipmentId], (_, { rows: { _array } }) => {
                    const existingAssets = _array;
                    console.log("existingAssets", existingAssets);

                    if (existingAssets.length === 1) {
                        console.log("in assets if");

                        const existingAsset = existingAssets[0];
                        const jobAssetId = existingAsset.id;

                        const sqlUpdate = "UPDATE JobAssets SET completed = 1, toBeSynced = 1 WHERE id = ?";
                        tx.executeSql(sqlUpdate, [jobAssetId], () => {
                            const newJobAssetId = uuid.v4();
                            const sqlInsert = `
                                INSERT INTO JobAssets (id, equipmentId, completed, isMissing, jobId, toBeSynced)
                                VALUES (?, ?, 0, 0, ?, 1)
                            `;
                            tx.executeSql(sqlInsert, [newJobAssetId, inspectionEquipmentId, jobId], () => {
                                resolve(jobAssetId);
                            }, (txError) => reject(txError));
                        }, (txError) => reject(txError));
                    } else if (existingAssets.length === 2) {
                        console.log("in assets else if");

                        const incompleteAsset = existingAssets.find(asset => asset.completed === 0);
                        console.log("incompleteAsset", incompleteAsset);
                        const jobAssetId = incompleteAsset.id;
                        const sqlUpdate = "UPDATE JobAssets SET completed = 1, toBeSynced = 1 WHERE id = ?";
                        tx.executeSql(sqlUpdate, [jobAssetId], () => {
                            resolve(jobAssetId);
                        }, (txError) => reject(txError));
                    }
                }, (txError) => reject(txError));
            });
        });
    }

    addInspection(
        examinationType: any, testsMade: any, protectiveDevicesState: any, protectiveDevicesStateComment: any,
        isSafeOperatingLimitChanged: any, isSafeOperatingLimitChangedDate: any, repairsRequired: any, observations: any,
        writtenSchemeChanges: any, safeToUse: any, inServiceInspectionDate: any,
        inServiceNextInspectionDate: any, outOfServiceInspectionDate: any, outOfServiceNextInspectionDate: any,
        inspectionEquipmentId: any, engineerId: any, jobId: any, pressureInspectionPartId: any, conditionOfParts: any, conditionOfPartsDefect: any, missing: any,
        images: any
    ): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                // Retrieve jobAssetId before the transaction
                let jobAssetId = await this.getJobAssetId(jobId, inspectionEquipmentId);

                // Begin the transaction
                Database.getDb().transaction((tx) => {
                    // Format date fields
                    const formattedIsSafeOperatingLimitChangedDate = isSafeOperatingLimitChangedDate ? moment(isSafeOperatingLimitChangedDate).toISOString() : null;
                    const formattedInServiceInspectionDate = inServiceInspectionDate ? moment(inServiceInspectionDate).toISOString() : null;
                    const formattedInServiceNextInspectionDate = inServiceNextInspectionDate ? moment(inServiceNextInspectionDate).toISOString() : null;
                    const formattedOutOfServiceInspectionDate = outOfServiceInspectionDate ? moment(outOfServiceInspectionDate).toISOString() : null;
                    const formattedOutOfServiceNextInspectionDate = outOfServiceNextInspectionDate ? moment(outOfServiceNextInspectionDate).toISOString() : null;
                    const formattedProtectiveDevicesStateComment = protectiveDevicesStateComment ? protectiveDevicesStateComment : null;

                    // Insert the inspection record
                    const sql = `
                        INSERT OR REPLACE INTO ${this.TABLE_NAME} (
                            id, examinationType, testsMade, protectiveDevicesState, protectiveDevicesStateComment, 
                            isSafeOperatingLimitChanged, isSafeOperatingLimitChangedDate, repairsRequired, 
                            observations, writtenSchemeChanges, safeToUse, inServiceInspectionDate, 
                            inServiceNextInspectionDate, outOfServiceInspectionDate, 
                            outOfServiceNextInspectionDate, inspectionEquipmentId, engineerId, 
                            jobId, pressureInspectionPartId, conditionOfParts, conditionOfPartsDefect, missing, jobAssetId, toBeSynced
                        )
                        VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, 1)
                    `;
                    const inspectionId = uuid.v4();
                    tx.executeSql(sql, [
                        inspectionId, examinationType, testsMade, protectiveDevicesState, formattedProtectiveDevicesStateComment,
                        isSafeOperatingLimitChanged, formattedIsSafeOperatingLimitChangedDate, repairsRequired,
                        observations, writtenSchemeChanges, safeToUse, formattedInServiceInspectionDate,
                        formattedInServiceNextInspectionDate, formattedOutOfServiceInspectionDate, formattedOutOfServiceNextInspectionDate,
                        inspectionEquipmentId, engineerId, jobId, pressureInspectionPartId, conditionOfParts, conditionOfPartsDefect, missing, jobAssetId
                    ], () => {
                        console.log("Inspection inserted, ID:", inspectionId);
                    }, (txError) => {
                        console.error("Error inserting inspection:", txError);
                        reject(txError);
                    });

                    // Insert images
                    if (images.length > 0) {
                        const sqlImages = `INSERT INTO ${this.TABLE_NAME_IMAGES} (id, inspectionId, base64) VALUES (?, ?, ?)`;
                        for (const image of images) {
                            tx.executeSql(sqlImages, [uuid.v4(), inspectionId, image], () => {
                                console.log("Image inserted for inspectionId:", inspectionId);
                            }, (txError) => {
                                console.error("Error inserting image:", txError);
                                reject(txError);
                            });
                        }
                    }
                }, (error) => {
                    console.log("Transaction error:", error);
                    reject(error);
                }, () => {
                    console.log(`Inspection and Job Assets updated for JobId: ${jobId}, EquipId: ${inspectionEquipmentId}, JobAssetId: ${jobAssetId}`);
                    resolve();
                });
            } catch (e) {
                // Handle any errors outside the transaction (e.g., getting jobAssetId)
                console.error("Error inside addInspection:", e);
                reject(e);
            }
        });
    }


    deleteInspection(inspectionId: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            Database.getDb().transaction((tx) => {
                let sql = `DELETE FROM ${this.TABLE_NAME} WHERE id = ?`;
                tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => { });

                sql = `DELETE FROM ${this.TABLE_NAME_IMAGES} WHERE inspectionId = ?`;
                tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => { });

            }, (error) => {
                console.log("ERROR in PSSR deleteInspection:");
                console.log(error);
                reject();
            }, () => {
                resolve(true);
            });
        });
    }

    getForSync(): Promise<any> {
        return new Promise((resolve, reject) => {
            let data = [];
            Database.getDb().transaction((tx) => {
                const sql = `SELECT * FROM ${this.TABLE_NAME} WHERE toBeSynced = 1`;

                tx.executeSql(sql, [], (_, { rows: { _array } }) => {
                    data = _array;
                    for (let inspection of data) {
                        const sqlImages = `SELECT base64 FROM ${this.TABLE_NAME_IMAGES} WHERE inspectionId = ?`;
                        tx.executeSql(sqlImages, [inspection.id], (_, { rows: { _array } }) => {
                            inspection.images = [];
                            for (let image of _array) {
                                inspection.images.push(image.base64);
                            }
                        });
                    }

                });
            }, (error) => {
                console.log("ERROR in PSSR getForSync:");
                console.log(error);
                reject();
            }, () => {
                resolve(data);
            });
        });
    }

    updateInspection(id: string, examinationType: string, testsMade: string, protectiveDevicesState: string, protectiveDevicesStateComment: any,
        isSafeOperatingLimitChanged: number, isSafeOperatingLimitChangedDate: any, repairsRequired: string, observations: string, writtenSchemeChanges: number, safeToUse: number,
        inServiceInspectionDate: any, inServiceNextInspectionDate: any, outOfServiceInspectionDate: any, outOfServiceNextInspectionDate: any,
        jobId: string, pressureInspectionPartId: any, conditionOfParts: any, conditionOfPartsDefect: any, missing: number, images: any[]): Promise<void> {

        return new Promise((resolve, reject) => {
            try {
                Database.getDb().transaction((tx) => {

                    const formattedIsSafeOperatingLimitChangedDate = isSafeOperatingLimitChangedDate ? moment(isSafeOperatingLimitChangedDate).toISOString() : null;
                    const formattedInServiceInspectionDate = inServiceInspectionDate ? moment(inServiceInspectionDate).toISOString() : null;
                    const formattedInServiceNextInspectionDate = inServiceNextInspectionDate ? moment(inServiceNextInspectionDate).toISOString() : null;
                    const formattedOutOfServiceInspectionDate = outOfServiceInspectionDate ? moment(outOfServiceInspectionDate).toISOString() : null;
                    const formattedOutOfServiceNextInspectionDate = outOfServiceNextInspectionDate ? moment(outOfServiceNextInspectionDate).toISOString() : null;
                    const formattedProtectiveDevicesStateComment = protectiveDevicesStateComment ? protectiveDevicesStateComment : null;

                    const sql = `
                        UPDATE ${this.TABLE_NAME} 
                        SET 
                            examinationType = ?, testsMade = ?, protectiveDevicesState = ?, protectiveDevicesStateComment = ?,
                            isSafeOperatingLimitChanged = ?, isSafeOperatingLimitChangedDate = ?, repairsRequired = ?, observations = ?,
                            writtenSchemeChanges = ?, safeToUse = ?, inServiceInspectionDate = ?, 
                            inServiceNextInspectionDate = ?, outOfServiceInspectionDate = ?,
                            outOfServiceNextInspectionDate = ?, jobId = ?, pressureInspectionPartId = ?, conditionOfParts = ?, conditionOfPartsDefect = ?,
                            missing = ?, toBeSynced = 1
                        WHERE id = ?
                    `;

                    tx.executeSql(sql, [
                        examinationType, testsMade, protectiveDevicesState, formattedProtectiveDevicesStateComment,
                        isSafeOperatingLimitChanged, formattedIsSafeOperatingLimitChangedDate, repairsRequired, observations,
                        writtenSchemeChanges, safeToUse, formattedInServiceInspectionDate,
                        formattedInServiceNextInspectionDate, formattedOutOfServiceInspectionDate, formattedOutOfServiceNextInspectionDate,
                        jobId, pressureInspectionPartId, conditionOfParts, conditionOfPartsDefect, missing, id
                    ]);

                    if (images.length > 0) {
                        const sqlDeleteImages = `DELETE FROM ${this.TABLE_NAME_IMAGES} WHERE inspectionId = ?`;
                        tx.executeSql(sqlDeleteImages, [id]);

                        const sqlInsertImage = `INSERT INTO ${this.TABLE_NAME_IMAGES} (id, inspectionId, base64) VALUES (?, ?, ?)`;
                        for (const image of images) {
                            tx.executeSql(sqlInsertImage, [uuid.v4(), id, image]);
                        }
                    }

                }, (error) => {
                    console.log("ERROR in PSSR updateInspection:");
                    console.log(error);
                    reject();
                }, () => {
                    console.log(`Inspection updated for Id: ${id}`);
                    resolve();
                });
            } catch (e) {
                console.log("ERROR inside PSSR updateInspection:");
                console.log(e);
                reject();
            }
        });
    }

    getByJobId(jobId: string): Promise<any[]> {
        return new Promise((resolve, reject) => {
            Database.getDb().transaction((tx) => {
                tx.executeSql(
                    `SELECT * FROM PssrInspections WHERE jobId = ? AND toBeSynced = 0`,
                    [jobId],
                    (_, { rows: { _array } }) => resolve(_array),
                    (_, error) => reject(error)
                );
            });
        });
    }

    updateInspectionToBeSynced(inspectionId: string, toBeSynced: boolean): Promise<void> {
        return new Promise((resolve, reject) => {
            Database.getDb().transaction((tx) => {
                tx.executeSql(
                    `UPDATE PssrInspections SET toBeSynced = ? WHERE id = ?`,
                    [toBeSynced ? 1 : 0, inspectionId],
                    () => resolve(),
                    (_, error) => reject(error)
                );
            });
        });
    }

    getCompletedInspection(inspectionId: any): Promise<any> {
        return new Promise((resolve, reject) => {
            var data = [];
            Database.getDb().transaction((tx) => {
                var sql = `
              SELECT * FROM PssrInspections
              WHERE id = ?
            `;

                tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => {
                    data = _array;

                    for (let inspection of data) {
                        sql = `SELECT base64 FROM ${this.TABLE_NAME_IMAGES} WHERE inspectionId = ?`;
                        tx.executeSql(sql, [inspection.id], (_, { rows: { _array } }) => {
                            inspection.images = [];
                            for (let image of _array) {
                                inspection.images.push(image.base64)
                            }
                        });
                    }
                });
            }, (error) => {
                console.log(error);
                reject();
            }, () => {
                resolve(data);
            })
        });
    }

    insertOrUpdatePssrPartsList(entities: any): Promise<void> {
        return new Promise((resolve, reject) => {
            Database.getDb().transaction((tx) => {
                for (let entity of entities) {
                    var sql = `
                INSERT OR REPLACE INTO ${this.TABLE_NAME_PARTS} (id, name)
                VALUES (?, ?)
              `

                    tx.executeSql(sql, [
                        entity.id, entity.name
                    ]);
                }
            }, (error) => {
                console.log(error);
                reject();
            }, () => {
                resolve();
            })
        })
    }

    getPssrParts(): Promise<any> {
        return new Promise((resolve, reject) => {
            var data = [];
            Database.getDb().transaction((tx) => {
                var sql = `
              SELECT * FROM PssrInspectionParts ORDER BY name
            `;

                tx.executeSql(sql, [], (_, { rows: { _array } }) => {
                    data = _array;
                });
            }, (error) => {
                console.log(error);
                reject();
            }, () => {
                resolve(data);
            })
        })
    }

}