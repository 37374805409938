import * as React from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert, Image, Switch } from 'react-native';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../../constants/GlobalStyle';
import { EvilIcons } from '@expo/vector-icons';
import { customerApi, useUpdateModulesMutation } from '@store/services/api/customer-api';
import { useDispatch } from 'react-redux';
import { setUserModules } from '@store/slices/user-slice';

export default function CustomerDashboardScreen({ navigation, route }: { navigation: any, route: any }) {
  const dispatch = useDispatch();

  const [modules, setModules] = React.useState<any>([]);
  const [updateModules, updateModulesResult] = useUpdateModulesMutation();

  const [trigger, customerResult] = customerApi.useLazyGetCustomerQuery();
  React.useEffect(() => {
    if (route.params?.customerId != null) {
      trigger(route.params?.customerId);
    }
  }, [])

  React.useEffect(() => {
    if (customerResult.isSuccess) {
      setModules(customerResult.data.modules);
    }
  }, [customerResult])

  const toggleModule = async (module) => {
    const oldModules = modules;

    var updatedModules = modules.map(item => {
      if (module.id == item.id) {
        return { ...item, enabled: !item.enabled }
      }

      return item;
    });

    setModules(updatedModules);

    var result = await updateModules(updatedModules) as any;
    if (result.error) {
      alert(result.error);
      setModules(oldModules);
      return;
    }

    // Update our user with these modules
    dispatch(setUserModules(updatedModules));
  }

  return (
    <View style={styles.container}>
      <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => navigation.goBack()}>
          <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>{customerResult?.data?.name} - Dashboard</Text>
      </View>



      <View style={styles.module__list}>
        {modules.map(module => {
          return (
            <View key={module.moduleName} style={styles.module}>
              <Text style={styles.module__title}>{module.moduleName}</Text>

              <View style={styles.module__body}>
                <Switch
                  // trackColor={{false: '#767577', true: '#81b0ff'}}
                  // thumbColor={module.enabled ? '#f5dd4b' : '#f4f3f4'}
                  // ios_backgroundColor="#3e3e3e"
                  onValueChange={() => toggleModule(module)}
                  value={module.enabled}
                />
              </View>
              
            </View>
          )
        })}
      </View>
    </View>
  )
};

const styles = StyleSheet.create({
    container: {
      flex: 1,
      position: 'relative',
      padding: 10,
    },

    module__list: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap'
    },

      module: {
        flex: 1,
        height: 100,
        backgroundColor: '#ebebeb',
        margin: 10,
        borderRadius: 5,
        minWidth: 125,
        maxWidth: 125
      },

        module__title: {
          textAlign: 'center',
          padding: 10,
          backgroundColor: PRIMARY_COLOUR,
          color: '#FFF',
          fontFamily: 'OpenSans-Bold',
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
        },

        module__body: {
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }
  });
  