import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export interface EquipmentInspectionSlice {
  // Inspection Data
  showEquipmentInspectionModal: boolean,
  equipment: Array<any> | [],
}

const initialState: BasketState = {
  showInspectionModal: false,
  equipment: [],
}

export const equipmentInspectionSlice = createSlice({
  name: 'equipmentInspection',
  initialState,
  reducers: {
    setShowEquipmentInspectionModal: (state, action: PayloadAction<any>) => {
      state.showEquipmentInspectionModal = action.payload;
    },

    addEquipmentInspection: (state, action: PayloadAction<any>) => {
      if (state.equipment.filter(i => i.id == action.payload.id).length > 0)
        return;

      state.equipment = [...state.equipment, action.payload]
    },
    addMultipleEquipmentInspections: (state, action: PayloadAction<any[]>) => {
      const newEquipment = action.payload.filter(newEquipment =>
        !state.equipment.some(equipment => equipment.id === newEquipment.id));
      state.equipment.push(...newEquipment);
    },

    removeEquipmentInspection: (state, action: PayloadAction<any>) => {
      state.equipment = state.equipment.filter(i => i.id != action.payload);
    },

    removeMultipleEquipmentInspection: (state, action: PayloadAction<any>) => {
      state.equipment = state.equipment.filter(existingEquipment =>
        !action.payload.some(equipment => equipment.id === existingEquipment.id));
    },

    clearEquipmentBasket: (state, action: PayloadAction) => {
      state.equipment = [];
    },
  }
})

export const {
  addEquipmentInspection, removeEquipmentInspection, clearEquipmentBasket,
  setShowEquipmentInspectionModal, addMultipleEquipmentInspections, removeMultipleEquipmentInspection
} = equipmentInspectionSlice.actions;

export default equipmentInspectionSlice.reducer;