import * as React from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert } from 'react-native';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { EvilIcons } from '@expo/vector-icons';
import { Controller, useForm } from 'react-hook-form';
import { LoadingIndicator } from '../../../../components/navigation/controls/LoadingIndicator';

import { useIsFocused } from "@react-navigation/native";
import { locationApi, useCreateLocationMutation, useGetLocationQuery, useUpdateLocationMutation } from '../../../../../../store/services/api/location-api';
import { useGetAllCompaniesQuery } from '../../../../../../store/services/api/company-api';
import { Picker } from '@react-native-picker/picker';


export default function LocationManageScreen({navigation, route}: {navigation: any, route: any}) {
  const [createLocation, createLocationResult] = useCreateLocationMutation();
  const [updateLocation, updateLocationResult] = useUpdateLocationMutation();

  const [trigger, locationResult] = locationApi.useLazyGetLocationQuery();
  const { data: companies } = useGetAllCompaniesQuery(null);

  const { control, handleSubmit, getValues, setValue, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      name: "",
      address1: "",
      address2: "",
      address3: "",
      address4: "",
      postcode: "",
      companyId: "",
    }
  });

  React.useEffect(() => {
    if (route.params?.locationId != null) {
      trigger(route.params?.locationId);
    }
  }, [])

  React.useEffect(() => {
    if (locationResult.isSuccess) {
      var data = locationResult.data;
      setValue("id", data.id);
      setValue("name", data.name);
      setValue("address1", data.address1);
      setValue("address2", data.address2);
      setValue("address3", data.address3);
      setValue("address4", data.address4);
      setValue("postcode", data.postcode);
      setValue("companyId", data.companyId);
    }
  }, [locationResult]);

  const onSubmit = async (data) => {
    var result = null;

    if (data.id != null) {
      result = await updateLocation(data) as any;
    } else {
      result = await createLocation(data) as any;
    }

    console.log(result);
    
    if (result.error) {
      return;
    }

    navigation.goBack();
  };

  if (createLocationResult.isLoading || updateLocationResult.isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <View style={styles.container}>
      <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon}  onPress={() => navigation.goBack() }>
          <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>{getValues("name") == null ? "CREATE LOCATION" : "UPDATE LOCATION"}</Text>
      </View>
      
      <View style={GlobalStyle.form}>
        {/* ##### ROW ##### */}
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="name"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Location Name (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.name && <Text style={GlobalStyle.form__input__validation}>Name is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="address1"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Address Line 1 (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.address1 && <Text style={GlobalStyle.form__input__validation}>Address Line 1 is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="address2"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Address Line 2</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>
        </View>

        {/* ##### ROW ##### */}
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="address3"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Address Line 3</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="address4"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Address Line 4</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="postcode"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Postcode (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.postcode && <Text style={GlobalStyle.form__input__validation}>Postcode is required.</Text>}
          </View>
        </View>

        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="companyId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Company (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) =>
                      onChange(itemValue)
                    }>

                    <Picker.Item label="Please Select" value="" />

                    { companies != null && companies.map((value, index) => {
                      return (
                        <Picker.Item key={value.id} label={value.name} value={value.id} />
                      )
                    })}
                  </Picker>
                </View>
              )}
            />

            {errors.companyId && <Text style={GlobalStyle.form__input__validation}>Company is required.</Text>}
          </View>
        </View>

        <TouchableOpacity style={GlobalStyle.form__submit__button} onPress={handleSubmit(onSubmit)}>
          <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
