import { baseApi, PagedTableDto } from './base-api'

export const serviceApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getServicingInspections: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `servicing/GetServiceInspections?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    syncServiceQuestions: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `servicing/getservicequestions?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    syncServiceQuestionsResponses: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `servicing/getservicequestionresponses?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    syncServiceQuestionCategories: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `servicing/getservicequestioncategories?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    sendServiceInspection: build.mutation<any, any>({
      query: (body) => ({
        url: 'servicing/addservice',
        method: 'post',
        body: body
      }),
    }),
    // ---- SERVICE CATEGORIES
    getServiceCategories: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `servicing/GetServiceCategoriesList?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    createServiceCategory: build.mutation<any, any>({
      query: (body) => ({
        url: 'servicing/addservicecategory',
        method: 'post',
        body: body
      }),
    }),
    updateServiceCategory: build.mutation<any, any>({
      query: (body) => ({
        url: 'servicing/updateservicecategory',
        method: 'put',
        body: body
      }),
    }),
    deleteServiceCategory: build.mutation<any, any>({
      query: (id) => ({
        url: `servicing/deleteservicecategory/${id}`,
        method: 'delete',
      }),
    }),
    transferServiceCategory: build.mutation<any, any>({
      query: (body) => ({
        url: 'servicing/transferServiceCategory',
        method: 'post',
        body: body
      }),
    }),
    // ---- SERVICE QUESTIONS
    getServiceQuestions: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `servicing/getservicequestionslist?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    createServiceQuestion: build.mutation<any, any>({
      query: (body) => ({
        url: 'servicing/addservicequestion',
        method: 'post',
        body: body
      }),
    }),
    updateServiceQuestion: build.mutation<any, any>({
      query: (body) => ({
        url: 'servicing/updateservicequestion',
        method: 'put',
        body: body
      }),
    }),
    deleteServiceQuestion: build.mutation<any, any>({
      query: (id) => ({
        url: `servicing/deleteservicequestion/${id}`,
        method: 'delete',
      }),
    }),
    // ---- SERVICE QUESTION RESPONSES
    getServiceQuestionResponses: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `servicing/getservicequestionresponseslist?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    createServiceQuestionResponse: build.mutation<any, any>({
      query: (body) => ({
        url: 'servicing/addservicequestionresponse',
        method: 'post',
        body: body
      }),
    }),
    updateServiceQuestionResponse: build.mutation<any, any>({
      query: (body) => ({
        url: 'servicing/updateservicequestionresponse',
        method: 'put',
        body: body
      }),
    }),
    deleteServiceQuestionResponse: build.mutation<any, any>({
      query: (id) => ({
        url: `servicing/deleteservicequestionresponse/${id}`,
        method: 'delete',
      }),
    }),
    archiveServicingInspection: build.mutation<any, { id: number }>({
      query: (id) => ({
        url: `servicing/archiveservicinginspection/${id}`,
        method: 'put'
      }),
    }),
  }),
  overrideExisting: true,
})

export const {
  useSyncServiceQuestionsMutation,
  useSyncServiceQuestionCategoriesMutation,
  useSendServiceInspectionMutation,
  useGetServicingInspectionsQuery,
  useGetServiceCategoriesQuery,
  useLazyGetServiceQuestionResponsesQuery,
  useCreateServiceCategoryMutation,
  useUpdateServiceCategoryMutation,
  useDeleteServiceCategoryMutation,
  useTransferServiceCategoryMutation,
  useGetServiceQuestionsQuery,
  useCreateServiceQuestionMutation,
  useUpdateServiceQuestionMutation,
  useDeleteServiceQuestionMutation,
  useGetServiceQuestionResponsesQuery,
  useCreateServiceQuestionResponseMutation,
  useUpdateServiceQuestionResponseMutation,
  useDeleteServiceQuestionResponseMutation
} = serviceApi