import {HelpCentreVideo} from "@store/services/api/help-centre-api";
import {Pressable, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {Feather} from "@expo/vector-icons";
import React from "react";


interface VideoCardProps {
  video: HelpCentreVideo,
  showEdit: boolean,
  onShowVideoModal: (video: HelpCentreVideo) => void,
  onShowManageModal: (video: HelpCentreVideo) => void,
}

export function VideoCard({video, onShowVideoModal, showEdit, onShowManageModal}: VideoCardProps) {
  if (video == null) return null;
  return (
    <View style={styles.card}>
      <TouchableOpacity style={styles.thumbnail} onPress={() => onShowVideoModal(video)}>
        <Feather color='#ffffff' size={48} name='play-circle'/>
      </TouchableOpacity>
      <Text style={styles.title}>{video.title}</Text>
      <Text style={styles.description}>{video.description}</Text>
      {showEdit && (
        <Pressable style={styles.manageOptionContainer} onPress={() => onShowManageModal(video)}>
          <Feather color='#444' size={16} name='edit'/>
        </Pressable>
      )}
    </View>
  )
}
const styles = StyleSheet.create({
  card: {
    position: 'relative',
    backgroundColor: '#f1f1f1',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {width: 1, height: 2},
    shadowOpacity: 0.3,
    shadowRadius: 2,
    elevation: 5,
    padding: 10,
    width: 250,
    height: 250,
    margin: 10
  },
  thumbnail: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 125,
    backgroundColor: '#888',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginVertical: 10,
  },
  description: {
    fontSize: 14,
  },
  manageOptionContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    padding: 12
  }
});
