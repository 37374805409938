import {baseApi} from './base-api'

export const helpCentreApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getHelpCentreVideos: build.query<HelpCentreVideo[], void>({
      query: () => ({
        url: `helpcentre/getvideos`,
      }),
    }),
    removeHelpCentreVideo: build.mutation<void, string>({
      query: (fileName: string) => {
        const params = new URLSearchParams();
        params.append('fileName', fileName);
        return {
          url: `helpcentre/removeVideo?${params.toString()}`,
          method: 'DELETE'
        }
      },
    }),
    createHelpCentreVideo: build.mutation<void, HelpCentreVideoForm>({
      query: (data: HelpCentreVideoForm) => {
        const formData = new FormData();
        formData.append('title', data.title);
        formData.append('description', data.description);
        formData.append('file', data.file);
        formData.append('requiredRoles', data.requiredRoles.join(','));

        return {
          url: `helpcentre/postvideo`,
          method: 'POST',
          body: formData
        }
      },
    }),
    updateHelpCentreVideo: build.mutation<void, HelpCentreVideoForm>({
      query: (data: HelpCentreVideoForm) => {
        const formData = new FormData();
        formData.append('title', data.title);
        formData.append('description', data.description);
        formData.append('fileName', data.fileName);
        formData.append('requiredRoles', data.requiredRoles.join(','));

        return {
          url: `helpcentre/updatevideo`,
          method: 'POST',
          body: formData
        }
      },
    }),
  }),
  overrideExisting: true,
})

export const {
  useGetHelpCentreVideosQuery,
  useRemoveHelpCentreVideoMutation,
  useCreateHelpCentreVideoMutation,
  useUpdateHelpCentreVideoMutation,
} = helpCentreApi;

export interface HelpCentreVideo {
  url: string;
  title: string;
  description: string;
  fileName: string;
  requiredRoles: string;
}

export interface HelpCentreVideoForm {
  title: string;
  description: string;
  requiredRoles: string[];
  fileName?: string;
  file?: File;
}

