import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useFonts } from 'expo-font';
import { Provider } from 'react-redux';
import { store } from './store'
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import LoginScreen from './screens/LoginScreen';
import WebNavigator from './screens/web/screens/WebNavigator';
import AppNavigator from './screens/app/screens/AppNavigator';

import { LogBox } from 'react-native';

const Stack = createNativeStackNavigator();

const DefaultHeaderOptions = (title, showBackButton, navigation) => {
  return {
    title: title,
    headerBackVisible: showBackButton,
    headerStyle: {
      backgroundColor: '#006BFF',
    },
    headerTintColor: '#FFF',
    headerRight: () => (
      <TouchableOpacity onPress={() => logout(navigation)}>
        <Text style={{ color: '#FFF'}}>LOGOUT</Text>
      </TouchableOpacity>
    ),
  }
}

//const WebStack = create

// https://shopify.github.io/flash-list/
// https://blog.expo.dev/media-queries-with-react-native-for-ios-android-and-web-e0b73ed5777b
// https://dribbble.com/shots/15258503-SaaS-File-Management-Dashboard
const persistor = persistStore(store);

LogBox.ignoreLogs([
  "Looks like you're passing an inline function for 'component'", 
  "Deprecation warning: value", 
  "FlashList's rendered", 
  "redux-persist failed to create sync storage. falling back to noop storage.",
]);

export default function App() {
  const [fontsLoaded] = useFonts({
    'OpenSans-Light': require('./assets/fonts/Open_Sans/static/OpenSans/OpenSans-Light.ttf'),
    'OpenSans-Regular': require('./assets/fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf'),
    'OpenSans-Bold': require('./assets/fonts/Open_Sans/static/OpenSans/OpenSans-Bold.ttf'),
    'OpenSans-ExtraBold': require('./assets/fonts/Open_Sans/static/OpenSans/OpenSans-ExtraBold.ttf'),
  });

  if (!fontsLoaded) {
    return null;
  }


  // useEffect(() => {
  //   checkForUpdateAsync();
  // }, []);



  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <NavigationContainer linking={{ enabled: true }}>
          <Stack.Navigator options={{ animationEnabled: true }}>
            <Stack.Screen name="login" component={LoginScreen} options={{ headerShown: false,  }} />
            <Stack.Screen name="web" component={WebNavigator} options={{ headerShown: false, title: "Web" }}  />
            <Stack.Screen name="app" component={AppNavigator} options={{ headerShown: false, title: "App" }}  />
          </Stack.Navigator>
        </NavigationContainer>
      </PersistGate>
    </Provider>
  );
}
