import * as SQLite from 'expo-sqlite';
import { Database } from './database';
import uuid from 'react-native-uuid';

export class EngineerReportDatabase extends Database {
  TABLE_NAME = "EngineerReports";
  TABLE_NAME_ANSWERED_QUESTIONS = "EngineerReportAnsweredQuestions";
  TABLE_NAME_TIMESHEETS = "EngineerReportTimesheets";
  TABLE_NAME_PARTS = "EngineerReportParts";
  TABLE_NAME_QUESTIONS = "EngineerReportQuestions";

  initialise() : Promise<void> {
    return new Promise((resolve, reject) => {
      var sql = `CREATE TABLE IF NOT EXISTS EngineerReports ( 
        id TEXT PRIMARY KEY,
        engineerId TEXT,
        jobId TEXT,
        worksCarriedOut TEXT,
        furtherWorks TEXT,
        jobCompleted INT,
        clientName TEXT,
        clientSignature TEXT,
        customerPoNumber TEXT,
        toBeSynced INT
      )`;

      var sqlAnsweredQuestions = `CREATE TABLE IF NOT EXISTS EngineerReportAnsweredQuestions ( 
        id TEXT PRIMARY KEY,
        engineerReportId TEXT,
        question TEXT,
        response TEXT,
        position INT,
        engineerReportQuestionId TEXT
      )`;

      var sqlTimesheets = `CREATE TABLE IF NOT EXISTS EngineerReportTimesheets ( 
        id TEXT PRIMARY KEY,
        day TEXT,
        normalTime REAL,
        overTime REAL,
        doubleTime REAL,
        startTime TEXT,
        endTime TEXT,
        miles REAL,
        engineerReportId INT
      )`;

      var sqlParts = `CREATE TABLE IF NOT EXISTS EngineerReportParts ( 
        id TEXT PRIMARY KEY,
        partId INT,
        engineerReportId TEXT
      )`;

      var sqlQuestions = `CREATE TABLE IF NOT EXISTS EngineerReportQuestions ( 
        id TEXT PRIMARY KEY,
        question TEXT,
        boolResponse INT,
        position INT,
        isDeleted INT
      )`;

      Database.getDb().transaction((tx) => {
        tx.executeSql(sql);
        tx.executeSql(sqlAnsweredQuestions);
        tx.executeSql(sqlTimesheets);
        tx.executeSql(sqlParts);
        tx.executeSql(sqlQuestions);
      }, (error) => {
        console.log(error)
        reject();
      }, () => {
        resolve();
      })
    });
  }

  getById(engineerReportId) : Promise<any> {
    return new Promise((resolve, reject) => {
      var engineerReport = null;
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM ${this.TABLE_NAME} WHERE id = ?
        `;

        tx.executeSql(sql, [engineerReportId], (_, { rows: { _array } }) => {
          if (_array.length > 0) {
            engineerReport = _array[0];

            // Load answered questions
            engineerReport.answeredQuestions = [];
            sql = `SELECT * FROM ${this.TABLE_NAME_ANSWERED_QUESTIONS} WHERE engineerReportId = ?`;
            tx.executeSql(sql, [engineerReportId], (_, { rows: { _array } }) => {
              if (_array.length > 0) {
                engineerReport.answeredQuestions = _array;
              }
            });

            // Load timesheets
            engineerReport.timesheets = [];
            sql = `SELECT * FROM ${this.TABLE_NAME_TIMESHEETS} WHERE engineerReportId = ?`;
            tx.executeSql(sql, [engineerReportId], (_, { rows: { _array } }) => {
              if (_array.length > 0) {
                engineerReport.timesheets = _array;
              }
            });

            // Load parts
            engineerReport.parts = [];
            sql = `SELECT * FROM ${this.TABLE_NAME_PARTS} WHERE engineerReportId = ?`;
            tx.executeSql(sql, [engineerReportId], (_, { rows: { _array } }) => {
              if (_array.length > 0) {
                engineerReport.parts = _array;
              }
            });
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(engineerReport);
      })
    })
  }

  addEngineerReport(engineerReportId: any, engineerId: any, jobId: any, worksCarriedOut: any, furtherWorks: any, 
    jobCompleted: any, clientName: any, clientSignature: any, customerPoNumber: any, timesheets: any, parts: any, answeredQuestions: any) : Promise<string> {

    if (engineerReportId == null) {
      engineerReportId = uuid.v4();
    }

    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
          INSERT OR REPLACE INTO ${this.TABLE_NAME} (id, engineerId, jobId, worksCarriedOut, furtherWorks, jobCompleted, clientName, clientSignature, customerPoNumber, toBeSynced)
          VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, 1)
        `

        tx.executeSql(sql, [engineerReportId, engineerId, jobId, worksCarriedOut, furtherWorks, jobCompleted, clientName, clientSignature, customerPoNumber]);
        
        //Insert question
        sql = `
          INSERT OR REPLACE INTO ${this.TABLE_NAME_ANSWERED_QUESTIONS} (id, engineerReportId, question, response, position, engineerReportQuestionId)
          VALUES (?, ?, ?, ?, ?, ?)
        `;

        console.log(answeredQuestions);
        for (let question of answeredQuestions) {
          var id = question.id;
          if (id == null) { id = uuid.v4() }
          tx.executeSql(sql, [
            id, engineerReportId, question.question, question.response, question.position, question.engineerReportQuestionId
          ]);
        }

        //Insert timesheets
        sql = `
          INSERT OR REPLACE INTO ${this.TABLE_NAME_TIMESHEETS} (id, day, normalTime, overTime, doubleTime, startTime, endTime, miles, engineerReportId)
          VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?)
        `;

        for (let timesheet of timesheets) {
          var id = timesheet.id;
          if (id == null) { id = uuid.v4() }
          tx.executeSql(sql, [
            id, timesheet.day, timesheet.normalTime, timesheet.overTime, timesheet.doubleTime, 
            timesheet.startTime, timesheet.endTime, timesheet.miles, engineerReportId
          ]);
        }

        sql = `DELETE FROM ${this.TABLE_NAME_PARTS} WHERE engineerReportId = ?`;
        tx.executeSql(sql, [engineerReportId]);

        //Insert parts
        sql = `
          INSERT OR REPLACE INTO ${this.TABLE_NAME_PARTS} (id, partId, engineerReportId)
          VALUES (?, ?, ?)
        `;

        for (let part of parts) {
          var id = uuid.v4() as any;
          tx.executeSql(sql, [id, part.id, engineerReportId]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(engineerReportId.toString());
      });
    });
  }

  getForSync() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM ${this.TABLE_NAME} WHERE toBeSynced = 1
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;

          var sqlAnsweredQuestions = `SELECT * FROM ${this.TABLE_NAME_ANSWERED_QUESTIONS} WHERE engineerReportId = ?`;
          var sqlTimesheets = `SELECT * FROM ${this.TABLE_NAME_TIMESHEETS} WHERE engineerReportId = ?`;
          var sqlParts = `SELECT * FROM ${this.TABLE_NAME_PARTS} WHERE engineerReportId = ?`;
          for (let engineerReport of data) {
            tx.executeSql(sqlAnsweredQuestions, [engineerReport.id], (_, { rows: { _array } }) => {
              engineerReport.answeredQuestions = _array;
            });

            tx.executeSql(sqlTimesheets, [engineerReport.id], (_, { rows: { _array } }) => {
              engineerReport.timesheets = _array;
            });

            tx.executeSql(sqlParts, [engineerReport.id], (_, { rows: { _array } }) => {
              engineerReport.parts = _array;
            });
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  updateEngineerReportToBeSynced(id, toBeSynced) : Promise<boolean> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
          UPDATE ${this.TABLE_NAME} SET toBeSynced = ? WHERE id = ?
        `;

        tx.executeSql(sql, [toBeSynced, id], (_, { rows: { _array } }) => {
          
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(true);
      })
    });
  }

  insertOrUpdateEngineerReportQuestions(entities: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME_QUESTIONS} (id, question, boolResponse, position, isDeleted)
            VALUES (?, ?, ?, ?, ?)
          `

          tx.executeSql(sql, [
            entity.id, entity.question, entity.boolResponse, entity.position, entity.isDeleted
          ]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  getEngineerReportQuestions() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM EngineerReportQuestions WHERE isDeleted = 0 ORDER BY position
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

}