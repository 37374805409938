import { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity } from 'react-native';

import { useGetEngineersQuery } from '@store/services/api/user-api';
import GlobalStyle from '../../../../../constants/GlobalStyle';
import moment from 'moment';
import { Picker } from '@react-native-picker/picker';
import DateTimePicker from '@web/components/navigation/controls/DateTimePicker';
import { Foundation, Entypo, SimpleLineIcons} from '@expo/vector-icons';
import { erstrApi } from '@store/services/api/erstr-api';
import { PagedTableDto } from '@store/services/api/base-api';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { Basket, BasketIcon } from '@web/components/Basket';
import { DataTable } from 'react-native-paper';
import { useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { ReportableService } from '@store/services/reportable/reportable.service';
import Spinner from 'react-native-loading-spinner-overlay';
import React from 'react';
import { DataTableHeader } from '@web/components/table/DatatableHeader';
import PaginationComponent from '@web/components/Pagination';
import { MAX_ITEMS_BEFORE_ALL, FIRST_PAGE } from '../../../../../constants/Constants';
import {hasAdminPermissions} from "@web/screens/AuthenticatedScreens/System/Permissions/permissionsUtils";

export default function EngineerReportsScreen({ navigation }: { navigation: any }) {
  const { data: engineers } = useGetEngineersQuery(null);
  const user = useSelector((state: RootState) => state.user.user);
  const isAdmin = hasAdminPermissions(user);
  const [loading, setLoading] = useState<any>(false);
  const [sortedColumn, setSortedColumn] = React.useState<string>("inspectionDate");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(false);
  const [onBasketClose, setOnBasketClose] = useState<boolean>(false);

  const [engineerId, setEngineerId] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [startDate, setStartDate] = useState(moment().add(-1, 'months').toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [jobNumber, setJobNumber] = useState("");
  const [jobType, setJobType] = useState(-1);
  const [yourReference, setYourReference] = useState("");

  const [page, setPage] = useState<number>(0);
  const [trigger, inspectionResult] = erstrApi.useLazyGetEngineerReportsQuery();
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);

  useEffect(() => {
    loadTable();
  }, [page, sortedColumn, sortedColumnAscending, numberOfItemsPerPage, onBasketClose])

  const updateDateRange = (val) => {
    setDateRange(val);

    let startDate = moment();
    const endDate = moment();
    if (val == "-1") {
      startDate = moment().add(-1, 'week');
    } else {
      startDate = moment().add(-Number.parseInt(val), 'M');
    }

    setStartDate(startDate.toDate());
    setEndDate(endDate.toDate());
  }

  const resetFilters = () => {
    setEngineerId("");
    setDateRange("");
    setStartDate(moment().add(-1, 'months').toDate());
    setEndDate(moment().toDate());
    setJobNumber("");
    setJobType(-1);
    setYourReference("");
  }

  function applyFilters() {
    setPage((curr) => {
      if (curr == 0) loadTable();
      return 0;
    })
  }

  const loadTable = () => {
    const pageNumber = numberOfItemsPerPage > MAX_ITEMS_BEFORE_ALL ? FIRST_PAGE : page;
    var dto = new PagedTableDto(pageNumber, {
      engineerId: engineerId,
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString(),
      jobNumber: jobNumber,
      jobType: jobType,
      companyId: user.role === 'User' ? user.companyId : null,
      yourReference: yourReference,
      pageSize: numberOfItemsPerPage
    }, sortedColumn, sortedColumnAscending);

    trigger(dto).then(() => {
      setLoading(false);
    });
  }

  const downloadCert = async (engineerReport) => {
    setLoading(true);
    var reportableService = new ReportableService();

    var data = await reportableService.downloadERSTR(engineerReport.id);
    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data.data, data.filename);
      setLoading(false);
    }
  }

  if (inspectionResult.isLoading) {
    return <LoadingIndicator />
  }


  return (
    <View>
      <Spinner
        visible={loading}
        textContent={'Loading...'}
        textStyle={GlobalStyle.spinnerTextStyle}
      />
      <View style={styles.container}>
        <View style={GlobalStyle.filters}>
          <View style={GlobalStyle.filters__header}>
            <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
          </View>

          <View style={GlobalStyle.filter__columns}>
            <View style={GlobalStyle.column__flex}>
              <Text style={GlobalStyle.column__header}>Engineers</Text>
              <Picker
                style={[GlobalStyle.column, GlobalStyle.column__input]}
                enabled={user.role != 'User'}
                selectedValue={engineerId}
                onValueChange={(itemValue, itemIndex) => setEngineerId(itemValue)}>

                <Picker.Item label="All" value="" />

                {engineers != null && engineers.map((value, index) => {
                  return (
                    <Picker.Item key={value.id} label={value.name} value={value.id} />
                  )
                })}
              </Picker>
            </View>

            <View style={GlobalStyle.column__flex}>
              <Text style={GlobalStyle.column__header}>Job #</Text>
              <TextInput
                style={[GlobalStyle.column, GlobalStyle.column__input]}
                onChangeText={setJobNumber}
                value={jobNumber}
              />
            </View>

            <View style={GlobalStyle.column__flex}>
              <Text style={GlobalStyle.column__header}>Job Type</Text>
              <Picker
                style={[GlobalStyle.column, GlobalStyle.column__input]}
                selectedValue={jobType}
                onValueChange={(itemValue, itemIndex) => setJobType(itemValue)}>

                <Picker.Item label="All" value={-1} />
                <Picker.Item key={0} label={"ROTE"} value={0} />
                <Picker.Item key={1} label={"Servicing"} value={1} />
                <Picker.Item key={2} label={"Repair/Breakdown"} value={2} />
                <Picker.Item key={3} label={"Proof Load"} value={3} />
              </Picker>
            </View>

            <View style={GlobalStyle.column__flex}>
              <Text style={GlobalStyle.column__header}>Your Reference</Text>
              <TextInput
                style={[GlobalStyle.column, GlobalStyle.column__input]}
                onChangeText={setYourReference}
                value={yourReference}
              />
            </View>

            <View style={GlobalStyle.column__flex}></View>
          </View>

          <View style={GlobalStyle.filter__columns}>
            <View style={GlobalStyle.column__flex}>
              <Text style={GlobalStyle.column__header}>Date Range</Text>
              <Picker
                style={[GlobalStyle.column, GlobalStyle.column__input]}
                selectedValue={dateRange}
                onValueChange={(itemValue, itemIndex) => updateDateRange(itemValue)}>

                <Picker.Item label="Predefined Date Range" value="" />
                <Picker.Item label="1 Week" value="-1" />
                <Picker.Item label="1 Month" value="1" />
                <Picker.Item label="3 Months" value="3" />
                <Picker.Item label="6 Months" value="6" />
                <Picker.Item label="12 Months" value="12" />

              </Picker>
            </View>

            <View style={GlobalStyle.column__flex}>
              <Text style={GlobalStyle.column__header}>Start Date</Text>
              <DateTimePicker
                style={[GlobalStyle.column, GlobalStyle.column__input]}
                onChange={setStartDate}
                value={startDate}>
              </DateTimePicker>
            </View>

            <View style={GlobalStyle.column__flex}>
              <Text style={GlobalStyle.column__header}>End Date</Text>
              <DateTimePicker
                style={[GlobalStyle.column, GlobalStyle.column__input,]}
                onChange={setEndDate}
                value={endDate}>
              </DateTimePicker>
            </View>

            <View style={GlobalStyle.column__flex}>
              <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={applyFilters}>
                  <SimpleLineIcons name="magnifier" size={16} color="white" />
                </TouchableOpacity>

                <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                  <SimpleLineIcons name="ban" size={16} color="black" />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>

        <Basket onBasketClose={onBasketClose} setOnBasketClose={setOnBasketClose} />

        <DataTable style={GlobalStyle.table}>
          <DataTableHeader
            sortedColumn={sortedColumn}
            sortedColumnAscending={sortedColumnAscending}
            onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
            headers={[
              { text: 'Basket', visible: true },
              { text: 'Report', visible: true },
              { text: 'Job #', sortedName: "jobNumber", visible: true },
              { text: 'Engineer', sortedName: "engineer", visible: true },
              { text: 'Location', sortedName: "locationName", visible: true },
              { text: 'Company', sortedName: "companyName", visible: true },
              { text: 'Created', sortedName: "inspectionDate", visible: true },
              { text: 'Job Type', sortedName: "jobTypeFormatted", visible: true },
              { text: 'Options', visible: true }
            ]}
          />
          {inspectionResult.data?.list.map((element: any) => {
            return (
              <DataTable.Row key={element.id}>
                <DataTable.Cell>
                  <BasketIcon inspectionId={element.id} serialNumber={element.jobNumber} type={"erstr"} masterCategoryName={null} />
                </DataTable.Cell>
                <DataTable.Cell>
                  <View style={{ flexDirection: 'row' }}>
                    <TouchableOpacity onPress={() => downloadCert(element)}><Foundation name="page-pdf" size={24} color="black" /></TouchableOpacity>
                  </View>
                </DataTable.Cell>
                <DataTable.Cell>{element.jobNumber}</DataTable.Cell>
                <DataTable.Cell>{element.engineer}</DataTable.Cell>
                <DataTable.Cell>{element.locationName}</DataTable.Cell>
                <DataTable.Cell>{element.companyName}</DataTable.Cell>
                <DataTable.Cell>{element.inspectionDateFormatted}</DataTable.Cell>
                <DataTable.Cell>{element.jobTypeFormatted}</DataTable.Cell>
                <DataTable.Cell>
                  {isAdmin &&
                    <View style={{ flexDirection: 'row'}}>
                      <TouchableOpacity onPress={() => navigation.navigate("erstr-edit", { id: element.id })}>
                        <Entypo name="pencil" size={24} color="black" />
                      </TouchableOpacity>
                    </View>
                  }
                </DataTable.Cell>
              </DataTable.Row>
            )
          })}
        </DataTable>

      </View>
      <PaginationComponent
        numberOfItemsPerPage={numberOfItemsPerPage}
        setNumberOfItemsPerPage={setNumberOfItemsPerPage}
        page={page}
        setPage={setPage}
        fetchResult={inspectionResult}
        loading={loading}
        setLoading={setLoading}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },

});
