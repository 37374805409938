import React, {useState} from "react";
import {VideoCard} from "@web/screens/AuthenticatedScreens/Support/HelpCentre/Components/VideoCard";
import {FlatList, TouchableOpacity, StyleSheet} from "react-native";
import {HelpCentreVideo, useGetHelpCentreVideosQuery} from "@store/services/api/help-centre-api";
import {useSelector} from "react-redux";
import {RootState} from "@store/index";
import {ManageVideoModal} from "@web/screens/AuthenticatedScreens/Support/HelpCentre/Components/ManageVideoModal";
import {VideoViewModal} from "@web/screens/AuthenticatedScreens/Support/HelpCentre/Components/VideoViewModal";
import {Feather} from "@expo/vector-icons";
import {EmptyListIndicator} from "@components/FlatListComponents";
import {PRIMARY_COLOUR} from "../../../../../../../constants/GlobalStyle";

enum ModalType {
  NONE,
  VIDEO,
  MANAGE
}

export function VideoSection() {
  const {data: videos, isFetching, isError, refetch} = useGetHelpCentreVideosQuery();
  const [modalVisible, setModalVisible] = useState<ModalType>(ModalType.NONE);
  const [selectedVideo, setSelectedVideo] = useState<HelpCentreVideo>(null);
  const user = useSelector((state: RootState) => state.user.user);
  const isSystemUser = user.role === "System";

  function handleShowVideo(video: HelpCentreVideo) {
    setSelectedVideo(video);
    setModalVisible(ModalType.VIDEO);
  }

  function handleShowManage(video: HelpCentreVideo) {
    setSelectedVideo(video);
    setModalVisible(ModalType.MANAGE);
  }

  function hideModal() {
    setModalVisible(ModalType.NONE);
  }

  const emptyListText = isError ? 'There was an issue retrieving videos' : 'No videos available';

  return (
    <>
      {isSystemUser && (
        <TouchableOpacity
          style={styles.addButton}
          onPress={() => handleShowManage(null)}>
          <Feather name="plus" size={24} color="#fff"/>
        </TouchableOpacity>
      )}
      <FlatList
        data={videos}
        renderItem={({item}) => (
          <VideoCard
            video={item}
            showEdit={isSystemUser}
            onShowVideoModal={handleShowVideo}
            onShowManageModal={handleShowManage}/>
        )}
        contentContainerStyle={styles.container}
        keyExtractor={(item, index) => index.toString()}
        ListEmptyComponent={<EmptyListIndicator isLoading={isFetching} emptyText={emptyListText} />}
        horizontal>
      </FlatList>
      <VideoViewModal
        visible={modalVisible === ModalType.VIDEO}
        video={selectedVideo}
        onRequestClose={hideModal}/>
      <ManageVideoModal
        visible={modalVisible === ModalType.MANAGE}
        video={selectedVideo}
        onRequestClose={hideModal}
        onSuccess={() => {
          refetch();
          hideModal();
        }}/>
    </>
  )
}

const styles = StyleSheet.create({
  addButton: {
    position: 'absolute',
    top: 12,
    right: 12,
    backgroundColor: PRIMARY_COLOUR,
    borderRadius: 9999,
    padding: 8,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 15,
    zIndex: 10
  },
  container: {
    paddingBottom: 12
  }
});
