import * as React from 'react';
import { useState } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, ScrollView } from 'react-native';

import { DataTable } from 'react-native-paper';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { useIsFocused } from "@react-navigation/native";
import { Entypo, SimpleLineIcons, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { depotApi, useUpdateDepotMutation } from '@store/services/api/depot-api';
import { PagedTableDto } from '@store/services/api/base-api';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { DataTableHeader } from '@web/components/table/DatatableHeader';
import PaginationComponent from '@web/components/Pagination';
import { MAX_ITEMS_BEFORE_ALL, FIRST_PAGE } from '../../../../../../constants/Constants';
import Spinner from 'react-native-loading-spinner-overlay';

export default function DepotsListScreen({ navigation }: { navigation: any }) {
    const isFocused = useIsFocused();

    const [loading, setLoading] = useState<any>(false);
    const [page, setPage] = React.useState<number>(0);
    const [sortedColumn, setSortedColumn] = React.useState<string>("name");
    const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);
    const [isStatusChanged, setIsStatusChanged] = React.useState<boolean>(false);
    const [updateDepot, updateDepotResult] = useUpdateDepotMutation();

    const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);


    // Filters 
    const [depotName, setDepotName] = useState("");
    const [address1, setAddress1] = useState("");

    const [trigger, depotsResult] = depotApi.useLazyGetDepotsQuery();

    React.useEffect(() => {
        if (isFocused) {
            loadTable();
        }
    }, [page, isFocused, sortedColumn, sortedColumnAscending, numberOfItemsPerPage, isStatusChanged]);

    const loadTable = () => {
        const pageNumber = numberOfItemsPerPage > MAX_ITEMS_BEFORE_ALL ? FIRST_PAGE : page;
        trigger(new PagedTableDto(pageNumber, {
            name: depotName,
            address1: address1,
            pageSize: numberOfItemsPerPage
        }, sortedColumn, sortedColumnAscending)).then(() => {
            setLoading(false);
        });
    }

    const resetFilters = () => {
        setDepotName("");
        setAddress1("");
    }

    if (depotsResult.isLoading) {
        return <LoadingIndicator />
    }

    const archiveDepot = async (element) => {
        setIsStatusChanged(true);
        try {
            const isConfirmed = window.confirm('Are you sure you want to archive this depot?')
            if (isConfirmed) {
                await updateDepot({ ...element, isArchived: !element.isArchived });
                setIsStatusChanged(false);
            } else {
                return;
            }
        } catch (error) {
            console.error("Error changing depot status:", error);
        }
    };

    return (
        <ScrollView style={styles.container}>
            <Spinner
                visible={loading}
                textContent={'Loading...'}
                textStyle={GlobalStyle.spinnerTextStyle}
            />
            <View style={GlobalStyle.filters}>
                <View style={GlobalStyle.filters__header}>
                    <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
                </View>

                <View style={GlobalStyle.filter__columns}>
                    <View style={GlobalStyle.column__flex}>
                        <Text style={GlobalStyle.column__header}>Depot Name</Text>
                        <TextInput
                            style={[GlobalStyle.column, GlobalStyle.column__input]}
                            placeholder="Depot Name"
                            onChangeText={setDepotName}
                            value={depotName}
                        />
                    </View>

                    <View style={GlobalStyle.column__flex}>
                        <Text style={GlobalStyle.column__header}>Address 1</Text>
                        <TextInput
                            style={[GlobalStyle.column, GlobalStyle.column__input]}
                            placeholder="Address 1"
                            onChangeText={setAddress1}
                            value={address1}
                        />
                    </View>

                    <View style={GlobalStyle.column__flex}>
                        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={() => loadTable()}>
                                <SimpleLineIcons name="magnifier" size={16} color="white" />
                            </TouchableOpacity>

                            <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                                <SimpleLineIcons name="ban" size={16} color="black" />
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </View>

            <View style={{ flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                    <TouchableOpacity style={GlobalStyle.add__button} onPress={() => navigation.navigate("manage")}>
                        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 5, paddingRight: 10 }}>
                            <Ionicons name="add" size={24} color="white" /><Text style={GlobalStyle.form__submit__button__text}>Add New depot</Text>
                        </View>
                    </TouchableOpacity>

                    <DataTable style={GlobalStyle.table}>
                        <DataTableHeader
                            sortedColumn={sortedColumn}
                            sortedColumnAscending={sortedColumnAscending}
                            onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
                            headers={[
                                { text: 'Name', sortedName: "name", visible: true },
                                { text: 'Address 1', sortedName: "address1", visible: true },
                                { text: 'Address 2', sortedName: "address2", visible: true },
                                { text: 'Address 3', sortedName: "address3", visible: true },
                                { text: 'Address 4', sortedName: "address4", visible: true },
                                { text: 'Postcode', sortedName: "postcode", visible: true },
                                { text: 'Option', visible: true },
                            ]}
                        />

                        {depotsResult?.data?.list.map((element: any) => {
                            return (
                                <DataTable.Row key={element.id}>
                                    <DataTable.Cell>{element.name}</DataTable.Cell>
                                    <DataTable.Cell>{element.address1}</DataTable.Cell>
                                    <DataTable.Cell>{element.address2}</DataTable.Cell>
                                    <DataTable.Cell>{element.address3}</DataTable.Cell>
                                    <DataTable.Cell>{element.address4}</DataTable.Cell>
                                    <DataTable.Cell>{element.postcode}</DataTable.Cell>
                                    <DataTable.Cell>
                                        <TouchableOpacity onPress={() => navigation.navigate("manage", { depotId: element.id })}>
                                            <Entypo name="pencil" size={24} color="black" />
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={() => archiveDepot(element)}>
                                            <MaterialCommunityIcons name={element.isArchived ? "archive-minus" : "archive-plus"} size={24} color="black" />
                                        </TouchableOpacity>
                                    </DataTable.Cell>
                                </DataTable.Row>
                            )
                        })}
                    </DataTable>

                    <PaginationComponent
                        numberOfItemsPerPage={numberOfItemsPerPage}
                        setNumberOfItemsPerPage={setNumberOfItemsPerPage}
                        page={page}
                        setPage={setPage}
                        fetchResult={depotsResult}
                        loading={loading}
                        setLoading={setLoading}
                    />
                </View>
            </View>

        </ScrollView>
    );
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        position: 'relative',
        padding: 10,
    },

    form: {
        borderBottomWidth: 1,
        borderColor: '#CECECE',
        paddingBottom: 20,
        marginBottom: 10
    },

    form__buttons: {
        width: 150,
        flexDirection: 'row',
    },

    form__add__btn: {
        backgroundColor: PRIMARY_COLOUR,
        flex: 1,
        height: 31,
        alignSelf: 'flex-end',
        textAlign: 'center',
        marginLeft: 10,
        marginBottom: 5,

        alignItems: 'center',
        justifyContent: 'center',
    },

    form__add__btn__text: {
        color: '#FFF',
    },

    table__actions: {
        flexDirection: 'row',
    },

    action: {
        paddingLeft: 5,
        paddingRight: 5,
    }
});
