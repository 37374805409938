import {
  useAddCompanyFileMutation,
  useLazyGetCompanyFilesQuery,
  useRemoveCompanyFileMutation
} from "@store/services/api/company-api";
import * as React from "react";
import {FileManager} from "@components/management/Files/FileManager";

interface CompanyFileManagerProps {
  companyId: string,
  onClose: () => void
}

export const CompanyFileManager = ({companyId, onClose}: CompanyFileManagerProps) => {
  const addFileMutation = useAddCompanyFileMutation();
  const getCompanyFilesQuery = useLazyGetCompanyFilesQuery();
  const removeFileMutation = useRemoveCompanyFileMutation();

  return (
    <FileManager
      idName='companyId'
      entityId={companyId}
      onCancel={onClose}
      useAddFileMutation={addFileMutation}
      useRemoveFileMutation={removeFileMutation}
      useLazyGetFilesQuery={getCompanyFilesQuery} />
  )
}
