import { Entypo, FontAwesome, Foundation } from '@expo/vector-icons';
import { PagedTableDto } from '@store/services/api/base-api';
import { inspectionApi } from '@store/services/api/inspection-api';
import { ReportableService } from '@store/services/reportable/reportable.service';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { DataTable } from 'react-native-paper';
import GlobalStyle from '../../../../constants/GlobalStyle';
import { 
  CategoryFilter,
  CompanyIdFilter,
  DateRangeFilter,
  DescriptionFilter,
  EngineersFilter,
  JobNumberFilter,
  JobTypeFilter,
  LocationFilter,
  SearchFilters,
  SerialFilter,
  DateFilter, 
  SearchFilterButtons} from '@web/components/search-filters/SearchFilters';
import { useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { DataTableHeader } from '@web/components/table/DatatableHeader';
import { useGetAllCompaniesQuery } from '@store/services/api/company-api';
import { useGetAllLocationsQuery } from '@store/services/api/location-api';
import { useGetEngineersQuery } from '@store/services/api/user-api';
import { useGetAllCategoriesQuery } from '@store/services/api/category-api';
import PaginationComponent from '@web/components/Pagination';
import { invertBoolString } from '../../../../helpers/invert-string';
import { LoadingView } from '@web/components/LoadingView';
import { downloadCert, downloadRAMS } from '@store/services/reportable/reportable-inspection-helper';
import { InspectionDateRangeFilter } from '@web/components/search-filters/common-search-filters';

const tableHeaders = [
  { text: 'Report', sortedName: "report", visible: true, preventSorting: true },
  { text: 'Description', sortedName: "description", visible: true },
  { text: 'Inspection Date', sortedName: "inspectionDate", visible: true },
  { text: 'Next Inspection Date', sortedName: "nextInspectionDate", visible: true },
  { text: 'Safe For Use', sortedName: "safeForUse", visible: true },
  { text: 'Presented', sortedName: "missing", visible: true },
  { text: 'Serial Number', sortedName: "serialNumber", visible: true },
  { text: 'Manufacturer', sortedName: "ManufacturerName", visible: true },
  { text: 'Category', sortedName: "categoryName", visible: true },
  { text: 'Location', sortedName: "LocationName", visible: true },
  { text: 'Company', sortedName: "companyName", visible: true },
  { text: 'Job Type', sortedName: "jobType", visible: true },
]

export default function DashboardScreen({navigation}: {navigation: any}) {
  const inspectionData = false;
  const user = useSelector((state: RootState) => state.user.user);

  const { data: companies } = useGetAllCompaniesQuery(null);
  const { data: locations } = useGetAllLocationsQuery(null);
  const { data: engineers } = useGetEngineersQuery(null);
  const { data: categories } = useGetAllCategoriesQuery(null);

  const [stats, setStats] = useState({
    passedCount: 0,
    failedCount: 0,
    missingCount: 0,
    dueCount: 0
  });

  const [page, setPage] = React.useState<number>(0);
  const [trigger, inspectionResult] = inspectionApi.useLazyGetInspectionsDashboardQuery();
  const [sortedColumn, setSortedColumn] = React.useState<string>("inspectionDate");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(false);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);
  const [loading, setLoading] = React.useState(false);

  const [companyId, setCompanyId] = useState(user.companyId ?? "");
  const [locationId, setLocationId] = useState("");
  const [engineerId, setEngineerId] = useState("");
  const [jobType, setJobType] = useState("-1");
  const [dateRange, setDateRange] = useState("");
  const [startDate, setStartDate] = useState(moment().add(-6, 'month').toDate());
  const [endDate, setEndDate] = useState(moment().endOf('month').toDate());
  const [serialNumber, setSerialNumber] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [jobNumber, setJobNumber] = useState("");
  const [reportNumber, setReportNumber] = useState("");
  const [statusId, setStatusId] = useState("-1");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (!inspectionResult.isSuccess) return;

    const values = inspectionResult.data.pagination;
    setStats({
      passedCount: values.passed,
      failedCount: values.failed,
      missingCount: values.missing,
      dueCount: values.due
    })
  }, [inspectionResult])

  const loadTable = () => {
    var dto = new PagedTableDto(page, {
      companyId: companyId,
      locationId: locationId,
      engineerId: engineerId,
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString(),
      serialNumber: serialNumber,
      categoryId: categoryId,
      jobNumber: jobNumber,
      jobType: +jobType,
      reportNumber: reportNumber,
      statusId: statusId,
      description: description,
      inspectionData: inspectionData,
      pageSize: numberOfItemsPerPage
    }, sortedColumn, sortedColumnAscending);
    trigger(dto);
  }

  const handleCertDownload = (inspection) => {
    setLoading(true);

    downloadCert(inspection).finally(() => {
      setLoading(false);
    })
  }

  const handleRAMSDownload = async (inspection) => {
    setLoading(true);

    downloadRAMS(inspection).finally(() => {
      setLoading(false);
    })
  }

  const updateDateRange = (val) => {
    setDateRange(val);

    let startDate = moment();
    const endDate = moment();
    if (val == "-1") {
      startDate = moment().add(-1, 'week');
    } else {
      startDate = moment().add(-Number.parseInt(val), 'M');
    }

    setStartDate(startDate.toDate());
    setEndDate(endDate.toDate());
  }

  const handleSetStartDate = (val) => {
    setDateRange("");
    setStartDate(val);
  }

  const handleStatButtonPress = (val) => {
    setPage(0);
    setStatusId((id) => id == val ? "-1" : val);
  }

  const resetFilters = () => {
    setCompanyId(user.companyId ?? "");
    setLocationId("");
    setJobType("-1");
    setEngineerId("");
    setDateRange("");
    setStartDate(moment().add(-6, 'month').toDate());
    setEndDate(moment().endOf('month').toDate());
    setSerialNumber("");
    setCategoryId("");
    setJobNumber("");
    setReportNumber("");
    setDescription("");
    setPage(0);
  }

  const applyFilters = () => {
    setPage(curr => {
      if (curr == 0) loadTable();
      return 0;
    })
  }

  useEffect(() => {
    loadTable();
  }, [page, sortedColumn, sortedColumnAscending, statusId, numberOfItemsPerPage])
  

  return (
    <LoadingView isLoading={inspectionResult.isLoading || inspectionResult.status == 'pending' || loading}>
      <Stats>
        <StatFilterButton
          text="PASSED"
          count={stats.passedCount}
          color="#1ABB9C"
          onPress={() => handleStatButtonPress("0")}
          active={statusId == "0"}>
          <Entypo name="check" size={65} color="white" />
        </StatFilterButton>
        <StatFilterButton
          text="FAILED"
          count={stats.failedCount}
          color="#E74C3C"
          onPress={() => handleStatButtonPress("1")}
          active={statusId == "1"} >
          <Entypo name="circle-with-cross" size={65} color="white" />
        </StatFilterButton>
        <StatFilterButton
          text="NOT PRESENTED"
          count={stats.missingCount}
          color="#9B59B6"
          onPress={() => handleStatButtonPress("2")}
          active={statusId == "2"} >
          <FontAwesome style={[styles.stat__icon__feather]} name="question-circle" size={65} color="white" />
        </StatFilterButton>
        <StatFilterButton
          text={`DUE BY ${moment().add(3, 'month').format('DD/MM/yyyy')}`}
          count={stats.dueCount}
          color="#3498DB"
          onPress={() => handleStatButtonPress("3")}
          active={statusId == "3"} >
          <Entypo name="list" size={65} color="white" />
        </StatFilterButton>
      </Stats>

      <SearchFilters>
        <CompanyIdFilter value={companyId} onValueChange={setCompanyId} companies={companies} />
        <LocationFilter companyId={companyId} value={locationId} onValueChange={setLocationId} locations={locations} />
        <JobTypeFilter value={jobType} onValueChange={setJobType} />
        <InspectionDateRangeFilter value={dateRange} onValueChange={updateDateRange} />
        <DateFilter title="Inspection Start Date" value={startDate} onValueChange={handleSetStartDate} />
        <DateFilter title="Inspection End Date" value={endDate} onValueChange={setEndDate} />
        <JobNumberFilter value={jobNumber} onValueChange={setJobNumber} />
        <EngineersFilter value={engineerId} onValueChange={setEngineerId} engineers={engineers} />
        <SerialFilter value={serialNumber} onValueChange={setSerialNumber} />
        <DescriptionFilter value={description} onValueChange={setDescription} />
        <CategoryFilter value={categoryId} onValueChange={setCategoryId} categories={categories} />
        <SearchFilterButtons onSubmit={applyFilters} onClearFilters={resetFilters} />
      </SearchFilters>
      <>
        <DataTable style={GlobalStyle.table}>
          <DataTableHeader
            sortedColumn={sortedColumn}
            sortedColumnAscending={sortedColumnAscending}
            onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
            headers={tableHeaders}
          />

          { inspectionResult.data?.list.map((element: any) => {
            return (
              <DataTable.Row key={element.id}>
                <DataTable.Cell>
                  <View style={{ flexDirection: 'row'}}>
                    <TouchableOpacity onPress={() => handleCertDownload(element)}><Foundation name="page-pdf" size={24} color="black" /></TouchableOpacity>
                    { element.ramsId != null && <TouchableOpacity style={{ marginLeft: 20 }} onPress={() => handleRAMSDownload(element)}><Foundation name="page-pdf" size={24} color="blue" /></TouchableOpacity> }
                  </View>
                </DataTable.Cell>
                <DataTable.Cell>{element.description}</DataTable.Cell>
                <DataTable.Cell>{element.inspectionDateFormatted}</DataTable.Cell>
                <DataTable.Cell>{element.nextInspectionDateFormatted}</DataTable.Cell>
                <DataTable.Cell>{element.safeForUseFormatted}</DataTable.Cell>
                <DataTable.Cell>{invertBoolString(element.missingFormatted)}</DataTable.Cell>
                <DataTable.Cell>{element.serialNumber}</DataTable.Cell>
                <DataTable.Cell>{element.manufacturerName}</DataTable.Cell>
                <DataTable.Cell>{element.categoryName}</DataTable.Cell>
                <DataTable.Cell>{element.locationName}</DataTable.Cell>
                <DataTable.Cell>{element.companyName}</DataTable.Cell>
                <DataTable.Cell>{element.jobType}</DataTable.Cell>
              </DataTable.Row>
            )
          })}
        </DataTable>

        <PaginationComponent
          numberOfItemsPerPage={numberOfItemsPerPage}
          setNumberOfItemsPerPage={setNumberOfItemsPerPage}
          page={page}
          setPage={setPage}
          fetchResult={inspectionResult}
          setLoading={() => {}}
        />
      </>
    </LoadingView>
  )
}

function Stats({children}) {
  return (
    <View style={styles.stats__container}>
      {children}
    </View>
  )
}

function StatFilterButton({active = false, children, text, count, color, onPress = () => {}}) {
  const bgColStyle: any = {
    backgroundColor: color,
    opacity: active ? "100%" : "50%"
  }

  return (
    <TouchableOpacity style={[styles.stats__button, bgColStyle]} onPress={onPress}>
      {children}
      <View style={styles.stat__info}>
        <Text style={styles.stat__label}>{text}</Text>
        <Text style={styles.stat__count}>{count ?? 0}</Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  stats__container: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 10
  },
  stats__button: {
    height: 100,
    borderRadius: 5,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    flexGrow: 1,
    margin: 5,
    padding: 6,
    gap: 12
  },
  stat__info: {
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFF'
  },
  stat__icon__feather: {
    marginLeft: 6
  },
  stat__label: {
    color: '#FFF',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  stat__count: {
    color: '#FFF',
    fontWeight: 'bold',
    fontSize: 38
  }
});