import { createNativeStackNavigator } from '@react-navigation/native-stack';
import PssrPartsListScreen from './PssrPartsList';
import PssrPartManageScreen from './PssrPartsManage';


const Stack = createNativeStackNavigator();

export default function PssrParts({ navigation }: { navigation: any }) {
  return (
    <Stack.Navigator>
      <Stack.Screen name="list" component={PssrPartsListScreen} options={{ headerShown: false, title: 'PSSR Parts' }} />
      <Stack.Screen name="manage" component={PssrPartManageScreen} options={{ headerShown: false, title: 'PSSR Parts Manage' }} />
    </Stack.Navigator>
  )
};