import React, { useEffect, useState } from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Alert } from 'react-native';
import { EquipmentDatabase } from '@store/services/database/equipment-database';
import { useDispatch, useSelector } from 'react-redux';
import { CategoryDatabase } from '@store/services/database/category-database';
import { RootState } from '@store/index';
import PssrStep1 from './PssrInspectionSteps/PssrStep1';
import PssrStep2 from './PssrInspectionSteps/PssrStep2';
import PssrStep3 from './PssrInspectionSteps/PssrStep3';
import { PssrDatabase } from '@store/services/database/pssr-database';
import { clearCompletedExaminations, clearPssrData, setPssrData } from '@store/slices/pssr-slice';

const Stack = createNativeStackNavigator();

export default function PssrInspect({ navigation, route }: { navigation: any, route: any }) {
    const dispatch = useDispatch();
    const job = useSelector((state: RootState) => state.job);
    const pssrInspection = useSelector((state: RootState) => state.pssr);
    const [category, setCategory] = useState(null);
    const [isInspectionEdit, setIsInspectionEdit] = useState(false);

    const equipment = route.params?.equipment;

    const completedExaminations = useSelector((state: RootState) => state.pssr.completedExaminations);

    useEffect(() => {
        const loadData = async () => {

            const equipmentDatabase = new EquipmentDatabase();
            const equipment = await equipmentDatabase.getById(job.equipmentId);

            const categoryDatabase = new CategoryDatabase();
            const category = await categoryDatabase.getById(equipment.categoryId);

            setCategory(category);

            const inspectionDatabase = new PssrDatabase();
            let dbInspection = null;
            let dbInspectionData = null;
            if (pssrInspection.id !== null) {
                dbInspectionData = await inspectionDatabase.getCompletedInspection(pssrInspection.id);
                [dbInspection] = dbInspectionData;
            }

            if (dbInspectionData && dbInspectionData.length > 0) {
                dispatch(setPssrData({
                    id: dbInspection.id,
                    examinationType: dbInspection.examinationType,
                    testsMade: dbInspection.testsMade,
                    protectiveDevicesState: dbInspection.protectiveDevicesState,
                    protectiveDevicesStateComment: dbInspection.protectiveDevicesStateComment,
                    isSafeOperatingLimitChanged: dbInspection.isSafeOperatingLimitChanged,
                    isSafeOperatingLimitChangedDate: dbInspection.isSafeOperatingLimitChangedDate,
                    repairsRequired: dbInspection.repairsRequired,
                    observations: dbInspection.observations,
                    writtenSchemeChanges: dbInspection.writtenSchemeChanges,
                    inServiceInspectionDate: dbInspection.inServiceInspectionDate,
                    inServiceNextInspectionDate: dbInspection.inServiceNextInspectionDate,
                    outOfServiceInspectionDate: dbInspection.outOfServiceInspectionDate,
                    outOfServiceNextInspectionDate: dbInspection.outOfServiceNextInspectionDate,
                    safeToUse: dbInspection.safeToUse,
                    images: dbInspection.images,
                    pressureInspectionPartId: dbInspection.pressureInspectionPartId,
                    conditionOfParts: dbInspection.conditionOfParts,
                    conditionOfPartsDefect: dbInspection.conditionOfPartsDefect
                }))
                setIsInspectionEdit(true);
            } else {
                dispatch(clearPssrData());
                setIsInspectionEdit(false);
            }
        }

        loadData();

        return () => {
            dispatch(clearPssrData());
        };
    }, []);

    const submitHandler = async (inspectionData, images) => {
        try {
            if (images == null) {
                images = [];
            }

            const inspectionDatabase = new PssrDatabase();

            if (isInspectionEdit) {
                await updateInspection(inspectionData, images, inspectionDatabase);
                navigation.navigate("JobAssets");
            } else {
                await processNewInspection(inspectionData, images, inspectionDatabase);
            }
        } catch (error) {
            console.error("Failed to handle inspection submission:", error);
        }
    };

    const updateInspection = async (inspectionData, images, db) => {
        await db.updateInspection(
            pssrInspection.id,
            inspectionData.examinationType,
            inspectionData.testsMade,
            inspectionData.protectiveDevicesState,
            inspectionData.protectiveDevicesStateComment,
            inspectionData.isSafeOperatingLimitChanged,
            inspectionData.isSafeOperatingLimitChangedDate,
            inspectionData.repairsRequired,
            inspectionData.observations,
            inspectionData.writtenSchemeChanges,
            inspectionData.safeToUse,
            inspectionData.inServiceInspectionDate,
            inspectionData.inServiceNextInspectionDate,
            inspectionData.outOfServiceInspectionDate,
            inspectionData.outOfServiceNextInspectionDate,
            job.jobId,
            inspectionData.pressureInspectionPartId,
            inspectionData.conditionOfParts,
            inspectionData.conditionOfPartsDefect,
            0,
            images
        );

        dispatch(clearPssrData());
    };

    const processNewInspection = async (inspectionData, images, db) => {
        if (inspectionData.examinationType === "Both In Service and Out of Service") {
            await handleBothExaminationTypes(inspectionData, images, db);
        } else if (inspectionData.examinationType === "Thorough In Service" && !completedExaminations.includes("Thorough Out of Service")) {
            await completeAndPromptNext(db, inspectionData, images, "Thorough Out of Service");
        } else if (inspectionData.examinationType === "Thorough Out of Service" && !completedExaminations.includes("Thorough In Service")) {
            await completeAndPromptNext(db, inspectionData, images, "Thorough In Service");
        } else {
            await completeInspection(db, inspectionData, images);
            dispatch(clearCompletedExaminations());
            dispatch(clearPssrData());
            navigation.navigate("JobAssets");
        }
        dispatch(clearPssrData());
    };

    const handleBothExaminationTypes = async (inspectionData, images, db) => {
        await completeInspection(db, {
            ...inspectionData,
            examinationType: "Thorough In Service",
            outOfServiceNextInspectionDate: null,
            outOfServiceInspectionDate: null
        }, images);

        await completeInspection(db, {
            ...inspectionData,
            examinationType: "Thorough Out of Service",
            inServiceNextInspectionDate: null,
            inServiceInspectionDate: null
        }, images);

        dispatch(clearPssrData());
        navigation.navigate("JobAssets");
    };

    const completeAndPromptNext = async (db, inspectionData, images, nextInspectionType) => {
        await completeInspection(db, inspectionData, images);
        dispatch(clearPssrData());

        Alert.alert(
            'Inspection Type',
            `Would you like to complete the ${nextInspectionType} examination next?`,
            [
                {
                    text: 'Yes',
                    onPress: () => {
                        dispatch(setPssrData({ examinationType: nextInspectionType }));
                        navigation.navigate("EquipmentDetails");
                    }
                },
                {
                    text: 'No',
                    onPress: () => navigation.navigate("JobAssets")
                },
            ]
        );
    };

    const completeInspection = async (db, inspectionData, images) => {
        await db.addInspection(
            inspectionData.examinationType,
            inspectionData.testsMade,
            inspectionData.protectiveDevicesState,
            inspectionData.protectiveDevicesStateComment,
            inspectionData.isSafeOperatingLimitChanged ? 1 : 0,
            inspectionData.isSafeOperatingLimitChangedDate,
            inspectionData.repairsRequired,
            inspectionData.observations,
            inspectionData.writtenSchemeChanges ? 1 : 0,
            inspectionData.safeToUse ? 1 : 0,
            inspectionData.inServiceInspectionDate,
            inspectionData.inServiceNextInspectionDate,
            inspectionData.outOfServiceInspectionDate,
            inspectionData.outOfServiceNextInspectionDate,
            job.equipmentId,
            job.engineerId,
            job.jobId,
            inspectionData.pressureInspectionPartId,
            inspectionData.conditionOfParts,
            inspectionData.conditionOfPartsDefect,
            0,
            images
        );
    };

    return (
        <Stack.Navigator initialRouteName="PssrStep1">
            <Stack.Screen
                name="PssrStep1"
                component={PssrStep1}
                options={{ headerShown: false, title: 'Step1' }}
            />
            <Stack.Screen
                name="PssrStep2"
                component={PssrStep2}
                options={{ headerShown: false, title: 'PssrStep2' }}
                initialParams={{ equipment }}
            />
            <Stack.Screen
                name="PssrStep3"
                options={{ headerShown: false, title: 'PssrStep3' }}>
                {() => (
                    <PssrStep3
                        navigation={navigation}
                        route={route}
                        submitHandler={submitHandler}
                    />
                )}
            </Stack.Screen>
        </Stack.Navigator>
    )
};