import { baseApi, PagedTableDto } from './base-api'

export const colourApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getColours: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `colours/getcolours?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    syncColours: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `colours/getcoloursforsync?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    getAllColours: build.query<any, any>({
      query: () => ({
        url: `colours/getallcolours`,
      })
    }),
    createColour: build.mutation<any, any>({
      query: (body) => ({
        url: 'colours/addcolour',
        method: 'post',
        body: body
      }),
    }),
    updateColour: build.mutation<any, any>({
      query: (body) => ({
        url: `colours/updatecolour/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    deleteColour: build.mutation<any, any>({
      query: (id) => ({
        url: `colours/deletecolour/${id}`,
        method: 'delete',
      }),
    }),
    getColour: build.query<any, any>({
      query: (colourId) => ({
        url: `colours/getcolour/${colourId}`,
      }),
    }),
    
  }),
  overrideExisting: true,
})

export const { useGetColoursQuery, useGetAllColoursQuery, useLazyGetColoursQuery, useLazyGetColourQuery, useCreateColourMutation, useUpdateColourMutation, useDeleteColourMutation } = colourApi