import { baseApi } from './base-api'

export const authApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<any, any>({
      query: (body) => ({
        url: '/auth',
        method: 'post',
        body: body
      }),
    })
  }),
  overrideExisting: true,
})

export const { useLoginMutation } = authApi