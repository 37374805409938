import { View, StyleSheet, Text, TextInput, Modal, TouchableOpacity, Dimensions, Platform } from 'react-native';
import { colours } from '../../../../../../config';
import { useState, useEffect } from 'react';
import { DateTimePickerAndroid } from '@react-native-community/datetimepicker';
import moment from 'moment';
import { DatePicker } from '@app/components/DatePicker';

const isMobileScreen = Dimensions.get("screen").width < 480;

export const Timesheets = ({ timesheets, setTimesheets }: { timesheets: any, setTimesheets: any }) => {
  const [selectedTimesheet, setSelectedTimesheet] = useState(null);
  const [timeValue, setTimeValue] = useState();

  useEffect(() => {
    setTimesheets(timesheets);
  }, [timesheets])

  const updateNormalTime = (hours) => {
    setSelectedTimesheet(prev => ({ ...prev, normalTime: hours }));
  }

  const updateOverTime = (hours) => {
    setSelectedTimesheet(prev => ({ ...prev, overTime: hours }));
  }

  const updateDoubleTime = (hours) => {
    setSelectedTimesheet(prev => ({ ...prev, doubleTime: hours }));
  }

  const updateSelectedTimesheet = (timesheet) => {
    setSelectedTimesheet(timesheet);
  }

  const save = () => {
    const updatedTimesheet = {
      ...selectedTimesheet,
      startTime: selectedTimesheet.startTime === '' ? moment().startOf('day').format("HH:mm") : selectedTimesheet.startTime,
      endTime: selectedTimesheet.endTime === '' ? moment().startOf('day').format("HH:mm") : selectedTimesheet.endTime
    };

    let updatedList = [...timesheets];
    const index = updatedList.findIndex(t => t.day === updatedTimesheet.day);

    if (index !== -1) {
      updatedList[index] = updatedTimesheet;
    } else {
      updatedList.push(updatedTimesheet);
    }

    setTimesheets(updatedList);
    setSelectedTimesheet(null);
  };

  return (
    <View>
      <Modal
        animationType="slide"
        transparent={true}
        visible={selectedTimesheet != null}>
        <View style={styles.modal__block}>
          <View style={styles.modal}>
            <View style={styles.row}>
              <View style={styles.date__time}>
                <Text style={styles.date__label}>Normal Time</Text>
                <TextInput keyboardType={'numeric'} style={styles.date__value} onChangeText={(text) => updateNormalTime(text)}>{selectedTimesheet?.normalTime}</TextInput>
              </View>
              <View style={styles.date__time}>
                <Text style={styles.date__label}>Over Time</Text>
                <TextInput keyboardType={'decimal-pad'} style={styles.date__value} onChangeText={(text) => updateOverTime(text)}>{selectedTimesheet?.overTime}</TextInput>
              </View>
              <View style={styles.date__time}>
                <Text style={styles.date__label}>Double Time</Text>
                <TextInput keyboardType={'decimal-pad'} style={styles.date__value} onChangeText={(text) => updateDoubleTime(text)}>{selectedTimesheet?.doubleTime}</TextInput>
              </View>
            </View>

            <View style={styles.row}>
              <TouchableOpacity style={styles.date__time}>
                <Text style={styles.date__label}>Start Time</Text>
                <DatePicker
                  mode={Platform.OS === "ios" ? "time" : null}
                  maximumDate={null}
                  value={
                    selectedTimesheet?.startTime
                      ? moment(selectedTimesheet.startTime, 'HH:mm').format('HH:mm')
                      : moment().startOf('day').format("HH:mm")
                  }
                  onChanged={(date) => setSelectedTimesheet(prev => ({ ...prev, startTime: moment(date, 'HH:mm').format("HH:mm") }))}
                />

              </TouchableOpacity>

              <TouchableOpacity style={styles.date__time}>
                <Text style={styles.date__label}>End Time</Text>
                <DatePicker
                  mode={Platform.OS === "ios" ? "time" : null}
                  maximumDate={null}
                  value={
                    selectedTimesheet?.endTime
                      ? moment(selectedTimesheet.endTime, 'HH:mm').format('HH:mm')
                      : moment().startOf('day').format("HH:mm")
                  }
                  onChanged={(date) => setSelectedTimesheet(prev => ({ ...prev, endTime: moment(date, 'HH:mm').format("HH:mm") }))}
                />
              </TouchableOpacity>

              <View style={styles.date__time}>
                <Text style={styles.date__label}>Milage</Text>
                <TextInput keyboardType={'decimal-pad'} style={styles.date__value} onChangeText={(text) => setSelectedTimesheet(prev => ({ ...prev, miles: text }))}>{selectedTimesheet?.miles}</TextInput>
              </View>
            </View>


            <View style={{ flex: 1 }}></View>

            <TouchableOpacity style={styles.proceed__button} onPress={() => save()}>
              <Text style={styles.proceed__button__text}>SAVE</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      {isMobileScreen ? (
        <View style={styles.mobile__view__container}>
          {timesheets != null && timesheets.length > 0 && timesheets.map((timesheet: any, index: number) => (
            <View key={timesheet.day} style={styles.row__container}>
              <View style={styles.column}>
                <TouchableOpacity onPress={() => updateSelectedTimesheet(timesheet)}>
                  <Text style={styles.mobile__column}>{timesheet.day}</Text>
                  <Text style={styles.mobile__column}>{timesheet.normalTime == 0 ? '-' : timesheet.normalTime}</Text>
                  <Text style={styles.mobile__column}>{timesheet.overTime == 0 ? '-' : timesheet.overTime}</Text>
                  <Text style={styles.mobile__column}>{timesheet.doubleTime == 0 ? '-' : timesheet.doubleTime}</Text>
                  <Text style={styles.mobile__column}>{timesheet.startTime}</Text>
                  <Text style={styles.mobile__column}>{timesheet.endTime}</Text>
                  <Text style={styles.mobile__column}>{timesheet.miles == null || timesheet.miles == "" ? 'Tap to set' : timesheet.miles}</Text>
                </TouchableOpacity>
              </View>

              <View style={styles.column}>
                <Text style={[styles.mobile__column, { borderWidth: 0, marginBottom: 2 }]}></Text>
                <Text style={[styles.mobile__column, { backgroundColor: colours.primary, color: '#FFF' }]}>Normal Time</Text>
                <Text style={[styles.mobile__column, { backgroundColor: colours.primary, color: '#FFF' }]}>Over Time</Text>
                <Text style={[styles.mobile__column, { backgroundColor: colours.primary, color: '#FFF' }]}>Double Time</Text>
                <Text style={[styles.mobile__column, { backgroundColor: colours.primary, color: '#FFF' }]}>Start Time</Text>
                <Text style={[styles.mobile__column, { backgroundColor: colours.primary, color: '#FFF' }]}>End Time</Text>
                <Text style={[styles.mobile__column, { backgroundColor: colours.primary, color: '#FFF' }]}>Miles</Text>
              </View>
            </View>
          ))}
        </View>
      ) : (
        <View>
          <View style={styles.row}>
            <Text style={styles.header}></Text>
            <Text style={styles.header}>Normal Time</Text>
            <Text style={styles.header}>Over Time</Text>
            <Text style={styles.header}>Double Time</Text>
            <Text style={styles.header}>Start Time</Text>
            <Text style={styles.header}>End Time</Text>
            <Text style={styles.header}>Miles</Text>
          </View>

          {timesheets != null && timesheets.length > 0 && timesheets.map((timesheet: any, index: number) => {
            return (
              <TouchableOpacity key={timesheet.day} style={styles.row} onPress={() => updateSelectedTimesheet(timesheet)}>
                <Text style={styles.column}>{timesheet.day}</Text>
                <Text style={styles.column}>{timesheet.normalTime == 0 ? '-' : timesheet.normalTime}</Text>
                <Text style={styles.column}>{timesheet.overTime == 0 ? '-' : timesheet.overTime}</Text>
                <Text style={styles.column}>{timesheet.doubleTime == 0 ? '-' : timesheet.doubleTime}</Text>
                <Text style={styles.column}>{timesheet.startTime}</Text>
                <Text style={styles.column}>{timesheet.endTime}</Text>
                <Text style={styles.column}>{timesheet.miles == null || timesheet.miles == "" ? 'Tap to set' : timesheet.miles}</Text>
              </TouchableOpacity>
            )
          })}
        </View>
      )}

    </View>
  )
}


const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    marginBottom: isMobileScreen ? 0 : 5
  },

  header: {
    flex: 1,
    textAlign: 'center',
    padding: 10,
    backgroundColor: colours.primary,
    color: '#FFF',
    fontSize: 14,
    marginLeft: 2.5,
    marginRight: 2.5,
    fontFamily: 'OpenSans-Bold',
  },
  mobile__column: {
    flex: 1,
    textAlign: 'center',
    padding: 10,
    fontSize: 14,
    borderColor: "black",
    borderWidth: 1,
    fontFamily: 'OpenSans-Bold',
    borderRadius: 5,
    marginBottom: 1
  },

  column: {
    flex: 1,
    textAlign: 'center',
    padding: isMobileScreen ? 0 : 10,
    backgroundColor: '#FFF',
    fontSize: 14,
    marginLeft: isMobileScreen ? 0 : 2.5,
    marginRight: isMobileScreen ? 0 : 2.5,
    fontFamily: 'OpenSans-Regular',
  },

  modal__block: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },

  modal: {
    backgroundColor: '#FFF',
    padding: 10,
    width: isMobileScreen ? 370 : 600,
    borderRadius: 5,

    flexDirection: 'column',
  },

  date__time: {
    flex: 1,
    textAlign: 'center',
    margin: 10,
  },

  date__value: {
    borderBottomWidth: 1,
    borderBottomColor: '#CECECE',
    fontSize: 24,
    marginTop: 10,
    paddingBottom: 5,
    textAlign: 'center'
  },

  date__label: {
    fontWeight: 'bold',
    fontSize: 14,
    textAlign: 'center',
  },

  proceed__button: {
    padding: 10,
    backgroundColor: colours.primary,
    marginTop: 20,
    borderRadius: 7,
    height: 45,


    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  proceed__button__text: {
    fontFamily: 'OpenSans-Bold',
    color: '#FFF',
    textAlign: 'center',
    fontSize: 18
  },
  mobile__view__container: {
    display: 'flex'
  },
  row__container: {
    flexDirection: 'row',
    marginBottom: 10,
    overflow: 'hidden',
  },
});