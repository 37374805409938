import { useState, useEffect } from 'react';
import { useGetAllColoursQuery } from '@store/services/api/colour-api';
import { useGetAllPurposesQuery } from '@store/services/api/purpose-api';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Touchable, Modal } from 'react-native';
import GlobalStyle from '../../../../../../constants/GlobalStyle';
import { Controller, useForm } from 'react-hook-form';
import { Picker } from '@react-native-picker/picker';
import RoteStep1 from '@components/management/Inspection/Rote/Step1';
import { ScrollView } from 'react-native-gesture-handler';
import RoteStep3 from '@components/management/Inspection/Rote/Step3';
import { useGetEngineersQuery } from '@store/services/api/user-api';
import { RootState, store } from '@store/index';
import { useDispatch, useSelector } from 'react-redux';
import { inspectionApi } from '@store/services/api/inspection-api';
import { clearEquipmentBasket, setShowEquipmentInspectionModal } from '@store/slices/equipment-inspection-slice';
import CoreAlert from '@components/core-alert';
import RoteStep4 from '@components/management/Inspection/Rote/Step4';
import { AntDesign } from '@expo/vector-icons';
import Checkbox from 'expo-checkbox';
import { useGetAllDepotsQuery } from '@store/services/api/depot-api';

export default function EquipmentInspection() {
  const dispatch = useDispatch();

  const { data: colours } = useGetAllColoursQuery(null);
  const { data: purposes } = useGetAllPurposesQuery(null);
  const { data: engineers } = useGetEngineersQuery(null);
  const { data: depots } = useGetAllDepotsQuery(null);
  const [showDepot, setShowDepot] = useState<boolean>(false);

  const equipment = useSelector((state: RootState) => state.equipmentInspection.equipment);
  const isEveryEquipmentMarine = equipment.every(e => e.masterCategoryName === "Marine 2006");
  const isSingleEquipmentMarine = equipment.length === 1 && equipment[0].masterCategoryName === "Marine 2006";
  let marineEquipmentId = null;

  if (isSingleEquipmentMarine) {
    marineEquipmentId = equipment[0].id;
  }



  const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      engineerId: null,
      purposeId: null,
      colourId: null,
      jobNumber: "",
      yourReference: "",
      depotId: null,
      purposeInterval: null,
    }
  });

  const data = watch();

  useEffect(() => {
    var purpose = purposes?.find(p => p.id == data.purposeId);
    if (purpose == null) return;

    setValue('purposeInterval', purpose.interval);
  }, [data.purposeId])

  const validate = (inspectionData) => {
    if (data.engineerId == null) {
      alert("Select engineer");
      return false;
    }

    if (data.purposeId == null) {
      alert("Select purpose");
      return false;
    }

    if (data.colourId == null) {
      alert("Select colour");
      return false;
    }

    if (data.colourId == null) {
      alert("Select colour");
      return false;
    }

    if (inspectionData.firstExamination == null) {
      alert("Select first examination");
      return false;
    }

    if (inspectionData.firstExamination == true && inspectionData.installedCorrectly == null) {
      alert("Select installed correctly");
      return false;
    }

    if (showDepot && data.depotId == null) {
      alert("Select a depot for internal inspection");
      return false;
    }

    return true;
  }

  const submit = async (inspectionData, images) => {
    if (!validate(inspectionData)) return;

    var dto = {
      equipmentIds: equipment.map(e => e.id),
      engineerId: data.engineerId,
      purposeId: data.purposeId,
      colourId: data.colourId,
      inspectionDate: inspectionData.inspectionDate,
      nextInspectionDate: inspectionData.nextInspectionDate,
      safeForUse: inspectionData.safeForUse,
      firstExamination: inspectionData.firstExamination,
      installedCorrectly: inspectionData.installedCorrectly,
      immediateToPersons: inspectionData.immediateToPersons,
      defects: inspectionData.defects,
      becomeDangerToPersons: inspectionData.becomeDangerToPersons,
      repair: inspectionData.repair,
      repairDate: inspectionData.repairDate,
      particulars: inspectionData.particulars,
      identification: inspectionData.identification,
      jobNumber: data.jobNumber,
      yourReference: data.yourReference,
      nextTestDate: inspectionData.nextTestDate,
      depotId: data.depotId
    }

    var result = await store.dispatch(inspectionApi.endpoints.addEquipmentInspection.initiate(dto)) as any;
    if (result.error) {
      alert("Error. Please try again");
      return;
    }

    dispatch(clearEquipmentBasket());
    dispatch(setShowEquipmentInspectionModal(false));
    CoreAlert("Successfully saved inspection", "Success");
  }

  const onClosed = () => {
    dispatch(clearEquipmentBasket());
    dispatch(setShowEquipmentInspectionModal(false));
  }

  document.addEventListener('keydown', (event) => {
    if (event.code == "Escape") {
      onClosed();
    }
  })

  return (
    <View>
      <button onClick={onClosed} style={styles.closeButton}>
        <AntDesign name="closecircle" size={24} color="black" />
      </button>
      <ScrollView style={styles.container}>
        <View style={GlobalStyle.form}>
          <View>
            {<View style={[GlobalStyle.column__flex]}>
              <Text style={styles.form__label}>Internal inspection?</Text>
              <Checkbox
                value={showDepot}
                onValueChange={setShowDepot}
                style={[GlobalStyle.column, GlobalStyle.column__input, { margin: 5, padding: 3 }]}
              />
            </View>}
            {showDepot && <View>
              <Controller
                control={control}
                name="depotId"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <View >
                    <Text style={styles.form__label}>Depot</Text>
                    <View style={styles.form__input}>
                      <Picker
                        selectedValue={value}
                        onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                        itemStyle={{ height: 50 }}>

                        <Picker.Item label="Please Select" value="" />

                        {depots != null && depots.map((value, index) => {
                          return (
                            <Picker.Item key={value.id} label={value.name} value={value.id} />
                          )
                        })}
                      </Picker>
                    </View>
                  </View>
                )}
              />
            </View>}
          </View>
          <View>
            <Controller
              control={control}
              name="jobNumber"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={styles.form__label}>Job Number</Text>
                  <TextInput
                    style={styles.form__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>
          <View>
            <Controller
              control={control}
              name="yourReference"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={styles.form__label}>Your Reference</Text>
                  <TextInput
                    style={styles.form__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>
          <View>
            <Controller
              control={control}
              name="engineerId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View >
                  <Text style={styles.form__label}>Engineer (*)</Text>
                  <View style={styles.form__input}>
                    <Picker
                      selectedValue={value}
                      onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                      itemStyle={{ height: 50 }}>

                      <Picker.Item label="Please Select" value="" />

                      {engineers != null && engineers.map((value, index) => {
                        return (
                          <Picker.Item key={value.id} label={value.name} value={value.id} />
                        )
                      })}
                    </Picker>
                  </View>
                </View>
              )}
            />

            {errors.engineerId && <Text style={GlobalStyle.form__input__validation}>Engineer is required.</Text>}
          </View>

          <View>
            <Controller
              control={control}
              name="purposeId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View >
                  <Text style={styles.form__label}>Purpose of Examination (*)</Text>
                  <View style={styles.form__input}>
                    <Picker
                      selectedValue={value}
                      onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                      itemStyle={{ height: 50 }}>

                      <Picker.Item label="Please Select" value="" />

                      {purposes != null && purposes.map((value, index) => {
                        return (
                          <Picker.Item key={value.id} label={value.purpose} value={value.id} />
                        )
                      })}
                    </Picker>
                  </View>
                </View>
              )}
            />

            {errors.purposeId && <Text style={GlobalStyle.form__input__validation}>Purpose is required.</Text>}
          </View>

          <View>
            <Controller
              control={control}
              name="colourId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View >
                  <Text style={styles.form__label}>Colour (*)</Text>
                  <View style={styles.form__input}>
                    <Picker
                      selectedValue={value}
                      onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                      itemStyle={{ height: 50 }}>

                      <Picker.Item label="Please Select" value="" />

                      {colours != null && colours.map((value, index) => {
                        return (
                          <Picker.Item key={value.id} label={value.name} value={value.id} />
                        )
                      })}
                    </Picker>
                  </View>
                </View>
              )}
            />

            {errors.colourId && <Text style={GlobalStyle.form__input__validation}>Colour is required.</Text>}
          </View>

          <View>
            <RoteStep1 navigation={null} route={null} />
          </View>

          <View>
            <RoteStep3 navigation={null} route={null} checklist={null} />
          </View>

          <View>
            <RoteStep4 navigation={null} route={null} submitHandler={submit} purposeInterval={data.purposeInterval} checklist={null} isMarineEquipment={isEveryEquipmentMarine} marineEquipmentId={marineEquipmentId} context={"equipment inspection"} isModal={true} />
          </View>
        </View>
      </ScrollView>
    </View>
  )
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flex: 1,
    maxHeight: '80vh',
    margin: 0
  },

  form__rows: {
    marginRight: 5,
    flexDirection: "row",
    alignItems: "center"
  },

  form__label: {
    fontFamily: 'OpenSans-Light',
    fontWeight: 'bold',
    paddingTop: 10,
    fontSize: 14,
    flex: 1
  },

  form__input: {
    flex: 2,
    fontFamily: 'OpenSans-Regular',
    padding: 5,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#CECECE',
    marginTop: 5,
  },

  closeButton: {
    alignSelf: 'flex-end',
    border: 'none',
    backgroundColor: 'white',
    paddingBottom: 10,
    cursor: 'pointer'
  },

  category__filter__column: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    flexWrap: 'wrap',
    maxWidth: "33.5%"
  },

});