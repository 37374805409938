import { baseApi, PagedTableDto } from './base-api'

export const inspectionApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getInspection: build.query<any, any>({
      query: (id) => ({
        url: `inspections/getinspection/${id}`,
      }),
    }),
    getInspections: build.query<any, PagedTableDto>({
      query: (dto) => ({
        //url: `inspection/getinspections?pageNumber=${dto.page + 1}&filters=${dto.getFilters()}`,
        url: `inspections/getinspections?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    getInspectionsDashboard: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `inspections/getinspectionsdashboard?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    sendInspection: build.mutation<any, any>({
      query: (body) => ({
        url: 'inspections/addinspection',
        method: 'post',
        body: body
      }),
    }),
    updateInspection: build.mutation<any, any>({
      query: (body) => ({
        url: 'inspections/updateinspection',
        method: 'put',
        body: body
      }),
    }),
    addEquipmentInspection: build.mutation<any, any>({
      query: (body) => ({
        url: 'inspections/addequipmentinspection',
        method: 'post',
        body: body
      }),
    }),
    archiveInspection: build.mutation<any, { id: number }>({
      query: (id) => ({
        url: `inspections/archiveinspection/${id}`,
        method: 'put'
      }),
    }),
    getInspectionImages: build.query<any, PagedTableDto>({
      query: (id) => ({
        url: `inspections/getinspectionimages?inspectionId=${id}`,
      }),
    }),
  }),
  overrideExisting: true,
})

export const {
  useSendInspectionMutation,
  useGetInspectionsQuery,
  useGetInspectionQuery,
  useUpdateInspectionMutation,
  useAddEquipmentInspectionMutation,
  useArchiveInspectionMutation,
  useGetInspectionsDashboardQuery,
  useGetInspectionImagesQuery
} = inspectionApi