import React from 'react';
import { View, Text, Switch, StyleSheet } from 'react-native';
import { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';


const PermissionSwitch = ({ permission, togglePermission }) => {
    return (
    <View key={permission.permissionName} style={styles.permission}>
      <Text style={styles.permission__title}>{permission.name}</Text>

      <View style={styles.permission__body}>
        <Switch
          onValueChange={() => togglePermission(permission)}
          value={permission.hasAccess}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
    permission: {
        flex: 1,
        height: 100,
        backgroundColor: '#ebebeb',
        margin: 10,
        borderRadius: 5,
        minWidth: 125,
        maxWidth: 125
    },

    permission__title: {
        textAlign: 'center',
        padding: 5,
        backgroundColor: PRIMARY_COLOUR,
        color: '#FFF',
        fontFamily: 'OpenSans-Bold',
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
    },

    permission__body: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
});

export default PermissionSwitch;