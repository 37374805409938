import View from "@expo/html-elements/build/primitives/View";
import { StyleSheet } from "react-native";
import { LoadingIndicator } from "./navigation/controls/LoadingIndicator";

export function LoadingView({isLoading, topOffset = 0, children}) {
    const container: any = {
        width: '100%',
        height: '100%',
        position: 'relative'
    }

    const loadingContainer: any = {
        top: topOffset,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'absolute',
        zIndex: 99,
        backgroundColor: 'rgba(0,0,0,0.1)'
    }

    return (
        <View style={container}>
            {isLoading && 
                <View style={loadingContainer}>
                    <LoadingIndicator />
                </View>
            }
            {children}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        position: 'relative'
    }
})