import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert, ScrollView, Button } from 'react-native';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { Controller, useForm } from 'react-hook-form';
import { serviceApi, useCreateServiceCategoryMutation, useCreateServiceQuestionMutation, useCreateServiceQuestionResponseMutation, useDeleteServiceCategoryMutation, useDeleteServiceQuestionMutation, useDeleteServiceQuestionResponseMutation, useTransferServiceCategoryMutation, useUpdateServiceCategoryMutation, useUpdateServiceQuestionMutation, useUpdateServiceQuestionResponseMutation } from '@store/services/api/service-api';
import { PagedTableDto } from '@store/services/api/base-api';
import { useEffect, useState } from 'react';
import { DataTable } from 'react-native-paper';
import { Ionicons, Feather } from '@expo/vector-icons';
import Checkbox from "expo-checkbox";
import React from 'react';
import { DataTableHeader } from '@web/components/table/DatatableHeader';
import PaginationComponent from '@web/components/Pagination';
import { FIRST_PAGE, MAX_ITEMS_BEFORE_ALL } from '../../../../../../constants/Constants';
import Spinner from 'react-native-loading-spinner-overlay';
import { useGetAllCategoriesQuery } from '@store/services/api/category-api';
import { Picker } from '@react-native-picker/picker';

function ServiceQuestionResponsesManage({ question }) {
  const [page, setPage] = useState<number>(0);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);
  const [loading, setLoading] = useState<any>(false);

  const [trigger, responsesResult] = serviceApi.useLazyGetServiceQuestionResponsesQuery();
  const [createResponse, createResponseResult] = useCreateServiceQuestionResponseMutation();
  const [updateResponse, updateResponseResult] = useUpdateServiceQuestionResponseMutation();
  const [deleteResponse, deleteResponseResult] = useDeleteServiceQuestionResponseMutation();

  const { control, handleSubmit, getValues, setValue, reset, watch, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      position: 0,
      response: "",
      commentMandatory: false,
      isPassDefault: false,
      serviceQuestionId: question?.id
    }
  });

  const id = watch("id");

  useEffect(() => {
    loadTable();
  }, [page, question, numberOfItemsPerPage]);

  const loadTable = () => {
    if (question == null) return;
    const pageNumber = numberOfItemsPerPage > MAX_ITEMS_BEFORE_ALL ? FIRST_PAGE : page;
    trigger(new PagedTableDto(pageNumber, {
      serviceQuestionId: question.id,
      pageSize: numberOfItemsPerPage
    })).then(() => {
      setLoading(false);
    });
  }

  const decreasePosition = async (response) => {
    if (response.position <= 1) { return; }

    var responseDto = JSON.parse(JSON.stringify(response));
    responseDto.position--;

    var result = await updateResponse(responseDto) as any;
    if (result.error) {
      alert(result.error)
      return;
    }

    loadTable();
  }

  const increasePosition = async (response) => {
    if (response.position == responsesResult?.data.list.length) { return; }

    var responseDto = JSON.parse(JSON.stringify(response));
    responseDto.position++;

    var result = await updateResponse(responseDto) as any;
    if (result.error) {
      alert(result.error)
      return;
    }

    loadTable();
  }

  const edit = (response) => {
    setValue("id", response.id);
    setValue("response", response.response);
    setValue("position", response.position);
    setValue("commentMandatory", response.commentMandatory);
    setValue("isPassDefault", response.isPassDefault);
  }


  const remove = async (response) => {
    if (confirm("Are you sure you want to delete this response?")) {
      var result = await deleteResponse(response.id) as any;
      if (result.error) {
        alert(result.error)
        return;
      }

      loadTable();
    }
  }

  const onSubmit = async (data) => {
    data.serviceQuestionId = question.id;
    var result = null;
    if (data.id != null) {
      result = await updateResponse(data) as any;
    } else {
      result = await createResponse(data) as any;
    }

    if (result.error) {
      alert(result.error)
      return;
    }

    reset();
    loadTable();
  }

  return (
    <View style={{ flex: 1 }}>
      <Spinner
        visible={loading}
        textContent={'Loading...'}
        textStyle={GlobalStyle.spinnerTextStyle}
      />
      <View>
        <Text style={[GlobalStyle.add__new__link, styles.add__new__link]}>{question != null ? question.question + ' - ' : null} Question Responses</Text>
        {question != null &&
          <View style={[GlobalStyle.form, styles.form]}>
            <View style={GlobalStyle.form__row}>
              <View style={[GlobalStyle.form__column, GlobalStyle.form__column__full]}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="response"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View>
                      <Text style={GlobalStyle.form__column__text}>Response (*)</Text>
                      <TextInput
                        style={GlobalStyle.form__column__input}
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}>
                      </TextInput>
                    </View>
                  )}
                />

                {errors.response && <Text style={GlobalStyle.form__input__validation}>Response is required.</Text>}
              </View>
            </View>
            <View style={GlobalStyle.form__row}>
              <View style={[GlobalStyle.form__column, GlobalStyle.form__column__full]}>
                <Controller
                  control={control}
                  name="commentMandatory"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View>
                      <Text style={GlobalStyle.form__column__text}>Comment Required</Text>
                      <Checkbox
                        style={GlobalStyle.form__column__input}
                        onValueChange={onChange}
                        value={value}>
                      </Checkbox>
                    </View>
                  )}
                />
              </View>

              <View style={[GlobalStyle.form__column, GlobalStyle.form__column__full]}>
                <Controller
                  control={control}
                  name="isPassDefault"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View>
                      <Text style={GlobalStyle.form__column__text}>Default Pass</Text>
                      <Checkbox
                        style={GlobalStyle.form__column__input}
                        onValueChange={onChange}
                        value={value}>
                      </Checkbox>
                    </View>
                  )}
                />
              </View>

              <View style={styles.form__buttons}>
                <TouchableOpacity style={styles.form__add__btn} onPress={handleSubmit(onSubmit)}>
                  <Text style={styles.form__add__btn__text}>{id == null ? 'Add' : 'Update'}</Text>
                </TouchableOpacity>

                {id != null &&
                  <TouchableOpacity style={styles.form__add__btn} onPress={() => reset()}>
                    <Text style={styles.form__add__btn__text}>Clear</Text>
                  </TouchableOpacity>
                }
              </View>
            </View>
          </View>
        }
      </View>

      {question == null &&
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Text style={{ fontSize: 28, fontStyle: 'italic' }}>Select Question</Text>
        </View>
      }

      {question != null &&
        <View>
          <DataTable style={GlobalStyle.table}>
            <DataTable.Header>
              <DataTable.Title>Response</DataTable.Title>
              <DataTable.Title>Position</DataTable.Title>
              <DataTable.Title>Default Pass</DataTable.Title>
              <DataTable.Title>Actions</DataTable.Title>
            </DataTable.Header>

            {responsesResult?.data?.list.map((element: any) => {
              return (
                <DataTable.Row key={element.id}>
                  <DataTable.Cell>{element.response}</DataTable.Cell>
                  <DataTable.Cell>
                    {element.position}
                    <View style={styles.table__actions}>
                      <TouchableOpacity style={styles.action} onPress={() => decreasePosition(element)}><Ionicons name="chevron-up-outline" size={24} color="black" /></TouchableOpacity>
                      <TouchableOpacity style={styles.action} onPress={() => increasePosition(element)}><Ionicons name="chevron-down-outline" size={24} color="black" /></TouchableOpacity>
                    </View>
                  </DataTable.Cell>
                  <DataTable.Cell>{element.isPassDefault ? 'Yes' : 'No'}</DataTable.Cell>
                  <DataTable.Cell>
                    <View style={styles.table__actions}>
                      <TouchableOpacity style={styles.action} onPress={() => edit(element)}><Feather name="edit" size={20} color="black" /></TouchableOpacity>
                      <TouchableOpacity style={styles.action} onPress={() => remove(element)}><Feather name="trash" size={20} color="black" /></TouchableOpacity>
                    </View>
                  </DataTable.Cell>
                </DataTable.Row>
              )
            })}
          </DataTable>

          <PaginationComponent
            numberOfItemsPerPage={numberOfItemsPerPage}
            setNumberOfItemsPerPage={setNumberOfItemsPerPage}
            page={page}
            setPage={setPage}
            fetchResult={responsesResult}
            loading={loading}
            setLoading={setLoading}
          />
        </View>
      }
    </View>
  )
}

function ServiceQuestionsManage({ category, onSelected }) {
  const [selectedQuestion, setSelectedQuestion] = useState<any>(null);

  const [page, setPage] = useState<number>(0);
  const [sortedColumn, setSortedColumn] = React.useState<string>("position");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);
  const [loading, setLoading] = useState<any>(false);

  const [trigger, questionsResult] = serviceApi.useLazyGetServiceQuestionsQuery();
  const [createQuestion, createQuestionResult] = useCreateServiceQuestionMutation();
  const [updateQuestion, updateQuestionResult] = useUpdateServiceQuestionMutation();
  const [deleteQuestion, deleteQuestionResult] = useDeleteServiceQuestionMutation();

  const { control, handleSubmit, getValues, setValue, reset, watch, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      position: 0,
      question: "",
      serviceQuestionCategoryId: category?.id
    }
  });

  const id = watch("id");

  useEffect(() => {
    loadTable();
  }, [page, category, sortedColumn, sortedColumnAscending, numberOfItemsPerPage]);

  useEffect(() => {
    onSelected(selectedQuestion);
  }, [selectedQuestion])

  const loadTable = () => {
    if (category == null) return;
    const pageNumber = numberOfItemsPerPage > MAX_ITEMS_BEFORE_ALL ? FIRST_PAGE : page;
    trigger(new PagedTableDto(pageNumber, {
      serviceCategoryId: category.id,
      pageSize: numberOfItemsPerPage
    }, sortedColumn, sortedColumnAscending)).then(() => {
      setLoading(false);
    });
  }

  const decreasePosition = async (question) => {
    if (question.position <= 1) { return; }

    var questionDto = JSON.parse(JSON.stringify(question));
    questionDto.position--;

    var result = await updateQuestion(questionDto) as any;
    if (result.error) {
      alert(result.error)
      return;
    }

    loadTable();
  }

  const increasePosition = async (question) => {
    if (question.position == questionsResult?.data.list.length) { return; }

    var questionDto = JSON.parse(JSON.stringify(question));
    questionDto.position++;

    var result = await updateQuestion(questionDto) as any;
    if (result.error) {
      alert(result.error)
      return;
    }

    loadTable();
  }

  const edit = (question) => {
    setValue("id", question.id);
    setValue("question", question.question);
    setValue("position", question.position);
  }

  const toggleQuestion = (question) => {
    if (selectedQuestion != null && selectedQuestion.id == question.id) {
      setSelectedQuestion(null);
    } else {
      setSelectedQuestion(question);
    }

  }

  const remove = async (category) => {
    if (confirm("Are you sure you want to delete this question?")) {
      var result = await deleteQuestion(category.id) as any;
      if (result.error) {
        alert(result.error)
        return;
      }
      setSelectedQuestion(null);
      loadTable();
    }
  }

  const onSubmit = async (data) => {

    data.serviceQuestionCategoryId = category.id;
    var result = null;
    if (data.id != null) {
      result = await updateQuestion(data) as any;
    } else {
      result = await createQuestion(data) as any;
    }

    if (result.error) {
      alert(result.error)
      return;
    }

    reset();
    loadTable();
  }

  return (
    <View style={{ flex: 1 }}>
      <Spinner
        visible={loading}
        textContent={'Loading...'}
        textStyle={GlobalStyle.spinnerTextStyle}
      />
      <View>
        <Text style={[GlobalStyle.add__new__link, styles.add__new__link]}>{category != null ? category.title + ' - ' : null} Service Questions</Text>
        {category != null &&
          <View style={[GlobalStyle.form, styles.form]}>
            <View style={GlobalStyle.form__row}>
              <View style={[GlobalStyle.form__column, GlobalStyle.form__column__full]}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="question"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View>
                      <Text style={GlobalStyle.form__column__text}>Question (*)</Text>
                      <TextInput
                        style={GlobalStyle.form__column__input}
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}>
                      </TextInput>
                    </View>
                  )}
                />

                {errors.question && <Text style={GlobalStyle.form__input__validation}>Name is required.</Text>}
              </View>

              <View style={styles.form__buttons}>
                <TouchableOpacity style={styles.form__add__btn} onPress={handleSubmit(onSubmit)}>
                  <Text style={styles.form__add__btn__text}>{id == null ? 'Add' : 'Update'}</Text>
                </TouchableOpacity>

                {id != null &&
                  <TouchableOpacity style={styles.form__add__btn} onPress={() => reset()}>
                    <Text style={styles.form__add__btn__text}>Clear</Text>
                  </TouchableOpacity>
                }
              </View>
            </View>
          </View>
        }
      </View>

      {category == null &&
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Text style={{ fontSize: 28, fontStyle: 'italic' }}>Select Category</Text>
        </View>
      }

      {category != null &&
        <View>
          <DataTable style={GlobalStyle.table}>
            <DataTableHeader
              sortedColumn={sortedColumn}
              sortedColumnAscending={sortedColumnAscending}
              onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
              headers={[
                { text: 'Name', sortedName: "question", visible: true },
                { text: 'Position', sortedName: "position", visible: true },
                { text: 'Actions', visible: true },
              ]}
            />

            {questionsResult?.data?.list.map((element: any) => {
              return (
                <DataTable.Row key={element.id}>
                  <DataTable.Cell>{element.question}</DataTable.Cell>
                  <DataTable.Cell>
                    {element.position}
                    <View style={styles.table__actions}>
                      <TouchableOpacity style={styles.action} onPress={() => decreasePosition(element)}><Ionicons name="chevron-up-outline" size={24} color="black" /></TouchableOpacity>
                      <TouchableOpacity style={styles.action} onPress={() => increasePosition(element)}><Ionicons name="chevron-down-outline" size={24} color="black" /></TouchableOpacity>
                    </View>
                  </DataTable.Cell>
                  <DataTable.Cell>
                    <View style={styles.table__actions}>
                      <TouchableOpacity style={styles.action} onPress={() => edit(element)}><Feather name="edit" size={20} color="black" /></TouchableOpacity>
                      <TouchableOpacity style={styles.action} onPress={() => remove(element)}><Feather name="trash" size={20} color="black" /></TouchableOpacity>
                      <View style={styles.action}>
                        <Checkbox style={[GlobalStyle.form__column__input]}
                          onValueChange={() => toggleQuestion(element)}
                          value={selectedQuestion == null ? false : selectedQuestion.id == element.id}>
                        </Checkbox>
                      </View>
                    </View>
                  </DataTable.Cell>
                </DataTable.Row>
              )
            })}
          </DataTable>

          <PaginationComponent
            numberOfItemsPerPage={numberOfItemsPerPage}
            setNumberOfItemsPerPage={setNumberOfItemsPerPage}
            page={page}
            setPage={setPage}
            fetchResult={questionsResult}
            loading={loading}
            setLoading={setLoading}
          />
        </View>
      }
    </View>
  )
}

function ServiceCategoriesManage({ onSelected, categoryId, setIsServiceCategoryListEmpty }) {
  const [selectedCategory, setSelectedCategory] = useState<any>(null);

  const [page, setPage] = useState<number>(0);
  const [sortedColumn, setSortedColumn] = React.useState<string>("position");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);
  const [loading, setLoading] = useState<any>(false);

  const [trigger, categoriesResult] = serviceApi.useLazyGetServiceCategoriesQuery();
  const [createCategory, createCategoryResult] = useCreateServiceCategoryMutation();
  const [updateCategory, updateCategoryResult] = useUpdateServiceCategoryMutation();
  const [deleteCategory, deleteCategoryResult] = useDeleteServiceCategoryMutation();

  const { control, handleSubmit, getValues, setValue, reset, watch, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      position: 0,
      title: "",
    }
  });

  const id = watch("id");

  useEffect(() => {
    loadTable();
  }, [page, sortedColumn, sortedColumnAscending, numberOfItemsPerPage, categoryId]);

  useEffect(() => {
    if (categoriesResult?.data?.list.length === 0) {
      setIsServiceCategoryListEmpty(true);
    } else {
      setIsServiceCategoryListEmpty(false);
    }
  }, [categoriesResult?.data?.list]);

  useEffect(() => {
    onSelected(selectedCategory);
  }, [selectedCategory])

  const loadTable = () => {
    const pageNumber = numberOfItemsPerPage > MAX_ITEMS_BEFORE_ALL ? FIRST_PAGE : page;
    trigger(new PagedTableDto(pageNumber, { pageSize: numberOfItemsPerPage, categoryId: categoryId }, sortedColumn, sortedColumnAscending))
      .then(() => {
        setLoading(false);
      });
  }

  const decreasePosition = async (category) => {
    if (category.position <= 1) { return; }

    var categoryDto = JSON.parse(JSON.stringify(category));
    categoryDto.categoryId = categoryId;
    categoryDto.position--;

    var result = await updateCategory(categoryDto) as any;
    if (result.error) {
      alert(result.error)
      return;
    }

    loadTable();
  }

  const increasePosition = async (category) => {
    if (category.position == categoriesResult?.data.list.length) { return; }

    var categoryDto = JSON.parse(JSON.stringify(category));
    categoryDto.categoryId = categoryId;
    categoryDto.position++;

    var result = await updateCategory(categoryDto) as any;
    if (result.error) {
      alert(result.error)
      return;
    }

    loadTable();
  }

  const edit = (category) => {
    setValue("id", category.id);
    setValue("title", category.title);
    setValue("position", category.position);
  }

  const remove = async (category) => {
    if (confirm("Are you sure you want to delete this category?")) {
      var result = await deleteCategory(category.id) as any;
      if (result.error) {
        console.log(result.error);
        alert(result.error)
        return;
      }

      loadTable();
    }
  }

  const toggleCategory = (category) => {
    if (selectedCategory != null && selectedCategory.id == category.id) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(category);
    }

  }

  const onSubmit = async (data) => {
    var result = null;
    data.categoryId = categoryId;

    if (data.id != null) {
      result = await updateCategory(data) as any;
    } else {
      result = await createCategory(data) as any;
    }

    if (result.error) {
      alert(result.error)
      return;
    }

    reset();
    loadTable();
  }

  return (
    <ScrollView>
      <Spinner
        visible={loading}
        textContent={'Loading...'}
        textStyle={GlobalStyle.spinnerTextStyle}
      />
      <View>
        <Text style={[GlobalStyle.add__new__link, styles.add__new__link]}>Service Categories</Text>
        <View style={[GlobalStyle.form, styles.form]}>
          <View style={GlobalStyle.form__row}>
            <View style={[GlobalStyle.form__column, GlobalStyle.form__column__full]}>
              <Controller
                control={control}
                rules={{ required: true }}
                name="title"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <Text style={GlobalStyle.form__column__text}>Category (*)</Text>
                    <TextInput
                      style={GlobalStyle.form__column__input}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}>
                    </TextInput>
                  </View>
                )}
              />

              {errors.title && <Text style={GlobalStyle.form__input__validation}>Category is required.</Text>}
            </View>

            <View style={styles.form__buttons}>
              <TouchableOpacity style={styles.form__add__btn} onPress={handleSubmit(onSubmit)}>
                <Text style={styles.form__add__btn__text}>{id == null ? 'Add' : 'Update'}</Text>
              </TouchableOpacity>

              {id != null &&
                <TouchableOpacity style={styles.form__add__btn} onPress={() => reset()}>
                  <Text style={styles.form__add__btn__text}>Clear</Text>
                </TouchableOpacity>
              }
            </View>
          </View>
        </View>
      </View>

      <View>
        <DataTable style={GlobalStyle.table}>

          <DataTableHeader
            sortedColumn={sortedColumn}
            sortedColumnAscending={sortedColumnAscending}
            onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
            headers={[
              { text: 'Name', sortedName: "title", visible: true },
              { text: 'Position', sortedName: "position", visible: true },
              { text: 'Actions', visible: true },
            ]}
          />

          {categoriesResult?.data?.list.map((element: any) => {
            return (
              <DataTable.Row key={element.id}>
                <DataTable.Cell>{element.title}</DataTable.Cell>
                <DataTable.Cell>
                  {element.position}
                  <View style={styles.table__actions}>
                    <TouchableOpacity style={styles.action} onPress={() => decreasePosition(element)}><Ionicons name="chevron-up-outline" size={24} color="black" /></TouchableOpacity>
                    <TouchableOpacity style={styles.action} onPress={() => increasePosition(element)}><Ionicons name="chevron-down-outline" size={24} color="black" /></TouchableOpacity>
                  </View>
                </DataTable.Cell>
                <DataTable.Cell>
                  <View style={styles.table__actions}>
                    <TouchableOpacity style={styles.action} onPress={() => edit(element)}><Feather name="edit" size={20} color="black" /></TouchableOpacity>
                    <TouchableOpacity style={styles.action} onPress={() => remove(element)}><Feather name="trash" size={20} color="black" /></TouchableOpacity>
                    <View style={styles.action}>
                      <Checkbox style={[GlobalStyle.form__column__input]}
                        onValueChange={() => toggleCategory(element)}
                        value={selectedCategory == null ? false : selectedCategory.id == element.id}>
                      </Checkbox>
                    </View>
                  </View>
                </DataTable.Cell>
              </DataTable.Row>
            )
          })}
        </DataTable>

        <PaginationComponent
          numberOfItemsPerPage={numberOfItemsPerPage}
          setNumberOfItemsPerPage={setNumberOfItemsPerPage}
          page={page}
          setPage={setPage}
          fetchResult={categoriesResult}
          loading={loading}
          setLoading={setLoading}
        />
      </View>


    </ScrollView>
  )
}

export default function ServiceQuestions({ navigation }: { navigation: any }) {
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [selectedQuestion, setSelectedQuestion] = useState<any>(null);
  const [categoryId, setCategoryId] = useState<string>("");
  const [showTransfer, setShowTransfer] = useState<boolean>(false);
  const [isServiceCategoryListEmpty, setIsServiceCategoryListEmpty] = useState<boolean>(false);

  const { data: categories } = useGetAllCategoriesQuery(null);
  const [transferServiceCategory, transferServiceCategoryResult] = useTransferServiceCategoryMutation();

  const handleCategoryTransfer = async (destinationCategoryId) => {
    let result = null;
    const data = { categoryId, destinationCategoryId };
    if (isServiceCategoryListEmpty) {
      alert("No service categories to transfer");
      return;
    }
    if (destinationCategoryId && categoryId) {
      result = await transferServiceCategory(data) as any;
    }

    if (result.error) {
      alert(result.error)
      return;
    }
    alert("Successfully transferred service questions")
    setShowTransfer(false);
    setCategoryId("");
    setSelectedCategory(null);
    setSelectedQuestion(null);

  }

  return (
    <View style={styles.service__content}>
      <View style={{ flexDirection: 'row', width: '100%' }}>
        <View style={styles.category__header}>
          <View style={styles.category__filter__column}>
            <CategoryPicker categoryId={categoryId} setCategoryId={setCategoryId} categories={categories} />
          </View>
          <View style={styles.category__filter__column}>
            {categoryId && !isServiceCategoryListEmpty && <View style={[GlobalStyle.column__flex]}>
              <Text style={[GlobalStyle.add__new__link]}>Duplicate</Text>
              <Checkbox
                value={showTransfer}
                onValueChange={setShowTransfer}
                style={[GlobalStyle.column, GlobalStyle.column__input, { margin: 8, padding: 5 }]}
              />
            </View>}
          </View>
          <View style={styles.category__filter__column}>
            <CategoryTransfer
              categories={categories}
              handleTransfer={handleCategoryTransfer}
              sourceCategoryId={categoryId}
              showTransfer={showTransfer}
            />
          </View>
        </View>
      </View>
      <View style={{ flexDirection: 'row', width: '100%' }}>
        <View style={styles.service__block}>
          <ServiceCategoriesManage
            onSelected={(category) => setSelectedCategory(category)}
            categoryId={categoryId}
            setIsServiceCategoryListEmpty={setIsServiceCategoryListEmpty} />
        </View>
        <View style={styles.service__block}>
          <ServiceQuestionsManage category={selectedCategory} onSelected={(question) => setSelectedQuestion(question)} />
        </View>
        <View style={styles.service__block}>
          <ServiceQuestionResponsesManage question={selectedQuestion} />
        </View>
      </View>
    </View>
  )
};

function CategoryPicker({ categoryId, setCategoryId, categories }: { categoryId: any, setCategoryId: any, categories: any }) {
  return (
    <View style={GlobalStyle.column__flex}>
      <Text style={GlobalStyle.add__new__link}>Equipment Category</Text>
      <Picker
        style={[GlobalStyle.column, GlobalStyle.column__input]}
        selectedValue={categoryId}
        onValueChange={(itemValue, itemIndex) => setCategoryId(itemValue)}>
        <Picker.Item label="Please select a category" value="" />
        {categories != null &&
          categories.map((value, index) => {
            return <Picker.Item key={value.id} label={value.name} value={value.id} />;
          })}
      </Picker>
    </View>
  );
};

function CategoryTransfer({ sourceCategoryId, handleTransfer, categories, showTransfer }:
  { sourceCategoryId: any, handleTransfer: any, categories: any, showTransfer: any }) {
  const [destinationCategoryId, setDestinationCategoryId] = useState<string>("");

  useEffect(() => {
    if (!showTransfer) {
      setDestinationCategoryId("");
    }
  }, [showTransfer]);

  return (
    <View>
      {showTransfer && (<View>
        <Text style={GlobalStyle.add__new__link}>Destination category:</Text>
        <View style={GlobalStyle.form__row}>
          <Picker
            style={[GlobalStyle.column, GlobalStyle.column__input]}
            selectedValue={destinationCategoryId}
            onValueChange={(itemValue, itemIndex) => setDestinationCategoryId(itemValue)}>
            <Picker.Item label="Please select a category" value="" />
            {(categories != null && sourceCategoryId) &&
              categories.filter(c => c.id !== sourceCategoryId).map((value, index) => {
                return <Picker.Item key={value.id} label={value.name} value={value.id} />;
              })}
          </Picker>
          <View style={[styles.form__buttons, { alignItems: 'stretch' }]}>
            <TouchableOpacity style={[styles.form__add__btn, { margin: 0, height: '100%' }]}
              onPress={() => handleTransfer(destinationCategoryId)}>
              <Text style={styles.form__add__btn__text}>Transfer</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>)}
    </View >
  );
};

const styles = StyleSheet.create({
  service__content: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10
  },

  service__block: {
    flex: 1,
    padding: 10,
    backgroundColor: '#FFF',
    margin: 10,
    borderWidth: 1,
    borderColor: '#CECECE'
  },

  add__new__link: {
    borderBottomColor: '#CECECE',
    borderBottomWidth: 1,
    paddingBottom: 10,
    marginBottom: 0,
  },

  form: {
    borderBottomWidth: 1,
    borderColor: '#CECECE',
    paddingBottom: 20,
    marginBottom: 10
  },

  form__buttons: {
    width: 150,
    flexDirection: 'row',
    alignItems: 'center'
  },

  form__add__btn: {
    backgroundColor: PRIMARY_COLOUR,
    flex: 1,
    height: 31,
    alignSelf: 'flex-end',
    textAlign: 'center',
    marginLeft: 10,
    marginBottom: 5,

    alignItems: 'center',
    justifyContent: 'center',
  },

  form__add__btn__text: {
    color: '#FFF',
  },

  table__actions: {
    flexDirection: 'row',
  },

  action: {
    paddingLeft: 5,
    paddingRight: 5,
  },

  category__header: {
    flex: 1,
    flexDirection: 'row',
    padding: 10,
    backgroundColor: '#FFF',
    margin: 10,
    borderWidth: 1,
    borderColor: '#CECECE'
  },
  category__filter__column: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    flexWrap: 'wrap',
    maxWidth: "33.5%"
  },
});
