import { baseApi } from './base-api'

export const engineerReportApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    sendEngineerReport: build.mutation<any, any>({
      query: (body) => ({
        url: `engineerreport/addengineerreport`,
        method: 'post',
        body: body
      }),
    }),
    syncEngineerReportQuestions: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `engineerreport/getengineerreportquestions?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    getEngineerReport: build.query<EngineerReport, string>({
      query: (id: string) => ({
        url: `engineerreport/geterstr?id=${id}`,
        method: 'GET'
      }),
    }),
    updateEngineerReport: build.mutation<void, EngineerReport>({
      query: (body) => ({
        url: `engineerreport/updateerstr/${body.id}`,
        method: 'PUT',
        body: body
      })
    }),
  }),
  overrideExisting: true,
})

export const {
  useUpdateEngineerReportMutation,
  useSendEngineerReportMutation,
  useGetEngineerReportQuery,
} = engineerReportApi

export interface EngineerReport {
  id: string,
  engineerId: string,
  jobId: string,
  worksCarriedOut: string,
  furtherWorks: string,
  jobCompleted: boolean,
  clientName: string,
  clientSignature: string,
  customerPoNumber: string,
  timesheets: EngineerTimesheet[],
  parts: any,
  answeredQuestions: any
}

export interface EngineerTimesheet {
  id: string,
  day: string,
  normalTime: number,
  overTime: number,
  doubleTime: number,
  startTime: string,
  endTime: string,
  miles: number,
  dayIdx: number
}
