import * as React from "react";
import {FileManager} from "@components/management/Files/FileManager";
import {
  useAddFileMutation,
  useLazyGetEquipmentFilesQuery,
  useRemoveFileMutation
} from "@store/services/api/equipment-api";

interface EquipmentFileManagerProps {
  equipmentId: string,
  onClose: () => void
}

export const EquipmentFileManager = ({equipmentId, onClose}: EquipmentFileManagerProps) => {
  const addFileMutation = useAddFileMutation();
  const getCompanyFilesQuery = useLazyGetEquipmentFilesQuery();
  const removeFileMutation = useRemoveFileMutation();

  return (
    <FileManager
      idName='equipmentId'
      entityId={equipmentId}
      onCancel={onClose}
      useAddFileMutation={addFileMutation}
      useRemoveFileMutation={removeFileMutation}
      useLazyGetFilesQuery={getCompanyFilesQuery} />
  )
}
