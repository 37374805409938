import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert, Switch } from 'react-native';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { EvilIcons } from '@expo/vector-icons';
import { Controller, useForm } from 'react-hook-form';
import { LoadingIndicator } from '../../../../components/navigation/controls/LoadingIndicator';
import { Picker } from '@react-native-picker/picker';
import { useGetAllCompaniesWithEmailPreferencesQuery, useUpdateCompanyEmailPreferencesMutation } from '@store/services/api/company-api';
import { EMAIL_REGEX } from '../../../../../../constants/Constants';

export default function EmailsManageScreen({ navigation }) {
    const [companyId, setCompanyId] = useState<string>("");

    const { data: companies, isLoading } = useGetAllCompaniesWithEmailPreferencesQuery(null);
    const [updateEmailPreferences, updateEmailPreferencesResult] = useUpdateCompanyEmailPreferencesMutation();

    const { control, handleSubmit, setValue, watch, formState: { errors } } = useForm({
        defaultValues: {
            inspectionDueReminderEmail: "",
            jobCompletedEmail: "",
            inspectionDueReminderEnabled: false,
            jobCompletedEnabled: false,
            emailPreferenceId: ""
        }
    });

    const watchInspectionDueReminderEnabled = watch("inspectionDueReminderEnabled", false);
    const watchJobCompletedEnabled = watch("jobCompletedEnabled", false);

    useEffect(() => {
        if (companyId && companies) {
            const selectedCompany = companies.find(company => company.id === companyId);
            if (selectedCompany) {
                setValue("inspectionDueReminderEmail", selectedCompany.inspectionDueReminderEmail || "");
                setValue("jobCompletedEmail", selectedCompany.jobCompletedEmail || "");
                setValue("inspectionDueReminderEnabled", selectedCompany.inspectionDueReminderEnabled || false);
                setValue("jobCompletedEnabled", selectedCompany.jobCompletedEnabled || false);
                setValue("emailPreferenceId", selectedCompany.emailPreferenceId || "");
            }
        }
    }, [companyId, companies]);

    const validateEmail = (email: string) => {
        return email === "" || EMAIL_REGEX.test(email.trim());
    };

    const onSubmit = async (data) => {
        if (!validateEmail(data.inspectionDueReminderEmail)) {
            alert("Reminder Email is not valid.");
            return;
        }

        if (!validateEmail(data.jobCompletedEmail)) {
            alert("Job Completion Email is not valid.");
            return;
        }
        var result = null;
        data.companyId = companyId;

        if (data.emailPreferenceId != null) {
            result = await updateEmailPreferences(data) as any;
            if (result.error) {
                return;
            }
        }
        navigation.goBack();
    };

    if (isLoading) {
        return <LoadingIndicator />
    }

    return (
        <View style={styles.container}>
            <View style={GlobalStyle.sub__title}>
                <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => navigation.goBack()}>
                    <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
                </TouchableOpacity>
                <Text style={GlobalStyle.sub__title__text}>Email Management</Text>
            </View>
            <View style={GlobalStyle.filters}>
                <View style={[GlobalStyle.filter__columns, { maxWidth: "33.5%" }]}>
                    <CompanyPicker companyId={companyId} setCompanyId={setCompanyId} companies={companies} />
                </View>
            </View>
            <View style={GlobalStyle.form}>
                <View style={GlobalStyle.form__row}>
                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="inspectionDueReminderEmail"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Reminder Email</Text>
                                    <TextInput
                                        style={GlobalStyle.form__column__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}
                                        editable={watchInspectionDueReminderEnabled}
                                    />
                                </View>
                            )}
                        />
                    </View>
                </View>
                <View style={GlobalStyle.form__row}>
                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="jobCompletedEmail"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Job Completion Email</Text>
                                    <TextInput
                                        style={GlobalStyle.form__column__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}
                                        editable={watchJobCompletedEnabled}
                                    />
                                </View>
                            )}
                        />
                    </View>
                </View>
                <View style={GlobalStyle.form__row}>
                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="inspectionDueReminderEnabled"
                            render={({ field: { onChange, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Enable Reminder</Text>
                                    <Switch
                                        onValueChange={onChange}
                                        value={value}
                                    />
                                </View>
                            )}
                        />
                    </View>
                </View>
                <View style={GlobalStyle.form__row}>
                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="jobCompletedEnabled"
                            render={({ field: { onChange, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Enable Job Completion Email</Text>
                                    <Switch
                                        onValueChange={onChange}
                                        value={value}
                                    />
                                </View>
                            )}
                        />
                    </View>
                </View>
                <TouchableOpacity style={GlobalStyle.form__submit__button} onPress={handleSubmit(onSubmit)}>
                    <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
}

function CompanyPicker({ companyId, setCompanyId, companies }) {
    return (
        <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.add__new__link}>Company</Text>
            <Picker
                style={[GlobalStyle.column, GlobalStyle.column__input]}
                selectedValue={companyId}
                onValueChange={(itemValue, itemIndex) => setCompanyId(itemValue)}>
                <Picker.Item label="Please select a company" value="" />
                {companies && companies.map((value) => (
                    <Picker.Item key={value.id} label={value.name} value={value.id} />
                ))}
            </Picker>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        position: 'relative',
        padding: 10,
    },
});
