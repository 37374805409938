import { baseApi, PagedTableDto } from './base-api'

export const countryApi = baseApi.injectEndpoints({
    endpoints: (build) => ({

        getCountries: build.query<any, PagedTableDto>({
            query: (dto) => ({
                url: `countries/getcountries?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
            }),
            transformResponse: (response: any, meta, arg) => {
                var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

                return {
                    list: response,
                    pagination: pagination
                };
            }
        }),
        getCountry: build.query<any, any>({
            query: (countryId) => ({
                url: `countries/getcountry/${countryId}`,
            }),
        }),
        getAllCountries: build.query<any, any>({
            query: (countryId) => ({
                url: `countries/getallcountries`,
            }),
        }),

    }),
    overrideExisting: true,
})

export const { useGetCountriesQuery, useGetCountryQuery, useGetAllCountriesQuery } = countryApi