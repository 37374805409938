import * as React from 'react';
import { useState } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, ScrollView } from 'react-native';


import { DataTable } from 'react-native-paper';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { useIsFocused } from "@react-navigation/native";
import {Entypo, SimpleLineIcons, Ionicons, AntDesign} from '@expo/vector-icons';
import {companyApi} from '@store/services/api/company-api';
import { PagedTableDto } from '@store/services/api/base-api';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { DataTableHeader } from '@web/components/table/DatatableHeader';
import PaginationComponent from '@web/components/Pagination';
import { MAX_ITEMS_BEFORE_ALL, FIRST_PAGE } from '../../../../../../constants/Constants';
import Spinner from 'react-native-loading-spinner-overlay';
import {CompanyFileManager} from "@components/management/Files/CompanyFileManager";

export default function CompaniesListScreen({navigation}: {navigation: any}) {
  const isFocused = useIsFocused();

  const [page, setPage] = React.useState<number>(0);
  const [sortedColumn, setSortedColumn] = React.useState<string>("name");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);
  const [loading, setLoading] = useState<any>(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>(null);

  // Filters 
  const [companyName, setCompanyName] = useState("");
  const [address1, setAddress1] = useState("");  

  const [trigger, companiesResult] = companyApi.useLazyGetCompaniesQuery();
  
  React.useEffect(() => {
    if (isFocused) {
      loadTable();
    }
  }, [page, isFocused, sortedColumn, sortedColumnAscending, numberOfItemsPerPage]);

  const loadTable = () => {
    const pageNumber = numberOfItemsPerPage > MAX_ITEMS_BEFORE_ALL ? FIRST_PAGE : page;
    trigger(new PagedTableDto(pageNumber, {
      name: companyName, 
      address1: address1,
      pageSize: numberOfItemsPerPage
    }, sortedColumn, sortedColumnAscending))
    .then(() => {
      setLoading(false);
    });
  }

  const resetFilters = () => {
    setCompanyName("");
    setAddress1("");
  }

  function applyFilters() {
    setPage((curr) => {
      if (curr == 0) loadTable();
      return 0;
    })
  }

  if (companiesResult.isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <ScrollView style={styles.container}>
      <Spinner
        visible={loading}
        textContent={'Loading...'}
        textStyle={GlobalStyle.spinnerTextStyle}
      />
      <CompanyFileManager
        onClose={() => setSelectedCompanyId(null)}
        companyId={selectedCompanyId} />

      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Company Name</Text>
            <TextInput 
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              placeholder="Company Name"
              onChangeText={setCompanyName}
              value={companyName}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Address 1</Text>
            <TextInput 
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              placeholder="Address 1"
              onChangeText={setAddress1}
              value={address1}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={applyFilters}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

      <TouchableOpacity style={GlobalStyle.add__button} onPress={() => navigation.navigate("manage")}>
        <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 5, paddingRight: 10}}>
          <Ionicons name="add" size={24} color="white" /><Text style={GlobalStyle.form__submit__button__text}>Add New Company</Text>
        </View>
      </TouchableOpacity>

      <DataTable style={GlobalStyle.table}>
        <DataTableHeader
            sortedColumn={sortedColumn}
            sortedColumnAscending={sortedColumnAscending}
            onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
            headers={[
              { text: 'Upload', visible: true },
              { text: 'Name', sortedName: "name", visible: true },
              { text: 'Address 1', sortedName: "address1", visible: true },
              { text: 'Address 2', sortedName: "address2", visible: true },
              { text: 'Address 3', sortedName: "address3", visible: true },
              { text: 'Address 4', sortedName: "address4", visible: true },
              { text: 'Postcode', sortedName: "postcode", visible: true },
              { text: 'Phone Number', visible: true },
              { text: 'Option', visible: true },
            ]}
          />

        { companiesResult?.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>
                <TouchableOpacity onPress={() => setSelectedCompanyId(element.id)}>
                  <AntDesign name="folder1" size={24} color="black" />
                </TouchableOpacity>
              </DataTable.Cell>
              <DataTable.Cell>{element.name}</DataTable.Cell>
              <DataTable.Cell>{element.address1}</DataTable.Cell>
              <DataTable.Cell>{element.address2}</DataTable.Cell>
              <DataTable.Cell>{element.address3}</DataTable.Cell>
              <DataTable.Cell>{element.address4}</DataTable.Cell>
              <DataTable.Cell>{element.postcode}</DataTable.Cell>
              <DataTable.Cell>{element.phoneNumber}</DataTable.Cell>
              <DataTable.Cell>
                <TouchableOpacity onPress={() => navigation.navigate("manage", { companyId: element.id })}>
                  <Entypo name="pencil" size={24} color="black" />
                </TouchableOpacity>
              </DataTable.Cell>
            </DataTable.Row>
          )
        })}

        
      </DataTable>

      <PaginationComponent
        numberOfItemsPerPage={numberOfItemsPerPage}
        setNumberOfItemsPerPage={setNumberOfItemsPerPage}
        page={page}
        setPage={setPage}
        fetchResult={companiesResult}
        loading={loading}
        setLoading={setLoading}
      />

    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
