import * as React from 'react';
import { useState } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, ScrollView } from 'react-native';

import { DataTable } from 'react-native-paper';
import { useIsFocused } from "@react-navigation/native";
import GlobalStyle from '../../../../../constants/GlobalStyle';
import { useGetAllCompaniesQuery } from '@store/services/api/company-api';
import { useGetAllLocationsQuery } from '@store/services/api/location-api';
import { jobApi, useCompleteJobMutation, useDeleteJobMutation, useInvoiceJobMutation, useReopenJobMutation } from '@store/services/api/job-api';
import { PagedTableDto } from '@store/services/api/base-api';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { Picker } from '@react-native-picker/picker';
import { SimpleLineIcons, Ionicons, AntDesign, Entypo } from '@expo/vector-icons';
import { useSelector } from "react-redux";
import { RootState } from "@store/index";
import { hasFeatureAccess } from '../System/Permissions/permissionsUtils';
import { JobManagementPermissions, PermissionCategories } from '../System/Permissions/permissionsEnums';
import { DataTableHeader } from '@web/components/table/DatatableHeader';
import PaginationComponent from '@web/components/Pagination';
import { FIRST_PAGE, MAX_ITEMS_BEFORE_ALL } from '../../../../../constants/Constants';
import Spinner from 'react-native-loading-spinner-overlay';

export default function JobsListScreen({ navigation }: { navigation: any }) {
  const user = useSelector((state: RootState) => state.user.user);
  const permissions = useSelector((state: RootState) => state.user.user.permissions);
  const isSystemUser = user.role === "System";
  const modules = useSelector((state: RootState) => state.user.modules);
  const [sortedColumn, setSortedColumn] = React.useState<string>("createdOn");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(false);
  const isUserPermissionsEnabled = modules.isUserPermissionsEnabled;
  const [loading, setLoading] = useState<any>(false);

  const isFocused = useIsFocused();
  const [completeJob, completeJobResult] = useCompleteJobMutation();
  const [invoiceJob, invoiceJobResult] = useInvoiceJobMutation();
  const [deleteJob, deleteJobResult] = useDeleteJobMutation();
  const [reopenJob, reopenJobResult] = useReopenJobMutation();
  const { data: companies } = useGetAllCompaniesQuery(null);
  const { data: locations } = useGetAllLocationsQuery(null);

  // Filters 
  const [companyId, setCompanyId] = useState("");
  const [locationId, setLocationId] = useState("");
  const [jobNumber, setJobNumber] = useState("");
  const [statusId, setStatusId] = useState("0");
  const [yourReference, setYourReference] = useState("");

  const [page, setPage] = React.useState<number>(0);
  const [trigger, jobsResult] = jobApi.useLazyGetJobsQuery();
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);

  React.useEffect(() => {
    if (isFocused) {
      loadTable();
    }
  }, [page, isFocused, sortedColumn, sortedColumnAscending, numberOfItemsPerPage]);

  const loadTable = () => {
    const pageNumber = numberOfItemsPerPage > MAX_ITEMS_BEFORE_ALL ? FIRST_PAGE : page;
    trigger(new PagedTableDto(pageNumber, {
      companyId: companyId,
      locationId: locationId,
      jobNumber: jobNumber,
      statusId: statusId,
      yourReference: yourReference,
      pageSize: numberOfItemsPerPage
    }, sortedColumn, sortedColumnAscending)).then(() => {
      setLoading(false);
    });
  }

  const resetFilters = () => {
    setCompanyId("");
    setLocationId("");
    setJobNumber("");
    setStatusId("0");
    setYourReference("");
  }

  function applyFilters() {
    setPage((curr) => {
      if (curr == 0) loadTable();
      return 0;
    })
  }

  const markJobCompleted = async (job) => {
    if (job.isInvoiced) {
      alert("Job is already invoiced");
      return;
    }

    if (job.isCompleted && confirm("Are you sure you want to invoice this job?")) {
      var result = await invoiceJob(job.id) as any;
      if (result.error) {
        alert("Error invoicing job. Please try again.")
        return;
      }

      loadTable();
      return;
    }

    if (confirm("Are you sure you want to complete this job?")) {
      var result = await completeJob(job.id) as any;
      if (result.error) {
        alert("Error completing job. Please try again.")
        return;
      }

      loadTable();
    }
  }

  const markJobDeleted = async (id) => {
    if (confirm("Are you sure you want to delete this job?")) {
      var result = await deleteJob(id) as any;
      if (result.error) {
        alert("Error deleting job. Please try again.")
        return;
      }

      loadTable();
    }
  }

  const markJobCurrent = async (id) => {
    if (confirm("Are you sure you want to reopen this job?")) {
      var result = await reopenJob(id) as any;
      if (result.error) {
        alert("Error reopening job. Please try again.")
        return;
      }

      loadTable();
    }
  }


  if (jobsResult.isLoading) {
    return <LoadingIndicator />
  }

  return (
    <ScrollView style={styles.container}>
      <Spinner
        visible={loading}
        textContent={'Loading...'}
        textStyle={GlobalStyle.spinnerTextStyle}
      />
      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Company</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              selectedValue={companyId}
              onValueChange={(itemValue, itemIndex) => { setCompanyId(itemValue); }}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="All Companies" value="" />

              {companies != null && companies.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Location</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              selectedValue={locationId}
              onValueChange={(itemValue, itemIndex) => setLocationId(itemValue)}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="All Locations" value="" />

              {locations != null && companyId != "" && locations.filter(l => l.companyId == companyId).map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Job #</Text>
            <TextInput
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              placeholder="Job #"
              value={jobNumber}
              onChangeText={setJobNumber}
            />
          </View>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Status</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              selectedValue={statusId}
              onValueChange={(itemValue, itemIndex) => setStatusId(itemValue)}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="All" value="-1" />
              <Picker.Item label="Current" value="0" />
              <Picker.Item label="Completed" value="1" />
              <Picker.Item label="Invoiced" value="2" />
              <Picker.Item label="Deleted" value="3" />
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Your Reference</Text>
            <TextInput
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              placeholder="Your Reference"
              value={yourReference}
              onChangeText={setYourReference}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={applyFilters}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

      {(!isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Administration, JobManagementPermissions.AddJob))) && (<TouchableOpacity style={GlobalStyle.add__button} onPress={() => navigation.navigate("manage")}>
        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 5, paddingRight: 10 }}>
          <Ionicons name="add" size={24} color="white" /><Text style={GlobalStyle.form__submit__button__text}>Add New Job</Text>
        </View>
      </TouchableOpacity>)}

      <DataTable style={GlobalStyle.table}>
        <DataTableHeader
          sortedColumn={sortedColumn}
          sortedColumnAscending={sortedColumnAscending}
          onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
          headers={[
            { text: 'Company', sortedName: "locationCompanyName", visible: true },
            { text: 'Location', sortedName: "locationName", visible: true },
            { text: 'Job Number', sortedName: "jobNumber", visible: true },
            { text: 'Added', sortedName: "createdOn", visible: true },
            { text: 'Job Type', sortedName: "jobType", visible: true },
            { text: 'Progress', sortedName: "inspectionsCount", visible: true },
            { text: 'Options', visible: true }
          ]}
        />

        {jobsResult?.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>{element.companyName}</DataTable.Cell>
              <DataTable.Cell>{element.locationName}</DataTable.Cell>
              <DataTable.Cell>{element.jobNumber}</DataTable.Cell>
              <DataTable.Cell>{element.createdOnFormatted}</DataTable.Cell>
              <DataTable.Cell>{element.jobTypeFormatted}</DataTable.Cell>
              <DataTable.Cell>{element.inspectionsCount} / {element.jobAssetsCount}</DataTable.Cell>
              <DataTable.Cell>
                <View style={{ flexDirection: 'row' }}>
                  <TouchableOpacity onPress={() => navigation.navigate("manage", { jobId: element.id })} style={{ marginRight: 20 }}>
                    <Entypo name="pencil" size={24} color="black" />
                  </TouchableOpacity>

                  <TouchableOpacity onPress={() => markJobCompleted(element)} style={{ marginRight: 20 }}>
                    <Ionicons name="ios-checkmark-done-circle" size={24} color={element.isInvoiced && 'blue' || element.isCompleted && 'green'} />
                  </TouchableOpacity>

                  {!element.isDeleted &&
                    <TouchableOpacity onPress={() => markJobDeleted(element.id)} style={{ marginRight: 20 }}>
                      <AntDesign name="delete" size={24} color="red" />
                    </TouchableOpacity>
                  }

                  {(element.isCompleted && !element.isInvoiced && !element.isDeleted) && <TouchableOpacity onPress={() => markJobCurrent(element.id)}>
                    <Entypo name="cycle" size={24} color="black" />
                  </TouchableOpacity>}
                </View>
              </DataTable.Cell>

              {/* <DataTable.Cell>
                <TouchableOpacity onPress={() => navigation.navigate("inspect", { jobId: element.id })}>
                  <Text>INSPECT</Text>
                </TouchableOpacity>
              </DataTable.Cell> */}
            </DataTable.Row>
          )
        })}
      </DataTable>

      <PaginationComponent
        numberOfItemsPerPage={numberOfItemsPerPage}
        setNumberOfItemsPerPage={setNumberOfItemsPerPage}
        page={page}
        setPage={setPage}
        fetchResult={jobsResult}
        loading={loading}
        setLoading={setLoading}
      />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
