import * as React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Alert, Platform } from 'react-native';
import { LoadingIndicator } from '../../../../components/navigation/controls/LoadingIndicator';

import { DataTable } from 'react-native-paper';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { useIsFocused } from "@react-navigation/native";
import { Entypo, Ionicons } from '@expo/vector-icons';
import { PagedTableDto } from '@store/services/api/base-api';
import { useDeleteUserMutation, userApi } from '@store/services/api/user-api';
import { useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { hasFeatureAccess } from '../../System/Permissions/permissionsUtils';
import { JobManagementPermissions, PermissionCategories } from '../../System/Permissions/permissionsEnums';
import { DataTableHeader } from '@web/components/table/DatatableHeader';

export default function TabletUsersList({ navigation }: { navigation: any }) {
  const user = useSelector((state: RootState) => state.user.user);
  const permissions = useSelector((state: RootState) => state.user.user.permissions);
  const isSystemUser = user.role === "System";
  const modules = useSelector((state: RootState) => state.user.modules);
  const isUserPermissionsEnabled = modules.isUserPermissionsEnabled;
  const [deleteUser, deleteUserResult] = useDeleteUserMutation();
  const isFocused = useIsFocused()
  const [sortedColumn, setSortedColumn] = React.useState("name")
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState(true)

  const [trigger, engineersResult] = userApi.useLazyGetOrderedEngineersQuery();

  React.useEffect(() => {
    if (isFocused) {
      loadTable();
    }
  }, [isFocused, sortedColumn, sortedColumnAscending]);

  const loadTable = () => {
    var dto = new PagedTableDto(0, {}, sortedColumn, sortedColumnAscending);
    trigger(dto);
  }

  const remove = async (entityId) => {
    if (Platform.OS == 'web') {
      if (confirm("Are you sure you want to delete this user?")) {
        var result = await deleteUser(entityId) as any;
        if (result.error) {
          Alert.alert("Error", "Something went wrong. Please try again");
          return;
        }

        loadTable();
      }
    }
  }

  if (engineersResult.isLoading || deleteUserResult.isLoading) {
    return <LoadingIndicator />
  }

  return (
    <View style={styles.container}>
      {(!isUserPermissionsEnabled || (hasFeatureAccess(user, permissions, isSystemUser, PermissionCategories.Administration, JobManagementPermissions.AddJob))) && <View>
        <TouchableOpacity style={GlobalStyle.add__button} onPress={() => navigation.navigate("manage")}>
          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 5, paddingRight: 10 }}>
            <Ionicons name="add" size={24} color="white" /><Text style={GlobalStyle.form__submit__button__text}>Add New Engineer</Text>
          </View>
        </TouchableOpacity>


        <DataTable style={GlobalStyle.table}>
          <DataTableHeader
            sortedColumn={sortedColumn}
            sortedColumnAscending={sortedColumnAscending}
            onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
            headers={[
              { text: 'Name', sortedName: "name", visible: true },
              { text: 'Email', visible: true },
              { text: 'Option', visible: true }
            ]}
          />

          {engineersResult?.data?.map((element: any) => {
            return (
              <DataTable.Row key={element.id}>
                <DataTable.Cell>{element.name}</DataTable.Cell>
                <DataTable.Cell>{element.email}</DataTable.Cell>
                <DataTable.Cell>
                  <View style={{ flexDirection: 'row' }}>
                    <TouchableOpacity onPress={() => navigation.navigate("manage", { userId: element.id })}>
                      <Entypo name="pencil" size={24} color="black" />
                    </TouchableOpacity>

                    <TouchableOpacity onPress={() => remove(element.id)} style={{ marginLeft: 20 }}>
                      <Ionicons name="ios-remove-circle" size={24} color="red" />
                    </TouchableOpacity>
                  </View>

                </DataTable.Cell>
              </DataTable.Row>
            )
          })}
        </DataTable>
      </View>}

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
