import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export interface BreakdownState {
  // Inspection Data
  faults: Array<any> | null,
  parts: Array<any> | null,
  images: Array<any> | [],
  hours: any | null,
  hoursHalf: any | null,
  hoursDouble: any | null,
  isEdit: boolean | null,
  id: any | null
}

const initialState: BreakdownState = {
  faults: null,
  parts: null,
  images: [],

  hours: null,
  hoursHalf: null,
  hoursDouble: null,
  isEdit: null,
  id: null
}

export const breakdownSlice = createSlice({
  name: 'breakdown',
  initialState,
  reducers: {
    setBreakdownData: (state, action: PayloadAction<any>) => {
      if (action.payload.faults != null) state.faults = action.payload.faults;
      if (action.payload.parts != null) state.parts = action.payload.parts;
      if (action.payload.images != null) state.images = action.payload.images;
      if (action.payload.hours != null) state.hours = action.payload.hours;
      if (action.payload.hoursHalf != null) state.hoursHalf = action.payload.hoursHalf;
      if (action.payload.hoursDouble != null) state.hoursDouble = action.payload.hoursDouble;
      if (action.payload.isEdit != null) state.isEdit = action.payload.isEdit;
      if (action.payload.id != null) state.id = action.payload.id;
    },

    clearBreakdownData: (state, action: PayloadAction) => {
      state.faults = null;
      state.parts = null;
      state.images = [];

      state.hours = null;
      state.hoursHalf = null;
      state.hoursDouble = null;
      state.isEdit = null;
      state.id = null;
    },
  }
})

export const { setBreakdownData, clearBreakdownData } = breakdownSlice.actions;

export default breakdownSlice.reducer;