import * as React from 'react';
import { Platform, StyleSheet, Text, View, Button, TouchableOpacity } from 'react-native';
import { Camera, CameraType } from 'expo-camera';

export function CameraModal({ onPictureTaken, onCancel }) {
  const [camera, setCamera] = React.useState(null);
  const [type, setType] = React.useState(CameraType.back);
  const [permission, requestPermission] = Camera.useCameraPermissions();

  React.useEffect(() => {
    if (permission == null)
      return;

    if (!permission.granted) {
      requestPermission();
    }
  }, [permission]);
  

  if (permission != null && !permission.granted) {
    // Camera permissions are not granted yet
    return (
      <View style={styles.container}>
        <Text style={{ textAlign: 'center' }}>We need your permission to show the camera</Text>
      </View>
    );
  }

  const takePhoto = async () => {
    if (!camera) return

    const photo = await camera.takePictureAsync({ base64: true, quality: 0.2, imageType: 'jpeg' });
    
    onPictureTaken('data:image/jpeg;base64,' + photo.base64);
  }

  const cancel = () => {
    onCancel();
  }

  return (
    <View style={styles.container}>
      <Camera ref={ref => setCamera(ref)} style={styles.camera} type={type} >
        <View style={styles.buttonContainer}>
        <TouchableOpacity style={styles.button} onPress={cancel}>
            <Text style={styles.text}>Cancel</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.button} onPress={takePhoto}>
            <Text style={styles.text}>Take Photo</Text>
          </TouchableOpacity>
        </View>
      </Camera>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  camera: {
    flex: 1,
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: 'transparent',
    margin: 64,
  },
  button: {
    flex: 1,
    alignSelf: 'flex-end',
    alignItems: 'center',
  },
  text: {
    fontSize: 24,
    fontWeight: 'bold',
    color: 'white',
  },
})