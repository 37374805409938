import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

enum ModuleTypes {
  DOC = "DOC",
  BREAKDOWNS = "Breakdowns",
  ROTE = "ROTE",
  SERVICE = "Service",
  TIMESHEETS = "Timesheets",
  ASSET_REGISTER = "Asset Register",
  POWRA = "POWRA",
  PROOF_LOAD = "ProofLoads",
  ADD_JOB_MOBILE = "Add Job Mobile",
  JOB_APPROVAL = "Job Approval",
  USER_PERMISSIONS = "User Permissions",
  MISSING_ASSETS = "Missing Assets",
  INSTALLATION = "Installation",
  NOTIFICATIONS = "Notifications",
  PSSR = "PSSR"
}

export enum UserRole {
  ADMIN = "Admin",
  SYSTEM = "System",
  USER = "User",
}

export class User {
  id: string;
  name: string;
  companyId: string;
  customerId: string;
  customerName: string;
  modules: any[];
  permissions: {
    id: string;
    permissionId: string;
    roleId: string;
    name: string;
    formattedName: string;
    category: string;
    hasAccess: boolean;
    role: string;

  }[];
  role: string;
  roleId: string;
  brandingImageBase64: string;

  static IsModuleEnabled = (module: string, modules: any[]): boolean => {
    if (modules == null || modules.length == 0) return false;

    const userModule = modules.find(m => m.moduleName == module);
    return userModule == null ? false : userModule.enabled;
  }
}

export class Modules {
  isRoteEnabled: boolean;
  isBreakdownEnabled: boolean;
  isDOCEnabled: boolean;
  isServiceEnabled: boolean;
  isTimesheetEnabled: boolean;
  isAssetRegisterEnabled: boolean;
  isPOWRAEnabled: boolean;
  isProofLoadEnabled: boolean;
  isAddJobMobileEnabled: boolean;
  isJobApprovalEnabled: boolean;
  isUserPermissionsEnabled: boolean;
  isMissingAssetsEnabled: boolean;
  isInstallationEnabled: boolean;
  isNotificationsEnabled: boolean;
  isPssrEnabled: boolean;
}

export class Permissions {
}

export interface UserState {
  user: User,
  token: string | null,

  modules: Modules | null,
  permissions: Permissions | null
}

const initialState: UserState = {
  user: null,
  token: null,
  modules: new Modules(),
  permissions: new Permissions()
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      state.token = action.payload?.token;
    },
    setCustomer: (state, action: PayloadAction<any>) => {
      var user = state.user;
      user.customerId = action.payload.customerId;
      user.customerName = action.payload.customerName;

      state.user = user;
    },
    setUserModules: (state, action: PayloadAction<any>) => {
      const serverModules = action.payload;

      var user = state.user;
      user.modules = serverModules;
      state.user = user;

      var modules = state.modules ?? new Modules();
      modules.isRoteEnabled = User.IsModuleEnabled(ModuleTypes.ROTE, user.modules);
      modules.isBreakdownEnabled = User.IsModuleEnabled(ModuleTypes.BREAKDOWNS, user.modules);
      modules.isDOCEnabled = User.IsModuleEnabled(ModuleTypes.DOC, user.modules);
      modules.isServiceEnabled = User.IsModuleEnabled(ModuleTypes.SERVICE, user.modules);
      modules.isTimesheetEnabled = User.IsModuleEnabled(ModuleTypes.TIMESHEETS, user.modules);
      modules.isAssetRegisterEnabled = User.IsModuleEnabled(ModuleTypes.ASSET_REGISTER, user.modules);
      modules.isPOWRAEnabled = User.IsModuleEnabled(ModuleTypes.POWRA, user.modules);
      modules.isProofLoadEnabled = User.IsModuleEnabled(ModuleTypes.PROOF_LOAD, user.modules);
      modules.isAddJobMobileEnabled = User.IsModuleEnabled(ModuleTypes.ADD_JOB_MOBILE, user.modules);
      modules.isJobApprovalEnabled = User.IsModuleEnabled(ModuleTypes.JOB_APPROVAL, user.modules);
      modules.isUserPermissionsEnabled = User.IsModuleEnabled(ModuleTypes.USER_PERMISSIONS, user.modules);
      modules.isMissingAssetsEnabled = User.IsModuleEnabled(ModuleTypes.MISSING_ASSETS, user.modules);
      modules.isInstallationEnabled = User.IsModuleEnabled(ModuleTypes.INSTALLATION, user.modules);
      modules.isNotificationsEnabled = User.IsModuleEnabled(ModuleTypes.NOTIFICATIONS, user.modules);
      modules.isPssrEnabled = User.IsModuleEnabled(ModuleTypes.PSSR, user.modules);

      state.modules = modules;
    },
  }
})

export const { setUser, setCustomer, setUserModules } = userSlice.actions;

export default userSlice.reducer;
