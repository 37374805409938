import { baseApi, PagedTableDto } from './base-api'

export const companyApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCompanies: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `companies/getcompanies?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    getCompany: build.query<any, any>({
      query: (companyId) => ({
        url: `companies/getcompany/${companyId}`,
      }),
    }),
    getAllCompanies: build.query<any, any>({
      query: (companyId) => ({
        url: `companies/getallcompanies`,
      }),
    }),
    createCompany: build.mutation<any, any>({
      query: (body) => ({
        url: 'companies/addcompany',
        method: 'post',
        body: body
      }),
    }),
    updateCompany: build.mutation<any, any>({
      query: (body) => ({
        url: `companies/updatecompany/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    syncCompanies: build.mutation<any, any>({
      query: (dto) => ({
        url: `companies/getcompaniesforsync?lastmodifiedon=${dto.lastModifiedOn}&downloaded=${dto.downloaded}&amountToFetch=${dto.amountToFetch}`,
      })
    }),
    updateCompanyEmailPreferences: build.mutation<any, any>({
      query: (body) => ({
        url: `companies/UpdateCompanyEmailPreferences/${body.emailPreferenceId}`,
        method: 'put',
        body: body
      }),
    }),
    getAllCompaniesWithEmailPreferences: build.query<any, any>({
      query: () => ({
        url: `companies/getAllCompaniesWithEmailPreferences`,
      }),
    }),
    getAllCompaniesForFilter: build.query<any, any>({
      query: (companyId) => ({
        url: `companies/getallcompaniesforfilter`,
      }),
    }),
    getCompanyFiles: build.query<CompanyFile[], string>({
      query: (companyId) => ({
        url: `companies/getcompanyfiles?companyId=${companyId}`,
      }),
    }),
    addCompanyFile: build.mutation<any, any>({
      query: (body) => ({
        url: 'companies/postcompanyfile',
        method: 'post',
        body: body
      }),
    }),
    removeCompanyFile: build.mutation<any, any>({
      query: (body) => ({
        url: `companies/removecompanyfile?companyId=${body.companyId}&fileName=${body.fileName}`,
        method: 'delete',
        body: body
      }),
    }),
  }),
  overrideExisting: true,
})

export const
  {
    useGetCompaniesQuery,
    useGetCompanyQuery,
    useGetAllCompaniesQuery,
    useCreateCompanyMutation,
    useUpdateCompanyMutation,
    useGetAllCompaniesWithEmailPreferencesQuery,
    useUpdateCompanyEmailPreferencesMutation,
    useGetAllCompaniesForFilterQuery,
    useLazyGetCompanyFilesQuery,
    useAddCompanyFileMutation,
    useRemoveCompanyFileMutation
  } = companyApi

export interface CompanyFile {
  fileName: string,
  base64: string,
  mimeType: string
}
