import { useState, useEffect } from 'react';
import { TitleBar } from '@app/components/TitleBar';
import { StyleSheet, Text, TextInput, View, TouchableOpacity, Alert, Image } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { ServiceDatabase } from '@store/services/database/service-database';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { FlashList } from '@shopify/flash-list';
import RadioButtonGroup, { RadioButtonItem } from "expo-radio-button";
import config, { colours } from '../../../../../config';
import { DateTimePickerAndroid } from '@react-native-community/datetimepicker';
import moment from 'moment';
import { MaterialIcons, MaterialCommunityIcons, Ionicons } from '@expo/vector-icons';
import { useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { CategoryDatabase } from '@store/services/database/category-database';
import { CameraModal } from '@app/components/modals/CameraModal';
import { Keyboard } from 'react-native';


export default function ServiceInspect({ navigation, route }: { navigation: any, route: any }) {
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);
  const categoryId = route.params?.categoryId;

  const job = useSelector((state: RootState) => state.job);
  const [ref, setRef] = useState(null);
  const [loading, setLoading] = useState<any>(false);

  const [tabs, setTabs] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<any>(null);
  const [index, setIndex] = useState<any>(0);

  const [questions, setQuestions] = useState<any>([]);

  const [inspectionDate, setInspectionDate] = useState<any>(moment());
  const [nextInspectionDate, setNextInspectionDate] = useState<any>(moment());

  const [safeForUse, setSafeForUse] = useState<any>(null);

  const [showCamera, setShowCamera] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [dbInspection, setDbInspection] = useState(null);

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow', () => { setKeyboardVisible(true); }
    );
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide', () => { setKeyboardVisible(false); }
    );

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
  }, [])

  useEffect(() => {
    const sortAndGroupQuestions = (questions) => {
      var groupedQuestions = questions.sort((a, b) => a.questionCategoryPosition - b.questionCategoryPosition).reduce((r, a) => {
        r[a.title] = r[a.title] || [];
        r[a.title].push(a);
        return r;
      }, Object.create(null));

      return groupedQuestions;
    }

    const initTabs = (groupedQuestions) => {
      var tabs = [];

      for (let group of Object.entries(groupedQuestions)) {
        var key = group[0];
        var questions = (group[1] as any).sort((a, b) => a.questionPosition - b.questionPosition);

        tabs.push({
          title: key,
          questions: questions,
          isSave: false,
        });
      }

      // Add our save tab
      tabs.push({
        title: "Save",
        isSave: true,
        questions: [],
      })

      setTabs(tabs);
    }

    const loadServiceQuestions = async () => {
      const serviceDatabase = new ServiceDatabase();
      var questions = await serviceDatabase.getServiceQuestions(categoryId);
      const inspectionDatabase = new ServiceDatabase();
      var dbInspectionData = await inspectionDatabase.getCompletedInspection(job.jobId, job.equipmentId);
      const [dbInspection] = dbInspectionData;
      if (dbInspectionData.length > 0 && dbInspection.questions.length > 0) {
        setDbInspection(dbInspection);
        questions = questions.map((question) => {
          const historicalAnswer = dbInspection.questions.find((aq) => aq.questionId === question.id);
          return historicalAnswer
            ? { ...question, response: historicalAnswer.response, comments: historicalAnswer.comments, imageBase64: historicalAnswer.imageBase64 }
            : question;
        });
        setInspectionDate(moment(dbInspection.inspectionDate).toDate());
        setNextInspectionDate(moment(dbInspection.nextInspectionDate).toDate());
      }

      // Group our questions by title and order by position
      var groupedQuestions = sortAndGroupQuestions(questions);

      // Initialise our screens
      initTabs(groupedQuestions);
    }
    const updateNextInspectionDate = async () => {
      // Get the category and use that interval
      var categoryDatabase = new CategoryDatabase();
      var category = await categoryDatabase.getById(job.categoryId);
      setNextInspectionDate(moment().add(category.inspectionInterval, 'M'));
    }

    loadServiceQuestions();

    updateNextInspectionDate();

    setLoading(false);
  }, [])

  useEffect(() => {
    if (activeTab == null && tabs != null && tabs.length > 0) {
      updateActiveTab(0);
    }
  }, [tabs])

  const previous = () => {
    if (index == 0)
      return;

    var idx = index - 1;
    setIndex(idx);

    updateActiveTab(idx);
  }

  const next = () => {
    if (!validateQuestions(activeTab)) {
      return;
    }

    if (index == tabs.length - 1) {
      save();
      return;
    };

    var idx = index + 1;
    setIndex(idx);

    updateActiveTab(idx);
  }

  const updateActiveTab = (idx) => {
    var newTab = tabs[idx];
    setActiveTab(newTab);

    // Update our questions
    setQuestions([...newTab.questions]);
  }

  const passRemaining = () => {
    for (let question of activeTab.questions) {
      if (question.response == null) {
        var defaultPassResponse = question.responses.find(r => r.isPassDefault);
        if (defaultPassResponse != null) {
          question.response = defaultPassResponse.id;
        }
      }
    }

    setQuestions([...questions]);
    next();
  }

  const passRemainingPrompt = () => {
    Alert.alert('Pass Remaining', 'Are you sure you want to pass the remaining questions?', [
      { text: 'No', style: 'cancel', },
      { text: 'Yes', onPress: () => passRemaining() }
    ]);
  }

  const updateQuestionResponse = (question, response) => {
    question.response = response;
    setQuestions([...questions]);
  }

  const updateQuestionComments = (question, comments) => {
    question.comments = comments;
    setQuestions([...questions]);
  }

  const onPictureTaken = (base64) => {
    selectedQuestion.imageBase64 = base64;
    setQuestions([...questions]);
    setShowCamera(false);
  }

  const removeImage = (question) => {
    Alert.alert('Image', 'Are you sure you want to remove this image?', [
      { text: 'No', style: 'cancel', },
      { text: 'Yes', onPress: () => { question.imageBase64 = null; setQuestions([...questions]); } }
    ]);

  }

  const takePicture = (question) => {
    setSelectedQuestion(question);
    setShowCamera(true);
  }

  const validateQuestions = (tab) => {
    var questions = tab.questions;
    for (var i = 0; i < questions.length; i++) {
      var question = questions[i];
      if (question.response == null) {
        ref.scrollToIndex({ animated: true, index: i, viewPosition: 0 });
        Alert.alert("Validation", "Please select response for: " + question.question);
        return false;
      }

      var response = question.responses.find(r => r.id == question.response);
      if (response != null && response.commentMandatory == true && (question.comments == "" || question.comments == null)) {
        ref.scrollToIndex({ animated: true, index: i, viewPosition: 0 });
        Alert.alert("Validation", "Enter comments for: " + question.question);
        return false;
      }
    }

    return true;
  }

  const setInspectionDatePicker = () => {
    DateTimePickerAndroid.open({
      mode: "date",
      value: moment(inspectionDate).toDate(),
      onChange: (evt, date) => { setInspectionDate(moment(date)); }
    });
  }

  const setNextInspectionDatePicker = () => {
    DateTimePickerAndroid.open({
      mode: "date",
      value: moment(nextInspectionDate).toDate(),
      onChange: (evt, date) => { setNextInspectionDate(moment(date)); }
    });
  }

  const save = async () => {
    if (safeForUse == null) {
      Alert.alert("Validation", "Select safe for use");
      return;
    }

    setLoading(true);

    var questionList = [];
    for (let tab of tabs) {
      questionList = questionList.concat(tab.questions);
    }

    var serviceDatabase = new ServiceDatabase();

    if (dbInspection) {
      await serviceDatabase.updateInspection(dbInspection.id,
        moment(inspectionDate).format(), moment(nextInspectionDate).format("YYYY-MM-DD"), safeForUse,
        job.equipmentId, job.engineerId, job.jobId, null, null, false, questionList, []
      );
    } else {
      await serviceDatabase.addInspection(
        inspectionDate.format(), nextInspectionDate.format("YYYY-MM-DD"), safeForUse,
        job.equipmentId, job.engineerId, job.jobId, null, null, false, questionList, []
      );
    }

    setLoading(false);

    navigation.navigate("JobAssets");
  }

  const renderItem = ({ item, index }) => (

    <View style={styles.row}>
      <View>
        <Text style={styles.question__title}>{item.question}</Text>
      </View>

      <View>
        <RadioButtonGroup
          containerStyle={{ marginRight: 40, marginTop: 10, marginBottom: 10, flexDirection: 'column' }}
          radioBackground={colours.primary}
          selected={item.response}
          onSelected={(val) => updateQuestionResponse(item, val)}>

          {item.responses.sort((a, b) => a.position - b.position).map(response => {
            return (
              <RadioButtonItem style={{ marginLeft: 20 }} value={response.id} label={response.response} />
            )
          })}
        </RadioButtonGroup>
      </View>

      <View>
        <Text>Comments</Text>
        <TextInput
          style={styles.comments}
          value={item.comments}
          //onEndEditing={(evt) => updateQuestionComments(item, evt.nativeEvent.text)} 
          onChangeText={(text) => updateQuestionComments(item, text)} />
      </View>

      <View style={{ flexDirection: 'row' }}>
        <TouchableOpacity style={styles.picture__button} onPress={() => takePicture(item)}>
          <Text style={styles.picture__button__text}>Take Image</Text>
        </TouchableOpacity>

        {item.imageBase64 != null && item.imageBase64 != "" &&
          <TouchableOpacity onPress={() => removeImage(item)}>
            <Image
              style={[styles.image]}
              resizeMode={"contain"}
              source={{
                uri: item.imageBase64,
              }}
            />
          </TouchableOpacity>
        }
      </View>
    </View>
  );


  if (tabs.length == 0 || activeTab == null || loading) {
    return <LoadingIndicator />
  }

  if (showCamera) {
    return (
      <CameraModal onCancel={() => setShowCamera(false)} onPictureTaken={(image) => onPictureTaken(image)} />
    )
  }

  return (
    <View style={styles.container}>
      <TitleBar navigation={navigation} title={"Service"} showBackButton={true} showSearchButton={false} onSynced={null} promptBack={true} onSearch={null} />

      {!isKeyboardVisible &&
        <View style={styles.tabs}>
          {tabs.map((screen: any, index: number) => {
            return (
              <TouchableOpacity key={index} style={[styles.tab, activeTab.title == screen.title && styles.tab__active]}>
                <Text style={[styles.tab__text, activeTab.title == screen.title && styles.tab__text__active]}>{screen.title}</Text>
              </TouchableOpacity>
            )
          })}
        </View>
      }

      {!activeTab.isSave && <Text style={styles.questions__header}>QUESTIONS</Text>}

      {!activeTab.isSave &&
        <FlashList
          data={questions}
          renderItem={renderItem}
          estimatedItemSize={100}
          ref={(ref) => { setRef(ref); }}
          automaticallyAdjustKeyboardInsets={true}
        />
      }

      {activeTab.isSave &&
        <View style={{ flex: 1 }}>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1, backgroundColor: '#FFF', borderWidth: 1, borderColor: '#CECECE', padding: 10, marginTop: 10, marginRight: 5 }}>
              <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>Inspection Date</Text>

              <TouchableOpacity
                style={{ flexDirection: 'row', alignItems: 'center', padding: 10, borderWidth: 1, borderColor: '#cecece' }}
                onPress={() => setInspectionDatePicker()}>
                <Text style={{ flex: 1 }}>{moment(inspectionDate).format("DD/MM/YYYY")}</Text>
                <MaterialIcons name="date-range" size={24} color="black" />
              </TouchableOpacity>
            </View>

            <View style={{ flex: 1, backgroundColor: '#FFF', borderWidth: 1, borderColor: '#CECECE', padding: 10, marginTop: 10, marginLeft: 5 }}>
              <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>Next Inspection Date</Text>

              <TouchableOpacity
                style={{ flexDirection: 'row', alignItems: 'center', padding: 10, borderWidth: 1, borderColor: '#cecece' }}
                onPress={() => setNextInspectionDatePicker()}>
                <Text style={{ flex: 1 }}>{moment(nextInspectionDate).format("DD/MM/YYYY")}</Text>
                <MaterialIcons name="date-range" size={24} color="black" />
              </TouchableOpacity>
            </View>
          </View>

          <View style={styles.safe__block}>
            <TouchableOpacity style={[styles.safe__button, styles.not_safe, safeForUse == false && styles.unsafe_checked]} onPress={() => setSafeForUse(false)}>
              <MaterialCommunityIcons name="cancel" size={44} color={safeForUse == false ? 'white' : 'black'} />
              <Text style={[styles.safe__button__text, safeForUse == false && styles.safe__button__text__checked]}>REMOVED FROM SERVICE</Text>
            </TouchableOpacity>

            <TouchableOpacity style={[styles.safe__button, styles.safe, safeForUse == true && styles.safe_checked]} onPress={() => setSafeForUse(true)}>
              <Ionicons name="checkmark-circle-outline" size={44} color={safeForUse == true ? 'white' : 'black'} />
              <Text style={[styles.safe__button__text, safeForUse == true && styles.safe__button__text__checked]}>SERVICABLE</Text>
            </TouchableOpacity>
          </View>
        </View>
      }

      {!isKeyboardVisible &&
        <View style={styles.action__buttons}>
          <TouchableOpacity style={styles.action__button} onPress={() => previous()}>
            <Text style={styles.action__button__text}>PREVIOUS</Text>
          </TouchableOpacity>

          <TouchableOpacity style={styles.action__button} onPress={() => passRemainingPrompt()}>
            <Text style={styles.action__button__text}>PASS REMAINING</Text>
          </TouchableOpacity>

          <TouchableOpacity style={styles.action__button} onPress={() => next()}>
            <Text style={styles.action__button__text}>
              {index == tabs.length - 1 ? 'SAVE' : 'NEXT'}
            </Text>
          </TouchableOpacity>
        </View>
      }
    </View>
  )
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FAFAFA',
    padding: 20,
    paddingTop: 40,
  },

  row: {
    marginBottom: 5,
    backgroundColor: '#FFF',
    padding: 10,
    borderWidth: 1,
    borderColor: '#CECECE',
  },

  question__title: {
    fontFamily: 'OpenSans-Bold',
    flex: 1,
  },

  questions__header: {
    fontFamily: 'OpenSans-Bold',
    padding: 10,
    fontSize: 22,
    backgroundColor: '#e8e8e8',
    marginBottom: 20,
    marginTop: 20,
    borderRadius: 5,
  },

  comments: {
    borderBottomWidth: 1,
    borderBottomColor: '#CECECE',
    fontSize: 20,
    padding: 5,
  },

  action__buttons: {
    flexDirection: 'row',
    marginTop: 30,
  },

  action__button: {
    backgroundColor: colours.primary,
    flex: 1,
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 5
  },

  action__button__text: {
    color: '#FFF',
    padding: 10,
    textAlign: 'center',
    fontSize: 22
  },

  tabs: {
    flexDirection: 'row',
    marginTop: 10,
  },

  tab: {
    flex: 1,
    backgroundColor: '#FFF',
    borderBottomWidth: 1,
    borderBottomColor: colours.primary,
  },

  tab__active: {
    backgroundColor: colours.primary
  },

  tab__text: {
    color: colours.primary,
    fontFamily: 'OpenSans-Bold',
    fontSize: 16,
    padding: 10,
    textAlign: 'center'
  },

  tab__text__active: {
    color: '#FFF'
  },

  safe__block: {
    flexDirection: 'row',
    marginTop: 20,
  },

  safe__button: {
    flex: 1,
    height: 140,
    backgroundColor: '#edebeb',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderRadius: 5,
  },

  safe__button__text: {
    fontFamily: 'OpenSans-Bold',
    marginLeft: 10,
    fontSize: 28
  },

  not_safe: {
    marginRight: 5,
  },

  safe: {
    marginLeft: 5,
  },

  unsafe_checked: {
    backgroundColor: 'red',
  },

  safe_checked: {
    backgroundColor: 'green',
  },

  safe__button__text__checked: {
    color: '#FFF'
  },

  picture__button: {
    backgroundColor: "#FFF",
    marginTop: 20,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: config.colours.primary,
    width: 200,
    alignSelf: 'flex-start'
  },

  picture__button__text: {
    padding: 5,
    color: config.colours.primary,
    textAlign: 'center',
    fontFamily: 'OpenSans-ExtraBold'
  },

  image: {
    height: 150,
    width: 150,
    marginRight: 20,
    marginLeft: 20,
  },
})