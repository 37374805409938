import * as React from 'react';
import { useState } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity } from 'react-native';
import { DataTable } from 'react-native-paper';
import GlobalStyle from '../../../../../../constants/GlobalStyle';
import { useIsFocused } from "@react-navigation/native";
import { Entypo, Ionicons, SimpleLineIcons } from '@expo/vector-icons';
import { PagedTableDto } from '@store/services/api/base-api';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { colourApi, useDeleteColourMutation } from '@store/services/api/colour-api';
import PaginationComponent from '@web/components/Pagination';
import { MAX_ITEMS_BEFORE_ALL, FIRST_PAGE } from '../../../../../../constants/Constants';
import Spinner from 'react-native-loading-spinner-overlay';

export default function ColoursListScreen({navigation}: {navigation: any}) {
  const isFocused = useIsFocused()

  const [page, setPage] = React.useState<number>(0);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);
  const [loading, setLoading] = useState<any>(false);

  const [colourName, setColourName] = useState("");

  const [trigger, coloursResult] = colourApi.useLazyGetColoursQuery(); 
  const [deleteColour, deleteColourResult] = useDeleteColourMutation();
  
  const loadTable = () => {
    const pageNumber = numberOfItemsPerPage > MAX_ITEMS_BEFORE_ALL ? FIRST_PAGE : page;
    var dto = new PagedTableDto(pageNumber, { 
      name: colourName,
      pageSize: numberOfItemsPerPage
    });
    trigger(dto).then(() => {
      setLoading(false);
    });
  }

  const resetFilters = () => {
    setColourName("");
  }

  function applyFilters() {
    setPage((curr) => {
      if (curr == 0) loadTable();
      return 0;
    })
  }

  const markColourDeleted = async (id) => {
    if (confirm("Are you sure you want to delete this colour?")) {
      var result = await deleteColour(id) as any;
      if (result.error) {
        alert("Error deleting colour. Please try again.")
        return;
      }

      loadTable();
    }
  }

  React.useEffect(() => {
    if (isFocused) {
      loadTable();
    }
  }, [page, isFocused, numberOfItemsPerPage]);

  if (coloursResult.isLoading) {
    return <LoadingIndicator/>
  }


  return (
    <View style={styles.container}>
      <Spinner
        visible={loading}
        textContent={'Loading...'}
        textStyle={GlobalStyle.spinnerTextStyle}
      />
      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Colour</Text>
            <TextInput 
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              onChangeText={setColourName}
              value={colourName}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={applyFilters}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

      <TouchableOpacity style={GlobalStyle.add__button} onPress={() => navigation.navigate("manage")}>
        <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 5, paddingRight: 10}}>
          <Ionicons name="add" size={24} color="white" /><Text style={GlobalStyle.form__submit__button__text}>Add New Colour</Text>
        </View>
      </TouchableOpacity>

      <DataTable style={GlobalStyle.table}>
        <DataTable.Header>
          <DataTable.Title>Name</DataTable.Title>
          <DataTable.Title>Options</DataTable.Title>
        </DataTable.Header>

        { coloursResult?.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>{element.name}</DataTable.Cell>
              <DataTable.Cell>
                <TouchableOpacity onPress={() => navigation.navigate("manage", { colourId: element.id })} style={{ marginRight: 10 }}>
                  <Entypo name="pencil" size={24} color="black" />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => markColourDeleted(element.id)}>
                <Ionicons name="ios-remove-circle" size={24} color="red" />
                    </TouchableOpacity>
              </DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>

      <PaginationComponent
        numberOfItemsPerPage={numberOfItemsPerPage}
        setNumberOfItemsPerPage={setNumberOfItemsPerPage}
        page={page}
        setPage={setPage}
        fetchResult={coloursResult}
        loading={loading}
        setLoading={setLoading}
      />

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
