import * as SQLite from 'expo-sqlite';
import { Database } from './database';
import uuid from 'react-native-uuid';
import { addColumnIfNotExist } from '../../../helpers/database-helpers';

export class BreakdownDatabase extends Database {
  TABLE_NAME = "BreakdownInspections";
  TABLE_NAME_INSPECTION_PARTS = "BreakdownInspectionParts";
  TABLE_NAME_INSPECTION_FAULTS = "BreakdownInspectionFaults";
  TABLE_NAME_COMMON_FAULTS = "BreakdownCommonFaults";
  TABLE_NAME_PARTS = "BreakdownParts";
  TABLE_NAME_IMAGES = "BreakdownInspectionImages";

  initialise(): Promise<void> {
    return new Promise((resolve, reject) => {

      var sql = `CREATE TABLE IF NOT EXISTS BreakdownInspections ( 
        id TEXT PRIMARY KEY,
        inspectionDate TEXT,
        nextInspectionDate TEXT,
      
        equipmentId TEXT,
        engineerId TEXT,
        jobId TEXT,
        purposeId TEXT,
        colourId TEXT,
        missing INT,

        hours INT,
        hoursHalf INT,
        hoursDouble INT,
        toBeSynced INT
      )`;

      var sqlInspectionParts = `CREATE TABLE IF NOT EXISTS BreakdownInspectionParts ( 
        id TEXT PRIMARY KEY,
        breakdownPartId TEXT,
        breakdownInspectionId TEXT
      )`;

      var sqlInspectionFaults = `CREATE TABLE IF NOT EXISTS BreakdownInspectionFaults ( 
        id TEXT PRIMARY KEY,
        breakdownFaultId TEXT,
        actionTaken TEXT,
        remarks TEXT,
        hours TEXT,
        breakdownInspectionId TEXT
      )`;

      var sqlFaults = `CREATE TABLE IF NOT EXISTS BreakdownCommonFaults ( 
        id TEXT PRIMARY KEY,
        fault TEXT
      )`;

      var sqlParts = `CREATE TABLE IF NOT EXISTS BreakdownParts ( 
        id TEXT PRIMARY KEY,
        itemCode TEXT,
        description TEXT,
        description2 TEXT,
        unitCost INT,
        partNo TEXT
      )`;

      var sqlImages = `CREATE TABLE IF NOT EXISTS BreakdownInspectionImages (
        id TEXT PRIMARY KEY,
        inspectionId TEXT,
        base64 TEXT
      )`

      Database.getDb().transaction((tx) => {
        tx.executeSql(sql);
        tx.executeSql(sqlInspectionParts);
        tx.executeSql(sqlInspectionFaults);
        tx.executeSql(sqlFaults);
        tx.executeSql(sqlParts);
        tx.executeSql(sqlImages);

        addColumnIfNotExist(tx, 'BreakdownInspections', 'toBeSynced', 'INT');
      }, (error) => {
        console.log(error)
        reject();
      }, () => {
        resolve();
      })
    });
  }

  addInspection(inspectionDate: any, nextInspectionDate: any, equipmentId: any, engineerId: any, jobId: any,
    purposeId: any, colourId: any, missing: any, parts: any, faults, images, hours: any, hoursHalf: any, hoursDouble: any): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        Database.getDb().transaction((tx) => {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME} (
              id, inspectionDate, nextInspectionDate, equipmentId, engineerId, jobId, purposeId, colourId, missing, hours, hoursHalf, hoursDouble, toBeSynced
            )
            VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, 1)
          `
          var inspectionId = uuid.v4();

          if (hours == null) { hours = 0; }
          if (hoursHalf == null) { hoursHalf = 0; }
          if (hoursDouble == null) { hoursDouble = 0; }

          tx.executeSql(sql, [
            inspectionId, inspectionDate, nextInspectionDate, equipmentId, engineerId, jobId, purposeId, colourId, missing,
            hours, hoursHalf, hoursDouble
          ]);

          if (faults != null) {
            for (let fault of faults) {
              var sql = `
                INSERT OR REPLACE INTO ${this.TABLE_NAME_INSPECTION_FAULTS} (
                  id, breakdownFaultId, actionTaken, remarks, hours, breakdownInspectionId
                )
                VALUES (?, ?, ?, ?, ?, ?)
              `;

              tx.executeSql(sql, [uuid.v4(), fault.commonFault.id, fault.action, fault.details, fault.hours, inspectionId]);
            }
          }

          if (parts != null) {
            for (let part of parts) {
              var sql = `
                INSERT OR REPLACE INTO ${this.TABLE_NAME_INSPECTION_PARTS} (
                  id, breakdownPartId, breakdownInspectionId
                )
                VALUES (?, ?, ?)
              `;

              tx.executeSql(sql, [uuid.v4(), part.id, inspectionId]);
            }
          }

          if (images.length > 0) {
            sql = `INSERT INTO ${this.TABLE_NAME_IMAGES} (id, inspectionId, base64) VALUES (?, ?, ?)`;
            for (let image of images) {
              tx.executeSql(sql, [uuid.v4(), inspectionId, image]);
            }
          }

          // Update this asset to be completed 
          sql = "UPDATE JobAssets SET completed = 1, toBeSynced = 1 WHERE jobId = ? and equipmentId = ?";
          tx.executeSql(sql, [jobId, equipmentId]);

        }, (error) => {
          console.log("ERROR");
          console.log(error);
          reject();
        }, () => {
          console.log("UPDATED JOB Assets - JobId: " + jobId + " EquipId: " + equipmentId);
          resolve();
        })
      } catch (e) {
        console.log("ERROR INSIDE BREAKDOWN")
        reject();
        console.log(e)
      }
    })
  }

  insertOrUpdateList(entities: any): Promise<void> {
    throw new Error('Method not implemented.');
  }

  insertOrUpdateCommonFaultsList(entities: any): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME_COMMON_FAULTS} (id, fault)
            VALUES (?, ?)
          `

          tx.executeSql(sql, [
            entity.id, entity.fault
          ]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  insertOrUpdatePartsList(entities: any): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME_PARTS} (id, itemCode, description, description2, unitCost, partNo)
            VALUES (?, ?, ?, ?, ?, ?)
          `

          tx.executeSql(sql, [
            entity.id, entity.itemCode, entity.description, entity.description2, entity.unitCost, entity.partNo
          ]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  getBreakdownParts(): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM BreakdownParts ORDER BY description,description2,itemCode
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    })
  }

  getBreakdownPartsBySearch(search: string): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM BreakdownParts WHERE Description LIKE ? OR PartNo LIKE ? ORDER BY itemCode
        `;

        tx.executeSql(sql, [`%${search}%`, `%${search}%`], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  getBreakdownCommonFaults(): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM BreakdownCommonFaults ORDER BY fault
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    })
  }

  getForSync(): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM ${this.TABLE_NAME} WHERE toBeSynced = 1 OR toBeSynced IS NULL
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;

          var sqlFaults = `SELECT * FROM ${this.TABLE_NAME_INSPECTION_FAULTS} WHERE breakdownInspectionId = ?`;
          var sqlParts = `SELECT * FROM ${this.TABLE_NAME_INSPECTION_PARTS} WHERE breakdownInspectionId = ?`;
          for (let inspection of data) {
            tx.executeSql(sqlFaults, [inspection.id], (_, { rows: { _array } }) => {
              inspection.faults = _array;
            });
          }

          for (let inspection of data) {
            tx.executeSql(sqlParts, [inspection.id], (_, { rows: { _array } }) => {
              inspection.parts = _array;
            });

            sql = `SELECT base64 FROM ${this.TABLE_NAME_IMAGES} WHERE inspectionId = ?`;
            tx.executeSql(sql, [inspection.id], (_, { rows: { _array } }) => {
              inspection.images = [];
              for (let image of _array) {
                inspection.images.push(image.base64)
              }
            });
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  deleteInspection(inspectionId): Promise<boolean> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `DELETE FROM ${this.TABLE_NAME_INSPECTION_PARTS} WHERE breakdownInspectionId = ?`;
        tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => { });

        sql = `DELETE FROM ${this.TABLE_NAME_INSPECTION_FAULTS} WHERE breakdownInspectionId = ?`;
        tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => { });

        sql = `DELETE FROM ${this.TABLE_NAME_IMAGES} WHERE inspectionId = ?`;
        tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => { });

        sql = `DELETE FROM ${this.TABLE_NAME} WHERE id = ?`;
        tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => { });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(true);
      })
    });
  }

  getCompletedBreakdownInspection(jobId, equipmentId): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM ${this.TABLE_NAME}
          WHERE jobId = ? AND equipmentId = ?
        `;

        tx.executeSql(sql, [jobId, equipmentId], (_, { rows: { _array } }) => {
          data = _array;

          const sqlFaults = `
          SELECT 
            f.id as faultId, f.breakdownFaultId, f.actionTaken, f.remarks, f.hours, f.breakdownInspectionId, 
            cf.fault 
          FROM ${this.TABLE_NAME_INSPECTION_FAULTS} f
          LEFT JOIN ${this.TABLE_NAME_COMMON_FAULTS} cf ON f.breakdownFaultId = cf.id
          WHERE f.breakdownInspectionId = ?`;

          var sqlParts = `
          SELECT p.id, p.itemCode, p.description, p.description2, p.unitCost, p.partNo, ip.id AS inspectionPartId  
          FROM ${this.TABLE_NAME_INSPECTION_PARTS} ip
          LEFT JOIN ${this.TABLE_NAME_PARTS} p ON ip.breakdownPartId = p.id WHERE breakdownInspectionId = ?`;

          for (let inspection of data) {
            tx.executeSql(sqlFaults, [inspection.id], (_, { rows: { _array } }) => {
              inspection.faults = _array.map(fault => ({
                action: fault.actionTaken,
                commonFault: {
                  fault: fault.fault,
                  id: fault.breakdownFaultId
                },
                commonFaultId: fault.breakdownFaultId,
                details: fault.remarks,
                hours: fault.hours,
                inspectionFaultId: fault.faultId
              }));
            });
            tx.executeSql(sqlParts, [inspection.id], (_, { rows: { _array } }) => {
              inspection.parts = _array.map(part => ({
                id: part.id,
                itemCode: part.itemCode,
                description: part.description,
                description2: part.description2,
                unitCost: part.unitCost,
                partNo: part.partNo,
                inspectionPartId: part.inspectionPartId
              }));
            });

            sql = `SELECT base64 FROM ${this.TABLE_NAME_IMAGES} WHERE inspectionId = ?`;
            tx.executeSql(sql, [inspection.id], (_, { rows: { _array } }) => {
              inspection.images = [];
              for (let image of _array) {
                inspection.images.push(image.base64)
              }
            });
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  updateInspection(inspectionId, inspectionDate, nextInspectionDate, equipmentId, engineerId, jobId, purposeId, colourId, missing, parts, faults, images, hours, hoursHalf,
    hoursDouble
  ) {
    return new Promise<void>((resolve, reject) => {
      try {
        Database.getDb().transaction((tx) => {
          let sql = `
            UPDATE ${this.TABLE_NAME} 
            SET inspectionDate = ?, nextInspectionDate = ?, equipmentId = ?, engineerId = ?, jobId = ?, 
                purposeId = ?, colourId = ?, missing = ?, hours = ?, hoursHalf = ?, hoursDouble = ?, toBeSynced = 1
            WHERE id = ?
          `;
          tx.executeSql(sql, [
            inspectionDate, nextInspectionDate, equipmentId, engineerId, jobId,
            purposeId, colourId, missing, hours, hoursHalf, hoursDouble, inspectionId
          ]);

          sql = `DELETE FROM ${this.TABLE_NAME_INSPECTION_FAULTS} WHERE breakdownInspectionId = ?`;
          tx.executeSql(sql, [inspectionId]);

          if (faults != null) {
            for (let fault of faults) {
              sql = `
                INSERT INTO ${this.TABLE_NAME_INSPECTION_FAULTS} (
                  id, breakdownFaultId, actionTaken, remarks, hours, breakdownInspectionId
                )
                VALUES (?, ?, ?, ?, ?, ?)
              `;
              tx.executeSql(sql, [uuid.v4(), fault.commonFault.id, fault.action, fault.details, fault.hours, inspectionId]);
            }
          }

          sql = `DELETE FROM ${this.TABLE_NAME_INSPECTION_PARTS} WHERE breakdownInspectionId = ?`;
          tx.executeSql(sql, [inspectionId]);

          if (parts != null) {
            for (let part of parts) {
              sql = `
                INSERT INTO ${this.TABLE_NAME_INSPECTION_PARTS} (
                  id, breakdownPartId, breakdownInspectionId
                )
                VALUES (?, ?, ?)
              `;
              tx.executeSql(sql, [uuid.v4(), part.id, inspectionId]);
            }
          }

          sql = `DELETE FROM ${this.TABLE_NAME_IMAGES} WHERE inspectionId = ?`;
          tx.executeSql(sql, [inspectionId]);

          if (images.length > 0) {
            sql = `INSERT INTO ${this.TABLE_NAME_IMAGES} (id, inspectionId, base64) VALUES (?, ?, ?)`;
            for (let image of images) {
              tx.executeSql(sql, [uuid.v4(), inspectionId, image]);
            }
          }

          sql = "UPDATE JobAssets SET completed = 1, toBeSynced = 1 WHERE jobId = ? AND equipmentId = ?";
          tx.executeSql(sql, [jobId, equipmentId]);

        }, (error) => {
          console.log("ERROR");
          console.log(error);
          reject();
        }, () => {
          console.log("UPDATED JOB Assets - JobId: " + jobId + " EquipId: " + equipmentId);
          resolve();
        });
      } catch (e) {
        console.log("ERROR INSIDE BREAKDOWN");
        console.log(e);
        reject();
      }
    });
  }

  getByJobId(jobId: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        tx.executeSql(
          `SELECT * FROM BreakdownInspections WHERE jobId = ? AND toBeSynced = 0`,
          [jobId],
          (_, { rows: { _array } }) => resolve(_array),
          (_, error) => reject(error)
        );
      });
    });
  }

  updateInspectionToBeSynced(inspectionId: string, toBeSynced: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        tx.executeSql(
          `UPDATE BreakdownInspections SET toBeSynced = ? WHERE id = ?`,
          [toBeSynced ? 1 : 0, inspectionId],
          () => resolve(),
          (_, error) => reject(error)
        );
      });
    });
  }


}