import { baseApi, PagedTableDto } from './base-api'

export const installationApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getInstallationInspections: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `installation/GetInstallationInspections?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    syncInstallationQuestions: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `installation/getinstallationquestions?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    syncInstallationQuestionsResponses: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `installation/getinstallationquestionresponses?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    syncInstallationQuestionCategories: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `installation/getinstallationquestioncategories?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    sendInstallationInspection: build.mutation<any, any>({
      query: (body) => ({
        url: 'installation/addinstallation',
        method: 'post',
        body: body
      }),
    }),
    // ---- INSTALLATION CATEGORIES
    getInstallationCategories: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `installation/GetInstallationCategoriesList?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    createInstallationCategory: build.mutation<any, any>({
      query: (body) => ({
        url: 'installation/addinstallationcategory',
        method: 'post',
        body: body
      }),
    }),
    updateInstallationCategory: build.mutation<any, any>({
      query: (body) => ({
        url: 'installation/updateinstallationcategory',
        method: 'put',
        body: body
      }),
    }),
    deleteInstallationCategory: build.mutation<any, any>({
      query: (id) => ({
        url: `installation/deleteinstallationcategory/${id}`,
        method: 'delete',
      }),
    }),
    transferInstallationCategory: build.mutation<any, any>({
      query: (body) => ({
        url: 'installation/transferInstallationCategory',
        method: 'post',
        body: body
      }),
    }),
    // ---- INSTALLATION QUESTIONS
    getInstallationQuestions: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `installation/getinstallationquestionslist?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    createInstallationQuestion: build.mutation<any, any>({
      query: (body) => ({
        url: 'installation/addinstallationquestion',
        method: 'post',
        body: body
      }),
    }),
    updateInstallationQuestion: build.mutation<any, any>({
      query: (body) => ({
        url: 'installation/updateinstallationquestion',
        method: 'put',
        body: body
      }),
    }),
    deleteInstallationQuestion: build.mutation<any, any>({
      query: (id) => ({
        url: `installation/deleteinstallationquestion/${id}`,
        method: 'delete',
      }),
    }),
    // ---- INSTALLATION QUESTION RESPONSES
    getInstallationQuestionResponses: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `installation/getinstallationquestionresponseslist?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    createInstallationQuestionResponse: build.mutation<any, any>({
      query: (body) => ({
        url: 'installation/addinstallationquestionresponse',
        method: 'post',
        body: body
      }),
    }),
    updateInstallationQuestionResponse: build.mutation<any, any>({
      query: (body) => ({
        url: 'installation/updateinstallationquestionresponse',
        method: 'put',
        body: body
      }),
    }),
    deleteInstallationQuestionResponse: build.mutation<any, any>({
      query: (id) => ({
        url: `installation/deleteinstallationquestionresponse/${id}`,
        method: 'delete',
      }),
    }),
    archiveInstallationInspection: build.mutation<any, { id: number }>({
      query: (id) => ({
        url: `installation/archiveinstallationinspection/${id}`,
        method: 'put'
      }),
    }),
  }),
  overrideExisting: true,
})

export const {
  useSyncInstallationQuestionsMutation,
  useSyncInstallationQuestionCategoriesMutation,
  useSendInstallationInspectionMutation,
  useGetInstallationInspectionsQuery,
  useGetInstallationCategoriesQuery,
  useLazyGetInstallationQuestionResponsesQuery,
  useCreateInstallationCategoryMutation,
  useUpdateInstallationCategoryMutation,
  useDeleteInstallationCategoryMutation,
  useTransferInstallationCategoryMutation,
  useGetInstallationQuestionsQuery,
  useCreateInstallationQuestionMutation,
  useUpdateInstallationQuestionMutation,
  useDeleteInstallationQuestionMutation,
  useGetInstallationQuestionResponsesQuery,
  useCreateInstallationQuestionResponseMutation,
  useUpdateInstallationQuestionResponseMutation,
  useDeleteInstallationQuestionResponseMutation
} = installationApi