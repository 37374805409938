import React, { useEffect, useState } from 'react';
import { TitleBar } from '@app/components/TitleBar';
import { StyleSheet, Text, TextInput, View, TouchableOpacity, Alert, Platform } from 'react-native';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { FlashList } from '@shopify/flash-list';
import { RadioButton } from 'react-native-paper';
import { RootState } from '@store/index';
import { CategoryDatabase } from '@store/services/database/category-database';
import { useDispatch, useSelector } from 'react-redux';
import { setInspectionData, updateAnsweredQuestion } from '@store/slices/rote-inspection-slice';
import { colours } from '../../../../config';
import { InspectionDatabase } from '@store/services/database/inspection-database';

export default function RoteStep2({ navigation, route }: { navigation: any, route: any }) {
  const [loading, setLoading] = useState<any>(false);
  const [questions, setQuestions] = useState<any>([]);

  const dispatch = useDispatch();
  const job = useSelector((state: RootState) => state.job);


  useEffect(() => {
    const loadCategoryQuestions = async () => {
      setLoading(true);
      const categoryDatabase = new CategoryDatabase();
      const questions = await categoryDatabase.getCategoryQuestions(job.categoryId);
      let updatedQuestions = null;
      const inspectionDatabase = new InspectionDatabase();
      var dbInspectionData = await inspectionDatabase.getCompletedInspection(job.jobId, job.equipmentId);
      const [dbInspection] = dbInspectionData;

      if (dbInspectionData.length > 0 && dbInspection.questions.length > 0) {
        updatedQuestions = questions.map((question) => {
          const historicalAnswer = dbInspection.questions.find((aq) => aq.questionId === question.id);
          return historicalAnswer
            ? { ...question, response: historicalAnswer.response, comments: historicalAnswer.comments }
            : question;
        });
      }
      if (updatedQuestions) {
        updatedQuestions.forEach((question) => {
          dispatch(updateAnsweredQuestion({ questionId: question.id, response: question.response, comments: question.comments }));
        });
      }
      setQuestions(updatedQuestions || questions);
      setLoading(false);
    };

    loadCategoryQuestions();
  }, []);
  const passRemaining = () => {
    const updatedQuestions = questions.map((question) => {
      if (question.response == null) {
        const defaultPassResponse = question.responses.find((r) => r.isPassDefault);
        if (defaultPassResponse != null) {
          question.response = defaultPassResponse.id;
          question.comments = "";
        }
      }
      return question;
    });

    setQuestions(updatedQuestions);

    updatedQuestions.forEach((question) => {
      dispatch(updateAnsweredQuestion({ questionId: question.id, response: question.response, comments: question.comments }));
    });
  };

  const passRemainingPrompt = () => {
    Alert.alert('Pass Remaining', 'Are you sure you want to pass the remaining questions?', [
      { text: 'No', style: 'cancel' },
      { text: 'Yes', onPress: () => passRemaining() },
    ]);
  };

  const updateQuestionResponse = (question, response) => {
    question.response = response;
    setQuestions([...questions]);

    dispatch(updateAnsweredQuestion({ questionId: question.id, response, comments: question.comments }));
  };

  const updateQuestionComments = (question, comments) => {
    setQuestions(prevQuestions =>
      prevQuestions.map(q =>
        q.id === question.id ? { ...q, comments } : q
      )
    );

    dispatch(updateAnsweredQuestion({ questionId: question.id, response: question.response, comments }));
  };

  const validateQuestions = () => {
    for (let i = 0; i < questions.length; i++) {
      const question = questions[i];
      if (question.response == null && question.responses.length > 0) {
        Alert.alert('Error', 'Please select a response for: ' + question.question);
        return false;
      }

      const response = question.responses.find((r) => r.id == question.response);
      if (response != null && response.commentMandatory == true && (question.comments == '' || question.comments == null)) {
        Alert.alert('Error', 'Enter comments for: ' + question.question);
        return false;
      }
    }

    return true;
  };


  const renderItem = ({ item, index, }) => (
    <View style={styles.row}>
      <View style={{ flexDirection: 'column', alignItems: 'flex-start', flex: 1 }}>
        <Text style={styles.question__title}>{item.question}</Text>

        {item.responses && item.responses.length > 0 && (
          <View style={{ marginTop: 10, flexDirection: 'column', alignItems: 'flex-start' }}>
            {item.responses.sort((a, b) => a.position - b.position).map(response => (
              <View key={response.id} style={{ flexDirection: 'row', alignItems: 'center', marginRight: 20 }}>
                <RadioButton.Android
                  value={response.id}
                  status={item.response === response.id ? 'checked' : 'unchecked'}
                  onPress={() => updateQuestionResponse(item, response.id)}
                  color={colours.primary}
                  uncheckedColor={'#d3d3d3'}
                />

                <Text>{response.response}</Text>
              </View>
            ))}
          </View>
        )}
      </View>

      <View>
        <Text>Comments</Text>
        <TextInput
          multiline={true}
          style={styles.comments}
          value={item.comments}
          onChangeText={(text) => updateQuestionComments(item, text)}
        />
      </View>
    </View>
  );

  if (questions.length === 0 || loading) {
    return <LoadingIndicator />;
  }

  function next(): void {
    if (!validateQuestions()) {
      return;
    }

    navigation.navigate('Step3');
  }

  return (
    <View style={styles.container}>
      {Platform.OS != 'web' ? <TitleBar navigation={navigation} title={"Step 2 of 4"} showSearchButton={false} showBackButton={true} onSynced={null} onSearch={null} /> : null}

      <View style={styles.questions__header}>
        <Text style={styles.questions__header__text}>QUESTIONS</Text>
      </View>

      <FlashList
        data={questions}
        renderItem={renderItem}
        keyExtractor={(item) => item.id.toString()}
        estimatedItemSize={100}
      />

      <View style={styles.action__buttons}>
        <TouchableOpacity style={styles.action__button} onPress={() => passRemainingPrompt()}>
          <Text style={styles.action__button__text}>PASS REMAINING</Text>
        </TouchableOpacity>

        <TouchableOpacity style={styles.action__button} onPress={() => next()}>
          <Text style={styles.action__button__text}>Next</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    ...Platform.select({
      android: { flex: 1, padding: 40, },
      ios: { flex: 1, padding: 20, paddingTop: 60 },
      web: { padding: 20, paddingBottom: 0, paddingTop: 0 },
    }),
    backgroundColor: '#FAFAFA',
  },

  row: {
    marginBottom: 5,
    backgroundColor: '#FFF',
    padding: 10,
    borderWidth: 1,
    borderColor: '#CECECE',
  },

  question__title: {
    fontFamily: 'OpenSans-Bold',
    flex: 1,
  },

  questions__header: {
    fontFamily: 'OpenSans-Bold',
    padding: 10,
    fontSize: 22,
    backgroundColor: '#e8e8e8',
    marginBottom: 20,
    marginTop: 20,
    borderRadius: 5,
  },

  comments: {
    borderBottomWidth: 1,
    borderBottomColor: '#CECECE',
    fontSize: 20,
    padding: 5,
  },

  action__buttons: {
    flexDirection: 'row',
    marginTop: 30,
  },

  action__button: {
    backgroundColor: colours.primary,
    flex: 1,
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 5
  },

  action__button__text: {
    color: '#FFF',
    padding: 10,
    textAlign: 'center',
    fontSize: 22
  },

  tabs: {
    flexDirection: 'row',
    marginTop: 10,
  },

  tab: {
    flex: 1,
    backgroundColor: '#FFF',
    borderBottomWidth: 1,
    borderBottomColor: colours.primary,
  },

  tab__active: {
    backgroundColor: colours.primary
  },

  tab__text: {
    color: colours.primary,
    fontFamily: 'OpenSans-Bold',
    fontSize: 16,
    padding: 10,
    textAlign: 'center'
  },

  tab__text__active: {
    color: '#FFF'
  },

  safe__block: {
    flexDirection: 'row',
    marginTop: 20,
  },

  safe__button: {
    flex: 1,
    height: 140,
    backgroundColor: '#edebeb',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderRadius: 5,
  },

  safe__button__text: {
    fontFamily: 'OpenSans-Bold',
    marginLeft: 10,
    fontSize: 28
  },

  not_safe: {
    marginRight: 5,
  },

  safe: {
    marginLeft: 5,
  },

  unsafe_checked: {
    backgroundColor: 'red',
  },

  safe_checked: {
    backgroundColor: 'green',
  },

  safe__button__text__checked: {
    color: '#FFF'
  },
  questions__header__text: {
    fontFamily: 'OpenSans-Bold',
    fontSize: 22,
  },
});