import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export interface PssrState {
    // Inspection Data
    id: string | null,
    examinationType: string | null,
    testsMade: string | null,
    protectiveDevicesState: string | null,
    protectiveDevicesStateComment: string | null,
    isSafeOperatingLimitChanged: boolean | null,
    isSafeOperatingLimitChangedDate: string | null,
    repairsRequired: string | null,
    observations: string | null,
    writtenSchemeChanges: boolean | null,
    safeToUse: boolean | null,
    inServiceInspectionDate: string | null,
    inServiceNextInspectionDate: string | null,
    outOfServiceInspectionDate: string | null,
    outOfServiceNextInspectionDate: string | null,
    pressureInspectionPartId: string | null,
    conditionOfParts: string | null,
    conditionOfPartsDefect: string | null,

    images: any | null,
    completedExaminations: string[]
}

const initialState: PssrState = {
    id: null,
    examinationType: null,
    testsMade: null,
    protectiveDevicesState: null,
    protectiveDevicesStateComment: null,
    isSafeOperatingLimitChanged: null,
    isSafeOperatingLimitChangedDate: null,
    repairsRequired: null,
    observations: null,
    writtenSchemeChanges: null,
    safeToUse: null,
    inServiceInspectionDate: null,
    inServiceNextInspectionDate: null,
    outOfServiceInspectionDate: null,
    outOfServiceNextInspectionDate: null,
    images: [],
    completedExaminations: [],
    pressureInspectionPartId: null,
    conditionOfParts: null,
    conditionOfPartsDefect: null,
}

export const pssrSlice = createSlice({
    name: 'pssr',
    initialState,
    reducers: {
        setPssrData: (state, action: PayloadAction<any>) => {
            if (action.payload.id != null) state.id = action.payload.id;
            if (action.payload.examinationType != null) state.examinationType = action.payload.examinationType;
            if (action.payload.testsMade != null) state.testsMade = action.payload.testsMade;
            if (action.payload.protectiveDevicesState != null) state.protectiveDevicesState = action.payload.protectiveDevicesState;
            if (action.payload.protectiveDevicesStateComment != null) state.protectiveDevicesStateComment = action.payload.protectiveDevicesStateComment;

            if (action.payload.isSafeOperatingLimitChanged != null) state.isSafeOperatingLimitChanged = action.payload.isSafeOperatingLimitChanged;
            if (action.payload.isSafeOperatingLimitChangedDate != null) state.isSafeOperatingLimitChangedDate = action.payload.isSafeOperatingLimitChangedDate;
            if (action.payload.repairsRequired != null) state.repairsRequired = action.payload.repairsRequired;
            if (action.payload.observations != null) state.observations = action.payload.observations;
            if (action.payload.writtenSchemeChanges != null) state.writtenSchemeChanges = action.payload.writtenSchemeChanges;
            if (action.payload.safeToUse != null) state.safeToUse = action.payload.safeToUse;
            if (action.payload.inServiceInspectionDate != null) state.inServiceInspectionDate = action.payload.inServiceInspectionDate;
            if (action.payload.inServiceNextInspectionDate != null) state.inServiceNextInspectionDate = action.payload.inServiceNextInspectionDate;
            if (action.payload.outOfServiceInspectionDate != null) state.outOfServiceInspectionDate = action.payload.outOfServiceInspectionDate;
            if (action.payload.outOfServiceNextInspectionDate != null) state.outOfServiceNextInspectionDate = action.payload.outOfServiceNextInspectionDate;
            if (action.payload.pressureInspectionPartId != null) state.pressureInspectionPartId = action.payload.pressureInspectionPartId;
            if (action.payload.conditionOfParts != null) state.conditionOfParts = action.payload.conditionOfParts;
            if (action.payload.conditionOfPartsDefect != null) state.conditionOfPartsDefect = action.payload.conditionOfPartsDefect;
        },

        addCompletedExamination: (state, action: PayloadAction<string>) => {
            if (!state.completedExaminations.includes(action.payload)) {
                state.completedExaminations.push(action.payload);
            }
        },

        clearCompletedExaminations: (state, action: PayloadAction<string>) => {
            state.completedExaminations = [];
        },

        clearPssrData: (state, action: PayloadAction) => {
            state.id = null;
            state.examinationType = null;
            state.testsMade = null;
            state.protectiveDevicesState = null;
            state.protectiveDevicesStateComment = null;

            state.isSafeOperatingLimitChanged = null;
            state.isSafeOperatingLimitChangedDate = null;
            state.repairsRequired = null;
            state.observations = null;
            state.writtenSchemeChanges = null;
            state.safeToUse = null;
            state.inServiceInspectionDate = null;
            state.inServiceNextInspectionDate = null;
            state.outOfServiceInspectionDate = null;
            state.outOfServiceNextInspectionDate = null;
            state.pressureInspectionPartId = null;
            state.conditionOfParts = null;
            state.conditionOfPartsDefect = null;
            state.images = [];

        },
    }
})

export const { setPssrData, clearPssrData, addCompletedExamination, clearCompletedExaminations } = pssrSlice.actions;

export default pssrSlice.reducer;