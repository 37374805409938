import * as React from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert, ActivityIndicator, Dimensions } from 'react-native';
import { EvilIcons, Ionicons } from '@expo/vector-icons';
import config from '../../../config';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { SyncService } from '@store/services/sync-service';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ModulesDatabase } from '@store/services/database/modules-database';
import { setUserModules } from '@store/slices/user-slice';
import Checkbox from 'expo-checkbox';

const screenWidth = Dimensions.get("screen").width;
const isPortrait8Inch = screenWidth <= 641;

export function TitleBar({ navigation, title, showBackButton, showSearchButton, onSynced, onSearch, promptBack = false, backButtonDelegate = null, showExactSn = false }) {

  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const updater = useSelector((state: RootState) => state.updater);
  const [syncing, setSyncing] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [exactSN, setExactSN] = React.useState(false);

  React.useEffect(() => {
    const syncIfUpdated = async () => {
      var appUpdated = await AsyncStorage.getItem("appUpdated");
      if (appUpdated == "true") {
        await sync();
        await AsyncStorage.setItem("appUpdated", "false");
      }
    }

    syncIfUpdated();
  }, []);

  const goBack = () => {
    if (promptBack) {
      Alert.alert('Back', 'Are you sure you want to go back? This may lose any progress', [
        { text: 'No', style: 'cancel', },
        { text: 'Yes', onPress: () => navigation.goBack() }
      ]);
      return;
    }

    if (backButtonDelegate != null) {
      backButtonDelegate();
    } else {
      navigation.goBack();
    }
  }

  const logout = () => {
    Alert.alert("Logout", "Are you sure you want to logout?", [
      {
        text: 'No',
        style: 'cancel'
      },
      { text: 'Yes', onPress: () => { AsyncStorage.removeItem("user"); navigation.navigate("login"); } }
    ])
  }

  const search = () => {
    onSearch(searchQuery, exactSN);
  }

  const loadModules = async () => {
    const modulesDatabase = new ModulesDatabase();
    const modules = await modulesDatabase.getModules();
    dispatch(setUserModules(modules));
  }

  const sync = async () => {
    if (syncing)
      return;

    setSyncing(true);
    var syncService = new SyncService();
    await syncService.syncAll(dispatch);
    setSyncing(false);

    await loadModules();

    if (onSynced) onSynced();
  }


  return (
    <View style={styles.title__container}>
      <View style={styles.title__header}>
        {showBackButton &&
          <TouchableOpacity style={styles.title__header__icon} onPress={() => goBack()}>
            <Ionicons name="arrow-back-circle-outline" size={isPortrait8Inch ? 20 : 32} color="black" />
          </TouchableOpacity>
        }
        <Text style={styles.title__header__text}>{title}</Text>
        <TouchableOpacity style={styles.sync__button} onPress={() => sync()}>
          {!syncing &&
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Ionicons name="sync" size={isPortrait8Inch ? 18 : 24} color="#FFF" />
              <Text style={styles.sync__button__text}>Sync</Text>
            </View>
          }
          {syncing &&
            <View style={{ flexDirection: 'row' }}>
              <ActivityIndicator color="#FFF" />
              {updater.percentage > 0 && updater.percentage <= 100 && <Text style={{ color: '#FFF', fontWeight: 'bold', marginLeft: 5 }}>{updater.percentage.toFixed(0) + '%'}</Text>}
            </View>
          }
        </TouchableOpacity>
        <TouchableOpacity style={styles.user__block} onPress={() => logout()}>
          <Text style={styles.user__text}>{user.name}</Text>
          <Ionicons name="exit-outline" size={isPortrait8Inch ? 18 : 24} color="black" style={{ marginLeft: 10 }} />
        </TouchableOpacity>
      </View>

      {showSearchButton &&
        <View style={styles.search__bar}>
          <TextInput onChangeText={setSearchQuery} style={styles.search__input} placeholder="Search" />
          <TouchableOpacity style={styles.search__button} onPress={() => search()}>
            <EvilIcons name="search" size={isPortrait8Inch ? 20 : 24} color="#FFF" />
          </TouchableOpacity>
          {showExactSn &&
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Checkbox value={exactSN} onValueChange={setExactSN} />
              <Text style={{ marginLeft: 2 }}>Exact SN</Text>
            </View>}
        </View>

      }
    </View>

  )
}

const styles = StyleSheet.create({
  title__container: {
    flexDirection: 'column',
  },

  title__header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: isPortrait8Inch ? 3 : 5
  },

  title__header__icon: {
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: isPortrait8Inch ? 5 : 20,
  },

  title__header__text: {
    marginBottom: isPortrait8Inch ? 2 : 5,
    fontFamily: 'OpenSans-Bold',
    fontSize: isPortrait8Inch ? 16 : 32,
    flex: 1,
  },

  sync__button: {
    backgroundColor: config.colours.primary,
    width: isPortrait8Inch ? 'auto' : 100,
    minWidth: 50,
    height: isPortrait8Inch ? 'auto' : 40,
    borderRadius: 5,
    flexDirection: 'row',
    padding: 5,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: isPortrait8Inch ? 10 : 20,
  },

  sync__button__text: {
    fontFamily: 'OpenSans-Bold',
    color: '#FFF',
    marginLeft: 10,
    display: isPortrait8Inch ? 'none' : 'flex',
  },


  user__block: {
    marginLeft: isPortrait8Inch ? 5 : 10,
    backgroundColor: '#FFF',
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 5,
    fontFamily: 'OpenSans-Bold',
    padding: isPortrait8Inch ? 5 : 15,
  },

  user__text: {

  },

  search__bar: {
    backgroundColor: '#FFF',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: isPortrait8Inch ? 0 : 5,
    marginTop: isPortrait8Inch ? 0 : 5,
  },

  search__input: {
    padding: 10,
    height: '80%',
    flex: 1,
    fontFamily: 'OpenSans-Regular',
  },

  search__button: {
    padding: 0,
    backgroundColor: config.colours.primary,
    height: isPortrait8Inch ? 29 : 35,
    width: isPortrait8Inch ? 29 : 35,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: isPortrait8Inch ? 2 : 10,
    paddingBottom: isPortrait8Inch ? 0 : 5,
  },
})