import * as React from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity } from 'react-native';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { EvilIcons } from '@expo/vector-icons';
import { Controller, useForm } from 'react-hook-form';

import { breakdownApi, useCreateBreakdownFaultMutation, useGetAllBreakdownFaultsQuery, useUpdateBreakdownFaultMutation } from '@store/services/api/breakdown-api';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';

export default function BreakdownFaultsManageScreen({ navigation, route }: { navigation: any, route: any }) {
  const [createBreakdownFault, createBreakdownFaultResult] = useCreateBreakdownFaultMutation();
  const [updateBreakdownFault, updateBreakdownFaultResult] = useUpdateBreakdownFaultMutation();
  const { data: faults } = useGetAllBreakdownFaultsQuery(null);

  const [trigger, breakdownFaultResult] = breakdownApi.useLazyGetBreakdownFaultQuery();

  const { control, handleSubmit, getValues, setValue, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      fault: ''
    }
  });

  React.useEffect(() => {
    if (route.params?.breakdownFaultId != null) {
      trigger(route.params?.breakdownFaultId);
    }
  }, [])

  React.useEffect(() => {
    if (breakdownFaultResult.isSuccess) {
      var data = breakdownFaultResult.data;
      setValue("id", data.id);
      setValue("fault", data.fault);
    }
  }, [breakdownFaultResult]);

  const validateFault = async (fault) => {
    if (!fault) {
      return "Fault is required.";
    }

    const userFaults = faults.map(c => c.fault);;
    const uniqueFaults = new Set(userFaults);
    const isFaultUnique = !uniqueFaults.has(fault);
    return isFaultUnique ? undefined : "Fault must be unique.";
  };

  const onSubmit = async (data) => {
    var result = null;

    if (data.id != null) {
      result = await updateBreakdownFault(data) as any;
    } else {
      result = await createBreakdownFault(data) as any;
    }

    if (result.error) {
      return;
    }

    navigation.goBack();
  };

  if (createBreakdownFaultResult.isLoading || updateBreakdownFaultResult.isLoading) {
    return <LoadingIndicator />
  }

  return (
    <View style={styles.container}>
      <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => navigation.goBack()}>
          <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>{getValues("id") == null ? "CREATE BREAKDOWN FAULT" : "UPDATE BREAKDOWN FAULT"}</Text>
      </View>

      <View style={GlobalStyle.form}>
        {/* ##### ROW ##### */}
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{
                validate: validateFault
              }}
              name="fault"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Fault (*)</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.fault && (
              <Text style={GlobalStyle.form__input__validation}>
                {errors.fault.message || "Validation error."}
              </Text>
            )}
          </View>

        </View>

        <TouchableOpacity style={GlobalStyle.form__submit__button} onPress={handleSubmit(onSubmit)}>
          <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
