import { baseApi, PagedTableDto } from './base-api'

export const breakdownApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getBreakdownInspections: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `breakdowns/getbreakdowninspections?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    getBreakdownPartsList: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `breakdowns/getpartslist?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    syncBreakdownCommonFaults: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `breakdowns/getcommonfaults?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    syncParts: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `breakdowns/getparts?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    createBreakdownPart: build.mutation<any, any>({
      query: (body) => ({
        url: 'breakdowns/addpart',
        method: 'post',
        body: body
      }),
    }),
    updateBreakdownPart: build.mutation<any, any>({
      query: (body) => ({
        url: `breakdowns/updatepart/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    getBreakdownPart: build.query<any, any>({
      query: (partId) => ({
        url: `breakdowns/getpart/${partId}`,
      }),
    }),
    deleteBreakdownPart: build.mutation<any, any>({
      query: (partId) => ({
        url: `breakdowns/deletebreakdownpart/${partId}`,
        method: 'DELETE',
      }),
    }),
    sendBreakdownInspection: build.mutation<any, any>({
      query: (body) => ({
        url: 'breakdowns/addbreakdown',
        method: 'post',
        body: body
      }),
    }),
    archiveBreakdownInspection: build.mutation<any, { id: number }>({
      query: (id) => ({
        url: `breakdowns/archivebreakdowninspection/${id}`,
        method: 'put'
      }),
    }),
    getBreakdownFaultsList: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `breakdowns/getfaultslist?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    deleteBreakdownFault: build.mutation<any, any>({
      query: (faultId) => ({
        url: `breakdowns/deletebreakdownfault/${faultId}`,
        method: 'DELETE',
      }),
    }),
    getBreakdownFault: build.query<any, any>({
      query: (faultId) => ({
        url: `breakdowns/getfault/${faultId}`,
      }),
    }),
    createBreakdownFault: build.mutation<any, any>({
      query: (body) => ({
        url: 'breakdowns/addFault',
        method: 'post',
        body: body
      }),
    }),
    updateBreakdownFault: build.mutation<any, any>({
      query: (body) => ({
        url: `breakdowns/updateFault/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    getAllBreakdownFaults: build.query<any, any>({
      query: () => ({
        url: "breakdowns/getAllBreakdownFaults",
      })
    }),
  }),
  overrideExisting: true,
})

export const {
  useSyncBreakdownCommonFaultsMutation,
  useSyncPartsMutation,
  useGetBreakdownPartQuery,
  useGetBreakdownPartsListQuery,
  useCreateBreakdownPartMutation,
  useUpdateBreakdownPartMutation,
  useDeleteBreakdownPartMutation,
  useSendBreakdownInspectionMutation,
  useGetBreakdownInspectionsQuery,
  useDeleteBreakdownFaultMutation,
  useGetBreakdownFaultsListQuery,
  useGetBreakdownFaultQuery,
  useCreateBreakdownFaultMutation,
  useUpdateBreakdownFaultMutation,
  useGetAllBreakdownFaultsQuery
} = breakdownApi