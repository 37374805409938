import * as SQLite from 'expo-sqlite';
import { Database } from './database';

export class ColourDatabase extends Database {
  TABLE_NAME = "Colours";

  initialise() : Promise<void> {
    return new Promise((resolve, reject) => {
      var sql = `CREATE TABLE IF NOT EXISTS Colours ( 
        id TEXT PRIMARY KEY,
        name TEXT
      )`;

      Database.getDb().transaction((tx) => {
        tx.executeSql(sql);
      }, (error) => {
        console.log(error)
        reject();
      }, () => {
        resolve();
      })
    });
  }

  insertOrUpdateList(entities: any): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        const insertOrUpdateSql = `
        INSERT OR REPLACE INTO ${this.TABLE_NAME} (id, name)
        VALUES (?, ?)
        `;

        const deleteSql = `DELETE FROM ${this.TABLE_NAME} WHERE id = ?`;

        for (let entity of entities) {
          if (entity.isDeleted) {
            tx.executeSql(deleteSql, [entity.id]);
          } else {
            tx.executeSql(insertOrUpdateSql, [entity.id, entity.name]);
          }
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  getAll() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM Colours ORDER BY name
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    })
  }
}