import { ScrollView, StyleSheet, Text, TextInput, View, TouchableOpacity, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { Controller, useForm } from 'react-hook-form';
import { colours as importedColours } from '../../../../../../config';
import { TitleBar } from '@app/components/TitleBar';
import { setPssrData } from '@store/slices/pssr-slice';
import RadioButtonGroup, { RadioButtonItem } from "expo-radio-button";
import moment from 'moment';
import { DatePicker } from '@app/components/DatePicker';
import { useEffect } from 'react';

export default function PssrStep2({ navigation, route }: { navigation: any, route: any }) {
    const dispatch = useDispatch();
    const inspection = useSelector((state: RootState) => state.pssr);
    const job = useSelector((state: RootState) => state.job);

    const { equipment } = route?.params || {};

    const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
        defaultValues: {
            isSafeOperatingLimitChanged: inspection.isSafeOperatingLimitChanged || 0,
            isSafeOperatingLimitChangedDate: inspection.isSafeOperatingLimitChanged ? inspection.isSafeOperatingLimitChangedDate : moment().add(1, 'months').toDate(),
            repairsRequired: "None",
            observations: "None",
            writtenSchemeChanges: inspection.writtenSchemeChanges || 0,
        }
    });

    useEffect(() => {
        if (inspection) {
            setValue("isSafeOperatingLimitChanged", inspection.isSafeOperatingLimitChanged || 0);
            setValue("isSafeOperatingLimitChangedDate", inspection.isSafeOperatingLimitChangedDate || moment().add(1, 'months').toDate());
            setValue("repairsRequired", inspection.repairsRequired || "None");
            setValue("observations", inspection.observations || "None");
            setValue("writtenSchemeChanges", inspection.writtenSchemeChanges || 0);
        }
    }, [inspection]);

    const isSafeOperatingLimitChanged = watch('isSafeOperatingLimitChanged')
    const onSubmit = async data => {

        dispatch(setPssrData({
            isSafeOperatingLimitChanged: data.isSafeOperatingLimitChanged,
            isSafeOperatingLimitChangedDate: data.isSafeOperatingLimitChangedDate,
            repairsRequired: data.repairsRequired,
            observations: data.observations,
            writtenSchemeChanges: data.writtenSchemeChanges
        }));

        navigation.navigate("PssrStep3");
    };

    return (
        <View style={styles.container}>


            <ScrollView
                automaticallyAdjustKeyboardInsets={true}
                style={styles.form}>
                <View>
                    <View>
                        <TitleBar navigation={navigation} title={'Step 2 of 3'} showSearchButton={false} showBackButton={true} onSynced={null} onSearch={null} />
                        <View style={styles.step__question}>
                            <Text style={styles.step__question__label}>Safe Operating Limit: </Text>
                            <Text style={styles.step__question__label}>{equipment?.swl}</Text>


                        </View>
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name="isSafeOperatingLimitChanged"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View style={styles.step__question}>
                                    <Text style={styles.step__question__label}>Change in Safe Operating Limit: </Text>

                                    <RadioButtonGroup
                                        containerStyle={{ marginTop: 10, flexDirection: 'row' }}
                                        radioBackground={importedColours.primary}
                                        selected={value}
                                        onSelected={onChange}>

                                        <RadioButtonItem value={0} label="No" />
                                        <RadioButtonItem value={1} style={{ marginLeft: 20 }} label="Yes" />
                                    </RadioButtonGroup>

                                    {errors.isSafeOperatingLimitChanged ? <Text style={styles.input__validation}>Please select a response before continuing</Text> : null}
                                </View>
                            )}
                        />
                        {isSafeOperatingLimitChanged == true &&
                            <View>
                                <Controller
                                    control={control}
                                    name="isSafeOperatingLimitChangedDate"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <View style={[styles.step__question]}>
                                            <Text style={styles.step__question__label}>Change In Safe Operating Limit Required By Date:</Text>
                                            <View style={styles.step__question__input}>
                                                <DatePicker value={value} mode="date" onChanged={onChange} maximumDate={moment().toDate()} />
                                            </View>
                                        </View>
                                    )}
                                />
                            </View>
                        }
                        <Controller
                            control={control}
                            name="repairsRequired"
                            rules={{ required: true }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View style={styles.step__question}>
                                    <Text style={styles.step__question__label}>Repairs (If Any) Required to Ensure Safe Working and Period Within Which They Should Be Completed:</Text>
                                    <TextInput
                                        multiline={true}
                                        style={styles.step__question__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}
                                    />

                                    {errors.repairsRequired && <Text style={styles.input__validation}>Enter details</Text>}
                                </View>
                            )}
                        />
                        <Controller
                            control={control}
                            name="observations"
                            rules={{ required: true }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View style={styles.step__question}>
                                    <Text style={styles.step__question__label}>Observations and Repairs Not Affecting Safe Working Condition:</Text>
                                    <TextInput
                                        multiline={true}
                                        style={styles.step__question__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}
                                    />

                                    {errors.observations && <Text style={styles.input__validation}>Enter details</Text>}
                                </View>
                            )}
                        />
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name="writtenSchemeChanges"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View style={styles.step__question}>
                                    <Text style={styles.step__question__label}>Changes to the Written Scheme: </Text>

                                    <RadioButtonGroup
                                        containerStyle={{ marginTop: 10, flexDirection: 'row' }}
                                        radioBackground={importedColours.primary}
                                        selected={value}
                                        onSelected={onChange}>

                                        <RadioButtonItem value={0} label="No" />
                                        <RadioButtonItem value={1} style={{ marginLeft: 20 }} label="Yes" />
                                    </RadioButtonGroup>

                                    {errors.writtenSchemeChanges ? <Text style={styles.input__validation}>Please select a response before continuing</Text> : null}
                                </View>
                            )}
                        />
                    </View>
                </View>
                <View style={{ flex: 1 }}>
                    <TouchableOpacity style={styles.proceed__button} onPress={handleSubmit(onSubmit)}>
                        <Text style={styles.proceed__button__text}>NEXT</Text>
                    </TouchableOpacity>
                </View>
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        ...Platform.select({
            android: { flex: 1, padding: 10, paddingTop: 60 },
            ios: { flex: 1, padding: 20, paddingTop: 60 },
            web: { padding: 20, paddingBottom: 0, paddingTop: 0 },
        }),
        backgroundColor: '#FAFAFA'
    },

    form: {
        flex: 1,
        marginTop: 10,
    },

    step__question: {
        padding: 20,
        backgroundColor: '#FFF',
        marginBottom: 20,
    },

    step__question__label: {
        fontFamily: 'OpenSans-Light',
        fontSize: 18,
        marginBottom: 5,
    },
    step__question__input: {
        borderWidth: 1,
        borderColor: '#CECECE',
        padding: 10,
        marginTop: 5,
    },

    input__validation: {
        color: 'red',
        marginTop: 10
    },

    proceed__button: {
        padding: 10,
        backgroundColor: importedColours.primary,
        marginTop: 20,
        borderRadius: 7,
        height: 45,


        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },

    proceed__button__text: {
        fontFamily: 'OpenSans-Bold',
        color: '#FFF',
        textAlign: 'center',
        fontSize: 18
    },
    inputIOS: {
        fontSize: 15,
        paddingVertical: 9,
        paddingHorizontal: 9,
        borderWidth: 1,
        marginRight: 10,
        borderColor: 'gray',
        borderRadius: 4,
        color: 'black',
        paddingRight: 30 // to ensure the text is never behind the icon
    },

    question: {
        margin: 5,
        padding: 10,
        backgroundColor: '#FFF',
    },

    question__label: {
        fontWeight: 'bold',
        fontSize: 20,
    },

    question__input: {
        borderWidth: 1,
        borderColor: '#CECECE',
        padding: 10,
        marginTop: 5,
    },

    image__container: {
        flexDirection: 'row',
        padding: 10,
        marginBottom: 20,
        alignItems: 'center'
    },

    // image__block: {
    //   flex: 1,
    // },

    image: {
        height: 150,
        width: 150,
        marginRight: 20,
        marginLeft: 20,
    },

    empty__image: {
        borderWidth: 1,
        borderColor: '#CECECE'
    },
    form__input__IOS: {
        flex: 2,
        fontFamily: 'OpenSans-Regular',
        padding: 5,
        marginTop: 5,
    },
});
