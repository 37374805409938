import * as React from 'react';
import { Platform, StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { DateTimePickerAndroid } from '@react-native-community/datetimepicker';
import DateTimePicker from '@react-native-community/datetimepicker';
import moment from 'moment';

export function DatePicker({ value, onChanged, mode = null, maximumDate = null, minimumDate = null, disabled: boolean = false }) {
    const dateMode = (mode === null || mode === "time") ? "time" : "date";
    if (dateMode == "time") {
        if (value != null && value != "") { value = moment().startOf('day').add(moment.duration(value)).toDate() }
    }

    const [date, setDate] = React.useState(value);

    React.useEffect(() => {
        if (value == null || value == "") {
            return;
        }

        if (moment(value).valueOf() !== moment(date).valueOf()) {
            setDate(value);
        }
    }, [value]);

    const updateAndroidDate = () => {
        if (this.disabled)
            return;
        var dateToUpdate = date;
        if (dateToUpdate == null || dateToUpdate == "") { dateToUpdate = moment().toDate() }
        DateTimePickerAndroid.open({
            mode: dateMode,
            maximumDate: maximumDate,
            minimumDate: minimumDate,
            value: moment(dateToUpdate).toDate(),
            onChange: (evt, date) => {
                if (evt.type == "dismissed") {
                    return;
                }

                setDate(date);
                onChanged(date)
            }
        });
    }

    const updateAndSetDate = (event, date) => {
        if (this.disabled)
            return;
        if (dateMode === "time") {
            const timeOnly = moment(date).format("HH:mm");
            setDate(date);
            onChanged(date);
        } else {
            setDate(date);
            onChanged(date);
        }
    };

    // If its android specifically
    if (Platform.OS == "android") {
        return (
            <TouchableOpacity style={{ width: '100%' }} onPress={() => updateAndroidDate()}>
                {date != null && date != "" &&
                    <Text style={{ width: '100%', textAlign: dateMode == 'time' ? 'center' : 'left' }}>
                        {dateMode == "time" && moment(date).format("HH:mm")}
                        {dateMode == "date" && moment(date).format("DD/MM/YYYY")}
                    </Text>}
                {date == null || date == "" && <Text style={{ width: '100%', textAlign: dateMode == 'time' ? 'center' : 'left' }}>Please Select</Text>}
                {/* {date != null && <Text style={{ width: '100%'}}>{date.format("HH:mm")}</Text>} */}
            </TouchableOpacity>
        )
    }

    // If its anything else
    return (
        <View style={{ width: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
            <DateTimePicker mode={mode} value={date} onChange={updateAndSetDate} display={'compact'}
                key={date} maximumDate={maximumDate} />
        </View>
    )
}