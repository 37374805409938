import * as React from 'react';
import { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, ScrollView, Image, Alert, Dimensions, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { TitleBar } from '../../../../screens/app/components/TitleBar';
import { Controller, useForm } from 'react-hook-form';
import { clearInspectionData, setInspectionData } from '@store/slices/rote-inspection-slice';
import moment from 'moment';
import { CameraModal } from '@app/components/modals/CameraModal';
import { DatePicker } from '@app/components/DatePicker';
import CoreAlert from '@components/core-alert';
import DateTimePicker from '@web/components/navigation/controls/DateTimePicker';
import { colours } from '../../../../config';
import { Camera } from 'expo-camera';
import { EquipmentDatabase } from '@store/services/database/equipment-database';
import { CategoryDatabase } from '@store/services/database/category-database';
import { equipmentApi } from '@store/services/api/equipment-api';
import { inspectionApi } from '@store/services/api/inspection-api';
import Checkbox from 'expo-checkbox';
import { InspectionDatabase } from '@store/services/database/inspection-database';

const isMobileScreen = Dimensions.get("screen").width < 480;

export default function RoteStep4({ navigation, route, submitHandler, purposeInterval, checklist, context, isMarineEquipment, marineEquipmentId, isModal = false }: { navigation: any, route: any, submitHandler: any, purposeInterval: any, checklist: any, isMarineEquipment: any, marineEquipmentId: any, context: any, isModal: boolean }) {
  const [permission, requestPermission] = Camera.useCameraPermissions();
  const dispatch = useDispatch();

  const inspection = useSelector((state: RootState) => state.roteInspection);

  const job = useSelector((state: RootState) => state.job);
  const [showCamera, setShowCamera] = useState(false);
  const [images, setImages] = useState([]);
  const [isMarine, setIsMarine] = useState(false);
  const [isMarineDisabled, setIsMarineDisabled] = useState(true);
  let equipmentDatabase = null;
  let categoryDatabase = null;

  if (Platform.OS !== "web") {
    equipmentDatabase = new EquipmentDatabase();
    categoryDatabase = new CategoryDatabase();
  }

  const isMarineCategory = ({ masterCategoryName }) => {
    return masterCategoryName === "Marine 2006";
  }
  const equipmentResult = equipmentApi.useGetEquipmentQuery(route?.params?.equipmentId || marineEquipmentId);
  const inspectionResult = inspectionApi.useGetInspectionQuery(route?.params?.inspectionId);

  useEffect(() => {
    const fetchData = async () => {
      if (Platform.OS !== "web") {
        const equipment = await equipmentDatabase.getById(job.equipmentId);
        if (isMarineCategory(equipment)) {

          if (equipment.nextTestDate) {
            setIsMarineDisabled(false);
            setValue("nextTestDate", moment.utc(equipment.nextTestDate, 'YYYY-MM-DDTHH:mm:ss').toDate())
          } else {
            setIsMarineDisabled(true);
          }
          setIsMarine(true);
        }
        if (context === "edit inspection") {
          const inspectionDatabase = new InspectionDatabase();
          var dbInspectionData = await inspectionDatabase.getCompletedInspection(job.jobId, job.equipmentId);
          const [dbInspection] = dbInspectionData;

          if (dbInspectionData.length > 0) {
            setValue("inspectionDate", moment(dbInspection.inspectionDate, 'YYYY-MM-DDTHH:mm:ss').toDate())
            setValue("nextInspectionDate", moment(dbInspection.nextInspectionDate, 'YYYY-MM-DDTHH:mm:ss').toDate())
            setValue("identification", dbInspection.identification)
            setValue("particulars", dbInspection.particulars)
            setValue("safeForUse", dbInspection.safeForUse)
          }
        }
      } else {
        if (isMarineEquipment) {
          setIsMarine(true);
          setIsMarineDisabled(false);
        }

        if (context === "job approval" && (equipmentResult.data && inspectionResult.data)) {
          if (isMarineCategory(equipmentResult.data)) {
            const nextTestDate =
              inspectionResult.data.nextTestDate
                ? (setIsMarineDisabled(false), inspectionResult.data.nextTestDate)
                : equipmentResult.data.nextTestDate
                  ? equipmentResult.data.nextTestDate
                  : moment().add(5, 'years').toDate();

            setValue("nextTestDate", nextTestDate);
            setIsMarine(true);
          }
          setValue("inspectionDate", inspectionResult.data.inspectionDate)
          setValue("nextInspectionDate", inspectionResult.data.nextInspectionDate)
        }
        else if (context = "equipment inspection" && equipmentResult.data) {
          if (isMarineCategory(equipmentResult.data) || isMarineEquipment) {
            setIsMarine(true);
            setValue("nextTestDate", equipmentResult.data.nextTestDate ? equipmentResult.data.nextTestDate : moment().add(5, 'years').toDate())
          }
        }
      }
    };

    fetchData();
  }, [equipmentResult.data, job.equipmentId]);



  // Used for web
  useEffect(() => {
    if (purposeInterval != null && inspDate != null && context === "equipment inspection") {
      var date = moment(inspDate);
      date = date.add(purposeInterval, 'M');
      setValue("nextInspectionDate", date.toDate());
    }
  }, [purposeInterval])

  const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      identification: inspection.identification,
      particulars: inspection.particulars,
      safeForUse: inspection.safeForUse,
      nextTestDate: moment().add(5, 'years').toDate(),
      inspectionDate: context === "job approval" ? inspection.inspectionDate : moment().toDate(),
      nextInspectionDate: context === "job approval" ? inspection.nextInspectionDate : moment().toDate()
    }
  });

  const safeForUse = watch("safeForUse");
  const inspDate = watch("inspectionDate");
  const nextInspDate = watch("nextInspectionDate");
  const nextTestDate = watch("nextTestDate");

  const data = watch();

  const updateMarine = async (disable) => {
    setIsMarineDisabled(disable);

    var date = moment(inspDate);
    if (disable) {
      setValue("nextTestDate", date.toDate());
    } else {
      let DBEquipment = await equipmentDatabase.getById(job.equipmentId);

      if (isMarineCategory(DBEquipment)) {

        if (DBEquipment.nextTestDate != null) {
          setValue("nextTestDate", moment.utc(DBEquipment.nextTestDate, 'YYYY-MM-DDTHH:mm:ss').toDate())
        } else {
          let DBNextTestDate = moment().add(5, 'years').toDate();
          setValue("nextTestDate", DBNextTestDate);
        }
      }
    }
  }
  if (Platform.OS === 'web') {
    useEffect(() => {
      const nextTestDate = (isMarine && !isMarineDisabled) ? data.nextTestDate : null;

      const updatedInspectionData = {
        ...inspection,
        identification: data.identification,
        particulars: data.particulars,
        safeForUse: data.safeForUse,
        images: images,
        inspectionDate: inspDate,
        nextInspectionDate: nextInspDate,
        nextTestDate: nextTestDate,
        isMarineDisabled: isMarineDisabled
      };
      
      dispatch(setInspectionData(updatedInspectionData));

    }, [data.identification, data.particulars, data.safeForUse, images, inspDate, nextInspDate, nextTestDate]);

    useEffect(() => {
      setData(data);
    }, [data, isMarineDisabled]);
  }

  const setData = (data) => {
    const nextTestDate = (isMarine && !isMarineDisabled) ? data.nextTestDate : null

    dispatch(setInspectionData({
      identification: data.identification,
      particulars: data.particulars,
      safeForUse: data.safeForUse,
      images: images,
      nextTestDate: nextTestDate,
      inspectionDate: data.inspectionDate,
      nextInspectionDate: data.nextInspectionDate,
      isMarineDisabled: isMarineDisabled
    }));

  };



  useEffect(() => {
    return () => {
      dispatch(clearInspectionData());
    };
  }, []);

  if (Platform.OS !== "web" && context === "edit inspection") {
    useEffect(() => {
      const fetchImages = async () => {
        const inspectionDatabase = new InspectionDatabase();
        var dbInspectionData = await inspectionDatabase.getCompletedInspection(job.jobId, job.equipmentId);
        const [dbInspection] = dbInspectionData;

        if (dbInspectionData.length > 0) {
          const imagesData = await inspectionDatabase.getInspectionImages(dbInspection.id);
          if (imagesData && imagesData.length > 0) {
            const imageUris = imagesData.map(img => `${img.base64}`);
            setImages(imageUris);
          }
        }
      };

      fetchImages();
    }, []);
  }


  const setDates = async () => {
    var date = moment(inspDate);
    if (safeForUse === true || safeForUse === null) {
      if (purposeInterval) {
        date = date.add(purposeInterval, 'M');
      } else if (inspection.purposeInterval === 6) {
        date = date.add(6, 'M');
      } else if (inspection.purposeInterval === 12) {
        date = date.add(12, 'M');
      } else if (Platform.OS !== "web") {
        var category = await categoryDatabase.getById(job.categoryId);
        date = date.add(category.inspectionInterval, 'M');
      } else {
        date = date.add(12, 'M');
      }
    } else {
      date = moment(inspDate);
    }
  
    setValue("nextInspectionDate", date.toDate());
  };

  useEffect(() => {
    setDates();
  }, [inspDate]);

  useEffect(() => {
    if (!safeForUse) {
      setDates();
    }
  }, [safeForUse])


  const addImage = (image) => {
    setImages([...images, image]);
    setShowCamera(false);
  }

  const removeImage = (image) => {
    Alert.alert("Images", "Are you sure you want to remove this image?", [
      {
        text: 'No',
        style: 'cancel'
      },
      { text: 'Yes', onPress: () => { setImages(images.filter(i => i !== image)) } }
    ])
  }

  const loadCamera = () => {
    if (images.length >= 3) {
      Alert.alert("You have reached the maximum images.")
      return;
    }

    setShowCamera(true);
  }

  const saveInspection = async (data) => {
    var inspectionData = { ...inspection };
    const nextTestDate = (isMarine && !isMarineDisabled) ? data.nextTestDate : null
    inspectionData.identification = data.identification;
    inspectionData.particulars = data.particulars;
    inspectionData.safeForUse = data.safeForUse;
    inspectionData.nextTestDate = nextTestDate;
    inspectionData.inspectionDate = data.inspectionDate;
    inspectionData.nextInspectionDate = data.nextInspectionDate;
    const answeredQuestions = inspectionData.answeredQuestions;

    submitHandler(inspectionData, images, answeredQuestions);
  }

  const onBack = () => {
    navigation.navigate("Step3");
  }

  const onSubmit = async data => {
    if (safeForUse == null) {
      CoreAlert("Select if this asset is safe for use", "Safe for use");
      return;
    }

    if (Platform.OS != 'web') {
      Alert.alert('Inspection', `Are you sure this is a ${safeForUse ? 'PASS' : 'FAIL'}`, [
        {
          text: 'NO',
        },
        { text: 'YES', onPress: () => saveInspection(data) },
      ]);
    } else {
      saveInspection(data);
    }
  };

  if (showCamera) {
    return (
      <CameraModal onCancel={() => setShowCamera(false)} onPictureTaken={(image) => addImage(image)} />
    )
  }

  return (
    <View style={styles.container}>
      {Platform.OS != 'web' ? <TitleBar navigation={navigation} title={`Step ${checklist === 1 ? '4' : '3'} of ${checklist === 1 ? '4' : '3'}`} showSearchButton={false} showBackButton={true} onSynced={null} onSearch={null} /> : null}

      <ScrollView style={styles.form}>
        <View>
          <Controller
            control={control}
            name="inspectionDate"
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={[styles.step__question]}>
                <Text style={styles.step__question__label}>Inspection Date:</Text>
                <View style={styles.step__question__input}>
                  {Platform.OS != 'web' ?
                    <DatePicker value={value} mode="date" onChanged={onChange} maximumDate={moment().toDate()} />
                    :
                    <DateTimePicker onChange={(date) => onChange(date)} value={value} maximumDate={moment().toDate()} inline={isModal} />
                  }
                </View>
              </View>
            )}
          />
        </View>
        <View>
          {nextInspDate != null &&
            <Controller
              control={control}
              name="nextInspectionDate"
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={[styles.step__question]}>
                  <Text style={styles.step__question__label}>Next Inspection Date:</Text>

                  <View style={styles.step__question__input}>
                    {Platform.OS != 'web' ?
                      <DatePicker value={value} mode="date" onChanged={onChange} />
                      :
                      <DateTimePicker onChange={(date) => onChange(date)} value={value} maximumDate={moment().toDate()} inline={isModal} />
                    }
                  </View>
                </View>
              )}
            />
          }
        </View>



        {isMarine && <View>
          <Controller
            control={control}
            name="nextTestDate"
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={[styles.step__question]}>
                <View style={styles.step__question_container} >
                  <View style={styles.step__question_flex_input} >
                    <Text style={styles.step__question__label}>Next Test Date:</Text>
                  </View>
                  <View style={styles.step__question_flex_button} >
                    <Text style={styles.step__question__label}>N/A:</Text>
                  </View>
                </View>

                <View style={styles.step__question_container} >
                  <View style={[(isMarineDisabled ? styles.disabled : styles.step__question_flex_input), styles.step__question_flex_input]}
                    pointerEvents={isMarineDisabled ? 'none' : 'auto'}>
                    {!isMarineDisabled && <View style={styles.step__question__input}>
                      {Platform.OS != 'web' ?
                        <DatePicker value={value} mode="date" disabled={isMarineDisabled} onChanged={onChange} minimumDate={new Date()} />
                        :
                        <DateTimePicker onChange={(date) => onChange(date)} value={value} maximumDate={moment().toDate()} inline={isModal} />
                      }
                    </View>}
                  </View>

                  <View style={styles.step__question_container_vertical}>
                    <View style={styles.step__question_flex_button}>
                      <View style={styles.step__question__label}>
                        <Checkbox style={{ marginTop: 10, marginLeft: 40 }} value={isMarineDisabled ? true : false} onValueChange={(val) => updateMarine(val)} color={colours.primary} />

                      </View>
                    </View>
                  </View>
                </View>


              </View>
            )}
          />
        </View>}


        <Controller
          control={control}
          name="identification"
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={styles.step__question}>
              <Text style={styles.step__question__label}>Identification of any parts not accessible for examination:</Text>

              <TextInput
                multiline={true}
                style={styles.step__question__input}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
              />
            </View>
          )}
        />

        <Controller
          control={control}
          name="particulars"
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={styles.step__question}>
              <Text style={styles.step__question__label}>Particulars of any test carried out as part of this examination:</Text>

              <TextInput
                multiline={true}
                style={styles.step__question__input}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
              />
            </View>
          )}
        />

        <View style={styles.image__container}>
          <TouchableOpacity onPress={() => loadCamera()} style={{ paddingLeft: 30, paddingRight: 30 }}>
            <Ionicons name="camera" size={92} color="black" />
          </TouchableOpacity>

          {images.map((image, idx) => {
            return (
              <TouchableOpacity key={idx} onPress={() => removeImage(image)}>
                <Image
                  style={[styles.image]}
                  resizeMode={"contain"}
                  source={{
                    uri: image,
                  }}
                />
              </TouchableOpacity>
            )
          })}
        </View>

        <Controller
          control={control}
          name="safeForUse"
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={styles.safe__block}>
              <TouchableOpacity style={[styles.safe__button, styles.not_safe, safeForUse == false && styles.unsafe_checked]} onPress={() => onChange(false)}>
                <MaterialCommunityIcons name="cancel" size={isMobileScreen ? 32 : 44} color={safeForUse == false ? 'white' : 'black'} />
                <Text style={[styles.safe__button__text, safeForUse == false && styles.safe__button__text__checked]}>NOT SAFE FOR USE</Text>
              </TouchableOpacity>

              <TouchableOpacity style={[styles.safe__button, styles.safe, safeForUse == true && styles.safe_checked]} onPress={() => onChange(true)}>
                <Ionicons name="checkmark-circle-outline" size={isMobileScreen ? 32 : 44} color={safeForUse == true ? 'white' : 'black'} />
                <Text style={[styles.safe__button__text, safeForUse == true && styles.safe__button__text__checked]}>SAFE FOR USE</Text>
              </TouchableOpacity>
            </View>
          )}
        />

      </ScrollView>

      <TouchableOpacity style={styles.proceed__button} onPress={handleSubmit(onSubmit)}>
        <Text style={styles.proceed__button__text}>SAVE</Text>
      </TouchableOpacity>

    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    ...Platform.select({
      android: { flex: 1, padding: 40, },
      ios: { flex: 1, padding: 20, paddingTop: 60 },
      web: { padding: 20, paddingBottom: 40, paddingTop: 0 },
    }),
    backgroundColor: '#FAFAFA',
  },
  step__question_container: {
    flexDirection: 'row',
  },
  step__question_container_vertical: {
    flexDirection: 'column',
  },

  form: {
    flex: 1,
    marginTop: 10,
  },

  step__question: {
    padding: 20,
    backgroundColor: '#FFF',
    marginBottom: 20,
  },

  step__question_flex_input: {
    width: '75%'
  },
  step__question_flex_button: {
    width: '20%',
    marginLeft: '5%'
  },


  step__question_flex: {
    flex: 1
  },

  step__question__label: {
    fontFamily: 'OpenSans-Light',
    fontSize: 18,
    marginBottom: 5,
  },

  disabled: {
    padding: 10,
    marginTop: 5,
    backgroundColor: '#CECECE',
    border: 'none'
  },

  step__question__input: {
    borderWidth: 1,
    borderColor: '#CECECE',
    padding: 10,
    marginTop: 5,
  },

  input__validation: {
    color: 'red',
    marginTop: 10
  },

  proceed__button: {
    padding: 10,
    backgroundColor: colours.primary,
    marginTop: 20,
    borderRadius: 7,
    height: 45,


    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  proceed__button__text: {
    fontFamily: 'OpenSans-Bold',
    color: '#FFF',
    textAlign: 'center',
    fontSize: 18
  },

  safe__block: {
    flexDirection: 'row',
  },

  safe__button: {
    flex: 1,
    height: 140,
    backgroundColor: '#edebeb',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderRadius: 5,
    padding: isMobileScreen ? 10 : 0
  },

  safe__button__text: {
    fontFamily: 'OpenSans-Bold',
    marginLeft: 10,
    fontSize: isMobileScreen ? 20 : 38
  },

  not_safe: {
    marginRight: 10,
  },

  safe: {
    marginLeft: 10,
  },

  unsafe_checked: {
    backgroundColor: 'red',
  },

  safe_checked: {
    backgroundColor: 'green',
  },

  safe__button__text__checked: {
    color: '#FFF'
  },

  image__container: {
    flexDirection: 'row',
    padding: 10,
    marginBottom: 20,
    alignItems: 'center'
  },

  // image__block: {
  //   flex: 1,
  // },

  image: {
    height: 150,
    width: 150,
    marginRight: 20,
    marginLeft: 20,
  },

  empty__image: {
    borderWidth: 1,
    borderColor: '#CECECE'
  }
});
