import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Feather, MaterialIcons } from '@expo/vector-icons';
import { Foundation } from '@expo/vector-icons';
import { ScrollView } from 'react-native-gesture-handler';
import { RootState } from '@store/index';
import { addEquipmentInspection, clearEquipmentBasket, removeEquipmentInspection, setShowEquipmentInspectionModal } from '@store/slices/equipment-inspection-slice';

export const EquipmentInspections = (props) => {
  const dispatch = useDispatch();
  const equipment = useSelector((state: RootState) => state.equipmentInspection.equipment);
  const [showList, setShowList] = useState<boolean>(false);

  useEffect(() => {
    dispatch(clearEquipmentBasket());
  }, []);

  useEffect(() => {
    if (equipment.length == 0) { setShowList(false); }
  }, [equipment])

  const toggleList = () => {
    if (equipment.length == 0) { return; }
    setShowList(!showList);
  }

  const removeFromBasket = (id) => {
    dispatch(removeEquipmentInspection(id));
  }

  const clearAllFromBasket = () => {
    dispatch(clearEquipmentBasket());
  }
  const isUniformEquipment = (equipment) => {
    const masterCategory = equipment[0].masterCategoryName;
    const inspectionInterval = equipment[0].inspectionInterval;
  
    return equipment.every(asset => (
      asset.masterCategoryName === masterCategory &&
      asset.inspectionInterval === inspectionInterval
    ));
  };
  
  const inspect = () => {
    if (isUniformEquipment(equipment)) {
      dispatch(setShowEquipmentInspectionModal(true));
    } else {
      alert("Equipment should belong to the same master category and have identical inspection intervals.");
    }

    setShowList(!showList);
  };

  return (
    <View style={styles.basket__container}>
      <View style={styles.basket}>
        <TouchableOpacity style={styles.title}  onPress={() => toggleList()}>
          <MaterialIcons name="check-circle" size={24} color="black" />
          <Text style={{ fontWeight: 'bold', marginLeft: 10 }}>Equipment Inspections</Text>
          <View style={{ flex: 1 }}></View>
          <Text><Text style={{ fontWeight: 'bold' }}>{ equipment.length }</Text><Text> Items</Text></Text>
        </TouchableOpacity>
      </View>

      {showList && 
        <View style={styles.basket__list}>
          <ScrollView>
            { equipment.map((equipment) => {
              return (
                <View style={styles.basket__row} key={equipment.id}>
                  <Text style={{ fontWeight: 'bold' }}>{equipment.serialNumber}</Text>
                  <View style={{ flex: 1 }}></View>
                  <TouchableOpacity onPress={() => removeFromBasket(equipment.id)}>
                    <Feather name="trash" size={20} color="red" />
                  </TouchableOpacity>
                </View>
              )
            })}
          </ScrollView>

          <View style={styles.action__buttons}>
            <TouchableOpacity style={[styles.action__button, styles.action__clear__button]} onPress={clearAllFromBasket}>
              <Text style={styles.action__button__text}>CLEAR</Text>
            </TouchableOpacity>
            
            <TouchableOpacity style={styles.action__button} onPress={inspect}>
              <Text style={styles.action__button__text}>INSPECT</Text>
            </TouchableOpacity>
          </View>
        </View>
      }
    </View>
  )
}

export const EquipmentInspectionIcon = ({equipmentId, serialNumber, masterCategoryName, nextTestDate, inspectionInterval}) => {
  const dispatch = useDispatch();
  const equipment = useSelector((state: RootState) => state.equipmentInspection.equipment);

  const inBasket = (id) => {
    return equipment.filter(i => i.id == id).length > 0;
  }

  const addToBasket = () => {
    dispatch(addEquipmentInspection({
      id: equipmentId,
      serialNumber: serialNumber,
      masterCategoryName: masterCategoryName,
      nextTestDate: nextTestDate,
      inspectionInterval: inspectionInterval
    }))
  }

  const removeFromBasket = () => {
    dispatch(removeEquipmentInspection(equipmentId));
  }

  
  if (inBasket(equipmentId)) {
    return (
      <TouchableOpacity onPress={() => removeFromBasket()}>
        <Foundation name="minus" size={24} color="black" />
      </TouchableOpacity>
    )
  }

  return (
    <TouchableOpacity onPress={() => addToBasket()}>
      <Foundation name="plus" size={24} color="black" />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  basket__container: {
    position: 'relative',
    zIndex: 999998
  },

  basket: {
    backgroundColor: '#FFF',
    padding: 10,
    marginBottom: 10,
    width: '300px',

    flexDirection: 'column',
    borderWidth: 1,
    borderColor: '#CECECE'
  },

    title: {
      flexDirection: 'row',
      alignItems: 'center',
    },

  basket__list: {
    position: 'absolute',
    top: 50,
    left: 0,
    zIndex: 9999999,
    backgroundColor: '#FFF',
    width: '300px',
    maxHeight: '300px',
    borderWidth: 1,
    borderColor: '#CECECE',
  },

    basket__row: {
      backgroundColor: '#f5f2f2',
      padding: 10,
      margin: 5,

      flexDirection: 'row',
      alignItems: 'center'
    },

  action__buttons: {
    flexDirection: 'row',
    borderTopWidth: 2,
    borderTopColor: '#CECECE',
    paddingTop: 5,
    paddingBottom: 5
  },

    action__button: {
      flex: 1,
      backgroundColor: 'black',
      margin: 5,
      borderRadius: 5,
      textAlign: 'center',
    },

      action__button__text: {
        color: '#FFF',
        padding: 5,
        textAlign: 'center',
      },

    action__clear__button: {
      backgroundColor: 'red'
    }
});