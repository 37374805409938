import {
  HelpCentreVideo,
  HelpCentreVideoForm, useCreateHelpCentreVideoMutation,
  useRemoveHelpCentreVideoMutation, useUpdateHelpCentreVideoMutation
} from "@store/services/api/help-centre-api";
import {Controller, useForm} from "react-hook-form";
import React, {useEffect} from "react";
import {Modal, Pressable, StyleSheet, Text, TextInput, TouchableOpacity, View} from "react-native";
import * as DocumentPicker from "expo-document-picker";
import {Feather} from '@expo/vector-icons';
import {PRIMARY_COLOUR} from "../../../../../../../constants/GlobalStyle";

interface VideoModalProps {
  video: HelpCentreVideo,
  onRequestClose: () => void,
  visible: boolean,
  onSuccess?: () => void
}

export function ManageVideoModal({video, onRequestClose, visible, onSuccess}: VideoModalProps) {
  const [removeVideo, {isSuccess: removeSuccess}] = useRemoveHelpCentreVideoMutation();
  const [createVideo, {isSuccess: createSuccess}] = useCreateHelpCentreVideoMutation();
  const [updateVideo, {isSuccess: updateSuccess}] = useUpdateHelpCentreVideoMutation();
  const isEdit: boolean = !!video;

  const {control, handleSubmit, reset, trigger, setValue, formState: {isValid}} = useForm<HelpCentreVideoForm>({
    defaultValues: {requiredRoles: []}
  });

  useEffect(() => {
    if (removeSuccess || createSuccess || updateSuccess) {
      onSuccess();
    }
  }, [removeSuccess, createSuccess, updateSuccess]);

  useEffect(() => {
    reset({
      title: video?.title ?? '',
      description: video?.description ?? '',
      requiredRoles: video?.requiredRoles.split(',') ?? [],
      fileName: video?.fileName ?? ''
    });
  }, [visible, video]);

  function onSubmit(data: HelpCentreVideoForm) {
    if (isEdit) {
      updateVideo(data);
      return;
    }

    createVideo(data);
  }

  function onRemove() {
    removeVideo(video.fileName)
  }

  const uploadFile = async () => {
    const documentResult = await DocumentPicker.getDocumentAsync({
      type: "video/*"
    });
    const videoFile = documentResult.assets[0].file;
    setValue("file", videoFile);
    trigger("file");
  }

  const modalTitle = video ? 'Edit Video' : 'Create Video';

  const userRoles = ["Admin", "User", "Engineer"];

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onRequestClose={onRequestClose}>
      <View style={styles.modalContainer}>
        <View style={styles.modal}>
          {isEdit && (
            <Pressable style={styles.removeButton} onPress={onRemove}>
              <Feather name="trash" size={24} color="red"/>
            </Pressable>
          )}
          <Text style={styles.titleText}>{modalTitle}</Text>
          <View>
            <Controller
              control={control}
              name="title"
              rules={{required: true}}
              render={({field}) => (
                <TextInput
                  placeholderTextColor='#999'
                  style={styles.textInput}
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Title"/>
              )}
            />

            <Controller
              control={control}
              name="description"
              rules={{required: true}}
              render={({field}) => (
                <TextInput
                  placeholderTextColor='#999'
                  style={styles.textInput}
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Description"/>
              )}
            />

            <Controller
              control={control}
              name="requiredRoles"
              rules={{required: true}}
              render={({field: {onChange, value}}) => (
                <select
                  style={styles.selectInput}
                  value={value}
                  onChange={e => onChange(Array.from(e.target.selectedOptions, option => option.value))} multiple>
                  {userRoles.map((role, i) => <option key={i} value={role}>{role}</option>)}
                </select>
              )}
            />

            {!isEdit && (
              <Controller
                control={control}
                name="file"
                rules={{required: !isEdit}}
                render={({field: {value}}) => (
                  <>
                    <TouchableOpacity style={[styles.uploadButton]} onPress={() => uploadFile()}>
                      <Text style={styles.uploadButtonText}>Select Video</Text>
                    </TouchableOpacity>
                    {value ? (
                      <Text>File Selected: {value.name}</Text>
                    ) : (
                      <Text>No file selected</Text>
                    )}
                  </>
                )}
              />
            )}
          </View>
          <View style={styles.buttonContainer}>
            <TouchableOpacity style={styles.cancelButton} onPress={onRequestClose}>
              <Text style={styles.cancelButtonText}>Cancel</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                ...styles.submitButton,
                ...(isValid ? {} : styles.invalidButton)
              }}
              onPress={handleSubmit(onSubmit)}>
              <Text style={styles.submitButtonText}>Submit</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  modalContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  modal: {
    backgroundColor: '#fff',
    padding: 24,
    minWidth: 400,
    position: 'relative',
    width: '40%'
  },
  titleText: {
    fontSize: 18,
    marginBottom: 12,
  },
  textInput: {
    borderWidth: 1,
    borderColor: '#CECECE',
    padding: 10,
    marginTop: 5,
  },
  selectInput: {
    borderWidth: 1,
    borderColor: '#CECECE',
    padding: 10,
    marginTop: 5,
    minHeight: 120,
  },
  removeButton: {
    position: 'absolute',
    zIndex: 10,
    top: 0,
    right: 0,
    padding: 12
  },
  uploadButton: {
    marginBottom: 10,
    backgroundColor: PRIMARY_COLOUR,
    width: 200,
    marginTop: 10,
  },
  uploadButtonValid: {
    borderWidth: 2,
    borderColor: 'green'
  },
  uploadButtonText: {
    fontFamily: 'OpenSans-Bold',
    padding: 5,
    color: '#FFF',
    textAlign: 'center',
    fontSize: 14,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  submitButton: {
    backgroundColor: PRIMARY_COLOUR,
    padding: 10,
    borderRadius: 2,
    width: '45%',
  },
  submitButtonText: {
    color: '#FFF',
    textAlign: 'center',
    fontSize: 14,
    fontFamily: 'OpenSans-Bold'
  },
  cancelButton: {
    borderWidth: 1,
    borderColor: '#CECECE',
    padding: 10,
    borderRadius: 2,
    width: '45%',
  },
  cancelButtonText: {
    color: '#333',
    textAlign: 'center',
    fontSize: 14,
    fontFamily: 'OpenSans-Bold'
  },
  invalidButton: {
    pointerEvents: 'none',
    backgroundColor: '#999',
    cursor: 'pointer'
  }
});
