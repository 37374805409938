import * as React from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { EvilIcons } from '@expo/vector-icons';
import { Controller, useForm } from 'react-hook-form';
import { companyApi, useCreateCompanyMutation, useUpdateCompanyMutation } from '../../../../../../store/services/api/company-api';
import { countryApi, useGetAllCountriesQuery } from '../../../../../../store/services/api/countries-api';
import { LoadingIndicator } from '../../../../components/navigation/controls/LoadingIndicator';
import { useIsFocused } from "@react-navigation/native";
import { useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { useGetAllCustomersQuery } from '@store/services/api/customer-api';


export default function CompanyManageScreen({ navigation, route }: { navigation: any, route: any }) {
    const user = useSelector((state: RootState) => state.user.user);
    const { data: countries } = countryApi.useGetAllCountriesQuery(null);

    const [createCompany, createCompanyResult] = useCreateCompanyMutation();
    const [updateCompany, updateCompanyResult] = useUpdateCompanyMutation();

    const [trigger, companyResult, lastPromiseInfo] = companyApi.useLazyGetCompanyQuery();

    const { control, handleSubmit, getValues, setValue, formState: { errors } } = useForm({
        defaultValues: {
            id: null,
            name: "",
            address1: "",
            address2: "",
            address3: "",
            address4: "",
            postcode: "",
            phoneNumber: "",
            emailAddress: "",
            countryId: "",
            contactName: "",
            contactPhoneNumber: ""
        }
    });

    React.useEffect(() => {
        if (route.params?.companyId != null) {
            trigger(route.params?.companyId);
        }
    }, [])

    React.useEffect(() => {
        if (companyResult.isSuccess) {
            var data = companyResult.data;
            setValue("id", data.id);
            setValue("name", data.name);
            setValue("address1", data.address1);
            setValue("address2", data.address2);
            setValue("address3", data.address3);
            setValue("address4", data.address4);
            setValue("postcode", data.postcode);
            setValue("phoneNumber", data.phoneNumber);
            setValue("emailAddress", data.emailAddress);
            setValue("countryId", data.countryId);
            setValue("contactName", data.contactName);
            setValue("contactPhoneNumber", data.contactPhoneNumber);
        }
    }, [companyResult]);

    const onSubmit = async (data) => {

        var result = null;
        if (data.id != null) {
            result = await updateCompany(data) as any;
        } else {
            result = await createCompany(data) as any;
        }

        if (result.error) {
            return;
        }

        navigation.goBack();
    };

    if (createCompanyResult.isLoading || updateCompanyResult.isLoading) {
        return <LoadingIndicator />
    }

    return (
        <View style={styles.container}>
            <View style={GlobalStyle.sub__title}>
                <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => navigation.goBack()}>
                    <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
                </TouchableOpacity>
                <Text style={GlobalStyle.sub__title__text}>MANAGE COMPANY</Text>
            </View>

            <View style={GlobalStyle.form}>
                {/* ##### ROW ##### */}
                <View style={GlobalStyle.form__row}>
                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name="name"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Company Name (*)</Text>
                                    <TextInput
                                        style={GlobalStyle.form__column__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}>
                                    </TextInput>
                                </View>
                            )}
                        />

                        {errors.name && <Text style={GlobalStyle.form__input__validation}>Name is required.</Text>}
                    </View>

                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name="address1"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Address Line 1 (*)</Text>
                                    <TextInput
                                        style={GlobalStyle.form__column__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}>
                                    </TextInput>
                                </View>
                            )}
                        />

                        {errors.address1 && <Text style={GlobalStyle.form__input__validation}>Address Line 1 is required.</Text>}
                    </View>

                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="address2"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Address Line 2</Text>
                                    <TextInput
                                        style={GlobalStyle.form__column__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}>
                                    </TextInput>
                                </View>
                            )}
                        />
                    </View>
                </View>

                {/* ##### ROW ##### */}
                <View style={GlobalStyle.form__row}>
                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="address3"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Address Line 3</Text>
                                    <TextInput
                                        style={GlobalStyle.form__column__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}>
                                    </TextInput>
                                </View>
                            )}
                        />
                    </View>

                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="address4"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Address Line 4</Text>
                                    <TextInput
                                        style={GlobalStyle.form__column__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}>
                                    </TextInput>
                                </View>
                            )}
                        />
                    </View>

                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="postcode"
                            rules={{ required: true }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Postcode (*)</Text>
                                    <TextInput
                                        style={GlobalStyle.form__column__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}>
                                    </TextInput>
                                </View>
                            )}
                        />

                        {errors.postcode && <Text style={GlobalStyle.form__input__validation}>Postcode is required.</Text>}
                    </View>
                </View>

                <View style={GlobalStyle.form__row}>
                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="phoneNumber"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Phone Number</Text>
                                    <TextInput
                                        style={GlobalStyle.form__column__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}>
                                    </TextInput>
                                </View>
                            )}
                        />
                    </View>

                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="emailAddress"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Email Address</Text>
                                    <TextInput
                                        style={GlobalStyle.form__column__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}>
                                    </TextInput>
                                </View>
                            )}
                        />
                    </View>
                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="countryId"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Country</Text>
                                    <View >
                                        <Picker style={styles.form__input}
                                            selectedValue={value}
                                            onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                                            itemStyle={[{ height: 50 }]}>

                                            <Picker.Item label="Please Select" value="" />

                                            {countries != null && countries.map((value, index) => {
                                                return (
                                                    <Picker.Item key={value.id} label={value.name} value={value.id} />
                                                )
                                            })}
                                        </Picker>
                                    </View>
                                </View>
                            )}
                        />
                    </View>
                </View>
                <View style={GlobalStyle.form__row}>
                    <View style={[GlobalStyle.form__column, { maxWidth: "32.5%" }]}>
                        <Controller
                            control={control}
                            name="contactName"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Contact Name</Text>
                                    <TextInput
                                        style={GlobalStyle.form__column__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}>
                                    </TextInput>
                                </View>
                            )}
                        />
                    </View>
                    <View style={[GlobalStyle.form__column, { maxWidth: "32.5%" }]}>
                        <Controller
                            control={control}
                            name="contactPhoneNumber"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Contact Phone Number</Text>
                                    <TextInput
                                        style={GlobalStyle.form__column__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}>
                                    </TextInput>
                                </View>
                            )}
                        />
                    </View>
                </View>

                <TouchableOpacity style={GlobalStyle.form__submit__button} onPress={handleSubmit(onSubmit)}>
                    <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        position: 'relative',
        padding: 10,
    },
    form__input: {
        flex: 2,
        fontFamily: 'OpenSans-Regular',
        padding: 5,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#CECECE',
        marginTop: 5,
    },
});
