import { View, Text, StyleSheet, TextInput, TouchableOpacity } from 'react-native';
import { DataTable } from 'react-native-paper';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import React, { useEffect, useState } from 'react';
import { categoryApi, useCreateCategoryQuestionResponseMutation, useUpdateCategoryQuestionResponseMutation, useDeleteCategoryQuestionResponseMutation, useCreateCategoryQuestionMutation, useUpdateCategoryQuestionMutation, useDeleteCategoryQuestionMutation } from '@store/services/api/category-api';
import { Controller, useForm } from 'react-hook-form';
import { PagedTableDto } from '@store/services/api/base-api';
import Checkbox from 'expo-checkbox';
import { EvilIcons, Feather, Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import PaginationComponent from '@web/components/Pagination';
import { FIRST_PAGE, MAX_ITEMS_BEFORE_ALL } from '../../../../../../constants/Constants';
import Spinner from 'react-native-loading-spinner-overlay';

 function CategoryQuestionsManage ({category, onSelected}) {
  const [selectedQuestion, setSelectedQuestion] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);

  const [page, setPage] = useState<number>(0);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);

  const [trigger, questionsResult] = categoryApi.useLazyGetCategoryQuestionsQuery();
  const [createQuestion, createQuestionResult] = useCreateCategoryQuestionMutation();
  const [updateQuestion, updateQuestionResult] = useUpdateCategoryQuestionMutation();
  const [deleteQuestion, deleteQuestionResult] = useDeleteCategoryQuestionMutation();

  const { control, handleSubmit, getValues, setValue, reset, watch, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      position: 0,
      question: "",
      categoryQuestionCategoryId: category?.id
    }
  });

  const id = watch("id");

  useEffect(() => {
    loadTable();
  }, [page, category, numberOfItemsPerPage]);

  useEffect(() => {
    onSelected(selectedQuestion);
  }, [selectedQuestion])

  const loadTable = () => {
    if (category == null) return;
    const pageNumber = numberOfItemsPerPage > MAX_ITEMS_BEFORE_ALL ? FIRST_PAGE : page;
    trigger(new PagedTableDto(pageNumber, {
      CategoryId: category.id,
      pageSize: numberOfItemsPerPage
    })).then(() => {
      setLoading(false);
    });
  }

  const decreasePosition = async (question) => {
    if (question.position <= 1) { return; }

    var questionDto = JSON.parse(JSON.stringify(question));
    questionDto.position--;

    var result = await updateQuestion(questionDto) as any;
    if (result.error) {
      alert(result.error)
      return;
    }

    loadTable();
  }

  const increasePosition = async (question) => {
    if (question.position == questionsResult?.data.list.length) { return; }

    var questionDto = JSON.parse(JSON.stringify(question));
    questionDto.position++;

    var result = await updateQuestion(questionDto) as any;
    if (result.error) {
      alert(result.error)
      return;
    }

    loadTable();
  }

  const edit = (question) => {
    setValue("id", question.id);
    setValue("question", question.question);
    setValue("position", question.position);
  }

  const toggleQuestion = (question) => {
    if (selectedQuestion != null && selectedQuestion.id == question.id) {
      setSelectedQuestion(null);
    } else {
      setSelectedQuestion(question);
    }

  }

  const remove = async (question) => {
    if (confirm("Are you sure you want to delete this question?")) {
      var result = await deleteQuestion(question.id) as any;
      if (result.error) {
        alert(result.error)
        return;
      }

      loadTable();
    }
  }

  const onSubmit = async (data) => {
    console.log(data, "data line 109");
    
    data.CategoryId = category.id;
    var result = null;
    if (data.id != null) {
      result = await updateQuestion(data) as any;
    } else {
      result = await createQuestion(data) as any;
    }

    if (result.error) {
      alert(result.error)
      return;
    }

    reset();
    loadTable();
  }

  return (
    <View style={{ flex: 1 }}>
      <Spinner
        visible={loading}
        textContent={'Loading...'}
        textStyle={GlobalStyle.spinnerTextStyle}
      />
      <View>
        <Text style={[GlobalStyle.add__new__link, styles.add__new__link]}>{category != null ? category.name + ' - ' : null} Category Questions</Text>
        {category != null &&
          <View style={[GlobalStyle.form, styles.form]}>
            <View style={GlobalStyle.form__row}>
              <View style={[GlobalStyle.form__column, GlobalStyle.form__column__full]}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="question"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View>
                      <Text style={GlobalStyle.form__column__text}>Question (*)</Text>
                      <TextInput
                        style={GlobalStyle.form__column__input}
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}>
                      </TextInput>
                    </View>
                  )}
                />

                {errors.question && <Text style={GlobalStyle.form__input__validation}>Name is required.</Text>}
              </View>

              <View style={styles.form__buttons}>
                <TouchableOpacity style={styles.form__add__btn} onPress={handleSubmit(onSubmit)}>
                  <Text style={styles.form__add__btn__text}>{id == null ? 'Add' : 'Update'}</Text>
                </TouchableOpacity>

                {id != null &&
                  <TouchableOpacity style={styles.form__add__btn} onPress={() => reset()}>
                    <Text style={styles.form__add__btn__text}>Clear</Text>
                  </TouchableOpacity>
                }
              </View>
            </View>
          </View>
        }
      </View>

      {category == null &&
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Text style={{ fontSize: 28, fontStyle: 'italic' }}>Select Category</Text>
        </View>
      }

      {category != null &&
        <View>
          <DataTable style={GlobalStyle.table}>
            <DataTable.Header>
              <DataTable.Title>Name</DataTable.Title>
              <DataTable.Title>Position</DataTable.Title>
              <DataTable.Title>Actions</DataTable.Title>
            </DataTable.Header>

            {questionsResult?.data?.list.map((element: any) => {
              return (
                <DataTable.Row key={element.id}>
                  <DataTable.Cell>{element.question}</DataTable.Cell>
                  <DataTable.Cell>
                    {element.position}
                    <TouchableOpacity style={styles.action} onPress={() => decreasePosition(element)}><Ionicons name="chevron-up-outline" size={24} color="black" /></TouchableOpacity>
                    <TouchableOpacity style={styles.action} onPress={() => increasePosition(element)}><Ionicons name="chevron-down-outline" size={24} color="black" /></TouchableOpacity>
                  </DataTable.Cell>
                  <DataTable.Cell>
                    <View style={styles.table__actions}>
                      <TouchableOpacity style={styles.action} onPress={() => edit(element)}><Feather name="edit" size={20} color="black" /></TouchableOpacity>
                      <TouchableOpacity style={styles.action} onPress={() => remove(element)}><Feather name="trash" size={20} color="black" /></TouchableOpacity>
                      <View style={styles.action}>
                        <Checkbox style={[GlobalStyle.form__column__input]}
                          onValueChange={() => toggleQuestion(element)}
                          value={selectedQuestion == null ? false : selectedQuestion.id == element.id}>
                        </Checkbox>
                      </View>
                    </View>
                  </DataTable.Cell>
                </DataTable.Row>
              )
            })}
          </DataTable>

          <PaginationComponent
            numberOfItemsPerPage={numberOfItemsPerPage}
            setNumberOfItemsPerPage={setNumberOfItemsPerPage}
            page={page}
            setPage={setPage}
            fetchResult={questionsResult}
            loading={loading}
            setLoading={setLoading}
          />
        </View>
      }
    </View>
  )
}

function CategoryQuestionsResponsesManage ({ question }) {
  const [responses, setResponses] = useState([]);
  const [page, setPage] = useState<number>(0);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);
  const [loading, setLoading] = useState<any>(false);

  const [trigger, responsesResult] = categoryApi.useLazyGetCategoryQuestionResponsesQuery();
  const [createResponse, createResponseResult] = useCreateCategoryQuestionResponseMutation();
  const [updateResponse, updateResponseResult] = useUpdateCategoryQuestionResponseMutation();
  const [deleteResponse, deleteResponseResult] = useDeleteCategoryQuestionResponseMutation();

  const { control, handleSubmit, getValues, setValue, reset, watch, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      position: 0,
      response: "",
      commentMandatory: false,
      isPassDefault: false,
      categoryQuestionId: question?.id
    }
  });

  const id = watch("id");

  useEffect(() => {
    loadTable();
  }, [page, question, numberOfItemsPerPage]);

  const loadTable = () => {
    if (question == null) return;
    const pageNumber = numberOfItemsPerPage > MAX_ITEMS_BEFORE_ALL ? FIRST_PAGE : page;
    trigger(new PagedTableDto(pageNumber, {
      categoryQuestionId: question.id,
      pageSize: numberOfItemsPerPage
    })).then((result) => {
      setLoading(false);
      setResponses(result.data?.list || []);
    });
  }

  const decreasePosition = async (response) => {
    if (response.position <= 1) { return; }

    var responseDto = JSON.parse(JSON.stringify(response));
    responseDto.position--;

    var result = await updateResponse(responseDto) as any;
    if (result.error) {
      alert(result.error)
      return;
    }

    loadTable();
  }

  const increasePosition = async (response) => {
    if (response.position == responsesResult?.data.list.length) { return; }

    var responseDto = JSON.parse(JSON.stringify(response));
    responseDto.position++;

    var result = await updateResponse(responseDto) as any;
    if (result.error) {
      alert(result.error)
      return;
    }

    loadTable();
  }

  const edit = (response) => {
    console.log(response);
    
    setValue("id", response.id);
    setValue("response", response.response);
    setValue("position", response.position);
    setValue("commentMandatory", response.commentMandatory);
    setValue("isPassDefault", response.isPassDefault);
  }


  const remove = async (response) => {
    if (confirm("Are you sure you want to delete this response?")) {
      var result = await deleteResponse(response.id) as any;
      if (result.error) {
        alert(result.error)
        return;
      }

      loadTable();
    }
  }

  const onSubmit = async (data) => {
    
    const hasDefaultPassResponse = responses.some((response) => response.isPassDefault);
    
    const isEditingDefaultPassResponse = responses.some(
      (response) => response.id === data.id && response.isPassDefault
    );

    if (data.response.trim() === "") {
    alert('Response cannot be empty.');
    return;
  }

    if (data.id == null && responses.length >= 5) {
      alert('You cannot add more than 5 responses.');
      return;
    }
    if (data.isPassDefault && !isEditingDefaultPassResponse) {
      if (hasDefaultPassResponse) {
        alert('You can only have one response with Default Pass');
        return;
      }
    }

    if (data.isPassDefault && data.commentMandatory) {
      alert('You can only have a response with Default Pass or Mandatory Comment selected');
        return;
    }


    data.categoryQuestionId = question.id;
    var result = null;
    if (data.id != null) {
      result = await updateResponse(data) as any;
    } else {
      result = await createResponse(data) as any;
    }

    if (result.error) {
      alert(result.error);
      return;
    }

    reset();
    loadTable();
  }

  return (
    <View style={{ flex: 1 }}>
      <Spinner
        visible={loading}
        textContent={'Loading...'}
        textStyle={GlobalStyle.spinnerTextStyle}
      />
      <View>
        <Text style={[GlobalStyle.add__new__link, styles.add__new__link]}>{question != null ? `${question.question} - ` : ''} Question Responses</Text>
        {question != null &&
          <View style={[GlobalStyle.form, styles.form]}>
            <View style={GlobalStyle.form__row}>
              <View style={[GlobalStyle.form__column, GlobalStyle.form__column__full]}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="response"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View>
                      <Text style={GlobalStyle.form__column__text}>Response (*)</Text>
                      <TextInput
                        style={GlobalStyle.form__column__input}
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}>
                      </TextInput>
                    </View>
                  )}
                />

                {errors.response && <Text style={GlobalStyle.form__input__validation}>Response is required.</Text>}                
              </View>

              <View style={[GlobalStyle.form__column, GlobalStyle.form__column__full]}>
                <Controller
                  control={control}
                  name="commentMandatory"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View>
                      <Text style={GlobalStyle.form__column__text}>Comment Required</Text>
                      <Checkbox 
                        style={GlobalStyle.form__column__input} 
                        onValueChange={onChange}
                        value={value}>
                      </Checkbox>
                    </View>
                  )}
                />
              </View>

              <View style={[GlobalStyle.form__column, GlobalStyle.form__column__full]}>
                <Controller
                  control={control}
                  name="isPassDefault"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View>
                      <Text style={GlobalStyle.form__column__text}>Default Pass</Text>
                      <Checkbox 
                        style={GlobalStyle.form__column__input} 
                        onValueChange={onChange}
                        value={value}>
                      </Checkbox>
                    </View>
                  )}
                />
              </View>

              <View style={styles.form__buttons}>
                <TouchableOpacity style={styles.form__add__btn} onPress={handleSubmit(onSubmit)}>
                  <Text style={styles.form__add__btn__text}>{id == null ? 'Add' : 'Update'}</Text>
                </TouchableOpacity>

                {id != null &&
                  <TouchableOpacity style={styles.form__add__btn} onPress={() => reset()}>
                    <Text style={styles.form__add__btn__text}>Clear</Text>
                  </TouchableOpacity>
                }
              </View>
            </View>
          </View>
        }
      </View>

      {question == null &&
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Text style={{ fontSize: 28, fontStyle: 'italic' }}>Select Question</Text>
        </View>
      }

      {question != null &&
        <View>
          <DataTable style={GlobalStyle.table}>
            <DataTable.Header>
              <DataTable.Title>Response</DataTable.Title>
              <DataTable.Title>Position</DataTable.Title>
              <DataTable.Title>Default Pass</DataTable.Title>
              <DataTable.Title>Comment required</DataTable.Title>
              <DataTable.Title>Actions</DataTable.Title>
            </DataTable.Header>

            {responsesResult?.data?.list.map((element: any) => {
              return (
                <DataTable.Row key={element.id}>
                  <DataTable.Cell>{element.response}</DataTable.Cell>
                  <DataTable.Cell>
                    {element.position}
                    <TouchableOpacity style={styles.action} onPress={() => decreasePosition(element)}><Ionicons name="chevron-up-outline" size={24} color="black" /></TouchableOpacity>
                    <TouchableOpacity style={styles.action} onPress={() => increasePosition(element)}><Ionicons name="chevron-down-outline" size={24} color="black" /></TouchableOpacity>
                  </DataTable.Cell>
                  <DataTable.Cell>{element.isPassDefault ? 'Yes' : 'No'}</DataTable.Cell>
                  <DataTable.Cell>{element.commentMandatory ? 'Yes' : 'No'}</DataTable.Cell>
                  <DataTable.Cell>
                    <View style={styles.table__actions}>
                      <TouchableOpacity style={styles.action} onPress={() => edit(element)}><Feather name="edit" size={20} color="black" /></TouchableOpacity>
                      <TouchableOpacity style={styles.action} onPress={() => remove(element)}><Feather name="trash" size={20} color="black" /></TouchableOpacity>
                    </View>
                  </DataTable.Cell>
                </DataTable.Row>
              )
            })}
          </DataTable>

          <PaginationComponent
            numberOfItemsPerPage={numberOfItemsPerPage}
            setNumberOfItemsPerPage={setNumberOfItemsPerPage}
            page={page}
            setPage={setPage}
            fetchResult={responsesResult}
            loading={loading}
            setLoading={setLoading}
          />
        </View>
      }
    </View>
  )
}

export default function CategoryQuestions({ route }: { route: any }) {
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [selectedQuestion, setSelectedQuestion] = useState<any>(null);
  const navigation = useNavigation();

  useEffect(() => {
    if (route.params && route.params.category) {
      setSelectedCategory(route.params.category);
    }
  }, [route.params]);

  return (
    <View style={styles.container}>
      <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => navigation.goBack()}>
          <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>Categories</Text>
      </View>

      <View style={GlobalStyle.form}>
        <View style={styles.service__content}>
          <View style={styles.service__block}>
            <CategoryQuestionsManage category={selectedCategory} onSelected={(question) => setSelectedQuestion(question)} />
          </View>
          <View style={styles.service__block}>
            <CategoryQuestionsResponsesManage question={selectedQuestion} />
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  service__content: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10
  },
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },

  service__block: {
    flex: 1,
    padding: 10,
    backgroundColor: '#FFF',
    margin: 10,
    borderWidth: 1,
    borderColor: '#CECECE'
  },

  add__new__link: {
    borderBottomColor: '#CECECE',
    borderBottomWidth: 1,
    paddingBottom: 10,
    marginBottom: 0,
  },

  form: {
    borderBottomWidth: 1,
    borderColor: '#CECECE',
    paddingBottom: 20,
    marginBottom: 10
  },

  form__buttons: {
    width: 150,
    flexDirection: 'row',
  },

  form__add__btn: {
    backgroundColor: PRIMARY_COLOUR,
    flex: 1,
    height: 31,
    alignSelf: 'flex-end',
    textAlign: 'center',
    marginLeft: 10,
    marginBottom: 5,

    alignItems: 'center',
    justifyContent: 'center',
  },

  form__add__btn__text: {
    color: '#FFF',
  },

  table__actions: {
    flexDirection: 'row',
  },

  action: {
    paddingLeft: 5,
    paddingRight: 5,
  }
});
