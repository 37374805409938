import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, Image, TouchableOpacity, TextInput, Dimensions, Platform } from "react-native";
import { MaterialCommunityIcons, AntDesign, EvilIcons, MaterialIcons, FontAwesome, Feather } from '@expo/vector-icons';
import { Controller, useForm } from "react-hook-form";
import GlobalStyle, { PRIMARY_COLOUR } from "../../constants/GlobalStyle";
import { Picker } from "@react-native-picker/picker";
import { colours } from "../../config";
import Checkbox from "expo-checkbox";
import RNPickerSelect from 'react-native-picker-select';

const isMobileScreen = Dimensions.get("screen").width < 480;

export function EquipmentExtra({ title, extras, onAddExtra, onRemoveExtra }) {
  const [extrasList, setExtrasList] = useState(extras);

  useEffect(() => {
    setExtrasList(extras);
  }, [extras])

  const updateSerial = (extra, text) => {
    extra.serialNum = text;
    setExtrasList([...extrasList]);
  }

  const updateSerial2 = (extra, text) => {
    extra.serialNum2 = text;
    setExtrasList([...extrasList]);
  }

  const updateMake = (extra, text) => {
    extra.make = text;
    setExtrasList([...extrasList]);
  }

  const updateMake2 = (extra, text) => {
    extra.make2 = text;
    setExtrasList([...extrasList]);
  }

  const updateType = (extra, text) => {
    extra.type = text;
    setExtrasList([...extrasList]);
  }

  const updateType2 = (extra, text) => {
    extra.type2 = text;
    setExtrasList([...extrasList]);
  }

  return (
    <View style={styles.equipment__extra__block}>
      <View style={styles.equipment__extra__title__block}>
        <Text style={styles.equipment__extra__title}>{title}</Text>
        <TouchableOpacity onPress={() => onAddExtra()}>
          <Text style={styles.equipment__extra__title__add__button__text}>Add</Text>
        </TouchableOpacity>
      </View>
      {isMobileScreen ? (
        <View>
          {extrasList.map((value, index) => {
            return (
              <View key={index} style={{ display: 'flex', padding: 5 }}>
                <View style={styles.form__rows}>
                  <Text style={styles.form__label} >Serial Number (*)</Text>
                  <TextInput
                    multiline={true}
                    style={styles.form__input}
                    onChangeText={(text) => updateSerial(value, text)}
                    value={value.serialNum}>
                  </TextInput>
                </View>

                <View style={styles.form__rows}>
                  <Text style={styles.form__label}>Make (*)</Text>
                  <TextInput
                    multiline={true}
                    style={styles.form__input}
                    onChangeText={(text) => updateMake(value, text)}
                    value={value.make}>
                  </TextInput>
                </View>

                <View style={styles.form__rows}>
                  <Text style={styles.form__label}>Type (*)</Text>
                  <TextInput
                    multiline={true}
                    style={styles.form__input}
                    onChangeText={(text) => updateType(value, text)}
                    value={value.type}>
                  </TextInput>
                </View>

                <View style={styles.form__rows}>
                  <Text style={styles.form__label}>Serial Number 2 (*)</Text>
                  <TextInput
                    multiline={true}
                    style={styles.form__input}
                    onChangeText={(text) => updateSerial2(value, text)}
                    value={value.serialNum2}>
                  </TextInput>
                </View>

                <View style={styles.form__rows}>
                  <Text style={styles.form__label}>Make 2 (*)</Text>
                  <TextInput
                    multiline={true}
                    style={styles.form__input}
                    onChangeText={(text) => updateMake2(value, text)}
                    value={value.make2}>
                  </TextInput>
                </View>

                <View style={styles.form__rows}>
                  <Text style={styles.form__label}>Type 2 (*)</Text>
                  <TextInput
                    multiline={true}
                    style={styles.form__input}
                    onChangeText={(text) => updateType2(value, text)}
                    value={value.type2}>
                  </TextInput>
                </View>

                <TouchableOpacity onPress={() => onRemoveExtra(value)} style={{ marginTop: 10, flex: 1, backgroundColor: '#ebebeb', padding: 10, alignItems: 'center', alignSelf: 'flex-end' }}>
                  <Text style={{ color: '#000' }}>Remove</Text>
                </TouchableOpacity>
              </View>
            )
          })}
        </View>
      ) : (
        <View>
          {extrasList.map((value, index) => {
            return (
              <View key={index} style={{ flexDirection: 'row', padding: 5, flex: 1 }}>
                <View style={{ marginRight: 5, flex: 1 }}>
                  <Text style={GlobalStyle.form__column__text}>Serial Number (*)</Text>
                  <TextInput
                    multiline={true}
                    style={GlobalStyle.form__column__input}
                    onChangeText={(text) => updateSerial(value, text)}
                    value={value.serialNum}>
                  </TextInput>
                </View>

                <View style={{ marginRight: 5, flex: 1 }}>
                  <Text style={GlobalStyle.form__column__text}>Make (*)</Text>
                  <TextInput
                    multiline={true}
                    style={GlobalStyle.form__column__input}
                    onChangeText={(text) => updateMake(value, text)}
                    value={value.make}>
                  </TextInput>
                </View>

                <View style={{ marginRight: 5, flex: 1 }}>
                  <Text style={GlobalStyle.form__column__text}>Type (*)</Text>
                  <TextInput
                    multiline={true}
                    style={GlobalStyle.form__column__input}
                    onChangeText={(text) => updateType(value, text)}
                    value={value.type}>
                  </TextInput>
                </View>

                <View style={{ marginRight: 5, flex: 1 }}>
                  <Text style={GlobalStyle.form__column__text}>Serial Number 2 (*)</Text>
                  <TextInput
                    multiline={true}
                    style={GlobalStyle.form__column__input}
                    onChangeText={(text) => updateSerial2(value, text)}
                    value={value.serialNum2}>
                  </TextInput>
                </View>

                <View style={{ marginRight: 5, flex: 1 }}>
                  <Text style={GlobalStyle.form__column__text}>Make 2 (*)</Text>
                  <TextInput
                    multiline={true}
                    style={GlobalStyle.form__column__input}
                    onChangeText={(text) => updateMake2(value, text)}
                    value={value.make2}>
                  </TextInput>
                </View>

                <View style={{ marginRight: 5, flex: 1 }}>
                  <Text style={GlobalStyle.form__column__text}>Type 2 (*)</Text>
                  <TextInput
                    multiline={true}
                    style={GlobalStyle.form__column__input}
                    onChangeText={(text) => updateType2(value, text)}
                    value={value.type2}>
                  </TextInput>
                </View>

                <TouchableOpacity onPress={() => onRemoveExtra(value)} style={{ flex: 1, backgroundColor: '#ebebeb', padding: 10, alignItems: 'center', alignSelf: 'flex-end' }}>
                  <Text style={{ color: '#000' }}>Remove</Text>
                </TouchableOpacity>
              </View>
            )
          })}
        </View>
      )}
    </View>
  )
}

export const EquipmentManageControl = ({ equipment, companies, locations, subLocations, masterCategories, categories, categoryFields, manufacturers, onCancel, onSubmit, hideMenu = false, disablePickers = false, docPermission = true }) => {
  const [selectedMasterCategoryName, setSelectedMasterCategoryName] = React.useState<string>("");
  const [selectedCategoryName, setSelectedCategoryName] = React.useState<string>("");
  const [equipmentFields, setEquipmentFields] = React.useState<string>([]);

  const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      description: "",
      serialNumber: "",
      assetNumber: "",
      dateOfManufacture: "",
      proofLoad: "",
      swl: "",
      companyId: "",
      locationId: "",
      subLocationId: null,
      categoryId: "",
      manufacturerId: "",

      numberOfHoists: "0",
      hoistType: "",
      abusNo: "",
      yearOfCommissioning: "",
      masterCategoryId: "",
      rfid: "",

      doc: false,
      transposedStandards: "",
      nationalStandards: "",
      dateOfFirstUse: "",
      scrapped: false,

      isCraneRadio: false,

      equipmentExtras: [],
      equipmentFields: [],

      safetyDeviceFitted: "",
      updateDOCHistory: false,
      updateHistory: false,
    }
  });

  const equipmentId = watch("id");
  const companyId = watch("companyId");
  const masterCategoryId = watch("masterCategoryId");
  const categoryId = watch("categoryId");
  const equipmentExtras = watch("equipmentExtras");
  const equipFields = watch("equipmentFields");
  const doc = watch("doc");
  const isCraneRadio = watch("isCraneRadio");
  const locationId = watch("locationId");
  const rfid = watch("rfid");

  const updateEquipmentField = (field, value) => {
    field.fieldValue = value;
    setEquipmentFields([...equipmentFields]);

    setValue('equipmentFields', equipmentFields);
  }

  const loadCategoryFields = (categoryId) => {
    if (categoryFields == null) return;

    var fieldsToSet = categoryFields.filter(c => c.categoryId == categoryId).map(c => ({
      fieldName: c.fieldName,
      fieldValue: equipFields.find(e => e.categoryFieldId == c.id)?.fieldValue,
      categoryFieldId: c.id
    }));

    setEquipmentFields(fieldsToSet);
    setValue('equipmentFields', fieldsToSet);
  }

  useEffect(() => {
    if (masterCategories == null || masterCategoryId == null || masterCategoryId == "") {
      setSelectedMasterCategoryName("");
      return;
    }
    var masterCategory = masterCategories.find(c => c.id == masterCategoryId);
    setSelectedMasterCategoryName(masterCategory.name);

  }, [masterCategoryId])

  useEffect(() => {
    if (categories == null || categoryId == null || categoryId == "") {
      setSelectedCategoryName("");
      return;
    }

    var category = categories.find(c => c.id == categoryId);
    if (category == null) return;

    setSelectedCategoryName(category.name);
    loadCategoryFields(category.id);
    // watch for category fields chance and reload category fields if data
    // is set after initial load
  }, [categoryId, categoryFields]);



  useEffect(() => {
    if (equipment && subLocations) {
      var data = equipment;

      setValue("id", data.id);
      setValue("description", data.description);
      setValue("serialNumber", data.serialNumber);
      setValue("assetNumber", data.assetNumber);
      setValue("dateOfManufacture", data.dateOfManufacture);
      setValue("proofLoad", data.proofLoad);
      setValue("swl", data.swl);
      setValue("companyId", data.companyId);
      setValue("locationId", data.locationId);
      const isValidSublocation = subLocations && subLocations?.some(sl => sl.id === data.subLocationId);
      setValue("subLocationId", isValidSublocation ? data.subLocationId : null);
      setValue("masterCategoryId", data.masterCategoryId);
      setValue("categoryId", data.categoryId);
      setValue("rfid", data.rfid);
      setValue("manufacturerId", data.manufacturerId);
      setValue("doc", data.doc);
      setValue("transposedStandards", data.transposedStandards);
      setValue("nationalStandards", data.nationalStandards);
      setValue("dateOfFirstUse", data.dateOfFirstUse);
      setValue("equipmentExtras", data.equipmentExtras == null ? [] : data.equipmentExtras);
      setValue("equipmentFields", data.equipmentFields == null ? [] : data.equipmentFields);

      setValue("numberOfHoists", data.numberOfHoists != null ? data.numberOfHoists.toString() : 0);
      setValue("hoistType", data.hoistType);
      setValue("abusNo", data.abusNo);
      setValue("yearOfCommissioning", data.yearOfCommissioning);
      setValue("isCraneRadio", data.isCraneRadio);
      setValue("safetyDeviceFitted", data.safetyDeviceFitted);
    }
  }, [equipment, subLocations]);

  const addEquipmentExtra = (type) => {
    var equipExtras = getValues("equipmentExtras");
    equipExtras.push({
      serialNum: "",
      make: "",
      equipmentExtraType: type
    });

    setValue("equipmentExtras", equipExtras)
  }

  const removeEquipmentExtra = (extra) => {
    var equipExtras = getValues("equipmentExtras");
    equipExtras.splice(equipExtras.indexOf(extra), 1);

    setValue("equipmentExtras", equipExtras)
  }

  const submit = async (data) => {
    onSubmit(data, data.id == null);
  };

  const saveAndDuplicate = async (data) => {
    onSubmit(data, data.id == null, false);
    setValue("serialNumber", "");
  }

  const isEdit = equipmentId !== null;
  const displayDuplicateButton = !isEdit

  const isPssr = selectedMasterCategoryName == "PSSR";
  return (
    <View style={styles.container}>
      {!hideMenu && <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => onCancel()}>
          <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>{getValues("id") == null ? "CREATE EQUIPMENT" : "UPDATE EQUIPMENT"}</Text>
      </View>
      }

      {isMobileScreen ? (
        <View style={GlobalStyle.form}>
          <View>
            <Controller
              control={control}
              name="companyId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={styles.form__rows}>
                  <Text style={styles.form__label}>Company (*)</Text>

                  {Platform.OS === "ios" ? (
                    <View style={styles.form__input__IOS}>
                      <RNPickerSelect
                        value={value}
                        onValueChange={(itemValue) => onChange(itemValue)}
                        items={companies.map((value) => ({ label: value.name, value: value.id }))}
                        style={styles}
                        disabled={disablePickers}
                      />
                    </View>
                  ) : (
                    <View style={styles.form__input}>
                      <Picker
                        selectedValue={value}
                        onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                        itemStyle={{ height: 50 }}
                        enabled={!disablePickers}>

                        <Picker.Item label="Please Select" value="" />

                        {companies != null && companies.map((value, index) => {
                          return (
                            <Picker.Item key={value.id} label={value.name} value={value.id} />
                          )
                        })}
                      </Picker>
                    </View>
                  )}


                </View>
              )}
            />

            {errors.companyId && <Text style={GlobalStyle.form__input__validation}>Company is required.</Text>}
          </View>

          <View>
            <Controller
              control={control}
              name="locationId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={styles.form__rows}>
                  <Text style={styles.form__label}>Location (*)</Text>
                  {Platform.OS === "ios" ? (
                    <View style={styles.form__input__IOS}>
                      <RNPickerSelect
                        value={value}
                        onValueChange={(itemValue) => onChange(itemValue)}
                        items={locations.filter(l => l.companyId == companyId).map((value) => ({ label: value.name, value: value.id }))}
                        style={styles}
                        disabled={disablePickers}
                      />
                    </View>
                  ) :

                    <View style={styles.form__input}>
                      <Picker
                        style={GlobalStyle.form__column__input}
                        selectedValue={value}
                        onValueChange={(itemValue, itemIndex) => { onChange(itemValue); }}
                        itemStyle={{ height: 50 }}
                        enabled={!disablePickers}>

                        <Picker.Item label="Please Select" value="" />

                        {locations != null && companyId != "" && locations.filter(l => l.companyId == companyId).map((value, index) => {
                          return (
                            <Picker.Item key={value.id} label={value.name} value={value.id} />
                          )
                        })}
                      </Picker>
                    </View>
                  }
                </View>
              )}
            />

            {errors.locationId && <Text style={GlobalStyle.form__input__validation}>Location is required.</Text>}
          </View>

          <View>
            <Controller
              control={control}
              name="subLocationId"
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={styles.form__rows}>
                  <Text style={styles.form__label}>Sub Location</Text>

                  {Platform.OS === "ios" ? (
                    <View style={styles.form__input__IOS}>
                      <RNPickerSelect
                        value={value}
                        onValueChange={(itemValue) => onChange(itemValue)}
                        items={subLocations.filter(l => l.locationId == locationId).map((value) => ({ label: value.name, value: value.id }))}
                        style={styles}
                      />
                    </View>
                  ) :
                    (<View style={styles.form__input}>
                      <Picker
                        style={GlobalStyle.form__column__input}
                        selectedValue={value}
                        onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                        itemStyle={{ height: 50 }}>

                        <Picker.Item label={"Optional"} value="" />

                        {subLocations != null && locationId != "" && subLocations.filter(l => l.locationId == locationId).map((value, index) => {
                          return (
                            <Picker.Item key={value.id} label={value.name} value={value.id} />
                          )
                        })}
                      </Picker>
                    </View>)
                  }
                </View>
              )}
            />
          </View>

          <View>
            <Controller
              control={control}
              rules={{ required: true }}
              name="serialNumber"
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={styles.form__rows}>
                  <Text style={styles.form__label}>SN (*)</Text>
                  <TextInput
                    multiline={true}
                    style={styles.form__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.serialNumber && <Text style={GlobalStyle.form__input__validation}>Serial Number is required.</Text>}
          </View>

          <View>
            <Controller
              control={control}
              rules={{ required: true }}
              name="description"
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={styles.form__rows}>
                  <Text style={styles.form__label}>Desc. (*)</Text>
                  <TextInput
                    multiline={true}
                    style={styles.form__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.description && <Text style={GlobalStyle.form__input__validation}>Description is required.</Text>}
          </View>

          <View>
            <Controller
              control={control}
              name="assetNumber"
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={styles.form__rows}>
                  <Text style={styles.form__label}>Asset</Text>
                  <TextInput
                    multiline={true}
                    style={styles.form__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>

          <View>
            <Controller
              control={control}
              name="dateOfManufacture"
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={styles.form__rows}>
                  <Text style={styles.form__label}>DOM</Text>
                  <TextInput
                    multiline={true}
                    style={styles.form__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    keyboardType={"number-pad"}>
                  </TextInput>
                </View>
              )}
            />
          </View>

          <View>
            <Controller
              control={control}
              name="proofLoad"
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={styles.form__rows}>
                  <Text style={styles.form__label}>Proof Load</Text>
                  <TextInput
                    multiline={true}
                    style={styles.form__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>

          <View>
            <Controller
              control={control}
              name="swl"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={styles.form__rows}>
                  <Text style={styles.form__label}>{(isPssr) ? "SOL" : "SWL" + " (*)"}</Text>
                  <TextInput
                    multiline={true}
                    style={styles.form__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.swl && <Text style={GlobalStyle.form__input__validation}>{(isPssr) ? "SOL" : "SWL" + " is required."}</Text>}
          </View>

          <View>
            <Controller
              control={control}
              name="manufacturerId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={styles.form__rows}>
                  <Text style={styles.form__label}>Manuf. (*)</Text>

                  {Platform.OS === "ios" ? (
                    <View style={styles.form__input__IOS}>
                      <RNPickerSelect
                        value={value}
                        onValueChange={(itemValue) => onChange(itemValue)}
                        items={manufacturers.map((value) => ({ label: value.name, value: value.id }))}
                        style={styles}
                      />
                    </View>
                  ) :
                    (<View style={styles.form__input}>
                      <Picker
                        selectedValue={value}
                        onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                        itemStyle={{ height: 50 }}>

                        <Picker.Item label="Please Select" value="" />

                        {manufacturers != null && manufacturers.map((value, index) => {
                          return (
                            <Picker.Item key={value.id} label={value.name} value={value.id} />
                          )
                        })}
                      </Picker>
                    </View>)
                  }
                </View>
              )}
            />

            {errors.manufacturerId && <Text style={GlobalStyle.form__input__validation}>Manufacturer is required.</Text>}
          </View>

          <View>
            <Controller
              control={control}
              name="masterCategoryId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={styles.form__rows}>
                  <Text style={styles.form__label}>Master (*)</Text>

                  {Platform.OS === "ios" ? (
                    <View style={styles.form__input__IOS}>
                      <RNPickerSelect
                        value={value}
                        onValueChange={(itemValue) => onChange(itemValue)}
                        items={masterCategories.map((value) => ({ label: value.name, value: value.id }))}
                        style={styles}
                      />
                    </View>
                  ) :
                    (<View style={styles.form__input}>
                      <Picker
                        style={GlobalStyle.form__column__input}
                        selectedValue={value}
                        onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                        itemStyle={{ height: 50 }}>

                        <Picker.Item label="Please Select" value="" />

                        {masterCategories != null && masterCategories.map((value, index) => {
                          return (
                            <Picker.Item key={value.id} label={value.name} value={value.id} />
                          )
                        })}
                      </Picker>
                    </View>)
                  }
                </View>
              )}
            />

            {errors.masterCategoryId && <Text style={GlobalStyle.form__input__validation}>Master Category is required.</Text>}
          </View>

          <View>
            <Controller
              control={control}
              name="categoryId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={styles.form__rows}>
                  <Text style={styles.form__label}>Category (*)</Text>

                  {Platform.OS === "ios" ? (
                    <View style={styles.form__input__IOS}>
                      <RNPickerSelect
                        value={value}
                        onValueChange={(itemValue) => onChange(itemValue)}
                        items={categories.filter(l => l.masterCategoryId == masterCategoryId).map((value) => ({ label: value.name, value: value.id }))}
                        style={styles}
                      />
                    </View>
                  ) :
                    (<View style={styles.form__input}>
                      <Picker
                        selectedValue={value}
                        onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                        itemStyle={{ height: 50 }}>

                        <Picker.Item label="Please Select" value="" />

                        {categories != null && masterCategoryId != "" && categories.filter(l => l.masterCategoryId == masterCategoryId).map((value, index) => {
                          return (
                            <Picker.Item key={value.id} label={value.name} value={value.id} />
                          )
                        })}
                      </Picker>
                    </View>)
                  }
                </View>
              )}
            />

            {errors.categoryId && <Text style={GlobalStyle.form__input__validation}>Category is required.</Text>}
          </View>

          <View>
            <Controller
              control={control}
              name="rfid"
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={styles.form__rows}>
                  <Text style={styles.form__label}>RFID/QR</Text>
                  <TextInput
                    multiline={true}
                    style={styles.form__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>

          {equipmentFields != null && equipmentFields.length > 0 &&
            <View style={GlobalStyle.form__row}>
              {equipmentFields.map((field, index) => {
                return (
                  <View key={field.id} style={[GlobalStyle.form__column, GlobalStyle.form__column__third]}>
                    <Text style={GlobalStyle.form__column__text}>{field.fieldName} {index % 3 == 0}</Text>
                    <TextInput
                      multiline={true}
                      style={GlobalStyle.form__column__input}
                      onChangeText={(text) => updateEquipmentField(field, text)}
                      value={field.fieldValue}>
                    </TextInput>
                  </View>
                )
              })}
            </View>
          }

          {(selectedMasterCategoryName == "PUWER 1998" || selectedMasterCategoryName == "LOLER 1998") && docPermission &&
            <View>
              <View>
                <View >
                  <Controller
                    control={control}
                    name="doc"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <View>
                        <Text style={styles.form__label}>DOC</Text>
                        <Checkbox
                          style={GlobalStyle.form__column__input}
                          onValueChange={onChange}
                          value={value}>
                        </Checkbox>
                      </View>
                    )}
                  />

                  {errors.transposedStandards && <Text style={GlobalStyle.form__input__validation}>Required.</Text>}
                </View>

                {doc == true &&
                  <View>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="transposedStandards"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <View style={styles.form__rows}>
                          <Text style={styles.form__label}>The transposed harmonised standards used (specify): (*)</Text>
                          <TextInput
                            multiline={true}
                            numberOfLines={5}
                            style={styles.form__input}
                            onBlur={onBlur}
                            onChangeText={onChange}
                            value={value}>
                          </TextInput>
                        </View>
                      )}
                    />

                    {errors.transposedStandards && <Text style={GlobalStyle.form__input__validation}>Required.</Text>}
                  </View>
                }

                {doc == true &&
                  <View>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="nationalStandards"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <View style={styles.form__rows}>
                          <Text style={styles.form__label}>The national standards and any technical specifications used (specify): (*)</Text>
                          <TextInput
                            numberOfLines={5}
                            multiline={true}
                            style={styles.form__input}
                            onBlur={onBlur}
                            onChangeText={onChange}
                            value={value}>
                          </TextInput>
                        </View>
                      )}
                    />

                    {errors.transposedStandards && <Text style={GlobalStyle.form__input__validation}>Required.</Text>}
                  </View>
                }
              </View>
            </View>
          }

          {(selectedMasterCategoryName == "PPE") &&
            <View>
              <View>
                <View>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="dateOfFirstUse"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <View style={styles.form__rows}>
                        <Text style={styles.form__label}>Date of First Use (*)</Text>
                        <TextInput
                          multiline={true}
                          style={styles.form__input}
                          onBlur={onBlur}
                          onChangeText={onChange}
                          value={value}>
                        </TextInput>
                      </View>
                    )}
                  />

                  {errors.transposedStandards && <Text style={GlobalStyle.form__input__validation}>Required.</Text>}
                </View>
              </View>
            </View>
          }

          {(isPssr) &&
            <View>
              <View style={GlobalStyle.form__row}>
                <View style={GlobalStyle.form__column}>
                  <Controller
                    control={control}
                    rules={{
                      required: {
                        value: watch("safetyDeviceFitted") === "",
                        message: "Required"
                      }
                    }}
                    name="safetyDeviceFitted"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <View>
                        <Text style={GlobalStyle.form__column__text}>Safety Device Fitted (*)</Text>
                        {Platform.OS === "ios" ? (
                          <View style={styles.form__input__IOS}>
                            <RNPickerSelect
                              value={value}
                              onValueChange={(itemValue) => onChange(itemValue)}
                              items={[
                                { label: "Yes", value: "Yes" },
                                { label: "No", value: "No" },
                                { label: "N/A", value: "N/A" }
                              ]}
                              style={styles}
                            />
                          </View>
                        ) :
                          (<View style={styles.form__input}>
                            <Picker
                              style={GlobalStyle.form__column__input}
                              selectedValue={value}
                              onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                              itemStyle={{ height: 50 }}>
                              <Picker.Item label="Please Select" value="" />
                              <Picker.Item label="Yes" value="Yes" />
                              <Picker.Item label="No" value="No" />
                              <Picker.Item label="N/A" value="N/A" />
                            </Picker>
                          </View>)
                        }
                      </View>
                    )}
                  />

                  {errors.safetyDeviceFitted && <Text style={GlobalStyle.form__input__validation}>{errors.safetyDeviceFitted.message}</Text>}
                </View>
              </View>
            </View>
          }

          {selectedMasterCategoryName == "PUWER 1998" &&
            <View>
              <View>
                <View>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="numberOfHoists"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <View style={styles.form__rows}>
                        <Text style={styles.form__label}>Number of Hoists</Text>

                        {Platform.OS === "ios" ? (
                          <View style={styles.form__input__IOS}>
                            <RNPickerSelect
                              value={value}
                              onValueChange={(itemValue) => onChange(itemValue)}
                              items={[
                                { label: "0", value: "0" },
                                { label: "1", value: "1" },
                                { label: "2", value: "2" }
                              ]}
                              style={styles}
                            />
                          </View>
                        ) :
                          (<View style={styles.form__input}>
                            <Picker
                              style={GlobalStyle.form__column__input}
                              selectedValue={value}
                              onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                              itemStyle={{ height: 50 }}>

                              <Picker.Item label="0" value="0" />
                              <Picker.Item label="1" value="1" />
                              <Picker.Item label="2" value="2" />
                            </Picker>
                          </View>)
                        }
                      </View>
                    )}
                  />

                  {errors.numberOfHoists && <Text style={GlobalStyle.form__input__validation}>Number of Hoists is required.</Text>}
                </View>

                <View>
                  <Controller
                    control={control}
                    name="hoistType"
                    rules={{
                      required: {
                        value: watch("numberOfHoists") !== "0",
                        message: "Hoist Type is required."
                      }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <View style={styles.form__rows}>
                        <Text style={styles.form__label}>Hoist Type</Text>
                        <TextInput
                          multiline={true}
                          style={styles.form__input}
                          onBlur={onBlur}
                          onChangeText={onChange}
                          value={value}>
                        </TextInput>
                      </View>
                    )}
                  />
                  {errors.hoistType && (<Text style={GlobalStyle.form__input__validation}>{errors.hoistType.message}</Text>)}
                </View>

                <View>
                  <Controller
                    control={control}
                    name="yearOfCommissioning"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <View style={styles.form__rows}>
                        <Text style={styles.form__label}>Year Of Commissioning</Text>
                        <TextInput
                          multiline={true}
                          style={styles.form__input}
                          onBlur={onBlur}
                          onChangeText={onChange}
                          value={value}>
                        </TextInput>
                      </View>
                    )}
                  />
                </View>
              </View>

              <View style={GlobalStyle.form__row}>
                {selectedCategoryName == "Crane" &&
                  <View style={GlobalStyle.form__column}>
                    <Controller
                      control={control}
                      name="isCraneRadio"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <View>
                          <Text style={GlobalStyle.form__column__text}>Radio or Pendant ({isCraneRadio ? 'Radio' : 'Pendant'})</Text>
                          <Checkbox
                            style={GlobalStyle.form__column__input}
                            onValueChange={onChange}
                            value={isCraneRadio ? true : false}>
                          </Checkbox>
                        </View>
                      )}
                    />
                  </View>
                }
              </View>
            </View>
          }

          {selectedMasterCategoryName != null && selectedMasterCategoryName == "PUWER 1998" &&
            <View style={{ marginTop: 10 }}>
              <View style={styles.equipment__extra__list}>
                <View>
                  <EquipmentExtra
                    title={'Hoist(s)'}
                    extras={equipmentExtras.filter(e => e.equipmentExtraType == 0)}
                    onAddExtra={() => addEquipmentExtra(0)}
                    onRemoveExtra={(extra) => removeEquipmentExtra(extra)} />
                </View>

                <View>
                  <EquipmentExtra
                    title={'Motors Cross Travel(s)'}
                    extras={equipmentExtras.filter(e => e.equipmentExtraType == 1)}
                    onAddExtra={() => addEquipmentExtra(1)}
                    onRemoveExtra={(extra) => removeEquipmentExtra(extra)} />
                </View>

                <View>
                  <EquipmentExtra
                    title={'Motors Long Travel(s)'}
                    extras={equipmentExtras.filter(e => e.equipmentExtraType == 2)}
                    onAddExtra={() => addEquipmentExtra(2)}
                    onRemoveExtra={(extra) => removeEquipmentExtra(extra)} />
                </View>
              </View>
            </View>
          }

          <View style={styles.form__buttons__container}>
            <TouchableOpacity style={[GlobalStyle.form__submit__button, styles.form__button]} onPress={handleSubmit(submit)}>
              <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
            </TouchableOpacity>
            {displayDuplicateButton &&
              <TouchableOpacity style={GlobalStyle.form__submit__button} onPress={handleSubmit(saveAndDuplicate)}>
                <Text style={GlobalStyle.form__submit__button__text}>SAVE & DUPLICATE</Text>
              </TouchableOpacity>}
          </View>
        </View>
      ) : (
        <View style={GlobalStyle.form}>
          <View style={GlobalStyle.form__row}>
            <View style={GlobalStyle.form__column}>
              <Controller
                control={control}
                name="companyId"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <Text style={GlobalStyle.form__column__text}>Company (*)</Text>
                    <Picker
                      style={GlobalStyle.form__column__input}
                      selectedValue={value}
                      onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                      itemStyle={{ height: 50 }}
                      enabled={!disablePickers}>

                      <Picker.Item label="Please Select" value="" />

                      {companies != null && companies.map((value, index) => {
                        return (
                          <Picker.Item key={value.id} label={value.name} value={value.id} />
                        )
                      })}
                    </Picker>
                  </View>
                )}
              />

              {errors.companyId && <Text style={GlobalStyle.form__input__validation}>Company is required.</Text>}
            </View>

            <View style={GlobalStyle.form__column}>
              <Controller
                control={control}
                name="locationId"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <Text style={GlobalStyle.form__column__text}>Location (*)</Text>
                    <Picker
                      style={GlobalStyle.form__column__input}
                      selectedValue={value}
                      onValueChange={(itemValue, itemIndex) => { onChange(itemValue); setValue("subLocationId", null) }}
                      itemStyle={{ height: 50 }}
                      enabled={!disablePickers}>

                      <Picker.Item label="Please Select" value="" />

                      {locations != null && companyId != "" && locations.filter(l => l.companyId == companyId).map((value, index) => {
                        return (
                          <Picker.Item key={value.id} label={value.name} value={value.id} />
                        )
                      })}
                    </Picker>
                  </View>
                )}
              />

              {errors.locationId && <Text style={GlobalStyle.form__input__validation}>Location is required.</Text>}
            </View>

            <View style={GlobalStyle.form__column}>
              <Controller
                control={control}
                name="subLocationId"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <Text style={GlobalStyle.form__column__text}>Sub Location</Text>
                    <Picker
                      style={GlobalStyle.form__column__input}
                      selectedValue={value}
                      onValueChange={(itemValue, itemIndex) => {
                        onChange(itemValue === "" ? null : itemValue);
                      }}
                      itemStyle={{ height: 50 }}>

                      <Picker.Item label={"Optional"} value="" />

                      {subLocations != null && locationId != "" && subLocations.filter(l => l.locationId == locationId).map((value, index) => {
                        return (
                          <Picker.Item key={value.id} label={value.name} value={value.id} />
                        )
                      })}
                    </Picker>
                  </View>
                )}
              />
            </View>
          </View>


          {/* ##### ROW ##### */}
          <View style={GlobalStyle.form__row}>
            <View style={GlobalStyle.form__column}>
              <Controller
                control={control}
                rules={{ required: true }}
                name="serialNumber"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <Text style={GlobalStyle.form__column__text}>Serial Number (*)</Text>
                    <TextInput
                      multiline={true}
                      style={GlobalStyle.form__column__input}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}>
                    </TextInput>
                  </View>
                )}
              />

              {errors.serialNumber && <Text style={GlobalStyle.form__input__validation}>Serial Number is required.</Text>}
            </View>

            <View style={GlobalStyle.form__column}>
              <Controller
                control={control}
                rules={{ required: true }}
                name="description"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <Text style={GlobalStyle.form__column__text}>Description (*)</Text>
                    <TextInput
                      multiline={true}
                      style={GlobalStyle.form__column__input}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}>
                    </TextInput>
                  </View>
                )}
              />

              {errors.description && <Text style={GlobalStyle.form__input__validation}>Description is required.</Text>}
            </View>

            <View style={GlobalStyle.form__column}>
              <Controller
                control={control}
                name="assetNumber"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <Text style={GlobalStyle.form__column__text}>Asset Number</Text>
                    <TextInput
                      multiline={true}
                      style={GlobalStyle.form__column__input}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}>
                    </TextInput>
                  </View>
                )}
              />
            </View>
          </View>

          <View style={GlobalStyle.form__row}>
            <View style={GlobalStyle.form__column}>
              <Controller
                control={control}
                name="dateOfManufacture"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <Text style={GlobalStyle.form__column__text}>Date Of Manufacture</Text>
                    <TextInput
                      multiline={true}
                      style={GlobalStyle.form__column__input}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}
                      keyboardType={"number-pad"}>
                    </TextInput>
                  </View>
                )}
              />
            </View>

            <View style={GlobalStyle.form__column}>
              <Controller
                control={control}
                name="proofLoad"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <Text style={GlobalStyle.form__column__text}>Proof Load</Text>
                    <TextInput
                      multiline={true}
                      style={GlobalStyle.form__column__input}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}>
                    </TextInput>
                  </View>
                )}
              />
            </View>

            <View style={GlobalStyle.form__column}>
              <Controller
                control={control}
                name="swl"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <Text style={GlobalStyle.form__column__text}>{isPssr ? "Safe Operating Limit" : "Safe Working Load"} (*)</Text>
                    <TextInput
                      multiline={true}
                      style={GlobalStyle.form__column__input}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}>
                    </TextInput>
                  </View>
                )}
              />

              {errors.swl && <Text style={GlobalStyle.form__input__validation}>{(isPssr) ? "SOL" : "SWL" + " is required."}</Text>}
            </View>
          </View>

          <View style={GlobalStyle.form__row}>
            <View style={GlobalStyle.form__column}>
              <Controller
                control={control}
                name="manufacturerId"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <Text style={GlobalStyle.form__column__text}>Manufacturer (*)</Text>
                    <Picker
                      style={GlobalStyle.form__column__input}
                      selectedValue={value}
                      onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                      itemStyle={{ height: 50 }}>

                      <Picker.Item label="Please Select" value="" />

                      {manufacturers != null && manufacturers.map((value, index) => {
                        return (
                          <Picker.Item key={value.id} label={value.name} value={value.id} />
                        )
                      })}
                    </Picker>
                  </View>
                )}
              />

              {errors.manufacturerId && <Text style={GlobalStyle.form__input__validation}>Manufacturer is required.</Text>}
            </View>

            <View style={GlobalStyle.form__column}>
              <Controller
                control={control}
                name="masterCategoryId"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <Text style={GlobalStyle.form__column__text}>Master Category (*)</Text>
                    <Picker
                      style={GlobalStyle.form__column__input}
                      selectedValue={value}
                      onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                      itemStyle={{ height: 50 }}>

                      <Picker.Item label="Please Select" value="" />

                      {masterCategories != null && masterCategories.map((value, index) => {
                        return (
                          <Picker.Item key={value.id} label={value.name} value={value.id} />
                        )
                      })}
                    </Picker>
                  </View>
                )}
              />

              {errors.masterCategoryId && <Text style={GlobalStyle.form__input__validation}>Master Category is required.</Text>}
            </View>

            <View style={GlobalStyle.form__column}>
              <Controller
                control={control}
                name="categoryId"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <Text style={GlobalStyle.form__column__text}>Category (*)</Text>
                    <Picker
                      style={GlobalStyle.form__column__input}
                      selectedValue={value}
                      onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                      itemStyle={{ height: 50 }}>

                      <Picker.Item label="Please Select" value="" />

                      {categories != null && masterCategoryId != "" && categories.filter(l => l.masterCategoryId == masterCategoryId).map((value, index) => {
                        return (
                          <Picker.Item key={value.id} label={value.name} value={value.id} />
                        )
                      })}
                    </Picker>
                  </View>
                )}
              />

              {errors.categoryId && <Text style={GlobalStyle.form__input__validation}>Category is required.</Text>}
            </View>
          </View>
          <View style={GlobalStyle.form__row}>
            <View style={GlobalStyle.form__column}>
              <Controller
                control={control}
                name="rfid"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <Text style={GlobalStyle.form__column__text}>RFID/QR</Text>
                    <TextInput
                      multiline={false}
                      style={GlobalStyle.form__column__input}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}>
                    </TextInput>
                  </View>
                )}
              />
            </View>
          </View>

          {equipmentFields != null && equipmentFields.length > 0 &&
            <View style={GlobalStyle.form__row}>
              {equipmentFields.map((field, index) => {
                return (
                  <View key={field.id} style={[GlobalStyle.form__column, GlobalStyle.form__column__third]}>
                    <Text style={GlobalStyle.form__column__text}>{field.fieldName} {index % 3 == 0}</Text>
                    <TextInput
                      multiline={true}
                      style={GlobalStyle.form__column__input}
                      onChangeText={(text) => updateEquipmentField(field, text)}
                      value={field.fieldValue}>
                    </TextInput>
                  </View>
                )
              })}
            </View>
          }

          {(selectedMasterCategoryName == "PUWER 1998" || selectedMasterCategoryName == "LOLER 1998") && docPermission &&
            <View>
              <View style={GlobalStyle.form__row}>
                <View style={GlobalStyle.form__column}>
                  <Controller
                    control={control}
                    name="doc"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <View>
                        <Text style={GlobalStyle.form__column__text}>DOC</Text>
                        <Checkbox
                          style={GlobalStyle.form__column__input}
                          onValueChange={onChange}
                          value={value}>
                        </Checkbox>
                      </View>
                    )}
                  />

                  {errors.transposedStandards && <Text style={GlobalStyle.form__input__validation}>Required.</Text>}
                </View>

                {doc == true &&
                  <View style={GlobalStyle.form__column}>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="transposedStandards"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <View>
                          <Text style={GlobalStyle.form__column__text}>The transposed harmonised standards used (specify): (*)</Text>
                          <TextInput
                            multiline={true}
                            style={GlobalStyle.form__column__input}
                            onBlur={onBlur}
                            onChangeText={onChange}
                            value={value}>
                          </TextInput>
                        </View>
                      )}
                    />

                    {errors.transposedStandards && <Text style={GlobalStyle.form__input__validation}>Required.</Text>}
                  </View>
                }

                {doc == true &&
                  <View style={GlobalStyle.form__column}>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="nationalStandards"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <View>
                          <Text style={GlobalStyle.form__column__text}>The national standards and any technical specifications used (specify): (*)</Text>
                          <TextInput
                            multiline={true}
                            style={GlobalStyle.form__column__input}
                            onBlur={onBlur}
                            onChangeText={onChange}
                            value={value}>
                          </TextInput>
                        </View>
                      )}
                    />

                    {errors.transposedStandards && <Text style={GlobalStyle.form__input__validation}>Required.</Text>}
                  </View>
                }
              </View>
            </View>
          }

          {(selectedMasterCategoryName == "PPE") &&
            <View>
              <View style={GlobalStyle.form__row}>
                <View style={GlobalStyle.form__column}>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="dateOfFirstUse"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <View>
                        <Text style={GlobalStyle.form__column__text}>Date of First Use (*)</Text>
                        <TextInput
                          multiline={true}
                          style={GlobalStyle.form__column__input}
                          onBlur={onBlur}
                          onChangeText={onChange}
                          value={value}>
                        </TextInput>
                      </View>
                    )}
                  />

                  {errors.transposedStandards && <Text style={GlobalStyle.form__input__validation}>Required.</Text>}
                </View>
              </View>
            </View>
          }
          {(isPssr) &&
            <View>
              <View style={GlobalStyle.form__row}>
                <View style={GlobalStyle.form__column}>
                  <Controller
                    control={control}
                    rules={{
                      required: {
                        value: watch("safetyDeviceFitted") === "",
                        message: "Required"
                      }
                    }}
                    name="safetyDeviceFitted"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <View>
                        <Text style={GlobalStyle.form__column__text}>Safety Device Fitted (*)</Text>
                        <Picker
                          style={GlobalStyle.form__column__input}
                          selectedValue={value}
                          onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                          itemStyle={{ height: 50 }}>
                          <Picker.Item label="Please Select" value="" />
                          <Picker.Item label="Yes" value="Yes" />
                          <Picker.Item label="No" value="No" />
                          <Picker.Item label="N/A" value="N/A" />
                        </Picker>
                      </View>
                    )}
                  />

                  {errors.safetyDeviceFitted && <Text style={GlobalStyle.form__input__validation}>{errors.safetyDeviceFitted.message}</Text>}
                </View>
              </View>
            </View>
          }

          {selectedMasterCategoryName == "PUWER 1998" &&
            <View>
              <View style={GlobalStyle.form__row}>
                <View style={GlobalStyle.form__column}>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="numberOfHoists"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <View>
                        <Text style={GlobalStyle.form__column__text}>Number of Hoists</Text>
                        <Picker
                          style={GlobalStyle.form__column__input}
                          selectedValue={value}
                          onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                          itemStyle={{ height: 50 }}>

                          <Picker.Item label="0" value="0" />
                          <Picker.Item label="1" value="1" />
                          <Picker.Item label="2" value="2" />
                        </Picker>
                      </View>
                    )}
                  />

                  {errors.numberOfHoists && <Text style={GlobalStyle.form__input__validation}>Number of Hoists is required.</Text>}
                </View>

                <View style={GlobalStyle.form__column}>
                  <Controller
                    control={control}
                    name="hoistType"
                    rules={{
                      required: {
                        value: watch("numberOfHoists") !== "0",
                        message: "Hoist Type is required."
                      }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <View>
                        <Text style={GlobalStyle.form__column__text}>Hoist Type</Text>
                        <TextInput
                          multiline={true}
                          style={GlobalStyle.form__column__input}
                          onBlur={onBlur}
                          onChangeText={onChange}
                          value={value}>
                        </TextInput>
                      </View>
                    )}
                  />
                  {errors.hoistType && (<Text style={GlobalStyle.form__input__validation}>{errors.hoistType.message}</Text>)}
                </View>

                <View style={GlobalStyle.form__column}>
                  <Controller
                    control={control}
                    name="yearOfCommissioning"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <View>
                        <Text style={GlobalStyle.form__column__text}>Year Of Commissioning</Text>
                        <TextInput
                          multiline={true}
                          style={GlobalStyle.form__column__input}
                          onBlur={onBlur}
                          onChangeText={onChange}
                          value={value}>
                        </TextInput>
                      </View>
                    )}
                  />
                </View>

                {/* <View style={GlobalStyle.form__column}>
                <Controller
                  control={control}
                  name="abusNo"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View>
                      <Text style={GlobalStyle.form__column__text}>Abus No</Text>
                      <TextInput 
                        style={GlobalStyle.form__column__input} 
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}>
                      </TextInput>
                    </View>
                  )}
                />
              </View> */}
              </View>

              <View style={GlobalStyle.form__row}>
                {selectedCategoryName == "Crane" &&
                  <View style={GlobalStyle.form__column}>
                    <Controller
                      control={control}
                      name="isCraneRadio"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <View>
                          <Text style={GlobalStyle.form__column__text}>Radio or Pendant ({isCraneRadio ? 'Radio' : 'Pendant'})</Text>
                          <Checkbox
                            style={GlobalStyle.form__column__input}
                            onValueChange={onChange}
                            value={isCraneRadio ? true : false}>
                          </Checkbox>
                        </View>
                      )}
                    />
                  </View>
                }
              </View>
            </View>
          }
          {selectedMasterCategoryName != null && selectedMasterCategoryName == "PUWER 1998" &&
            <View style={{ marginTop: 10 }}>
              <View style={styles.equipment__extra__list}>
                <View>
                  <EquipmentExtra
                    title={'Hoist(s)'}
                    extras={equipmentExtras.filter(e => e.equipmentExtraType == 0)}
                    onAddExtra={() => addEquipmentExtra(0)}
                    onRemoveExtra={(extra) => removeEquipmentExtra(extra)} />
                </View>

                <View>
                  <EquipmentExtra
                    title={'Motors Cross Travel(s)'}
                    extras={equipmentExtras.filter(e => e.equipmentExtraType == 1)}
                    onAddExtra={() => addEquipmentExtra(1)}
                    onRemoveExtra={(extra) => removeEquipmentExtra(extra)} />
                </View>

                <View>
                  <EquipmentExtra
                    title={'Motors Long Travel(s)'}
                    extras={equipmentExtras.filter(e => e.equipmentExtraType == 2)}
                    onAddExtra={() => addEquipmentExtra(2)}
                    onRemoveExtra={(extra) => removeEquipmentExtra(extra)} />
                </View>
              </View>
            </View>
          }

          {(Platform.OS == 'web' && isEdit) &&
            <View style={GlobalStyle.form__row}>
              <View style={GlobalStyle.form__column}>
                <Controller
                  control={control}
                  name="updateDOCHistory"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View>
                      <Text style={styles.form__label}>Update DOC History</Text>
                      <Text style={styles.form__label}>CAUTION: Checking this will force all historical DOC data to update.</Text>
                      <Checkbox
                        style={GlobalStyle.form__column__input}
                        onValueChange={onChange}
                        value={value}>
                      </Checkbox>
                    </View>
                  )}
                />
              </View>

              <View style={GlobalStyle.form__column}>
                <Controller
                  control={control}
                  name="updateHistory"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View>
                      <Text style={styles.form__label}>Update History</Text>
                      <Text style={styles.form__label}>CAUTION: Checking this will force all historical data to update.</Text>
                      <Checkbox
                        style={GlobalStyle.form__column__input}
                        onValueChange={onChange}
                        value={value}>
                      </Checkbox>
                    </View>
                  )}
                />
              </View>
            </View>
          }
          <View style={styles.form__buttons__container}>
            <TouchableOpacity style={[GlobalStyle.form__submit__button, styles.form__button]} onPress={handleSubmit(submit)}>
              <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
            </TouchableOpacity>
            {displayDuplicateButton &&
              <TouchableOpacity style={GlobalStyle.form__submit__button} onPress={handleSubmit(saveAndDuplicate)}>
                <Text style={GlobalStyle.form__submit__button__text}>SAVE & DUPLICATE</Text>
              </TouchableOpacity>}
          </View>
        </View>
      )}
    </View>
  );
}


const styles = StyleSheet.create({
  container: {
    display: "flex",
    margin: 0
  },

  form__rows: {
    marginRight: 5,
    flexDirection: "row",
    alignItems: "center"
  },

  form__label: {
    fontFamily: 'OpenSans-Light',
    fontWeight: 'bold',
    paddingTop: 10,
    fontSize: 14,
    flex: 1
  },

  form__input: {
    flex: 2,
    fontFamily: 'OpenSans-Regular',
    padding: 5,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#CECECE',
    marginTop: 5,
  },
  equipment__extra__list: {

  },
  equipment__extra__block: {
    flex: 1,
    margin: 5,
    marginBottom: 20
  },
  equipment__extra__title__block: {
    backgroundColor: colours.primary,
    padding: 10,
    flexDirection: 'row'
  },
  equipment__extra__title: {
    color: '#FFF',
    fontWeight: 'bold',
    flex: 1
  },

  equipment__extra__title__add__button__text: {
    color: '#FFF'
  },
  inputIOS: {
    fontSize: 18,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#CECECE',
    paddingRight: 30 // to ensure the text is never behind the icon
  },
  form__input__IOS: {
    flex: 2,
    fontFamily: 'OpenSans-Regular',
    padding: 5,
    marginTop: 5,
  },
  form__buttons__container: {
    display: "flex",
    flexDirection: "row",
    gap: 16
  },
  form__button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
});