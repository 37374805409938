import * as SQLite from 'expo-sqlite';
import { Database } from './database';
import { addColumnIfNotExist } from '../../../helpers/database-helpers';

export class LocationDatabase extends Database {
  TABLE_NAME = "Locations";
  TABLE_NAME_SUB_LOCATIONS = "SubLocations";

  initialise(): Promise<void> {
    return new Promise((resolve, reject) => {
      var sql = `CREATE TABLE IF NOT EXISTS Locations ( 
        id TEXT PRIMARY KEY,
        name TEXT,
        address1 TEXT,
        address2 TEXT,
        address3 TEXT,
        address4 TEXT,
        postcode TEXT,
        companyId TEXT,
        isArchived INT
      )`;

      var sqlSubLocations = `CREATE TABLE IF NOT EXISTS SubLocations ( 
        id TEXT PRIMARY KEY,
        name TEXT,
        locationId TEXT
      )`;

      Database.getDb().transaction((tx) => {
        tx.executeSql(sql);
        tx.executeSql(sqlSubLocations);

        addColumnIfNotExist(tx, "SubLocations", 'toBeSynced', 'INT');
        addColumnIfNotExist(tx, "Locations", "isArchived", "INT");
      }, (error) => {
        console.log(error)
        reject();
      }, () => {
        resolve();
      })
    });
  }

  insertOrUpdateList(entities: any): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME} (id, name, address1, address2, address3, address4, postcode, companyId, isArchived)
            VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?)
          `

          tx.executeSql(sql, [entity.id, entity.name, entity.address1, entity.address2, entity.address3, entity.address4, entity.postcode, entity.companyId, entity.isArchived]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  insertOrUpdateSubLocationsList(entities: any): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME_SUB_LOCATIONS} (id, name, locationId, toBeSynced)
            VALUES (?, ?, ?, ?)
          `

          tx.executeSql(sql, [entity.id, entity.name, entity.locationId, entity.toBeSynced]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  getAllLocations(): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM ${this.TABLE_NAME}
          WHERE isArchived = 0 or isArchived is null
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          //console.log(_array)
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    })
  }

  getAllSubLocations(): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM ${this.TABLE_NAME_SUB_LOCATIONS}
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          //console.log(_array)
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    })
  }
  getAllSubLocationsByLocation(locationId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM ${this.TABLE_NAME_SUB_LOCATIONS}
          WHERE locationId = ?
        `;

        tx.executeSql(sql, [locationId], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    })
  }

  getSubLocationsForSync(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        const sql = `
          SELECT * FROM SubLocations WHERE toBeSynced = 1
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    })
  }

  setSublocationsToBeSynced(sublocationId: any, toBeSynced: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
            UPDATE ${this.TABLE_NAME_SUB_LOCATIONS} SET toBeSynced = ? WHERE id = ?
          `

        tx.executeSql(sql, [toBeSynced, sublocationId]);
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  async addColumnIfNotExists(tableName, columnName, columnType): Promise<void> {
    const alterSql = `ALTER TABLE ${tableName} ADD COLUMN ${columnName} ${columnType}`;

    // Check if the column already exists
    const columnExists = await new Promise<boolean>((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        const query = `PRAGMA table_info(${tableName})`;
        tx.executeSql(query, [], (_, { rows }) => {
          for (let i = 0; i < rows.length; i++) {
            if (rows.item(i).name === columnName) {
              resolve(true);
              return;
            }
          }
          resolve(false);
        });
      }, (error) => {
        console.error(error);
        reject(error);
      });
    });

    if (!columnExists) {
      try {
        await new Promise((resolve, reject) => {
          Database.getDb().transaction((tx) => {
            tx.executeSql(alterSql, [], (_, { rowsAffected }) => {
              if (rowsAffected > 0) {
                console.log(`Column ${columnName} added to table ${tableName}`);
              } else {
                console.log(`Column ${columnName} already exists in table ${tableName}`);
              }
            });
          }, (error) => {
            console.error(error);
            reject(error);
          }, resolve);
        });
      } catch (error) {
        console.error('Unexpected error:', error);
        throw error;
      }
    }
  }
}
