import { createNativeStackNavigator } from '@react-navigation/native-stack';
import ManufacturerManageScreen from './ManufactureManage';
import ManufacturersListScreen from './ManufacturersList';

const Stack = createNativeStackNavigator();

export default function Manufacturers({ navigation }: { navigation: any }) {
  return (
    <Stack.Navigator>
      <Stack.Screen name="list" component={ManufacturersListScreen} options={{ headerShown: false, title: 'Manufacturers'  }} />
      <Stack.Screen name="manage" component={ManufacturerManageScreen} options={{ headerShown: false, title: 'Manufacturer Manage'}}  />
    </Stack.Navigator>
  )
};