import { baseApi, PagedTableDto } from './base-api'

export const customerApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCustomers: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `customers/getcustomers?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    getCustomer: build.query<any, any>({
      query: (categoryId) => ({
        url: `customers/getcustomer/${categoryId}`,
      }),
    }),
    getAllCustomers: build.query<any, any>({
      query: (companyId) => ({
        url: `customers/getallcustomers`,
      }),
    }),
    createCustomer: build.mutation<any, any>({
      query: (body) => ({
        url: 'customers/addcustomer',
        method: 'post',
        body: body
      }),
    }),
    updateCustomer: build.mutation<any, any>({
      query: (body) => ({
        url: `customers/updatecustomer/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    updateModules: build.mutation<any, any>({
      query: (body) => ({
        url: `customers/updatemodules`,
        method: 'put',
        body: body
      }),
    }),
    getModules: build.query<any, any>({
      query: () => ({
        url: `customers/getmodules`,
      }),
    }),
  }),
  overrideExisting: true,
})

export const { useGetCustomersQuery, useGetCustomerQuery, useGetAllCustomersQuery, useCreateCustomerMutation, useUpdateCustomerMutation, useUpdateModulesMutation, useGetModulesQuery } = customerApi